<template>
	<v-container fluid class="pa-0 mb-12">
    <v-alert :value="errorTitle!==''" :v-if="errorTitle!==''" type="error" dismissible flat class="notification" prominent v-model="alertErrorVisible"><span v-html="errorMessages"></span></v-alert>
    <v-alert :value="successTitle!==''" :v-if="successTitle!==''" type="success" dismissible flat class="notification" prominent v-model="alertSuccessVisible"><span v-html="successMessages"></span></v-alert>
    <loading :active.sync="isLoading" :is-full-page="fullPage" color="#4caf50"></loading>

    <!-- navbar -->
    <div class="navbar">
      <v-row align="center" class="flex-nowrap" style="white-space:nowrap; overflow:hidden;">
        <v-btn large id="btnBack" elevation="0" style="background-color:transparent;" @click="goback()">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-row justify="center">
          <h1 v-if="order">{{ order.order_id }}</h1>
          <!-- copy to clipboard icon -->
          <v-btn v-if="userIsOperator" id="btnCopy" icon elevation="0" @click="copyToClipboard(order.order_id)">
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </v-row>
      </v-row>

      <!-- have a print icon on the top right end of the navbar -->
      <v-btn class="my-2" id="btnPrint" icon elevation="0" @click="printOrder">
        <v-icon>mdi-printer</v-icon>
      </v-btn>
    </div>

    <v-row :style="showImpersonationInfo ? 'padding-top: 24px !important;' : 'padding-top:0px'">
			<v-col cols="12">
        <!-- Order Info -->
        <v-row justify="center">
            <v-card style="width:100%" class="elevation-4 pa-5">
              <v-card-text class="pa-0">
                  <h1>{{$t('text.orderInfoTitle')}}</h1>
          
                  <div class="field left-border">
                    <v-label>{{$t('text.orderDate')}}</v-label>
                    <p style="color:#000000;">{{order.order_date | formatDateTime}}</p>      
                  </div>
                
                  <div class="field left-border">
                    <v-label>{{$t('text.orderStatus')}}</v-label>
                    <p style="color:#000000;">{{getStatusLabel(order.status)}}</p>
                  </div>

                  <div class="field left-border">
                    <v-label>{{$t('text.boughtAt')}}</v-label>
                    <p style="color:#000000;">{{order.client_name}}</p>
                    <p style="color:#000000;" v-if="order.store">{{order.store.name}}</p>
                  </div>
             
                  <div class="field left-border">
                    <v-label>{{$t('text.paymentInfo')}}</v-label>
                    <p style="color:#000000;">{{order.payment_merchant}}</p>
                    <p style="color:#000000;">{{order.payment_method}}</p>
                    <p style="color:#000000;">{{$t(`text.paidIn`)}} {{getCurrency()}}</p>
                  </div>
                  <br/>
              </v-card-text>
            </v-card>
        </v-row>

        <br/>

        <!-- User Account Info -->
        <v-row justify="center">
           <v-card style="width:100%" class="elevation-4 pa-5">
              <v-card-text class="pa-0">
                <h1>{{$t('text.userAccountInfoTitle')}}</h1>

                <div class="field left-border">
                  <v-label>{{$t('text.clientName')}}</v-label>
                  <p style="color:#000000;">{{ order.firstname }} {{ order.lastname }}</p>
                </div>

                <div class="field left-border">
                  <v-label>{{$t('text.email')}}</v-label>
                  <p style="color:#000000">{{ order.email }}</p>
                </div>

                <div class="field left-border">
                  <v-label>{{$t('text.invoiceAddress')}}</v-label>
                  <p style="color:#000000;">{{order.street_address}}</p>
                  <p style="color:#000000;">{{order.zip}} {{order.city}}</p>
                  <p style="color:#000000;" v-if="order.country">{{order.country.name[selectedLocale]}}</p>
                </div>
              </v-card-text>
            </v-card>
        </v-row>

        <br/>

        <!-- Order Items -->
        <v-row justify="center">
            <v-card style="width:100%" class="elevation-4 pa-5">
              <v-card-text class="pa-0">
                  <h1>{{$t('text.itemsTitle')}}</h1>
                  <v-data-table
                      :headers="headers"
                      :items="order.orderItems"
                      fixed-header
                      hide-default-footer
                      class="elevation-0"
                      :items-per-page="50"
                      disable-sort
                      :mobile-breakpoint="950">

                    <template v-slot:item="{ item }">
                      <tr @click="showItemDetail(item)">
                        <td>{{ item.product_name }}</td>
                        <td>{{ getStatusLabel(item.status) }}</td>
                        <td align="right">{{ getCurrency() }} {{ formatPrice(item.price) }}</td>
                        <td align="right">{{ formatPrice(item.quantity) }}</td>
                        <td align="right">{{ getCurrency() }} {{ formatPrice(item.tax_amount) }}</td>
                        <td align="right">{{ formatPrice(item.tax_percentage) }}%</td>
                        <td align="right">{{ getCurrency() }} {{ formatPrice(item.discount) }}</td>
                        <td align="right">{{ getCurrency() }} {{ formatPrice(item.price * item.quantity) }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                  <br/>
              </v-card-text>
            </v-card>
        </v-row>
      </v-col>
    </v-row>

    <Dialog ref="itemDetailDialog"
            :confirmLabel="null"
            :cancelLabel="null"
            :title="$t('text.orderItemDetail')"
            width="750px"
            height="70%">
			<template #content>
				<div style="padding:10px">
          <v-alert :value="idCopied!==false" :v-if="idCopied!==false" type="success" dismissible flat class="notification" prominent v-model="idCopied"><span v-html="$t('text.textCopied')"></span></v-alert>
          <h1><b>{{selectedItem.product_name}}</b></h1><br/>
          <span class="evenWidth"><b>SKU:</b></span> {{selectedItem.sku}}
          <br/><br/>

          <div v-if="selectedItem.product_data">
              <div><h3><b>{{$t('text.productInfo')}}</b></h3></div>
              <div><span class="evenWidth" style="line-height:2"><b>{{$t('text.consumptionStartDate')}}:</b></span>{{selectedItem.product_data.consumptionDateStart}}</div>
              <div v-if="selectedItem.product_data.consumptionTimeStart">
                <span class="evenWidth" style="line-height:2"><b>{{$t('text.consumptionStartTime')}}:</b></span>{{selectedItem.product_data.consumptionTimeStart}}
              </div>

              <div v-if="userIsOperator">
                <div><span class="evenWidth"><b>Skipass order ID:</b></span>{{selectedItem.skisystem_data.order_id}}
                <v-btn id="btnCopy" icon elevation="0" @click="copyToClipboard(selectedItem.skisystem_data.order_id)">
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </div>

              <div v-if="selectedItem.skisystem_data.keycard"><span class="evenWidth"><b>Skipass keycard:</b></span>{{selectedItem.skisystem_data.keycard}}
                <v-btn id="btnCopy" icon elevation="0" @click="copyToClipboard(selectedItem.skisystem_data.keycard)">
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                
                <v-btn id="redirect" icon elevation="0" @click="redirect('keycard', selectedItem.skisystem_data.keycard)">
                  <v-icon>mdi-open-in-new</v-icon>
                </v-btn>
              </div>

              <div v-if="selectedItem.skisystem_data.permission_id"><span class="evenWidth"><b>Skipass permission ID:</b></span>{{selectedItem.skisystem_data.permission_id}}
                <v-btn id="btnCopy" icon elevation="0" @click="copyToClipboard(selectedItem.skisystem_data.permission_id)">
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                
                <v-btn id="redirect" icon elevation="0" @click="redirect('permissionId', selectedItem.skisystem_data.permission_id)">
                  <v-icon>mdi-open-in-new</v-icon>
                </v-btn>
              </div>

              <div><span class="evenWidth"><b>Skipass order reference:</b></span>{{selectedItem.skisystem_data.order_reference}}
                  <v-btn id="btnCopy" icon elevation="0" @click="copyToClipboard(selectedItem.skisystem_data.order_reference)">
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                
                <v-btn id="redirect" icon elevation="0" @click="redirect('orderId', selectedItem.skisystem_data.order_reference)">
                  <v-icon>mdi-open-in-new</v-icon>
                </v-btn>
              </div>
              </div>
           </div><br/>

           <div>
             <v-col v-for="person of selectedItem.person_data" :key="person.firstName" style="padding:0px">
            <div><h3><b>{{$t('text.contactPerson')}}</b></h3></div>
              <div style="line-height:2">
                <span class="evenWidth"><b>{{$t('text.firstName')}}:</b></span>
                <span class="evenWidth">{{person.firstName}}</span>
              </div>

              <div style="line-height:2">
                <span class="evenWidth"><b>{{$t('text.lastName')}}:</b></span>
                <span class="evenWidth">{{person.lastName}}</span>
              </div>
            </v-col><br/>
        </div>

        <span v-if="selectedItem.category !== 'SKIPASS'">
          <v-col v-for="person of selectedItem.person_data" :key="person.firstName" style="padding:0px">
            <div><h3><b>{{$t('text.contactPerson')}}</b></h3></div>
            <v-col v-for="attribute of Object.keys(person.additionalPersonalization)" :key="person.additionalPersonalization[attribute]" style="padding:0px">
              <div v-if="attribute==='firstName'">
                <span class="evenWidth"><b>{{$t('text.firstName')}}:</b></span>
                <span class="evenWidth">{{person.additionalPersonalization[attribute]}}</span>
              </div>

              <div v-if="attribute==='lastName'">
                <span class="evenWidth"><b>{{$t('text.lastName')}}:</b></span>
                <span class="evenWidth">{{person.additionalPersonalization[attribute]}}</span>
              </div>
            </v-col><br/>

             <div v-if="person.additionalPersonalization && Object.keys(person.additionalPersonalization).length > 0">
               <h3><b>{{$t('text.additionalPersonalisations')}}</b></h3>
              </div>
              <v-col v-for="attribute of Object.keys(person.additionalPersonalization)" :key="attribute" style="padding:0px">
              <div v-if="attribute !== 'lastName' && attribute!== 'firstName'">
                <span class="evenWidth"><b>{{mapAttributeToProperName(attribute, person.additionalPersonalization[attribute])}}:</b></span>
                <span v-if="person.additionalPersonalization[attribute].value">{{person.additionalPersonalization[attribute].value}}</span>
                <span v-if="!person.additionalPersonalization[attribute].value">{{person.additionalPersonalization[attribute]}}</span>
              </div>
            </v-col>
          </v-col> 
        </span>
        </div>
			</template>
		</Dialog>
  </v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Dialog from '@/components/common/Dialog.vue'
import Common from '@/mixins/Common.vue'

export default {
  name: 'OrderDetail',
  components: { Loading, Dialog },
  mixins: [Common],
  data () {
    return {
      isLoading: false,
      fullPage: true,
      errorTitle: '',
      errorDetail: '',
      successTitle: '',
      successDetail: '',
      alertErrorVisible: false,
      alertSuccessVisible: false,

      selectedItem: {},
      isView: true,

      order: {},
      idCopied: false,
    }
  },

  computed: {
    headers () {
      return [
        { text: this.$t('text.productName'), value: "name" },
        { text: this.$t('text.status'), value: "status" },
        { text: this.$t('text.price'), value: "price", align: 'right' },
        { text: this.$t('text.amount'), value: "quantity",  align: 'right' },
        { text: this.$t('text.taxesAmount'), value: "tax_amount", align: 'right' },
        { text: this.$t('text.taxesPercentage'), value: "tax_percentage", align: 'right' },
        { text: this.$t('text.discount'), value: "discount", align: 'right' },
        { text: this.$t('text.total'), value: "total", align: 'right' }
      ]
    },
    errorMessages() {
      return ' <span style="font-size:14pt;font-weight:bold;"> ' + this.errorTitle + ' </span><br> ' + this.errorDetail;
    },
    successMessages() {
      return ' <span style="font-size:14pt;font-weight:bold;"> ' + this.successTitle + ' </span><br> ' + this.successDetail;
    },
  },

  watch: {
    idCopied() {
      if (this.idCopied === true) {
        setTimeout(this.closeAlert, 2000)
      }
    }
  },

  mounted() {
    this.getOrderDetail();
  },

  methods: {
    closeAlert() {
      this.idCopied = false
    },
    getStatusLabel(status) {
      if (status && Object.keys(status).length > 0) {
        if (parseInt(status.refunded) === 1) {
          return this.$t('text.Refunded')
        } else if (parseInt(status.canceled) === 1) {
          return this.$t('text.Canceled')
        } else if (parseInt(status.shipped) === 1) {
          return this.$t('text.Shipped')
        } else if (parseInt(status.invoiced) === 1) {
          return this.$t('text.Invoiced')
        } else if (parseInt(status.ordered) === 1) {
          return this.$t('text.Ordered')
        }
      }
    },
    getCurrency() {
      return this.order.orderItems && this.order.orderItems.length > 0 ? this.order.orderItems[0].currency : ''
    },
    onDialogClose() {
			this.$refs.itemDetailDialog.show = false
		},
    showItemDetail(item) {
      this.selectedItem = item
      this.$refs.itemDetailDialog.show = true
    },
    mapAttributeToProperName(attributeName, attributeObj) {
      let finalResult = ''

      if (!attributeObj?.title) {
        const result = attributeName.replace( /([A-Z])/g, " $1" );
        finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      } else {
        finalResult = attributeObj?.title?.[this.selectedLocale]
      }
     
      return finalResult
    },
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    goback() {
      this.userIsOperator ? this.$router.push('/orders?viewType=client') : this.$router.push('/orders?viewType=sp')
    },
    async getOrderDetail() {
      this.isLoading = true;

      const res = await this.$httpGet(`/order`, {
        serviceProviderId: this.$store.state.selectedOrder.service_provider_id,
        clientId: this.$store.state.selectedOrder.client_id,
        orderId: this.$store.state.selectedOrder.order_id
      })

      this.order = res.order
      this.isLoading = false
    },
    copyToClipboard(copyText) {
      navigator.clipboard.writeText(copyText)
      this.idCopied = true
    },

    redirect(type, id) {
     switch(type) {
      case 'orderId':
        window.open('http://10.16.4.1/OnlineApps/TicketTracking/default.html?orderId=' + id, '_blank')
        break
      case 'permissionId':
        window.open('http://10.16.4.1/OnlineApps/TicketTracking/default.html?permissionId=' + id, '_blank')
        break
      case 'keycard':
        window.open('http://10.16.4.1/OnlineApps/TicketTracking/default.html?datacarrierId=' + id, '_blank')
        break
     }
    },
    printOrder() {
      // print the order detail page in landscape mode (because vertical mode looks horrendous)
      var css = '@page { size: landscape; }',
      head = document.head || document.getElementsByTagName('head')[0],
      style = document.createElement('style');

      style.type = 'text/css';
      style.media = 'print';

      if (style.styleSheet){
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }

      head.appendChild(style);

      window.print();
    }
  }
};
</script>

<style scoped>
.evenWidth {
  display: inline-block;
  width:300px;
}
#card {
  width: 100%
}
.container-grid { padding: 20px; }

/* you can use this to hide specific elements from the print view of the page */
@media print {
  #printPageButton {
    display: none;
  }
}

</style>