import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import Vuelidate from 'vuelidate'
import VueFilePicker from 'vue-file-picker'
import Router from "vue-router"
import router from './router/router'
import axios from "axios"
import VueAxios from "vue-axios"
import Vuex from 'vuex'
import moment from 'moment'
import store from './stores/store.js'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import 'vuetify/dist/vuetify.min.css'
import VueMask from 'v-mask'
import VuePapaParse from 'vue-papa-parse'
import { translationUtil } from './utils/translation-util'
import VueI18n from 'vue-i18n'

import '@/assets/css/materialdesignicons.min.css';

Vue.config.productionTip = false

// we need this to enable the communication between two child components without the parent caring about it (ticket and ticket personalisations)
export const eventBus = new Vue()

Vue.use(Vuelidate);
Vue.use(Router);
Vue.use(VueAxios, axios);
Vue.use(Vuex);
Vue.use(VueFilePicker);
Vue.use(VueI18n);
Vue.use(VueMask);
Vue.use(VuePapaParse);

let host = window.location.origin;

Vue.axios.defaults.baseURL = `${host}/api`;

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY')
  }
})

Vue.filter('formatDateTime', function(value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY HH:mm')
  }
})

Vue.filter('formatPrice', function(value) {
  if (value) {
    return value
  }

  return '0.00'
})

Vue.filter('truncate', function (text, length, suffix) {
  if (text.length > length) {
      return text.substring(0, length) + suffix;
  } else {
      return text;
  }
});

Vue.use(TiptapVuetifyPlugin, {
  vuetify, 
  iconsGroup: 'mdi'
})

async function init() {
  await store.init()
  const i18n = await translationUtil.init(store)
  new Vue({
    vuetify,
    router: router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')
}
init()