<template>
<v-container class="pa-0 mb-12" fluid>
	<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
	<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
	<loading :active.sync="isLoading" :is-full-page="true" color="#4caf50"></loading>

	<!-- Navbar -->
	<div class="navbar">
		<v-row align="center" class="flex-nowrap" style="overflow:hidden;">
			<v-btn id="btnBack" elevation="0" style="background-color:transparent;" @click="goback()"><v-icon>mdi-chevron-left</v-icon></v-btn>
			<v-row justify="center" style="overflow:hidden;">
				<h1>{{ title }}</h1>
			</v-row>
      <v-row justify="end" class="flex-nowrap">
        <v-btn class="gradientButton mr-2" elevation="0" @input="noToggle($event)" data-cy="addTicketType" @click="showAddOptionDialog('ticketType')">
          <v-icon size="24px">mdi-plus</v-icon>
          <span class="d-none d-lg-inline">{{this.$t('text.addTicketType')}}</span>
          <span class="d-lg-none">{{this.$t('text.type')}}</span>
        </v-btn>
        <v-btn class="gradientButton" elevation="0" @input="noToggle($event)" data-cy="addReduction" @click="showAddOptionDialog('reduction')">
          <v-icon size="24px">mdi-plus</v-icon>
          <span class="d-none d-lg-inline">{{this.$t('text.addReduction')}}</span>
          <span class="d-lg-none">{{this.$t('text.category')}}</span>
        </v-btn>
      </v-row>
		</v-row>
	</div>

	<div class="container-grid">
	  <!-- TicketTypes for service provider in client options-->
    <div class="pa-3">
      <v-card class="tableCard">
        <v-data-table fixed-header hide-default-footer disable-sort
          :headers="ticketHeaders"
          :items="ticketTypes"
          :items-per-page="999999"
          class="elevation-0">
           <template v-slot:item="{ item }">
            <tr :class="{clientOption: item.source === 'CLIENT'}" @click="!item.source ? showEditOptionDialog(item, 'ticketType') : ''" :key="item.sys.id">
              <td>{{ returnTitle(item) }}</td>
              <td>
               <v-chip v-if="item.fields.status" :color="getColor(item.fields.status)" dark>{{$t(`text.${item.fields.status}`)}}</v-chip>
               <v-chip v-else :color="getColor('active')" dark>{{$t(`text.active`)}}</v-chip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </div>

    <!-- Reductions for service provider in client options-->
    <div class="pa-3">
      <v-card class="tableCard">
        <v-data-table fixed-header hide-default-footer disable-sort
          :headers="reductionHeaders"
          :items="reductions"
          :items-per-page="999999"
          class="elevation-0">
           <template v-slot:item="{ item }">
            <tr :class="{clientOption: item.source === 'CLIENT'}" @click="!item.source ? showEditOptionDialog(item, 'reduction') : ''" :key="item.sys.id">
              <td><v-icon v-if="item.defaultAssignment"> mdi-minus-circle</v-icon>    {{ returnTitle(item) }}</td>
              <td>
               <v-chip v-if="item.fields.status" style="margin-right:10px" :color="getColor(item.fields.status)" dark>{{$t(`text.${item.fields.status}`)}}</v-chip>
               <v-chip v-else style="margin-right:10px" :color="getColor('active')" dark>{{$t(`text.active`)}}</v-chip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>

    <Dialog  ref="serviceAddOptionDialog"
      :title="addOptionTitle"
      :confirmLabel="$t('text.addOption')"
      :cancelLabel="$t('text.discardChanges')"
      :confirm-handler="addOption"
      :cancel-handler="resetOption" 
	  :show-close="false">
      <template #content>
        <v-row>
          <v-col>
            <v-row v-for="(value, propertyName) in name" :key="propertyName">
              <div class="col-2 col-lg-1 pa-0"><img :src="getFlagIcon(propertyName)" width="24px" height="24px" style="margin-top:12px;margin-right:5px"/></div>
              <v-text-field class="col-10 col-lg-11" data-cy="addOptionField" outlined v-model="name[propertyName]"></v-text-field>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </Dialog>

    <Dialog ref="serviceEditOptionDialog"
      :title="editOptionTitle"
      :confirmLabel="$t('text.saveChanges')"
      :cancelLabel="$t('text.discardChanges')"
      :deleteLabel="$t('text.deleteOption')"
      :confirm-handler="editOption"
      :cancel-handler="resetOption"
      :delete-handler="deleteOption"
	  :show-close="false">
      <template #content>
        <v-row>
          <v-col>
            <v-row v-for="(value, propertyName) in editItem.fields.title" :key="propertyName">
              <div class="col-2 col-lg-1 pa-0"><img :src="getFlagIcon(propertyName)" width="24px" height="24px" style="margin-top:12px;margin-right:5px"/></div>
              <v-text-field class="col-10 col-lg-11" data-cy="editOptionField" outlined v-model="editItem.fields.title[propertyName]"></v-text-field>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </Dialog>

    <br/>

  </v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Alert from '@/components/common/Alert.vue'
import Dialog from '@/components/common/Dialog'
import Common from '@/mixins/Common.vue'


export default {
	name: 'ServiceOptionsForProvider',
	components: { Loading, Alert, Dialog },
  	mixins: [ Common ],
	props: {
		serviceProviderClicked: Boolean,
		title: String,
	},

	data() {
		return {
			itemType: 'all',
			serviceOptions: [],
			ticketTypes: [],
			reductions: [],

			isLoading: false,

			errorTitle: '',
			errorDetail: '',
			successTitle: '',
			successDetail: '',

			optionType: '',

			name: {},
			editItem: {},
			originalItem: {},

			toggle_none: null,
		}
	},
	computed: {
		ticketHeaders () {
			return [
				{
					text: this.$t('text.ticketTypes'),
					align: 'left',
					sortable: true,
					value: 'item_type'
				},
				{ text: 'Status', width:'100px' }
			]
		},

		reductionHeaders () {
			return [
				{ text: this.$t('text.reductions'), sortable: true, value: 'item_type' },
				{ text: 'Status', width:'100px', value: 'status' }
			]
		},

		editOptionTitle() {
			if (this.optionType === 'ticketType') {
				return this.$t('text.editTicketType')
			} else if (this.optionType === 'reduction') {
				return this.$t('text.editReduction')
			}
			return ''
		},

		addOptionTitle() {
			if (this.optionType === 'ticketType') {
				return this.$t('text.addTicketType')
			} else if (this.optionType === 'reduction') {
				return this.$t('text.addReduction')
			}
			return ''
		}
	},

	async mounted() {
		await this.getProviderOptions()
	},

	methods: {
		goback() {
			this.$emit('go-back')
		},
		noToggle(event){
			this.toggle_none = event
		},
		returnTitle (item) {
			return item.fields?.title ? item.fields?.title[this.selectedLocale] : item.fields?.title.de ? item.fields?.title.de : 'NO_TITLE'
		},
		mergeOptions(spOptions, clientOptions) {
			let lookup = {}
			if (spOptions) {
				for (let o of spOptions)
				lookup[o.sys.id] = o
			}
			if (clientOptions) {
				for (let o of clientOptions) {
				if (!o.source) o['source'] = 'CLIENT'
				lookup[o.sys.id] = o
				}
			}
			return Object.values(lookup)
		},

		async getProviderOptions() {
			try {
				this.isLoading = true

				let res = await this.$httpGet(`/provider-option?id=${this.$store.state.selectedServiceProvider.sys.id}&itemType=${this.itemType}&locale=${this.selectedLocale}`)

				this.ticketTypes = res.options.ticketType ?? []
				this.reductions = res.options.reduction ?? []

				await this.getServiceProvider()

				this.isLoading = false
			}
			catch(error) {
				this.isLoading = false
				this.errorTitle = this.$t('text.ERROR');
				if (error.response) {
				this.errorDetail = error.response.data.error;
				}
				else {
				this.errorDetail = error;
				}
			}
		},
		async getServiceProvider() {
			var id = this.$store.state.selectedServiceProvider?.sys?.id
			if (!id) return

			try {
				const res = await this.$httpGet(`/serviceprovider/${id}`)
				
				await this.$store.commit('setSelectedServiceProvider', res.serviceProvider)
			}
			catch (error) {
				if (error.response?.status === 401) return this.$emit("show-login")
				console.error(error)
				this.showError(error.response ? error.response.data.error : error)
			}
			this.loading = false
		},
		showAddOptionDialog(itemType) {
			this.clear();
			this.optionType = itemType
			this.$refs.serviceAddOptionDialog.show = true;
		},
		showEditOptionDialog(item, type) {
			if (item && !this.isChangeDefault) {
				//Fill in missing locales
				for (const locale of this.locales) {
				if (!item.fields.title[locale.code]) {
					item.fields.title[locale.code] = ''
					}
				}
				this.optionType = type
				this.originalItem = JSON.parse(JSON.stringify(item));
				this.editItem = JSON.parse(JSON.stringify(item));
				this.$refs.serviceEditOptionDialog.show = true
			}
		},
		clear() {
			this.errorTitle = ""
			this.errorDetail = ""
			this.successTitle = ""
			this.successDetail = ""

			this.requestSubmitted = false

			this.resetOption()
		},
		resetOption() {
			for (const locale of this.locales) {
				this.name[locale.code] = ''
			}
			this.editItem = JSON.parse(JSON.stringify(this.originalItem));
		},
		showError(detail, title) {
			console.warn('showError', detail, new Error())
			this.errorTitle = title ?? this.$t('text.ERROR')
			this.errorDetail = detail
		},
		getColor (status) {
			if (status === "pending")  {
				return '#ff9e21'
			} else if (status === "active") {
				return '#66BB6A'
			}
		},
		getFlagIcon(locale) {
			if (locale === "de") {
				return this.getGermanFlagIcon()
			} else {
				const images = require.context('@/assets/flags/', false, /\.svg$/)
				return images('./' + locale + ".svg")
			}
		},
		getGermanFlagIcon() {
			var images = require.context('@/assets/flags/', false, /\.svg$/)

			if (this.$store.state.userSpecificGerman && this.$store.state.userSpecificGerman !== "") {
				return images('./' + this.$store.state.userSpecificGerman + ".svg")
			} else {
				return images('./' + "de.svg")
			}
		},

		addOption() {
			this.isLoading = true;
			let data
			// service provider adds option
			data = {
				itemType: this.optionType,
				name: this.name,
				serviceProviderId: this.$store.state.selectedServiceProvider.sys.id,
				clientId: this.$store.state.selectedClient.sys.id
			}

			this.$httpPost(`/option`, data).then(res => {
				this.clear()

				this.$refs.serviceAddOptionDialog.show = false

				this.successTitle = this.$t('text.SERVICE_OPTION_CREATED')
				this.successDetail = this.$t('text.serviceOptionCreatedSuccess')

				this.getProviderOptions()

				return true
			}).catch(error => {
				this.isLoading = false;
				this.errorAlertVisible = true;

				this.$refs.serviceAddOptionDialog.show = false;

				this.errorTitle = this.$t('text.ERROR');

				if (error.response) {
				if (error.response.data.error) {
					error.response.data.error === 'Please provide at least one name for the option' ? this.errorDetail = this.$t('text.optionHasNoName') : this.errorDetail = error.response.data.error
					console.error(error)
				}
				} else {
				this.errorDetail = error;
				}
			});
		},
		// TODO: needs to be adjusted for sp only
		editOption() {
			this.isLoading = true;

			let data

			// service provider edits option
			data = {
				item: this.editItem,
				clientId: this.$store.state.selectedClient.sys.id,
				serviceProviderId: this.$store.state.selectedServiceProvider.sys.id
			}

			this.$httpPut(`/option`, data).then(res => {
				this.clear()

				this.$refs.serviceEditOptionDialog.show = false

				this.successTitle = this.$t('text.SERVICE_OPTION_UPDATED')
				this.successDetail = this.$t('text.serviceOptionUpdatedSuccess')

				this.getProviderOptions()
				return true

			}).catch(error => {
				if (error.response?.status == 401) return this.$emit("show-login")
					this.showError(error.response ? error.response?.data.error : error)
			})
		},
		// TODO: needs to be adjusted for sp only
		deleteOption() {
			this.isLoading = true

			this.axios.delete(`/option`, {
				headers: this.requestHeaders(),
				data: {
					option: this.originalItem,
					clientId: this.$store.state.selectedClient.sys.id,
					serviceProviderId: this.$store.state.selectedServiceProvider.sys.id
				}
			}).then(res => {
				this.isLoading = false
				this.$refs.serviceEditOptionDialog.show = false

				if (res?.message === 'cannot delete option') {
					this.errorTitle = this.$t('text.ERROR')
					this.errorDetail = this.$t('text.serviceOptionInUseError')
				} else {
					this.successTitle = this.$t('text.OPTION_REQUEST')
					this.successDetail = this.$t('text.optionRequestDeleteSuccess')

					this.getProviderOptions()
				}

			}).catch(error => {
				if (error.response?.status == 401) return this.$emit("show-login")
					this.showError(error.response ? error.response?.data.error : error)
			});
		},
	}

}

</script>

<style scoped>

.tableCard {
  border-radius:5px;
  background-color:#808080 !important;
  top: 0px;
}
.clientOption {
  cursor: not-allowed;
  background-color: #f2f2f2 !important;
}
.dialogDiv {
  position:absolute;
  right:70px;
  top:55px;
  width:465px;
  max-width: calc(100vw - 20px);
  background:#ffffff;
  border-radius:5px;
  border:thin solid #cfcfcf;
  box-shadow:1px 1px 15px 0 rgba(0,0,0,0.2);
  padding:20px;
  padding-bottom:0px;
  color:#acacac;
  z-index: 999;
}
.optionRow {
  min-height: 42px;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #f2f2f2;
  margin-top: 10px;
  margin-bottom: 10px;
}

.buttonGroup {
	margin-top:-5px;
	height:20px;
	background: linear-gradient(0deg, #efefef 0%, #ffffff 100%);
	font-family: 'Inter', sans-serif;
	font-size: 12pt ;
	text-transform: none;
	letter-spacing: 0;
}

</style>