<template>
	<v-container class="main" fluid fill-height :style="{ 'background-image': backgroundImage }">
		<loading :active.sync="isLoading" :is-full-page="true" color="#4caf50"></loading>

		<!-- Nav Bar -->
		<v-row class="navbar pa-0 px-2 flex-nowrap" align="center" style="white-space:nowrap; overflow:hidden;">
			<h1>{{titleText}}</h1>
			<v-spacer/>
			<v-combobox readonly outlined hide-details
				v-if="isClientsVisible"
				style="max-width:300px"
				v-model="selectedClient"
				:items="clients"
				:menu-props="{openOnClick: true}"
				item-text="fields.title.de"
				item-value="fields.title.de"/>

			<p class="mr-3" v-if="isProvidersVisible">Operate as</p>

			<v-combobox readonly outlined hide-details
				v-if="isProvidersVisible"
				style="max-width:300px"
				v-model="selectedServiceProvider"
				:menu-props="{openOnClick: true}"
				:items="linkedServiceProviders"
				item-text="fields.title.de"
				item-value="fields.title.de"/>
		</v-row>

		<!-- Content -->
		<v-row align="center" justify="center">
			<v-col>
				<Grid
					:draggable="isCustomiseDashboard"
					:sortable="true"
					:items="serviceComponents"
					:cell-size="cellSize"
					@sort="sort"
				>
					<template slot="cell" slot-scope="props">
						<ComponentCard
							:data-cy="props.item.fields.link.de"
							:componentCard="props.item"
							:isCustomiseDashboard="false"
							@view-component="$emit('view-component',props.item)"
						/>
					</template>
				</Grid>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Grid from './grid/Grid.vue'
import ComponentCard from './ComponentCard.vue'
import FreshdeskWidget from '@/utils/FreshdeskWidget'
import Common from '@/mixins/Common.vue'

export default {
	name: 'Dashboard',
	components: { Loading, ComponentCard, Grid },
	mixins: [ Common ],

	data() {
		return {
			serviceComponents: [],
			isCustomiseDashboard: false,
			isLoading: false,
		}
	},

	computed: {
		backgroundImage() {
			if (!this.$store.state.selectedClient?.fields?.backgroundImage?.de?.sys) {
        return 'url(/myservices_background.jpg)'
      }
			return 'url(' + this.$store.state.selectedClient.fields.backgroundImage.de.fields.file.de.url + ')'
		},
		isClientsVisible() {
			return this.$store.state.loggedInUser?.fields?.type?.de === 'operator'
				&& !this.$store.state.isImpersonation
				&& this.$store.state.loggedInUser.fields.clients?.de?.length > 1
		},
		isProvidersVisible() {
			if (this.$store.state.loggedInUser?.fields?.type?.de === "serviceprovider")  {
				return this.$store.state.loggedInUser?.fields?.serviceProvider?.de?.fields.linkedServiceProviders?.de?.length >= 1
			} else if (this.$store.state.loggedInUser?.fields?.type?.de === 'operator') {
				return this.$store.state.selectedServiceProvider?.fields?.linkedServiceProviders?.de?.length >= 1
			} else {
				return false
			}
		},
		titleText() {
			if (this.$store.state.isImpersonation) {
        return this.$store.state.selectedServiceProvider?.fields.title.de ?? ''
      }
			return ''
		},
		clients() {
			let clients = this.$store.state.loggedInUser.fields.clients.de
			clients.sort((a, b) => { return a.fields.title.de.localeCompare(b.fields.title.de) })
			return clients
		},
		linkedServiceProviders() {
			var linkedServiceProviders = []
			const sp = this.$store.state.loggedInUser?.fields?.type?.de === "serviceprovider" ? this.$store.state.loggedInUser.fields?.serviceProvider?.de : this.$store.state.impersonatedServiceProvider
			
			if (sp) {
				linkedServiceProviders.push(sp)
				if (sp.fields.linkedServiceProviders?.de) {
					for (var lsp of sp.fields.linkedServiceProviders.de) {
						linkedServiceProviders.push(lsp)
					} 
				}
			}
			
			return linkedServiceProviders
		},
		selectedClient: {
			get: function () {
				return this.$store.state.selectedClient;
			},
			set: function (newClient) {
				this.$root.$emit('change-client', newClient);
			}
		},
		selectedServiceProvider: {
			get: function () {
				return this.$store.state.selectedServiceProvider;
			},
			set: function (newServiceProvider) {
				this.$root.$emit('change-service-provider', newServiceProvider);
			}
		},
    cellSize () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 150
        case 'sm': return 180
        case 'md': return 215
        case 'lg': return 225
        case 'xl': return 250
        default: return 200
      }
    }
	},
	
	watch: {
		'$store.state.selectedLocale': function() {
			this.selectedLocale = this.$store.state.selectedLocale
			FreshdeskWidget.install(this.$store)
		}
	},

	async mounted() {
		const maintenanceMode = await this.checkMaintenanceMode()
		
		if (maintenanceMode === true) {
			this.$emit("show-login")
		} else {
			this.$store.state.filter = null
			await this.$store.commit('setSelectedProduct', null)

			if (this.$store.state.loggedInUser) {
				if (Object.entries(this.$store.state.loggedInUser).length === 0
					|| this.tokenIsExpired(this.$store.state.loggedInUser.kc_token)) {
					this.showLogin()
					return
				}

				if (this.userIsOperator) {
					await this.$store.commit('setSelectedServiceProvider', null);
				}

				if (this.$store.state.loggedInUser.fields?.type?.de === 'operator' && this.$store.state.isImpersonation === true) {
					this.serviceComponents = this.$store.state.selectedServiceProvider.fields?.mainUserAccount?.fields?.applications?.de
				} else {
					this.serviceComponents = this.$store.state.loggedInUser.fields?.applications?.de
				}

				await this.$store.commit('setComponents', this.serviceComponents);
			}

			this.$root.$on('change-client', async (selectedClient) => {
				await this.getClient(selectedClient.sys.id)
			})

			this.$root.$on('change-service-provider', (selectedServiceProvider) => {
				this.isLoading = true
				
				if (selectedServiceProvider) {
					this.$httpGet(`/serviceprovider/${selectedServiceProvider.sys.id}`).then(async res => {
					if (selectedServiceProvider.sys.id !== this.$store.state.selectedServiceProvider.sys.id) {
						await this.$store.commit('setIsLinkedProvider', true);
						await this.$store.commit('setIsImpersonation', true);
					} else {
						await this.$store.commit('setIsLinkedProvider', false);
						await this.$store.commit('setIsImpersonation', false);
					}

					if (res.serviceProvider.fields.mainUserAccount) {
						this.serviceComponents = res.serviceProvider.fields.mainUserAccount.fields?.applications?.de
						await this.$store.commit('setComponents', this.serviceComponents);
					}

					await this.$store.commit('setSelectedServiceProvider', res.serviceProvider)

					this.isLoading = false
					window.setTimeout(() => { this.$router.go() }, 500)
					})
					.catch(error => {
					console.error(error)
					console.error(JSON.stringify(error))
					this.isLoading = false
					this.errorTitle = this.$t('text.ERROR');

					if (error.response) {
						if (error.response.status === 401) {
						this.$emit("show-login")
						}

						this.errorDetail = error.response.data.error;
					} else {
						this.errorDetail = error;
					}
					}); 
				}
			})
		
		}
	},

	created () {
		FreshdeskWidget.prefill('MyServices')
	},

	beforeDestroy () {
		this.$root.$off('change-client')
		this.$root.$off('change-service-provider')
	},
	
	methods: {
		async checkMaintenanceMode() {
			try {
				return await this.$httpGet(`/maintenance-mode`)
			} catch (error) {
				console.error(error)
			}
		},
		
		customiseDashboard() {
			this.isCustomiseDashboard = false
		},
		async getClient(clientId) {
			this.isLoading = true
			try {
				const res = await this.$httpGet(`/client/${clientId}`)
				await this.$store.commit('setSelectedClient', res.client)

				if (this.userIsOperator) {
					let components = await this.$httpGet(`/components/${this.$store.state.selectedClient.sys.id}/serviceprovider`)
					components.sort((a, b) => (a.fields.title[this.selectedLocale] > b.fields.title[this.selectedLocale]) ? 1 : -1)
					await this.$store.commit('setSPApplications', components)
				}

				// HACK: we have to wait for the storage to finish writing before we go away..
				window.setTimeout(() => { this.$router.go() }, 500)
			}
			catch (error) {
				console.error(error)
			}
			this.isLoading = false
		},
        sort(event) {
          var roles = "";

          for (var item of event.items) {
            roles = `${roles},${item.item.fields.keycloakRole.de}`
          }

          var data = {
            userId: this.$store.state.loggedInUser.sys.id,
            components: roles.substring(1, roles.length)
          }

          this.$httpPost('/dashboard', data).then(async res => {
            var applications = [];

            if (event && event.items) {
              var dashboardItems = event.items;

              if (res.applications && res.applications.length > 0) {
                for (var dashboardItem of dashboardItems) {
                  for (const application of res.applications) {
                    if (dashboardItem.item.fields.keycloakRole.de === application.fields.keycloakRole.de) {
                      applications.push(application)
                    }
                  }
                }
              }
            } else {
              applications = res.applications
            }

            await this.$store.commit('setComponents', applications);
          })
          .catch(error => {
            console.error(error)
          });
        },
		tokenIsExpired(token) {
			try {
				const payload = JSON.parse(atob(token.split('.')[1]))
				const exp = payload.exp * 1000
				return exp < new Date().getTime()
			}
			catch (e) {
				console.warn(e)
				return false
			}
		},
		async showLogin() {
			this.$root.app.showLogin()
		}
	}
}
</script>

<style scoped>
.main { background-size: cover !important; height: 100%; width: 100%; padding-bottom: 70px; }
/*#card { width: 1200px !important; }*/
</style>
