<template>
	<v-row justify="center">
		<Disclosure :title="$t('text.businessHours')" :error="sectionMessage.error" :message="sectionMessage.message" :lock="!userMayEditFields" data-cy="businessInfo" ref="SECTION_businessInfo">
			<p class="subTitle" v-if="app === 'BusinessProfile'" v-html="$t('text.businessProfileInfoDesc')"/>
			<p class="subTitle" v-if="app === 'ServiceDesigner'" v-html="$t('text.serviceDesignerInfoDesc')"/>
			<!-- Business Hours -->
			<div class="field left-border">
				<div v-if="app === 'BusinessProfile'">
					<v-label>{{$t('text.averageDurationOfStayInMinutes')}}</v-label>
					<v-text-field
						outlined dense hide-details v-mask="'####'"
						v-model="averageDurationOfStayInMinutesLocal"
						style="width:15%;"
						@input="$emit('average-duration-changed', averageDurationOfStayInMinutesLocal)"/>
					<p class="helpText" v-html="$t('text.averageDurationOfStayInMinutesDesc')" style="margin-left:0px"/>
				</div>
				<br/>
				<p class="subTitle" v-html="$t('text.businessHours')"/>
				<p class="helpText" v-if="!showCopyOption" v-html="$t('text.businessHoursHelp')"/>
				<p class="helpText" v-if="showCopyOption" v-html="$t('text.businessHoursServiceHelp')"/>

				<div v-if="showCopyOption">
					<v-radio-group v-model="copy" col>
						<v-radio style="height:48px" :label="$t('text.copyBusinessHours')" :value="true" ></v-radio>
						<hr/>
						<v-radio style="height:48px" :label="$t('text.newBusinessHours')" :value="false"></v-radio>
					</v-radio-group>
				</div>

				<div style="width:100%;padding:0px">
					<v-row style="padding:0px" align="center">
						<v-col style="padding-left:0px">
							<p class="subTitle" v-html="businessHoursText"/>
							<p  v-html="businessHoursHelpText"/>
						</v-col>
						<v-col style="padding-right:0px">
							<v-switch
								v-model="haveBusinessHours"
								:value="haveBusinessHours"
								inset label=""
								color="#009dd7"
								@change="toggleHaveBusinessHours($event)" 
								hide-details
								style="margin-right:0px;margin-top:0px">
							</v-switch> 
						</v-col>
					</v-row>
				</div>

				<fieldset :disabled="copy" :style="copy ? 'border:0;opacity:0.4' : 'border:0'">
					<div v-for="(businessHours, businessHoursIndex) in formattedBusinessHours" :key="businessHours.sys.id">
						<div>
							<div :style="errorIndexes.length > 0 && errorIndexes.findIndex(x => x === businessHoursIndex) > -1 ?  'border:1px solid red; border-radius: 10px;' : 'border:1px solid #9e9e9e; border-radius: 10px;'">
								<v-row style="padding-left:10px;padding-right:10px">
									<v-col style="padding-left:0px !important">
										<p class="helpText" style="padding-bottom:12px;margin-bottom:12px" v-html="$t('text.validFrom')"/>
										<v-btn class="gradientButton" elevation="0" style="width:100%;justify-content: left !important"
										@click="editBusinessHours(businessHoursIndex, false, 0)">{{ formatDate(businessHours.fields.validFromDate.de) }} <v-spacer/><v-icon>mdi-menu-down</v-icon></v-btn>
									</v-col>
									<v-col>
										<p class="helpText" style="padding-bottom:12px;margin-bottom:12px" v-html="$t('text.validUntil')"/>
										<v-btn class="gradientButton" elevation="0" style="width:100%;justify-content: left !important;"
										@click="editBusinessHours(businessHoursIndex, false, 0)">{{ formatDate(businessHours.fields.validToDate.de) }} <v-spacer/><v-icon>mdi-menu-down</v-icon></v-btn>
									</v-col>
								</v-row>

								<hr/>
								
								<!-- Display View -->
								<div v-for="(displayItem, displayItemIndex) in businessHours.displayArray" :key="displayItem.label">
									<v-row style="padding:10px" no-gutters>
										<v-col :style="'max-width:'+colCount*50+'px;margin-right:10px;padding-right:10px'">
											<v-row>
												<v-chip flat class="chip" @click="editBusinessHours(businessHoursIndex, false, displayItemIndex)">{{displayItem.daysLabel}}</v-chip>
											</v-row>
										</v-col>
										<v-col>
											<v-row>
												<span v-for="timeLabel in displayItem.timeLabels" :key="timeLabel">
													<v-chip flat class="chip mr-2 mb-1" @click="editBusinessHours(businessHoursIndex, false, displayItemIndex)">{{timeLabel}}</v-chip>
												</span>
												<v-spacer/>
											</v-row>
										</v-col>
									</v-row>
									<hr v-if="displayItemIndex !== businessHours.displayArray.length-1"/>
								</div>
								
								<hr/>

								<v-row style="padding:10px">
									<v-btn class="gradientButton" elevation="0" style="margin-right:10px" @click="editBusinessHours(businessHoursIndex, false, 0)"><v-icon>mdi-pencil</v-icon>{{$t('text.editBusinessHours')}}</v-btn>
									<v-btn class="gradientButton" elevation="0" style="margin-right:10px" v-if="businessHoursIndex > 0" @click="removeBusinessHours(businessHoursIndex, false)"><v-icon color="#fb3640">mdi-delete</v-icon> {{$t('text.deleteTimeSpan')}}</v-btn>
								</v-row>
							</div>
						</div>
					
						<br/>
						<v-btn v-if="businessHoursIndex===formattedBusinessHours.length-1" class="gradientButton" elevation="0" style="margin-right:10px" @click="addBusinessHours(false)"><v-icon>mdi-plus</v-icon>{{$t('text.addAnotherTimeFrame')}}</v-btn>
					</div>
				</fieldset>
			</div>

			<!-- Add Opening Option -->
			<Dialog ref="openingTimesDialog"
				:showClose="false"
				:confirmLabel="$t('text.confirmSelection')"
				:cancelLabel="$t('text.discardChanges')"
				:confirm-handler="confirmBusinessHours"
				:cancelHandler="cancelOpeningTimes"
				:title="$t('text.businessHours')"
				:height="'80%'"
				:width="'800px'">
				<template #content>
					<OpeningTimes ref="openingTimesDetail"
						:businessHours="selectedBusinessHours"
						:businessHoursExceptions="businessHoursExceptions"
						:selectedTime="selectedTime"
						:error="businessHoursError"
						@business-hours-changed="(val) => selectedBusinessHours = val"
					/>
				</template>
			</Dialog>

		</Disclosure>
	</v-row>
</template>

<script>
import Dialog from '@/components/common/Dialog.vue'
import OpeningTimes from './OpeningTimes.vue'
import Common from '@/mixins/Common'
import Disclosure from '@/components/common/Disclosure'
import moment from 'moment'
import { isEqual } from 'lodash'

export default {
	name: 'BusinessHours',
	
	components: { Dialog, OpeningTimes, Disclosure },

	mixins: [Common],

	props: {
		averageDurationOfStayInMinutes: Number,
		showCopyOption: Boolean,
		copyBusinessHours: Boolean,
		businessHours: Array,
		businessHoursExceptions: Array,
		app: String,
		updateModel: Boolean
	},
	data() {
		return {
		copy: false,
		selectedWeekdays: [],
		selectedBusinessHours: {},
		formattedBusinessHours: [],
		formattedExceptions: [],
		selectedBusinessHoursIndex: 0,
		weekdays: ['monday','tuesday','wednesday','thursday','friday','saturday','sunday'],

		originalBusinessHours: {},
		originalExceptions: {},

		sectionMessage: {
			error: false,
			message: ''
		},

		closedDays: false,
		maxDayCount: 7,
		businessHoursError: {},
		colCount: 0,
		hover: [],
		errorIndexes: [],
		selectedTime: 0,
		haveBusinessHours: true,
		addBusinessHoursClicked: false,
		averageDurationOfStayInMinutesLocal: 0,
		businessHoursLocal: [],
		initBusinessHours: [],
		initData: {}
	}},
	watch: {
		updateModel() {
			this.businessHoursLocal = JSON.parse(JSON.stringify(this.businessHours))
			this.initBusinessHours = JSON.parse(JSON.stringify(this.businessHours))
			this.setInitData()
		},
		selectedLocale() {
			this.formattedBusinessHours = []
			this.formattedExceptions = []
			this.formatBusinessHours()
			this.formatExceptions()
		},
		copy() {
			if (this.copy === true) {
				this.haveBusinessHours = false
			}
			if (this.haveBusinessHours === true) {
				if (this.copy) {
					this.$emit('copy-business-hours')
				} else {
					this.$emit('new-business-hours')
				}
			} else  {
				this.initData.businessHours = []
				this.businessHoursLocal = []
				this.formattedBusinessHours = []
			}
		},
		businessHours() {
			this.formatBusinessHours()
			this.haveBusinessHours = this.businessHours?.length > 0 ? true : false
			this.$forceUpdate()
		},
		businessHoursExceptions() {
			this.formatExceptions()
			this.$forceUpdate()
		}
	},
	computed: {
		businessHoursText() {
			if (this.app === "BusinessProfile") {
				return this.$t("text.haveBusinessHoursSP")
			} else if (this.app === "ServiceDetail") {
				return this.$t("text.haveBusinessHoursService")
			}
			return ""
		},
		businessHoursHelpText() {
			if (this.app === "BusinessProfile") {
				return this.$t("text.haveBusinessHoursSPHelp")
			} else if (this.app === "ServiceDetail") {
				return this.$t("text.haveBusinessHoursServiceHelp")
			}
			return ""
		}
	},
	created() {
		this.businessHoursLocal = JSON.parse(JSON.stringify(this.businessHours))
		this.initBusinessHours = JSON.parse(JSON.stringify(this.businessHours))
		this.setInitData()

		this.copy = this.copyBusinessHours
		this.haveBusinessHours = this.businessHours?.length > 0 ? true : false
		
		if (this.businessHoursLocal?.length >  0) {
			for (let businessHours of this.businessHoursLocal) {
				if (businessHours.fields.validFromDate.de === '' && businessHours.fields.validToDate.de === '') {
					businessHours.fields.validFromDate.de = moment(new Date()).format('YYYY-MM-DD')
					businessHours.fields.validToDate.de = moment(new Date()).add(1, 'year').format('YYYY-MM-DD')
				}
			}
		}
		
		this.formatBusinessHours()
		this.formatExceptions()

		this.averageDurationOfStayInMinutesLocal = this.averageDurationOfStayInMinutes
		
	},
	methods: {
		setInitData() {
			this.initData = {
				businessHours: this.initBusinessHours,
				averageDurationOfStay: this.averageDurationOfStayInMinutes
			}

		},
		sendData() {
			let businessHoursData = JSON.parse(JSON.stringify(this.businessHoursLocal))
			if (businessHoursData?.length) {
				for (let businessHours of businessHoursData) {
					delete businessHours.dayCount
					delete businessHours.displayArray
				}
			}

			let data = {
				businessHours: {de: businessHoursData}
			}

			data.changed = !isEqual(businessHoursData, this.initData.businessHours) || this.averageDurationOfStayInMinutesLocal !== this.initData.averageDurationOfStay ? true : false
			
			return data
		},
		validateAllFields() {
			let isValid = true
			this.resetSectionError(this.sectionMessage)

			if (!this.validateBusinessHours(false)) {
				isValid = false
				this.setSectionError(this.sectionMessage, this.$t('text.businessHoursOverlapError'))
			}

			return isValid
		},
		toggleHaveBusinessHours(event) {
			if (event &&  event === true) {
				if (!this.businessHoursLocal || this.businessHoursLocal.length === 0) {
					this.businessHoursLocal.push(this.getNewBusinessHours(false))
					this.formatBusinessHours()
				}
			} else {
				this.copy = false
				this.businessHoursLocal = []
				this.formattedBusinessHours = []
			}
			this.$emit("toggle-business-hours", event)
		},
		toggleHover(index, active) {
			this.hover[index] = active
			this.$forceUpdate()
		},
		formatDate(date) {
			if (date !== "") {
				return moment(String(date.split("T")[0])).format('DD.MM.YYYY')
			} else {
				return date
			}
		},
		formatBusinessHours() {
			this.formattedBusinessHours = []

			if (this.businessHoursLocal?.length > 0) {
				let displayArrayKey = 0

				for (const businessHoursEntry of this.businessHoursLocal) {
					if (businessHoursEntry.fields) {
						let displayMap = new Map()
						let displayArray = []
						let dayCount = 0
						
						for  (const weekday of this.weekdays) {
							const weekdayTimes = businessHoursEntry.fields.businessTimes.de[weekday]
							let mapKey = ''

							if (weekdayTimes.times?.length > 0) {
								for (const times of weekdayTimes.times) {
									mapKey+=times.openTime+'-'+times.closeTime+'|'
								}
								mapKey = mapKey.substring(0,mapKey.length-1)

								if (!displayMap.get(mapKey)) {
									displayMap.set(mapKey, [weekday])
								} else {
									displayMap.get(mapKey).push(weekday)
								}	
							}
						}

						if (displayMap.size > 0) {
							for (let [key, value] of displayMap) {
								displayArrayKey++

								let daysLabel = ''
								let days = []
								let timeLabels = []
								let times = []
								let text = ''

								if (key !== '-') {
									for (const element of value) {
										dayCount++
										days.push(element)
										text = 'text.'+element
										daysLabel += (this.$t(text)).substring(0,3) + ' • '
									}

									daysLabel = daysLabel.substring(0, daysLabel.length-2)
									timeLabels = key.split("|")

									if (timeLabels?.length > 0) {
										for (const timeLabel of timeLabels) {
											times.push({openTime:timeLabel.split("-")[0],closeTime:timeLabel.split("-")[1]})
										}
									}
									
									displayArray.push({daysLabel: daysLabel, days:days, timeLabels:timeLabels, times:times, closed:false, key:displayArrayKey})
									this.colCount = this.colCount < days.length ? days.length+1 : this.colCount
								}
							}

							if (displayMap.get("-")) {
								let daysLabel = ''
								let days = []
								let text = ''
								for (const element of displayMap.get("-")) {
									dayCount++
									days.push(element)
									text = 'text.'+element
									daysLabel += (this.$t(text)).substring(0,3) + ' • '
								}

								daysLabel = daysLabel.substring(0, daysLabel.length-2)

								displayArray.push({daysLabel: daysLabel, days:days, timeLabels:[this.$t('text.closed')], times: days.length < 7 ? [] : [{openTime:'',closeTime:''}], closed: true, key: displayArrayKey})
								this.colCount = this.colCount < days.length ? days.length+1 : this.colCount
							}
						}
						
						businessHoursEntry["displayArray"] = displayArray
						businessHoursEntry["dayCount"] = dayCount
						this.formattedBusinessHours.push(businessHoursEntry)
					}
				}
			}
		},
		formatExceptions() {
			this.formattedExceptions = []
			this.hover = []

			if (this.businessHoursExceptions?.length > 0) {
				let displayArrayKey = 0

				for (const businessHoursEntry of this.businessHoursExceptions) {
					if (businessHoursEntry.fields) {
						let displayMap = new Map()
						let displayArray = []
						
						let mapKey = ''

						if (businessHoursEntry.fields.validFromDate.de !== businessHoursEntry.fields.validToDate.de) {
							mapKey = this.formatDate(businessHoursEntry.fields.validFromDate.de)+' - '+this.formatDate(businessHoursEntry.fields.validToDate.de)
						} else {
							mapKey = this.formatDate(businessHoursEntry.fields.validFromDate.de)
						}

						displayMap.set(mapKey, businessHoursEntry.fields.businessTimes.de.monday.times)
						
						if (displayMap.size > 0) {
							for (let [key, value] of displayMap) {
								displayArrayKey++
								let timeLabels = []
					
								for (const time of value) {
									timeLabels.push(time.openTime+"-"+time.closeTime)
								}
								
								displayArray.push({daysLabel: key, days:this.weekdays, timeLabels:timeLabels, times:value, closed:false, key:displayArrayKey})
								this.colCount = 7
							}
						}
						
						businessHoursEntry["displayArray"] = displayArray
						businessHoursEntry["dayCount"] = 7
						
						if (businessHoursEntry.fields.validFromDate.de !== businessHoursEntry.fields.validToDate.de) {
							businessHoursEntry["repititionType"] = 'range'
						} else {
							businessHoursEntry["repititionType"] = 'multiple'
						}
						
						this.formattedExceptions.push(businessHoursEntry)
						this.hover.push(false)
					}
				}

				this.formattedExceptions.sort((a, b) => { return a.fields.validFromDate.de.localeCompare(b.fields.validFromDate.de) })
			}
		},
		addBusinessHours(isException) {
			if (isException) {
				if (!this.businessHoursExceptions) {
					this.businessHoursExceptions = []
				}
				this.businessHoursExceptions.push(this.getNewBusinessHours(true))
				this.formatExceptions()
				this.$forceUpdate()
				this.editBusinessHours(this.businessHoursExceptions.length-1, true, 0)
			} else {
				if (!this.businessHoursLocal) {
					this.businessHoursLocal = []
				}
				this.businessHoursLocal.push(this.getNewBusinessHours(false))
				this.formatBusinessHours()
				this.$forceUpdate()
				this.editBusinessHours(this.businessHoursLocal.length-1, false, 0)
			}

			this.addBusinessHoursClicked = true
		},
		removeBusinessHours(index, isException) {
			if (isException) {
				this.formattedExceptions.splice(index, 1)
				this.businessHoursExceptions.splice(index, 1)
			} else {
				this.formattedBusinessHours.splice(index, 1)
				this.businessHoursLocal.splice(index, 1)
			}
		},
		editBusinessHours(businessHoursIndex, isException, itemIndex) {
			this.addBusinessHoursClicked = false
			this.businessHoursError = {}
			this.selectedBusinessHoursIndex = businessHoursIndex
			this.selectedTime = itemIndex
				
			if (isException) {
				this.selectedBusinessHours = JSON.parse(JSON.stringify(this.formattedExceptions[businessHoursIndex]))
			} else {
				this.selectedBusinessHours = JSON.parse(JSON.stringify(this.formattedBusinessHours[businessHoursIndex]))
				this.originalBusinessHours = JSON.parse(JSON.stringify(this.selectedBusinessHours))
			}

			this.$refs.openingTimesDialog.show = true
		},
		getNewBusinessHours(isException) {
			const counter = Math.random()

			let today = moment(new Date()).format('YYYY-MM-DD')
			let validToDate = moment(new Date()).add(1, 'year').format('YYYY-MM-DD')

			return {
				sys: { id: 'id_bh_' + counter },
				fields: {
					validFromDate: { de: today },
					validToDate: { de: validToDate },
					isException: {de: isException},
					businessTimes: {
						de: {
							monday:    { times: [{ openTime: '', closeTime: '' }] },
							tuesday:   { times: [{ openTime: '', closeTime: '' }] },
							wednesday: { times: [{ openTime: '', closeTime: '' }] },
							thursday:  { times: [{ openTime: '', closeTime: '' }] },
							friday:    { times: [{ openTime: '', closeTime: '' }] },
							saturday:  { times: [{ openTime: '', closeTime: '' }] },
							sunday:    { times: [{ openTime: '', closeTime: '' }] },
							holidays:  { times: [{ openTime: '', closeTime: '' }] },
						},
					},
				},
			}	
		},
		confirmBusinessHours() {
			if (this.$refs.openingTimesDetail.businessHoursLocal.fields.isException?.de === true) {
				if (this.$refs.openingTimesDetail.repititionType.id === 1) {
					this.$refs.openingTimesDetail.businessHoursLocal.fields.validFromDate.de = this.$refs.openingTimesDetail.exceptionDates[0]
					this.$refs.openingTimesDetail.businessHoursLocal.fields.validToDate.de = this.$refs.openingTimesDetail.exceptionDates[1]
					this.confirmOpeningTimes()
				}  else {
					this.confirmOpeningTimesExceptions()
				}
			} else {
				this.confirmOpeningTimes()
			}
		},
		confirmOpeningTimes() {
			this.businessHoursError = {message:''}
			this.businessHoursLocal[this.selectedBusinessHoursIndex] = JSON.parse(JSON.stringify(this.$refs.openingTimesDetail.businessHoursLocal))

			//Might remove this "else" if overlapping exceptions need to be validated
			let isValidBusinessHours = this.validateBusinessHours(this.$refs.openingTimesDetail.businessHoursLocal.fields.isException?.de)
	
			if (!isValidBusinessHours) {
				this.businessHoursError = {message: this.$t('text.businessHoursOverlapError')}
			}
			
			if (this.businessHoursError.message.length > 0) return

			let openingTimes = this.$refs.openingTimesDetail.businessHoursLocal.displayArray
			
			this.businessHoursError = this.validateOpeningDates(this.$refs.openingTimesDetail.businessHoursLocal)
			if (this.businessHoursError.message.length > 0) return

			this.businessHoursError = this.validateOpeningTimes(openingTimes)
			if (this.businessHoursError.message.length > 0) return
		
			let openDays  = []
			//Format into business hours model
			this.$refs.openingTimesDialog.show = false
			let businessHours
			
			if (this.$refs.openingTimesDetail.businessHoursLocal.fields.isException?.de === true) {
				businessHours = this.businessHoursExceptions[this.selectedBusinessHoursIndex]
			} else {
				businessHours = this.businessHoursLocal[this.selectedBusinessHoursIndex]
			}
			
			for (const openingTime of openingTimes) {
				for (const day of openingTime.days) {
					openDays.push(day)
					let validTimes = []
					for (let time of openingTime.times) {
						if (time.openTime !== '' || time.closeTime !== '') { validTimes.push(time) }
					}

					if (validTimes.length > 0) {
						businessHours.fields.businessTimes.de[day].times = validTimes
					} else {
						businessHours.fields.businessTimes.de[day].times = [{openTime:'',closeTime:''}]
					}	
				}
			}

			if (openDays.length < 8) {
				//Set missing days to closed
				for (const weekday of this.weekdays) {
					if (openDays.findIndex(x => x === weekday) === -1) {
						businessHours.fields.businessTimes.de[weekday].times = [{openTime:'',closeTime:''}]
					}
				}
			}

			if (this.$refs.openingTimesDetail.businessHoursLocal.fields.isException?.de === true) {
				this.formatExceptions()
			} else {
				this.formatBusinessHours()
			}
		},
		confirmOpeningTimesExceptions() {
			this.businessHoursError = {message:''}
			let dayTimes = {}
			
			let openingTimes = this.$refs.openingTimesDetail.businessHoursLocal.displayArray[0]
			
			this.businessHoursError = this.validateOpeningTimes(openingTimes)
			if (this.businessHoursError.message.length > 0) return

			for (const day of openingTimes.days) {
				dayTimes[day] = {times: openingTimes.times}
			}

			//Specific Days
			if (this.$refs.openingTimesDetail.exceptionDates.length > 0) {
				for (const exceptionDate of this.$refs.openingTimesDetail.exceptionDates) {
					const exceptionIndex = this.businessHoursExceptions.findIndex(x => x.fields.validFromDate.de === exceptionDate && x.fields.validToDate.de === exceptionDate)
					
					if (exceptionIndex === -1) {
						let businessHoursException = this.getNewBusinessHours()

						businessHoursException.fields.validFromDate.de = exceptionDate
						businessHoursException.fields.validToDate.de = exceptionDate
						businessHoursException.fields.businessTimes = {de:dayTimes}
						businessHoursException.fields.isException = {de:true}

						this.businessHoursExceptions.push(businessHoursException)
					} else {
						this.businessHoursExceptions[exceptionIndex].fields.businessTimes = {de:dayTimes}
					}
				}
				this.$refs.openingTimesDialog.show = false
				this.formatExceptions()
			} else {
				this.businessHoursError = {message: this.$t('text.dateRequired')}
			}
		},
		cancelOpeningTimes() {
			this.businessHoursError = {}

			if (this.selectedBusinessHours.fields.isException && this.selectedBusinessHours.fields.isException.de === true) {
				this.formatExceptions()
				this.selectedBusinessHours = JSON.parse(JSON.stringify(this.formattedExceptions[this.selectedBusinessHoursIndex]))
			} else {
				this.formatBusinessHours()
				this.selectedBusinessHours = JSON.parse(JSON.stringify(this.formattedBusinessHours[this.selectedBusinessHoursIndex]))
			}

			// when Add another timeframe clicked
			if (this.selectedBusinessHoursIndex > 0 && this.addBusinessHoursClicked) {
				this.removeBusinessHours(this.selectedBusinessHoursIndex, false)
			}
			// when edit business hours clicked
			else if (!this.addBusinessHoursClicked) {
				// overwrite the model with the original model
				if (this.originalBusinessHours) {
					this.selectedBusinessHours = JSON.parse(JSON.stringify(this.originalBusinessHours))
					let index = this.businessHoursLocal.findIndex(x => x.sys.id === this.originalBusinessHours.sys.id)

					this.businessHoursLocal[index] = JSON.parse(JSON.stringify(this.originalBusinessHours))
					this.formattedBusinessHours[index] = JSON.parse(JSON.stringify(this.originalBusinessHours))
		
					this.$forceUpdate()
				}
			}
		},
		validateOpeningDates(businessHours) {
			let error = {message:'',displayIndex:-1}

			if (businessHours.fields.validFromDate.de > businessHours.fields.validToDate.de) {
				error.message = this.$t('text.fromDateAfterToDateError')
			}
			return error
		},
		validateOpeningTimes(openingTimes) {
			let error = {message:'',displayIndex:-1}

			if (openingTimes?.length > 0) {
				for (let i=0; i < openingTimes.length; i++) {
					const openingTime = openingTimes[i]

					if (openingTime.days.length === 0) {
						//Times specified but no days selected
						for (let time of openingTime.times) {
							if (time.openTime !== '' && time.closeTime !== '') {
								error.message = this.$t('text.missingDaySelection')
								error.displayIndex = i
								break
							}
						}
					} else {
						if (openingTime.times?.length > 0 && !openingTime.closed) {
							//Validate time inputs
							for (const time of openingTime.times) {
								if (time.openTime.length > 0 && time.closeTime.length > 0) {
									time.openTime = time.openTime.substring(0,5)
									time.closeTime = time.closeTime.substring(0,5)
									if (!this.validateHhMm(time.openTime) || !this.validateHhMm(time.closeTime)) {
										error.message = this.$t('text.invalidTime')
										
									}
								}
							}
						}
					}
					
					if (openingTime.times?.length > 0 && !openingTime.closed) {
						for (const time of openingTime.times) {
							time["errors"] = ""
							if (time.openTime.length > 0 && time.closeTime.length > 0) {
								if (time.openTime > time.closeTime) {
									error.message = this.$t('text.invalidTime')
									time["errors"] = this.$t('text.invalidTime')
								}
							}
						}
					}
				
				}
			}
			return error
		},
		validateHhMm(input) {
			var isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(input);
			return isValid;
		},
		validateBusinessHours(isException) {
			let businessHours = isException ? this.businessHoursExceptions : this.businessHoursLocal

			if (businessHours?.length > 0) {
				this.errorIndexes = []
				for (let i=0; i < businessHours.length; i++) {
					const businessHoursOne = businessHours[i]
					for (let j=i; j < businessHours.length; j++) {
						const businessHoursTwo = businessHours[j]
						if (businessHoursOne != businessHoursTwo) {
							const invalidFromDateOne = moment(businessHoursOne.fields.validFromDate.de.split("T")[0]).isBetween(businessHoursTwo.fields.validFromDate.de.split("T")[0], businessHoursTwo.fields.validToDate.de.split("T")[0], undefined, '[]');
							const invalidToDateOne = moment(businessHoursOne.fields.validToDate.de.split("T")[0]).isBetween(businessHoursTwo.fields.validFromDate.de.split("T")[0], businessHoursTwo.fields.validToDate.de.split("T")[0], undefined, '[]');
							const invalidFromDateTwo = moment(businessHoursTwo.fields.validFromDate.de.split("T")[0]).isBetween(businessHoursOne.fields.validFromDate.de.split("T")[0], businessHoursOne.fields.validToDate.de.split("T")[0], undefined, '[]');
							const invalidToDateTwo = moment(businessHoursTwo.fields.validToDate.de.split("T")[0]).isBetween(businessHoursOne.fields.validFromDate.de.split("T")[0], businessHoursOne.fields.validToDate.de.split("T")[0], undefined, '[]');
							
							if (invalidFromDateOne || invalidToDateOne || invalidFromDateTwo || invalidToDateTwo) {
								this.errorIndexes.push(j)
								this.errorIndexes.push(i)
							}
						}
					}	
				}

				if (this.errorIndexes.length > 0) {
					return false
				}
			}
			return true
		},
	}
}
</script>

<style scoped>
.card {
	border:1px solid #c0c0c0;
	border-radius: 10px;
}
hr { border: 1px solid #c0c0c0 !important; height: 1px; }
.v-text-field > .v-input__control > .v-input__slot::before { border-style: none !important;}
.v-text-field { padding-bottom: 12px !important; }
.v-chip.v-size--default { 
	border-radius: 5px !important;
	background-color: #ffffff !important;
	border: thin #dddddd solid !important;
	text-decoration: none !important;
	font-weight: normal !important;
}
.chip {
	 font-family: 'Inter', sans-serif;
  min-height: 32px !important;
  max-height: 32px !important;
  height: 32px !important;
  background: linear-gradient(0deg, #efefef 0%, #ffffff 100%) !important;
  border: thin #dddddd solid !important;
  display: flex !important;
  /*justify-content: space-around !important;*/
  align-items: center !important;
  border-radius: 5px !important;
  font-family: "Inter";
  font-size: 12pt !important;
  text-transform: none !important;
  letter-spacing: 0px !important;
  cursor: pointer !important;
}

</style>