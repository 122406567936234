<template>
    <v-form>
      <v-row align="center">
        <v-col style="margin-left:0px;margin-right:0px;padding:2px">
          <v-row align="center">
            <v-card flat class="card">
              <span style="min-width: 40px; max-width: 40px"><img width="40px" height="40px" style="cursor:pointer;margin-top:3px" src="@/assets/icons/move.png"/></span>
              <span style="min-width: 40px; max-width: 40px"><v-checkbox style="margin-top:6px !important" v-model="offer.selected" @change="$emit('input', offer)" /></span>
              <span style="min-width: 300px; margin-top: 11px">
                <v-icon v-if="offerMenuItemType == 'ticket'" style="margin-right:10px;margin-top:-3px">mdi-ticket</v-icon>
                <v-label v-if="offer.fields">{{ offer.fields.title[selectedLocale] | truncate(250, '...') }}</v-label>
              </span>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
</template>

<script>
import Common from '@/mixins/Common'

export default {
  mixins: [Common],
  props: {
		offer: {
			fields: { title: { de: '' } },
			selected: false
		},
		offerMenuItemType: String,
	},
}
</script>

<style scoped>
.card {
	background-color: transparent !important;
	min-width: 100%;
	max-height: 48px;
	margin-top: 6px;
	display: flex;
	overflow-x: auto;
	overflow-y: hidden;
}
</style>