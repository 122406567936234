<template>
	<v-row justify="center">
		<Disclosure :title="$t('text.applicationAccessInfoTitle')" :error="sectionMessage.error" :message="sectionMessage.message" :lock="!userMayEditFields" data-cy="applicationAccessInfo" ref="SECTION_applicationAccessInfo" >
			<p class="subTitle" v-html="$t('text.applicationAccessDesc')"/>
			<div class="field left-border">
				<v-label>{{$t('text.servicesDesc')}}</v-label><br/>
				<v-col v-for="component in serviceComponents" :key="component.id" style="margin:0px !important;padding:0px !important">
					<ApplicationComponent :component="component" :selectedComponents="selectedComponents" @add-component="addSelectedComponent" @remove-component="removeSelectedComponent"/>
				</v-col>
				<p v-if="applicationComponentErrors.length" class="error-text">{{ applicationComponentErrors[0] }}</p>
			</div>
		</Disclosure>
	</v-row>
</template>

<script>
import Common from '@/mixins/Common'
import Disclosure from '@/components/common/Disclosure'
import ApplicationComponent from '@/components/common/ApplicationComponent'
import { isEqual } from 'lodash'

export default {
	name: "UserAccount",
	components: {
		Disclosure,
		ApplicationComponent
	},
	mixins: [Common],

	props: {
		serviceProvider: {
		  type: Object,
		  required: true
		},
		updateModel: Boolean
	},
	data() {
		return {
			model: {},
			initData: {},
			// this is also called in the parent via a $ref
			selectedComponents:[],
			sectionMessage: {
			error: false,
			message: ''
			},
			serviceComponents:[],
			applicationComponentErrors: [],
		}
	},

	watch: {
		updateModel() {
			this.model = this.valueToModel(this.serviceProvider)
			this.setInitialComponents()
			this.setInitData()
		}
	},

	created() {
		this.model = this.valueToModel(this.serviceProvider)
		this.setInitialComponents()
		this.setInitData()
	},

	async mounted() {
		this.serviceComponents = this.$store.state.spApplications
	},

	methods: {
		valueToModel(v) {
			return JSON.parse(JSON.stringify(v ?? {}))
		},
		setInitData() {
			this.initData = {
				selectedComponents: JSON.parse(JSON.stringify(this.selectedComponents))
			}
		},
		sendData() {
			let data = {
				selectedComponents: this.selectedComponents
			}

			data.changed = !isEqual(data, this.initData)
      		return data
		},
		validateAllFields() {
			let allFieldsAreValid = true
			this.resetSectionError(this.sectionMessage)
			this.applicationComponentErrors = []
			
			if (this.selectedComponents.length === 0) {
				allFieldsAreValid = false
				this.setSectionError(this.sectionMessage, this.$t('text.missingFieldsError'))
				this.applicationComponentErrors.push(this.$t('text.missingFieldsError'))
			}

			return allFieldsAreValid
		},
		setInitialComponents() {
			if (this.model.fields.mainUserAccount) {
				if (Object.keys(this.model.fields.mainUserAccount).length > 0) {
					this.selectedComponents = this.model.fields.mainUserAccount.fields.applications?.de

					if (!this.selectedComponents) {
						this.selectedComponents = []
					}
				} 
			}
		},
		addSelectedComponent(component) {
			this.selectedComponents.push(component);

			if (this.selectedComponents.length > 0) {
				this.applicationComponentErrors = []
			}
		},
		removeSelectedComponent(component) {
			if (this.selectedComponents.length > 0) {
				for (var i=0; i < this.selectedComponents.length; i++) {
				if (this.selectedComponents[i].sys.id === component.sys.id) {
					this.selectedComponents.splice(i, 1);
				}
				}
			}
		},
	}
	
}
</script>