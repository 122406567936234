<template>
	<div>
		<v-row justify="center">
			<Disclosure :title="$t('text.publicInfoTitle')" :lock="!userMayEditFields" data-cy="publicInfo" ref="SECTION_publicInfo">
				<div class="field left-border">
					<v-label>{{$t('text.faq')}}</v-label>
					<v-select outlined dense return-object
						hide-details
						:items="faqGroups"
						:item-text="item => item && item.fields && item.fields.title ? item.fields.title[selectedLocale] : ''"
						v-model="model.fields.faqGroup.de"
					/>
					<p class="helpText" v-html="$t('text.faqBusinessProfileDesc')"/>
				</div>
				<div class="field left-border">
					<v-label>{{$t('text.longDescription')}}</v-label>
					<LanguageFlag v-model="serviceLocale" class="serviceLocale" />
					<tiptap-vuetify hide-details required
						class="enabled-text-style"
						v-model="model.fields.longDescription[serviceLocale]"
						:extensions="extensionsLongDescription"
					/>
					<p class="helpText" v-html="$t('text.longDescriptionHelp')"/>
				</div>
				<div class="field left-border">
					<v-label>{{$t('text.shortDescription')}}</v-label>
					<LanguageFlag v-model="serviceLocale" class="serviceLocale" />
					<v-textarea auto-grow outlined required counter="255" maxlength="255"
						v-model="model.fields.shortDescription[serviceLocale]"
					/>
					<p class="helpText" style="margin-top:-20px" v-html="$t('text.shortDescriptionHelp')"/>
				</div>
				<div class="field left-border">
					<v-label>{{$t('text.importantInfo')}}</v-label>
					<LanguageFlag v-model="serviceLocale" class="serviceLocale" />
					<tiptap-vuetify hide-details
							class="enabled-text-style"
							v-model="model.fields.importantInformation[serviceLocale]"
							:extensions="extensionsImportantInfo"
						/>
					<p class="helpText" v-html="$t('text.importantInfoHelp')"/>
				</div>
				<div class="field left-border" v-if="$store.state.selectedServiceProvider">
					<v-label>{{$t('text.images')}}</v-label>
					<div style="display: flex;margin-top:10px">
						<v-card width="100px" height="100px" flat style="border-radius:10px;border:1px solid rgba(0,0,0,.2);background-color:#ffffff;margin-right:10px" 
						@click="$refs.mediaViewer.show===true ? $refs.mediaViewer.show=false : $refs.mediaViewer.show=true">
							<v-row justify="center" align="center" style="height: 100%">
							<v-icon size="48px" color="#9da0a5">mdi-plus</v-icon>
							</v-row>
						</v-card>

						<draggable v-model="model.fields.imageGallery.de" group="model.fields.imageGallery.de" @start="drag=true" @end="drag=false" style="display:flex;flex-direction:row;flex-wrap:wrap;">
							<v-card draggable class="infoImageWrapper mr-2 mt-1" v-for="(image,index) in model.fields.imageGallery.de" :key="index" width="100px" height="100px" flat>
								<img v-if="image && image.fields && image.fields.media" :src="image.fields.media.de.fields.file.de.url ? image.fields.media.de.fields.file.de.url : image"  width="100px" height="100px">
								<img v-if="image && image.fields && image.fields.file" :src="image.fields.file.de ? image.fields.file.de.url : image.fields.file.url ? image.fields.file.url.de : image"  width="100px" height="100px">
								<v-icon class="removeImg"
										@click="image.imageURL ? removeImage(image.fields.media ? (image.fields.media.de.fields.file.de.url ? image.fields.media.de.fields.file.de.url : image) : (image.fields.file.de.url ? image.fields.file.de.url : image)) : removeImage(image)">
									mdi-close-circle
								</v-icon>
							</v-card>
						</draggable>
					</div><br/>
					<v-label>{{$t('text.imagesDesc')}}</v-label>
				</div>
			</Disclosure>
		</v-row>

		<MediaViewer ref="mediaViewer" @add-media="addMedia"/>
	</div>
</template>

<script>
	import Common from '@/mixins/Common'
	import Disclosure from '@/components/common/Disclosure'
	import LanguageFlag from '@/components/common/LanguageFlag.vue'
	import MediaViewer from '@/components/media/MediaViewer'
	import { TiptapVuetify, Heading, Bold, Italic, Underline, BulletList, OrderedList, ListItem, History, Link } from 'tiptap-vuetify'
	import draggable from 'vuedraggable'
	import { isEqual } from 'lodash'
	
	export default {
	  name: "PublicInfo",
	  components: {
		Disclosure,
		TiptapVuetify,
		draggable,
		LanguageFlag,
		MediaViewer
	  },
	  mixins: [Common],
	 
	  props: {
		serviceProvider: {
		  type: Object,
		  required: true
	  	},
		updateModel: Boolean
	  },
	  data() {
		return {
		  model: {},
		  initData: {},
		  sectionMessage: {
			error: false,
			message: ''
		  },
		  faqGroups: [],
		  extensionsLongDescription: [
				History,
				[Heading, {
				options: {
					levels: [1, 2, 3, 4, 5, 6]
				}
				}],
				Bold,
				Italic,
				Underline,
				ListItem,
				BulletList,
				OrderedList
		  ],
		  extensionsImportantInfo: [
				[Heading, {
					options: {
						levels: [6]
					}
				}],
				Bold,
				Link
		  ],
		}
	  },

		watch: {
			updateModel() {
				this.model = this.valueToModel(this.serviceProvider)

				if (this.$store.state.selectedServiceProvider?.sys?.id) {
					this.getFAQs()
				}
				this.setInitData()
			}
		},

	  	created() {
		  this.model = this.valueToModel(this.serviceProvider)

		  if (this.$store.state.selectedServiceProvider?.sys?.id) {
				this.getFAQs()
			}

		  this.setInitData()
		},

		methods: {
			valueToModel(v) {
				return JSON.parse(JSON.stringify(v ?? {}))
			},
			setInitData() {
				const initModel = JSON.parse(JSON.stringify(this.model))
				this.initData = {
					faqGroup: initModel.fields.faqGroup,
					longDescription: initModel.fields.longDescription,
					shortDescription: initModel.fields.shortDescription,
					importantInformation: initModel.fields.importantInformation,
					imageGallery:initModel.fields.imageGallery
				}
			},
			sendData() {
				let data = {
					faqGroup: this.model.fields.faqGroup,
					longDescription: this.model.fields.longDescription,
					shortDescription: this.model.fields.shortDescription,
					importantInformation: this.model.fields.importantInformation,
					imageGallery: this.model.fields.imageGallery
				}

				data.changed = !isEqual(data, this.initData)
				return data
			},
			async getFAQs() {
				try {
					//let res = await this.$httpGet(`/faqs?serviceProviderId=${this.$store.state.selectedServiceProvider.sys.id}`)

					this.faqGroups = this.$store.state.selectedServiceProvider.fields.faqGroups?.de ?? []
					if (this.faqGroups) {this.faqGroups.sort(this.compare)}

					//add an empty object to the top to make possible a deselect for v-select as v-select does not have a built-in way to select a blank option
					this.faqGroups.unshift({})
				}
				catch (error) {
					this.showError(error)
				}
			},
			addMedia() {
				if (this.$refs.mediaViewer.$data.selectedMedia) {
					let selectedMedia = this.$refs.mediaViewer.$data.selectedMedia

					if (selectedMedia && selectedMedia.length > 0) {
						for (var media of selectedMedia) {
							const imageIndex = this.model.fields.imageGallery.de.findIndex(x => x.sys.id === media.sys.id)

							if (imageIndex === -1) {
								this.model.fields.imageGallery.de.push(media)
							}
						}
					}

					this.$refs.mediaViewer.show = false
				}
			},
			removeImage(imageUrl) {
				if (this.model.fields.imageGallery.de && this.model.fields.imageGallery.de.length > 0) {
					for (var i=0; i < this.model.fields.imageGallery.de.length; i++) {
					//check if new or update
					if (this.model.fields.imageGallery.de[i].imageURL) {
						if (this.model.fields.imageGallery.de[i].imageURL === imageUrl) {
						this.model.fields.imageGallery.de.splice(i, 1);
						}
					} else if (this.model.fields.imageGallery.de[i] === imageUrl) {
						this.model.fields.imageGallery.de.splice(i, 1);
					}
					}
				}
			},
		}
	}
</script>
	
<style scoped>
	.tiptap-vuetify-editor__content { height: 300px !important; }
	.tiptap-vuetify-editor .ProseMirror { height: 300px !important; }
	.enabled-text-style >>> .tiptap-vuetify-editor__content{ background: #ffffff !important; height: 300px; }
	.infoImageWrapper { border-radius: 10px; background-color: #ffffff; }
	.infoImageWrapper img { border-radius: 10px !important; }
	.infoImageWrapper .removeImg { position: absolute; right: 2px; top: 2px; }
	.infoImageWrapper:hover .removeImg { color: #000; }
	.infoImageWrapper:hover .removeImg:active { color: #5a5a5a; }
	.serviceLocale { position: absolute; z-index: 10; margin-top: 32px; margin-left: -32px; zoom: 0.8; }
</style>
	
	