<template>
	<v-row justify="center">
		<Disclosure :title="$t('text.mainUserAccountTitle')" :error="sectionMessage.error" :message="sectionMessage.message" :lock="!userMayEditFields" data-cy="mainUserAccount" ref="SECTION_mainUserAccount">
			<div class="field left-border">
				<v-label>{{$t('text.personalName')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
				<v-text-field outlined dense autocomplete="off" required
					id="mainUserAccountName"
					v-model="mainUserAccount.name"
					:error-messages="mainUserAccountNameErrors"
					@input="$v.mainUserAccount.name.$touch()"
					@blur="$v.mainUserAccount.name.$touch()"
				/>
			</div>
			<div class="field left-border">
				<v-label>{{$t('text.email')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
				<v-text-field outlined dense required autocomplete="off"
					readonly onfocus="this.removeAttribute('readonly');"
					id="username"
					v-model="mainUserAccount.email"
					:error-messages="mainUserAccountEmailErrors"
					@input="$v.mainUserAccount.email.$touch()"
					@blur="checkEmailExists"
				/>
			</div>
			<div class="field left-border">
				<v-label>{{$t('text.password')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
				<v-text-field required autocomplete="off" outlined dense
					readonly onfocus="this.removeAttribute('readonly');"
					id="password"
					type="password"
					v-model="mainUserAccount.password"
					:error-messages="mainUserAccountPasswordErrors"
					@input="$v.mainUserAccount.password.$touch()"
					@blur="$v.mainUserAccount.password.$touch()"
				/>
			</div>
			<div class="field left-border">
				<v-label>{{$t('text.repeatPassword')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
				<v-text-field type="password" outlined dense required sameAs autocomplete="off"
					id="repeatPassword"
					v-model="mainUserAccount.repeatPassword"
					:error-messages="mainUserAccountRepeatPasswordErrors"
					@input="$v.mainUserAccount.repeatPassword.$touch()"
					@blur="$v.mainUserAccount.repeatPassword.$touch()"
				/>
			</div>
		</Disclosure>
	</v-row>
</template>

<script>
	import Common from '@/mixins/Common'
	import Disclosure from '@/components/common/Disclosure'
	import { required, minLength, maxLength, email, sameAs } from "vuelidate/lib/validators"
	import { isEqual } from 'lodash'
	
	export default {
	  name: "UserAccount",
	  components: {
		Disclosure
	  },
	  mixins: [Common],
	 
	  props: {
		serviceProvider: {
		  type: Object,
		  required: true
		},
		updateModel: Boolean
	  },
	  data() {
		return {
		  model: {},
		  initData: {},
		  sectionMessage: {
			error: false,
			message: ''
		  },
		  //this is also called in the parent component via $refs
		  mainUserAccount: {
				name:'',
				email:'',
				password:'',
				repeatPassword:'',
		  },
		  emailIsUnique: true,
		}
	  },
	  validations: {
			mainUserAccount: {
				name: { required, minLength: minLength(4), maxLength: maxLength(128) },
				email: { required, email, minLength: minLength(4), maxLength: maxLength(256) },
				password: { required, minLength: minLength(8) },
				repeatPassword: { required, minLength: minLength(8), sameAsPassword: sameAs('password') }
			}
		},
		computed: {
		mainUserAccountNameErrors() {
			let errors = []
			if (!this.$v.mainUserAccount.name.$dirty) return errors
			!this.$v.mainUserAccount.name.required && errors.push(this.$t('text.nameRequired'))
			!this.$v.mainUserAccount.name.minLength && errors.push(this.$t('text.nameMinChars'))
			!this.$v.mainUserAccount.name.maxLength && errors.push(this.$t('text.nameMaxChars'))
			return errors
		},
		mainUserAccountEmailErrors() {
			let errors = []
			if (!this.$v.mainUserAccount.email.$dirty) return errors
			!this.$v.mainUserAccount.email.email && errors.push(this.$t('text.emailInvalid'))
			!this.$v.mainUserAccount.email.required && errors.push(this.$t('text.emailRequired'))
			!this.$v.mainUserAccount.email.minLength && errors.push(this.$t('text.emailMinChars'))
			!this.$v.mainUserAccount.email.maxLength && errors.push(this.$t('text.emailMaxChars'))
			if (!this.emailIsUnique) { errors.push(this.$t('text.registrationEmailExistsError')) }
			return errors
		},
		mainUserAccountPasswordErrors() {
			let errors = []
			if (!this.$v.mainUserAccount.password.$dirty) return errors
			!this.$v.mainUserAccount.password.required && errors.push(this.$t('text.passwordRequired'))
			!this.$v.mainUserAccount.password.minLength && errors.push(this.$t('text.passwordMinChars'))
			return errors
		},
		mainUserAccountRepeatPasswordErrors() {
			let errors = []
			if (!this.$v.mainUserAccount.repeatPassword.$dirty) return errors
			!this.$v.mainUserAccount.repeatPassword.sameAsPassword && errors.push(this.$t('text.repeatPasswordSame'))
			!this.$v.mainUserAccount.repeatPassword.required && errors.push(this.$t('text.repeatPasswordRequired'))
			!this.$v.mainUserAccount.repeatPassword.minLength && errors.push(this.$t('text.repeatPasswordMinChars'))
			return errors
		},
	  },
	  watch: {
		updateModel() {
			this.model = this.valueToModel(this.serviceProvider)
			this.setInitialUserAccount()
			this.setInitData()
		}
	  },

	  created() {
		this.model = this.valueToModel(this.serviceProvider)
		this.setInitialUserAccount()
		this.setInitData()
	  },
	  methods: {
		valueToModel(v) {
			return JSON.parse(JSON.stringify(v ?? {}))
		},
		setInitData() {
			const initModel = JSON.parse(JSON.stringify(this.model))
			this.initData = {
				mainUserAccount: {
					name:'',
					email:'',
					password:'',
					repeatPassword:''
				}
			}
		},
		sendData() {
			let data = {
				mainUserAccount: this.mainUserAccount
			}

			data.changed = !isEqual(data, this.initData)
			return data
		},
		setInitialUserAccount() {
			if (!this.model.fields.mainUserAccount) {
				this.mainUserAccount = {
					name:'',
					email:'',
					password:'',
					repeatPassword:''
				}
			}
		},
		validateAllFields() {
			let allFieldsAreValid = true
			this.resetSectionError(this.sectionMessage)	

			if (this.model.fields.mainUserAccount?.fields) {
				if (!Object.keys(this.model.fields.mainUserAccount.fields).length || !this.objectValues(this.model.fields.mainUserAccount.fields)) {
					allFieldsAreValid = false
				}
			} else {
				if (this.mainUserAccount && Object.keys(this.mainUserAccount).length > 0) {
					if (!Object.keys(this.mainUserAccount).length || !this.objectValues(this.mainUserAccount)) {
						allFieldsAreValid = false
					} else {
						this.$v.mainUserAccount.name.$touch()
						this.$v.mainUserAccount.email.$touch()
						this.$v.mainUserAccount.password.$touch()
						this.$v.mainUserAccount.repeatPassword.$touch()

						if (this.mainUserAccountNameErrors.length ||
							this.mainUserAccountEmailErrors.length ||
							this.mainUserAccountPasswordErrors.length ||
							this.mainUserAccountRepeatPasswordErrors.length) {

							allFieldsAreValid = false
						}
					}
				}
			}

			if (!allFieldsAreValid) {
				this.setSectionError(this.sectionMessage, this.$t('text.userAccountRequiredError'))
			}

			return allFieldsAreValid
		},
		async checkEmailExists() {
			const res = await this.$httpGet(`/check-email?email=${this.mainUserAccount.email}`)
			this.emailIsUnique = !res.emailExists
		},
	  }
	}
	</script>
	
	<style scoped>
	
	</style>
	
	