<template>
  <v-row justify="center">
    <Disclosure :title="$t('text.contactInfoTitle')" :error="sectionMessage.error" :message="sectionMessage.message"
                :lock="!userMayEditFields" data-cy="contactInfo" ref="SECTION_contactInfo">
      <div>
        <v-radio-group v-model="model.fields.copyContactInfos.de" col>
          <v-radio data-cy="copyContactInfo" style="height:48px" :label="$t('text.copyContactInfos')" :value="true"
                   @input="copyContactInfos()"></v-radio>
          <hr/>
          <v-radio style="height:48px" :label="$t('text.newContactInfos')" :value="false"></v-radio>
        </v-radio-group>
      </div>
      <v-form :disabled="model.fields.copyContactInfos.de" v-for="contactInfoGroup in model.fields.contactInfoGroups.de"
              :key="contactInfoGroup.sys.id">
        <div v-if="hasField('contactInfo','contactInfoAddress', model)" class="field left-border">
          <v-label>{{ $t('text.serviceAddress') }} <span class="error-text">({{ $t('text.required') }})</span></v-label>
          <v-row v-if="contactInfoAddress">
            <v-row style="width:100%">
              <v-text-field outlined hide-details
                            style="padding-bottom:10px"
                            :placeholder="$t('text.placeHolderAddress')"
                            :error-messages="streetAddressErrors"
                            v-model="contactInfoAddress.fields.contactAddresses.de[0].fields.streetAddress.de"
                            @input="validateStreetAddress"
              />
            </v-row>
            <v-row style="width:100%">
              <v-text-field outlined hide-details
                            style="padding-right:10px;padding-bottom:10px"
                            :placeholder="$t('text.placeHolderPostCode')"
                            :error-messages="postalCodeErrors"
                            v-model="contactInfoAddress.fields.contactAddresses.de[0].fields.zipCode.de"
                            @input="validatePostalCode"
              />
              <v-text-field outlined hide-details
                            style="padding-bottom:10px"
                            :placeholder="$t('text.placeHolderCityTown')"
                            :error-messages="cityErrors"
                            v-model="contactInfoAddress.fields.contactAddresses.de[0].fields.city.de"
                            @input="validateCity"
              />
            </v-row>
            <v-row>
              <v-select outlined return-object required
                        :placeholder="$t('text.placeHolderCountry')"
                        :items="countries"
                        :error-messages="countryErrors"
                        :item-text="item => item && item.name ? item.name[selectedLocale] : ''"
                        v-model="contactInfoAddress.fields.contactAddresses.de[0].fields.country"
                        @input="validateCountry"
              />
            </v-row>
          </v-row>
        </div>
        <div v-if="hasField('contactInfo','location', model)" class="field left-border">
          <p class="subTitle ml-0" v-html="$t('text.locationDesc')"></p><br/>
          <LocationField ref="location"
                         v-model="model.fields.location.de"
                         :address="contactInfoAddress.fields.contactAddresses.de[0]"
          />
        </div>
        <div
            v-if="hasField('contactInfo','contactInfoPhone', model) || hasField('contactInfo','contactInfoEmail', model)"
            class="field left-border">
          <v-row style="width:100%">
            <v-col v-if="hasField('contactInfo','contactInfoPhone', model)" style="padding:0px 10px 0px 0px">
              <v-label>{{ $t('text.contactPhoneNumber') }}</v-label>
              <v-text-field v-if="contactInfoPhone" outlined persistent-hint :hint="phoneFormat"
                            v-model="contactInfoPhone.fields.contactDetail.de"
              />
            </v-col>
            <v-col v-if="hasField('contactInfo','contactInfoEmail', model)" style="padding:0">
              <v-label>{{ $t('text.contactEmailAddress') }}</v-label>
              <v-text-field v-if="contactInfoEmail"
                            outlined
                            v-model="contactInfoEmail.fields.contactDetail.de"
                            :error-messages="contactPersonEmailErrors"
                            :hide-details="contactPersonEmailErrors.length===0"
                            @input="validateContactPersonEmail"
              />
            </v-col>
          </v-row>
        </div>
      </v-form>
      <v-form>
        <div v-if="hasField('contactInfo','notificationEmails', model)" class="field left-border">
          <v-row>
            <div>
              <v-label>{{ $t('text.notificationEmailAddress') }}</v-label>
              <br/>
              <p class="helpText" style="margin-left: 0px !important" v-html="$t('text.notificationEmailHelp')"/>

              <v-row class="mb-2" v-for="(contactEmail, contactEmailIndex) in model.fields.notificationEmails.de"
                     :key="contactEmailIndex">
                <v-text-field outlined class="pr-2 mt-0"
                              :error-messages="validateNotificationEmails(contactEmail)"
                              v-model="model.fields.notificationEmails.de[contactEmailIndex]"
                />

                <v-btn v-if="model.fields.notificationEmails.de.length === 1" id="btnRemoveEmailRow"
                       class="gradientButton" elevation="0"
                       @click="model.fields.notificationEmails.de = ['']">
                  <v-icon size="24px">mdi-close</v-icon>
                </v-btn>
                <v-btn v-if="model.fields.notificationEmails.de.length > 1" id="btnRemoveEmailRow"
                       class="gradientButton" elevation="0"
                       @click="model.fields.notificationEmails.de.splice(contactEmailIndex, 1)">
                  <v-icon size="24px">mdi-delete</v-icon>
                </v-btn>
              </v-row>
            </div>
          </v-row>
          <v-row class="mt-2">
            <v-btn id="btnAddEmailRow" class="gradientButton" elevation="0"
                   style="height:40px;font-size:12pt;margin-right:10px"
                   @click="model.fields.notificationEmails.de.push('')">
              <v-icon size="24px">mdi-plus</v-icon>
              {{ $t('text.addAnotherNotificationEmail') }}
            </v-btn>
          </v-row>
        </div>
        <div v-if="hasField('contactInfo','meetingPoint', model)" class="field left-border">
          <v-label>{{ $t('text.meetingPointInfo') }} ({{ $t('text.voucherHelp') }})</v-label>
          <LanguageFlag v-model="serviceLocale" class="flag"/>
          <v-text-field class="mb-3" outlined hide-details
                        v-model="model.fields.meetingPoint[serviceLocale]"
          />
          <p class="helpText" v-html="$t('text.meetingPointInfoDesc')"/>
        </div>
        <div v-if="hasField('contactInfo','postPurchaseInfo', model)" class="field left-border">
          <v-label>{{ $t('text.postPurchaseInfo') }} ({{ $t('text.voucherHelp') }})</v-label>
          <LanguageFlag v-model="serviceLocale" class="flag"/>
          <v-textarea class="mb-3" rows="10" outlined hide-details
                      v-model="model.fields.vouchers.de[0].fields.generalInfo[serviceLocale]"
          />
          <p class="helpText" v-html="$t('text.postPurchaseInfoHelp')"/>
        </div>
      </v-form>
    </Disclosure>
  </v-row>
</template>

<script>
import Common from '@/mixins/Common.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import LocationField from '@/components/common/LocationField.vue'
import LanguageFlag from '@/components/common/LanguageFlag'
import { isEqual } from 'lodash'

export default {
  name: "ContactInfo",
  components: {
    Disclosure,
    LocationField,
    LanguageFlag
  },
  mixins: [Common],
  props: {
    product: Object,
    updateModel: Boolean,
    serviceProvider: Object,
    status: String,
  },
  data() {
    return {
      model: {},
      initData: {},

      sectionMessage: {
        error: false,
        message: ''
      },

      countries: this.getCountries(),
      notificationEmails: {de: ['']},
      contactInfoPhone: {
        sys: {id: 'id_2'},
        fields: {contactInfoType: {de: {fields: {type: {de: 'Phone'}}}}, contactDetail: {de: ''}}
      },
      contactInfoEmail: {
        sys: {id: 'id_3'},
        fields: {contactInfoType: {de: {fields: {type: {de: 'E-Mail'}}}}, contactDetail: {de: ''}}
      },
      contactInfoWebsite: {
        sys: {id: 'id_1'},
        fields: {contactInfoType: {de: {fields: {type: {de: 'Website'}}}}, contactDetail: {de: ''}}
      },
      contactInfoName: {
        sys: {id: 'id_5'},
        fields: {contactInfoType: {de: {fields: {type: {de: 'Name'}}}}, contactDetail: {de: ''}}
      },
      contactInfoAddress: {
        sys: {id: 'id_4'},
        fields: {
          contactInfoType: {de: {fields: {type: {de: 'Address'}}}},
          contactAddresses: {
            de: [{
              sys: {id: 'id_5'},
              fields: {
                streetAddress: {de: ''},
                zipCode: {de: ''},
                city: {de: ''},
                country: {de: {}},
              }
            }]
          }
        }
      },
      streetAddressErrors: [],
      postalCodeErrors: [],
      cityErrors: [],
      contactPersonEmailErrors: [],
      countryErrors: [],
      originalCopyContactInfos: false,
    }
  },
  computed: {
    phoneFormat() {
      if (!this.$store.state.userSpecificGerman) return '(+49 79 123 4567)'
      if (this.$store.state.userSpecificGerman === 'ch') return '(+41 79 123 4567)'
      if (this.$store.state.userSpecificGerman === 'at') return '(+43 79 123 4567)'
      if (this.$store.state.userSpecificGerman === 'de') return '(+49 79 123 4567)'
      return '(+49 79 123 4567)'
    },
  },
  watch: {
    updateModel() {
      this.model = this.valueToModel(this.product)
      this.setInitData()
    },
    'model.fields.copyContactInfos.de': {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          if (newVal === false) {
            this.setContactInfoDetails()
          } else {
            this.copyContactInfos()
          }
        }
      },
      deep: true
    },
  },
  created() {
    this.model = this.valueToModel(this.product)
    this.setContactInfoDetails()
  },
  async mounted() {
    if (this.status === 'new') {
      this.copyContactInfos()
    }

    if (this.model.fields.copyContactInfos.de === true) {
      this.copyContactInfos()
    }

    this.originalCopyContactInfos = this.product.fields.copyContactInfos.de
    this.setInitData()
  },

  methods: {
    valueToModel(v) {
      return JSON.parse(JSON.stringify(v ?? {}))
    },
    setInitData() {
      const initModel = JSON.parse(JSON.stringify(this.model))
      
      this.initData = {
        copyContactInfos: initModel.fields.copyContactInfos,
        contactInfoGroups: initModel.fields.contactInfoGroups,
        location: initModel.fields.location
      }

      if (this.hasField('contactInfo', 'notificationEmails', this.model)) {
        this.initData.notificationEmails = initModel.fields.notificationEmails
      }
      if (this.hasField('contactInfo', 'meetingPoint', this.model)) {
        this.initData.meetingPoint = initModel.fields.meetingPoint
      }
      if (this.hasField('contactInfo', 'postPurchaseInfo', this.model)) {
        this.initData.vouchers = initModel.fields.vouchers
      }
    },
    sendData() {
      let data = {
        copyContactInfos: this.model.fields.copyContactInfos,
        contactInfoGroups: this.model.fields.contactInfoGroups,
        location: this.model.fields.location
      }

      if (this.hasField('contactInfo', 'notificationEmails', this.model)) {
        data.notificationEmails = this.model.fields.notificationEmails
      }
      if (this.hasField('contactInfo', 'meetingPoint', this.model)) {
        data.meetingPoint = this.model.fields.meetingPoint
      }
      if (this.hasField('contactInfo', 'postPurchaseInfo', this.model)) {
        data.vouchers = this.model.fields.vouchers
      }

      data.changed = !isEqual(data, this.initData)

      return data
    },
    // temp validation
    validateAllFields() {
      let allFieldsAreValid = true
      this.resetSectionError(this.sectionMessage)

      const isStreetAddressValid = this.validateStreetAddress()
      const isPostalCodeValid = this.validatePostalCode()
      const isCityValid = this.validateCity()
      const isCountryValid = this.validateCountry()
      const isEmailValid = this.validateContactPersonEmail()
      let isNotificationEmailsValid = []
      for (const email of this.model.fields.notificationEmails?.de ?? []) {
        isNotificationEmailsValid.push(this.validateNotificationEmails(email))
      }
      // filter empty arrays out of isNotificationEmailsValid
      isNotificationEmailsValid = isNotificationEmailsValid.filter((el) => {
        return el.length > 0
      })

      if (!isStreetAddressValid || !isPostalCodeValid || !isCityValid || !isCountryValid || !isEmailValid || isNotificationEmailsValid.length > 0) {
        allFieldsAreValid = false
        this.setSectionError(this.sectionMessage, this.$t('text.missingFieldsError'))
        return false
      }

      return allFieldsAreValid
    },
    validateStreetAddress() {
      let isValid = true
      this.streetAddressErrors = []
      if (this.contactInfoAddress.fields.contactAddresses?.de[0]?.fields?.streetAddress?.de?.length === 0) {
        isValid = false
        this.streetAddressErrors.push(this.$t('text.addressRequiredError'))
      }
      return isValid
    },
    validatePostalCode() {
      let isValid = true
      this.postalCodeErrors = []
      if (this.contactInfoAddress.fields.contactAddresses?.de[0]?.fields?.zipCode?.de?.length === 0) {
        isValid = false
        this.postalCodeErrors.push(this.$t('text.addressRequiredError'))
      }
      return isValid
    },
    validateCity() {
      let isValid = true
      this.cityErrors = []
      if (this.contactInfoAddress.fields.contactAddresses?.de[0]?.fields?.city?.de?.length === 0) {
        isValid = false
        this.cityErrors.push(this.$t('text.addressRequiredError'))
      }
      return isValid
    },
    validateCountry() {
      let isValid = true
      this.countryErrors = []
      if (this.contactInfoAddress.fields.contactAddresses?.de[0]?.fields?.country?.de?.length === 0) {
        isValid = false
        this.countryErrors.push(this.$t('text.addressRequiredError'))
      }
      return isValid
    },
    validateContactPersonEmail() {
      let isValid = true
      this.contactPersonEmailErrors = []
      if (this.contactInfoEmail.fields?.contactDetail?.de && !this.validateEmail(this.contactInfoEmail.fields.contactDetail.de)) {
        isValid = false
        this.contactPersonEmailErrors.push(this.$t('text.invalidEmail'))
      }
      return isValid
    },
    validateEmail(email) {
      let re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/
      return re.test(String(email).toLowerCase());
    },
    validateNotificationEmails(email) {
      let errors = []
      if (email && email.length > 0 && !this.validateEmail(email)) {
        errors.push(this.$t('text.invalidEmail'))
      }
      return errors
    },
    setContactInfoDetails() {
      const p = this.model.fields
      if (p.contactInfoGroups?.de?.length > 0 && p.contactInfoGroups.de[0].fields?.contactInfos?.de?.length > 0) {
        let contactInfos = p.contactInfoGroups.de[0].fields?.contactInfos?.de

        if (contactInfos.length === 0) return

        this.contactInfoPhone = contactInfos?.find(ci => ci?.fields?.contactInfoType?.de?.fields?.type?.de === "Phone")
        this.contactInfoEmail = contactInfos?.find(ci => ci?.fields?.contactInfoType?.de?.fields?.type?.de === "E-Mail")
        //this.contactInfoWebsite = contactInfos?.find(ci => ci.fields?.contactInfoType?.de.fields.type.de === "Website")
        this.contactInfoWebsite = this.serviceProvider.fields.contactInfos?.de?.find(ci => ci?.fields?.contactInfoType?.de?.fields?.type?.de === 'Website')
        this.contactInfoName = contactInfos?.find(ci => ci?.fields?.contactInfoType?.de?.fields?.type?.de === "Name")
        this.contactInfoAddress = contactInfos?.find(ci => ci?.fields?.contactInfoType?.de?.fields?.type?.de === "Address")
        
        const country = this.countries.find(country => country.name.de === this.contactInfoAddress.fields.contactAddresses.de[0].fields?.country?.de)

        if (country) {
          this.contactInfoAddress.fields.contactAddresses.de[0].fields.country = country
        }
        
      } else {
        p.contactInfoGroups = {
          de: [{
            sys: {id: ''},
            fields: {
              contactInfos: {
                de: []
              },
            }
          }]
        }
      }

      this.contactInfoPhone.sys.id = ''
      this.contactInfoEmail.sys.id = ''
      if (this.contactInfoWebsite) { this.contactInfoWebsite.sys.id = '' }
      this.contactInfoName.sys.id = ''
      this.contactInfoAddress.sys.id = ''

      // We need a reference from internal data to the model data
      p.contactInfoGroups.de[0].fields.contactInfos.de = [
        this.contactInfoPhone,
        this.contactInfoEmail,
        this.contactInfoAddress,
        this.contactInfoWebsite,
        this.contactInfoName
      ]

      if (!("copyContactInfos" in p)) {
        p["copyContactInfos"] = {de: false}
      }
    },
    copyContactInfos() {
      const contactInfos = this.serviceProvider.fields.contactInfos?.de ?? []

      // copy address, etc. defaults from serviceProvider
      this.contactInfoPhone = contactInfos?.find(spi => spi.fields?.contactInfoType?.de.fields.type.de === "Phone")
      this.contactInfoEmail = contactInfos?.find(spi => spi.fields?.contactInfoType?.de.fields.type.de === "E-Mail")
      this.contactInfoWebsite = contactInfos?.find(spi => spi?.fields?.contactInfoType?.de?.fields.type.de === 'Website')
      this.contactInfoName = contactInfos?.find(spi => spi.fields?.contactInfoType?.de.fields.type.de === "Name")
      this.contactInfoAddress = contactInfos?.find(spi => spi.fields?.contactInfoType?.de.fields.type.de === "Address")
      const country = this.countries.find(c => c.name.de === this.contactInfoAddress.fields.contactAddresses.de[0].fields?.country?.de)
   
      if (country) {
        this.contactInfoAddress.fields.contactAddresses.de[0].fields.country = country
        this.contactInfoAddress.fields.contactAddresses.de[0].sys.id = ''
      }
      
      this.model.fields.location.de = this.serviceProvider.fields.location.de
    },
    // Note: This method is called via $refs in the parent component
    resetContactInfoIds() {
      const copyContactInfosChanged = this.originalCopyContactInfos !== this.model.fields.copyContactInfos.de
      // clear the id-s of the contact infos and contactInfoGroup of the product ONLY if the product was copying the contact infos from the service provider and this has now changed; otherwise we would be creating new contact infos every time we save the product
      if (this.model.fields.copyContactInfos.de === false && (this.status === 'new' || copyContactInfosChanged)) {
        this.model.fields.contactInfoGroups.de[0].sys.id = ''
        this.model.fields.contactInfoGroups.de[0].fields.contactInfos.de = this.model.fields.contactInfoGroups.de[0].fields.contactInfos.de.map(contactInfo => {
          if (contactInfo) {
            // clear the id-s of the contact infos except the website contact info
            if (contactInfo?.fields?.contactInfoType?.de?.fields?.type?.de !== 'Website') {
              contactInfo.sys.id = ''
            }
            // we also want to set the contactAddresses[0].sys.id to '' because otherwise the address contact info will still reference the sp address
            if (contactInfo?.fields?.contactAddresses?.de) {
              contactInfo.fields.contactAddresses.de.forEach(address => {
                address.sys.id = ''
              })
            }

            return contactInfo
          }
        })
      }
    },
  }
}
</script>



