<template>
  <div>
    <v-app-bar v-if="!hideNav" app color="#ffffff" elevation="0" height="64">
      <v-row id="componentRow" align="center">
        <div id="componentContainer">
          <v-list-item style="cursor: default;">
            <v-list-item-avatar height="36px" width="36px" min-width="30px" :color="color">
                <v-list-item>
                  <v-img style="width:16px;height:16px;opacity:0.5;" contain :src="this.selectedComponent && this.selectedComponent.fields ? this.selectedComponent.fields.icon.de.fields.file.de.url : ''"/>
                </v-list-item>
            </v-list-item-avatar>

            <v-list-item-content class="pa-0">
              <v-list-item-title style="font-size:16pt !important;color: #999999;">MyServices
                <v-list-item-title v-bind:style="[componentStyle]">{{getComponentName()}}</v-list-item-title>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <v-spacer/>
        <img src="@/assets/icons/discover.svg" width="24px" height="24px" class="mr-2" style="cursor:pointer" v-if="showDiscoverIcon" @click="showDashboard"/>
      </v-row>
      <v-divider vertical class="divider"></v-divider>
      <component :is="navbar" :title="title" :activeLink="activeLink" class="navbarComponent" style="width:100%;"
        @input="model = $event; $emit('input', model)"
        @event="$emit('event', $event)"
        v-model="model">
      </component>
      <v-divider vertical class="divider"></v-divider>
      <!-- Profile / Logout / Language Menu -->
      <div style="margin-right: 10px">
        <v-menu offset-y offset-x style="z-index:1000">
          <template v-slot:activator="{ on }">
            <v-row style="width:75px" align="center" justify="center" v-on="on">
              <v-btn id="btnUserProfile" icon style="background:none !important;border:0 !important;margin-left:10px;margin-right:-10px">
                <img src="@/assets/icons/user.svg" width="28px" height="28px" style="cursor:pointer"/>
              </v-btn>
              <img src="@/assets/icons/arrow-down.svg" width="24px" height="24px" style="cursor:pointer"/>
            </v-row>
          </template>
          <v-list style="width:300px">
            <v-list-item style="cursor:pointer" @click="setLocale('de')" v-if="localeVisible">
              <v-list-item-title data-cy="changeLanguage-de">
                <v-row align="center">
                  <LanguageFlag value="de" style="cursor:pointer;margin-right:5px" />
                  {{ $t('text.German') }}
                </v-row>
              </v-list-item-title>
            </v-list-item>
            <v-list-item style="cursor:pointer" @click="setLocale('en')" v-if="localeVisible">
              <v-list-item-title data-cy="changeLanguage-en">
                <v-row align="center">
                  <LanguageFlag value="en" style="cursor:pointer;margin-right:5px" />
                  {{ $t('text.English') }}
                </v-row>
              </v-list-item-title>
            </v-list-item>
            <v-list-item style="cursor:pointer" @click="setLocale('fr')" v-if="localeVisible">
              <v-list-item-title justify="center">
                <v-row align="center">
                  <LanguageFlag value="fr" style="cursor:pointer;margin-right:5px" />
                  {{ $t('text.French') }}
                </v-row>
              </v-list-item-title>
            </v-list-item>
            <v-list-item style="cursor:pointer" @click="setLocale('it')" v-if="localeVisible">
              <v-list-item-title justify="center">
                <v-row align="center">
                  <LanguageFlag value="it" style="cursor:pointer;margin-right:5px" />
                  {{$t('text.Italian')}}
                </v-row>
              </v-list-item-title>
            </v-list-item>
            <v-divider v-if="haveUser" />
            <v-list-item class="accountAction" style="cursor: default;" v-if="haveUser && this.$store.state.loggedInUser && this.$store.state.loggedInUser.fields">
              <v-list-item-title data-cy="userEmail">
                <v-icon>mdi-account</v-icon>
                {{ this.$store.state.loggedInUser.fields.email.de }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item class="accountAction" v-if="haveUser && userIsImpersonating" @click="exitImpersonation()">
              <v-list-item-title data-cy="isImpersonation">
                <v-icon>mdi-logout</v-icon>
                AS: {{ this.$store.state.selectedServiceProvider.fields.title.de }}<br />
              </v-list-item-title>
            </v-list-item>
            <!--
            TODO: also set the linked sp do this!
              v-if="item.addl && item.addl.isHomebase && item.fields.mainAccountId && item.fields.mainAccountId.de"
            -->
            <v-list-item class="accountAction" v-if="canImpersonate" @click="impersonate($store.state.selectedServiceProvider)">
              <v-list-item-title data-cy="enterImpersonation">
                <v-icon>mdi-login</v-icon>
                OP AS: {{ this.$store.state.selectedServiceProvider.fields.title.de }}<br />
              </v-list-item-title>
            </v-list-item>

            <v-divider v-if="haveUser" />
            <v-list-item style="cursor:pointer" v-if="haveUser" @click="showChangePasswordDialog()">
              <v-list-item-title><v-icon>mdi-key</v-icon> {{$t('text.changePassword')}}</v-list-item-title>
            </v-list-item>
            <v-list-item style="cursor:pointer" data-cy="logout" v-if="haveUser" @click="logout()">
              <v-list-item-title><v-icon>mdi-lock</v-icon> {{$t('text.logout')}}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <div v-show="showChangePassword" class="dialogDiv">
        <v-alert :value="errorTitle!==''" :v-if="errorTitle!==''" type="error" dismissible flat class="notification" prominent v-model="errorAlertVisible"><span v-html="errorMessages"></span></v-alert>
        <v-alert :value="successTitle!==''" :v-if="successTitle!==''" type="success" dismissible flat class="notification" prominent v-model="successAlertVisible"><span v-html="successMessages"></span></v-alert>

        <v-form>
          <v-row>
            <v-col>
              <v-label><h3>{{$t('text.changePassword')}}</h3></v-label><br/>
              <v-form>
                  <v-label>{{$t('text.currentPassword')}}</v-label>
                  <v-text-field type="password" outlined dense
                    v-model="currentPassword"
                    autocomplete="off"
                    :error-messages="currentPasswordErrors"
                    @input="$v.currentPassword.$touch()"
                    @blur="$v.currentPassword.$touch()"
                    required></v-text-field>

                  <v-label>{{$t('text.newPassword')}}</v-label>
                  <v-text-field type="password" outlined dense
                    v-model="password"
                    autocomplete="new-password"
                    :error-messages="passwordErrors"
                    @input="$v.password.$touch()"
                    @blur="$v.password.$touch()"
                    required></v-text-field>

                  <v-label>{{$t('text.repeatPassword')}}</v-label>
                  <v-text-field type="password" outlined dense
                    v-model="repeatPassword"
                    autocomplete="new-password"
                    :error-messages="repeatPasswordErrors"
                    @input="$v.repeatPassword.$touch()"
                    @blur="$v.repeatPassword.$touch()"
                    required sameAs></v-text-field>
                </v-form>
              <v-row style="margin-left:5px">
                <v-btn id="btnCancel" class="gradientButton" elevation="0" style="margin-right:10px" @click="showChangePassword=false">{{$t('text.cancel')}}</v-btn>
                <v-btn id="btnChangePassword" class="gradientButton" elevation="0" :disabled="currentPassword===''||password===''||repeatPassword===''" @click="changePassword()">{{$t('text.changePassword')}}</v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-app-bar>

    <!-- System Bar -->
    <v-system-bar v-if="showImpersonationInfo" color="rgb(0, 174, 239)" class="system-bar">
          <v-spacer></v-spacer>      
          <v-icon class="white--text">mdi-account-circle</v-icon>
          <span class="white--text"> 
            {{ $t('text.currentlyViewingAs') }} 
            {{ $t('text.serviceProvider') }} 
            {{ impersonatedName }}
            ({{ impersonatedEmail }})
          </span>
          <v-spacer></v-spacer>
    </v-system-bar>
  </div>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators"
import Common from '@/mixins/Common.vue';
import LanguageFlag from './LanguageFlag.vue';
import Dialog from '@/components/common/Dialog.vue'

export default {
	components: { LanguageFlag, Dialog },
	name: 'NavBar',
	mixins: [ Common ],

  props: {
    navbar: {},
    activeLink: {
      type: String,
      required: false
    },
	  value: {},
    hideNav: Boolean,
  },

  validations: {
    currentPassword: {
      required,
      minLength: minLength(8)
    },
    password: {
      required,
      minLength: minLength(8)
    },
    repeatPassword: {
      required,
      minLength: minLength(8),
      sameAsPassword: sameAs('password')
    },
  },

  data() {
    return {
      haveComponent: false,
      haveUser: false,
      showChangePassword: false,
      currentPassword: '',
      password: '',
      repeatPassword: '',
      loggedInUser: {},

      title: '',

      errorTitle: '',
      errorDetail: '',
      errorAlertVisible: false,
      successTitle: '',
      successDetail: '',
      successAlertVisible: false,

      componentStyle: {
        color: "#999999",
        fontWeight: "bold",
        fontSize: "16pt !important"
      },

      color: '#bfbfbf',
      icon: '',
      showDiscoverIcon: false,
      selectedComponent: {},
      user: {},
      model: null
    }
  },

  computed: {
    impersonatedName() {
      return this.$store.state.impersonatedServiceProvider?.fields?.title?.[this.selectedLocale]
    },
    impersonatedEmail() {
      return this.$store.state.impersonatedServiceProvider?.fields?.mainUserAccount?.fields?.email?.de
    },
    canImpersonate() {
      let impersonateEnabled = false

      if (this.haveUser &&
          !this.userIsImpersonating &&
          this.userIsHomeOperator &&
          (this.$store.state.selectedServiceProvider?.fields?.mainAccountId?.de || 
          this.$store.state.selectedServiceProvider?.fields?.userAccount?.de?.sys?.id)) {

          impersonateEnabled = true

      }

      return impersonateEnabled
    },
    localeVisible() {
      return this.$route.path === '/' || this.$route.path === '/dashboard' || this.$route.path === '/forgotPassword' ? true : false
    },
    errorMessages() {
      return ' <span style="font-size:14pt;font-weight:bold;"> ' + this.errorTitle + ' </span><br> ' + this.errorDetail
    },
    successMessages() {
      return ' <span style="font-size:14pt;font-weight:bold;"> ' + this.successTitle + ' </span><br> ' + this.successDetail
    },
    componentColour() {
      return this.selectedComponent.color
    },
    currentPasswordErrors() {
      const errors = []
      if (!this.$v.currentPassword.$dirty) return errors
      !this.$v.currentPassword.required && errors.push(this.$t('text.passwordRequired'))
      !this.$v.currentPassword.minLength && errors.push(this.$t('text.passwordMinChars'))
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push(this.$t('text.newPasswordRequired'))
      !this.$v.password.minLength && errors.push(this.$t('text.newPasswordMinChars'))
      return errors
    },
    repeatPasswordErrors() {
      const errors = []
      if (!this.$v.repeatPassword.$dirty) return errors
      !this.$v.repeatPassword.sameAsPassword && errors.push(this.$t('text.repeatPasswordSame'))
      !this.$v.repeatPassword.required && errors.push(this.$t('text.repeatPasswordRequired'))
      !this.$v.repeatPassword.minLength && errors.push(this.$t('text.repeatPasswordMinChars'))
      return errors
    },
  },

  watch: {
    '$store.state.selectedLocale': function() {
			this.selectedLocale = this.$store.state.selectedLocale
		},
    selectedComponent() {
      this.haveComponent = this.selectedComponent?.fields
      this.showDiscoverIcon = this.selectedComponent?.fields
      this.color = this.selectedComponent?.fields ? this.selectedComponent.fields.colour.de : '#bfbfbf'
      this.componentStyle.color = this.selectedComponent?.fields ? this.selectedComponent.fields.colour.de : '#bfbfbf'
      this.icon = this.selectedComponent?.fields ? this.getAssetUrl(this.selectedComponent.fields.icon.de) : ''
    },
    errorAlertVisible() {
      if (!this.errorAlertVisible) return
      setTimeout(this.closeAlert, 3000)
    },
    successAlertVisible() {
      if (!this.successAlertVisible) return
      setTimeout(this.closeAlert, 3000)
    },
    value(n) { this.model = n },
  },
  
  created() { this.model = this.value },

  async mounted() {
    this.loggedInUser = await this.$store.state.loggedInUser

    this.$root.$i18n.locale = this.selectedLocale
    this.$vuetify.lang.current = this.selectedLocale

    if (this.$store.state.loggedInUser && this.$store.state.loggedInUser.hasOwnProperty("kc_token")) {
      this.selectedComponent = this.$store.state.selectedComponent
      this.user = this.$store.state.loggedInUser
      this.haveUser = true
    } else {
      this.selectedComponent = {}
      this.user = {}
      this.haveUser = false
    }
  },

  methods: {
    async setLocale(locale) {
      this.$root.$i18n.locale = locale
      this.$vuetify.lang.current = locale
      await this.$store.commit('setSelectedLocale', locale)
    },

    showChangePasswordDialog() {
      this.currentPassword = "";
      this.password = "";
      this.repeatPassword = "";
      this.showChangePassword = true;
    },

    async changePassword() {
      this.$v.$touch();

      this.isLoading = true;
      let emailAddress = this.$store.state.isImpersonation ? this.$store.state.selectedServiceProvider.fields.mainUserAccount.email : this.$store.state.loggedInUser.fields.email.de

      var data = {
        email: emailAddress,
        currentPassword: this.currentPassword,
        password: this.password
      }

      try {
        const res = await this.$httpPost('/reset-password', data)

        this.successTitle = this.$t('text.PASSWORD_CHANGED');
        this.successDetail = this.$t('text.passwordChangeSuccess');

        this.successAlertVisible = true;
      } catch (error) {
        this.showError(this.$t('text.passwordChangeError'))
      }
    },

    async logout() {
      this.haveUser = false;
      this.$emit("show-login");
    },

    showDashboard() {
      this.$emit("show-dashboard")
    },

    showComponent() {
      this.$emit("show-component");
    },

    getComponentName() {
      if (this.selectedComponent) {
        let componentName = this.selectedComponent && this.selectedComponent.fields ? this.selectedComponent.fields.title[this.selectedLocale] : ''
        return componentName.length > 22 ? componentName.substring(0,22) + '...' : componentName
      }
    },

    closeAlert() {
      if (this.errorAlertVisible) {
        this.errorAlertVisible = false;
      } else if (this.successAlertVisible) {
        this.successAlertVisible = false;
        this.showChangePassword = false;
      }
    },
	},



}
</script>

<style scoped>
.v-app-bar.v-app-bar--fixed {
  border-bottom: 1px solid #b4c3ca !important;
}
#componentRow {
  width: 379.25px;
  min-width: 379.25px;
  max-width: 379.25px;
}

.headline {
  color: #999999;
  font-weight: normal;
  font-size: 16pt !important;
}
.gradient {
  background: linear-gradient(0deg, #efefef 0%, #ffffff 100%);
  border: thin #dddddd;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.v-list-item__title { font-size: 12pt !important; }
.v-list-item__title .v-icon { margin-right: 5px; }
.dialogDiv {
  position:absolute;
  right:10px;
  top:55px;
  width:465px;
  background:#ffffff;
  border-radius:5px;
  border:thin solid #cfcfcf;
  box-shadow:1px 1px 15px 0 rgba(0,0,0,0.2);
  padding: 20px 20px 0;
  color:#acacac;
  z-index: 999;
  max-width: calc(100vw - 20px);
}

.accountAction { background-color: #fafafa; }
.accountAction .v-list-item__title { font-size: 12px !important; }

.divider {
  border-color: #b4c3ca !important;
  border-width: 0.5px !important;
  opacity: 1 !important;
}
</style>