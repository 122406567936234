<template>
  <div style="width:100%; height: 100%;">
    <v-tooltip top open-delay="500" color="white" max-width="300px" content-class="tooltip-arrow-top">
      <template v-slot:activator="{ on, attrs }">
        <v-card flat class="card" :class="isCustomiseDashboard ? 'draggableCard' : ''" color="transparent" @mousedown="start()" @mouseup="stop()"
          v-bind="attrs" v-on="on">
            <v-badge bordered color="error" :content="badgeCount" overlap v-if="badgeCount>0"></v-badge>
            <div class="d-flex align-center img-container pointer" style="height: 70%" :style="{'background':componentCard.fields.colour.de + ' !important'}">
              <v-img style="opacity:0.5;" :height="imgSize" :width="imgSize" contain :src="getAssetUrl(componentCard.fields.icon.de)"/>
            </div>

            <div style="height: 30%" class="d-flex align-center justify-center px-1 text-center label pointer grey--text text--darken-2">
              <span :style="{ 'font-size': fontSize}">{{componentCard.fields.title[selectedLocale]}}</span>
            </div>
          </v-card>
        </template>
        <span>{{ componentCard.fields.tooltip ? componentCard.fields.tooltip[selectedLocale] : '' }}</span>
      </v-tooltip>
  </div>
</template>

<script>
import Common from '@/mixins/Common.vue'

export default {
	name: "ComponentCard",
	mixins: [ Common ],
	props: {
		componentCard: Object,
		isCustomiseDashboard: Boolean,
	},

  data () {
    return {
      badgeCount: 0,
      startCustomiseTimer: 0,
    }
  },

  computed: {
    imgSize () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 50
        case 'sm': return 60
        case 'md': return 70
        case 'lg': return 80
        case 'xl': return 80
        default: return 100
      }
    },
    fontSize () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '8pt'
        case 'sm': return '10pt'
        case 'md': return '12pt'
        case 'lg': return '14pt'
        case 'xl': return '16pt'
        default: return '14pt'
      }
    }
  },
  
  watch: {
		'$vuetify.lang.current': function() {
			this.selectedLocale = this.$vuetify.lang.current
		}
	},

  async mounted() {
    let url = ''
    let requestParams = ''
    let userId = ''
    let lookupCount = false

    switch (this.componentCard.fields.title.en) {
      case "Service Providers":
        lookupCount = true
        url = '/serviceprovider-count'
        requestParams = `?status=pending&client=${this.$store.state.selectedClient.sys.id}`

        if (this.$store.state.isImpersonation === true && this.$store.state.selectedServiceProvider.fields.tags?.de) {
          requestParams += `&tags=${this.$store.state.selectedServiceProvider.fields.tags.de}`
        }
        if (this.userIsOperator && this.$store.state.isImpersonation === false && !this.$store.state.loggedInUser.fields.canSeeObjectsWithTags.de.find(x => x === "ALL")) {
          requestParams += `&tags=${this.$store.state.loggedInUser.fields.canSeeObjectsWithTags.de}`
        }
        break

      case "Manage Services":
        lookupCount = true
        url = '/product-count'
        requestParams = `?status=pending&client=${this.$store.state.selectedClient.sys.id}`
        if (this.$store.state.isImpersonation === true && this.$store.state.selectedServiceProvider.fields.tags?.de) {
          requestParams += `&tags=${this.$store.state.selectedServiceProvider.fields.tags.de}`
        }
        if (this.userIsOperator && this.$store.state.isImpersonation === false && !this.$store.state.loggedInUser.fields.canSeeObjectsWithTags.de.find(x => x === "ALL")) {
          requestParams += `&tags=${this.$store.state.loggedInUser.fields.canSeeObjectsWithTags.de}`
        }
        break

      case "Messages":
        lookupCount = true
        url = '/message-count'
        userId = this.$store.state.isImpersonation === true ? this.$store.state.selectedServiceProvider?.fields?.userAccount?.de?.sys?.id : this.$store.state.loggedInUser.sys.id
        requestParams = `?userId=${userId}`
        break
    }

    if (lookupCount === true) {
      const res = await this.$httpGet(url, requestParams)
      this.badgeCount = res.count
    }
  },

  methods: {
    start() {
      if (!this.isCustomiseDashboard) {
        if (!this.startCustomiseTimer) {
          this.startCustomiseTimer = setTimeout(this.startDraggable, 1000);
        }
      }
    },

    async stop() {
      if (!this.isCustomiseDashboard) {
        clearTimeout(this.startCustomiseTimer);
        await this.$store.commit('setSelectedComponent', this.componentCard);
        this.$emit('view-component');
      
      } else {
        clearTimeout(this.$store.state.timer);
        var timerId = setTimeout(this.stopDraggable, 5000);
        await this.$store.commit('setTimer', timerId);
      }
    },

    startDraggable() {
      if (!this.isCustomiseDashboard) {
        clearTimeout(this.startCustomiseTimer);
        this.startCustomiseTimer = null;
        this.$emit('start-draggable');
      }
    },

    stopDraggable() {
      if (this.isCustomiseDashboard) {
        this.$emit('stop-draggable');
      }
    }
  }
}
</script>

<style scoped>
.card {
  height: 100%;
  width: 100%;
}

.label {
  width: 100% !important;
  background-color: white !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.img-container {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
.pointer {
  cursor: pointer;
}
.containerCorner {
  display: inline-block;
  position: absolute;
  z-index: 99;
  width: 13px;
  height: 16px;
  left: 0;
  top: 0;
}
.containerLock {
  display: inline-block;
  position: absolute;
  z-index: 100;
  width: 65px;
  height: 65px;
  left: 0;
  top: 0;
  margin-left: 10px;
  margin-top: 10px;
}
@keyframes shake {
  from {
    transform: rotate(-1.5deg);
  }
  to {
    transform: rotate(1.5deg);
  }
}

.draggableCard {
  animation-name: shake;
  animation-duration: 0.1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

</style>

<style lang="scss">
.v-badge {
  padding: 0 !important;
  position: absolute !important;
}

.v-tooltip__content {
  color: black !important;
  opacity: 1 !important;
  padding: 24px !important;
  z-index: 999 !important;
}

.tooltip-arrow-top::before {
  border-top: solid 8px white;
  border-right: solid 8px transparent;
  border-left: solid 8px transparent;
  transform: translateX(-50%);
  position: absolute;
  z-index: -21;
  content: '';
  top: 100%;
  left: 50%;
  height: 0;
  width: 0;
}
</style>
