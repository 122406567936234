<template class="justify-center">
  <v-form style="width: 100%;">
    <v-row>
      <v-divider vertical class="mx-1 mt-2 mb-5"></v-divider>
      <v-col>
        <v-row justify="space-between">
          <v-label>{{$t('text.question')}}</v-label>
          <v-menu offset-y offset-x v-if="index > 0">
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-icon color="#737373" style="cursor:pointer">mdi-dots-horizontal</v-icon>
              </div>
            </template>
            <v-list class="pa-0">
              <v-list-item style="cursor:pointer !important;" @click="removeQuestion">
                <v-list-item-title>{{$t('text.delete')}}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
        <v-row>
          <LanguageFlag v-model="serviceLocale" class="langFlag" />
          <v-text-field outlined required
            v-if="faqComponent.fields.question"
            v-model="faqComponent.fields.question[serviceLocale]"
            @keydown="changeStatus"
          />
        </v-row>
        <v-row>
          <v-label>{{$t('text.answer')}}</v-label>
          <br/>
        </v-row>
        <v-row>
          <LanguageFlag v-model="serviceLocale" class="langFlag" />
          <v-textarea outlined
            v-if="faqComponent.fields.answer"
            v-model="faqComponent.fields.answer[serviceLocale]"
            @keydown="changeStatus"
          />
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import LanguageFlag from '@/components/common/LanguageFlag.vue'
import Common from '@/mixins/Common'

export default {
	props: {
		faqComponent: Object,
    index: Number
	},
  mixins: [Common],
  components: { LanguageFlag },
	methods: {
		changeStatus() {
			this.$emit("status-changed")
		},
		removeQuestion() {
			this.$emit("remove-question")
		}
	},
	mounted() {
		if (!this.faqComponent.fields.question?.de) this.faqComponent.fields.question = { de: '' }
		if (!this.faqComponent.fields.answer?.de) this.faqComponent.fields.answer = { de: '' }
		this.$forceUpdate()
	},
}
</script>

<style scoped>
.enabled-text-style .v-input__control > .v-input__slot {
	background: #ffffff !important;
}
.langFlag {
  position: absolute;
  margin-top: 18px;
  margin-left: -32px;
  zoom: 0.8;
}
</style>
