<template>
	<v-container class="main" fluid style="padding-bottom: 100px;">
		<v-app-bar app color="#ffffff" flat>
			<v-card id="componentContainer" flat>
				<v-list-item class="listItemStyle-nocomp"  @click="$router.push('/')">
					<v-list-item-avatar height="36px" width="36px" min-width="30px" color="#bfbfbf"></v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title class="mainStyle-nocomp">MyServices</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-card>

			<v-container fluid style="margin-top:5px" class="navbarComponent">
				<v-stepper v-model="step" class="elevation-0">
					<v-stepper-header>
						<v-stepper-step :complete="step > 1" step="1" color="#62c522">{{ $t('text.step1Label') }}</v-stepper-step>
						<v-divider></v-divider>
						<v-stepper-step :complete="step > 2" step="2" color="#62c522">{{ $t('text.step2Label') }}</v-stepper-step>
					</v-stepper-header>
				</v-stepper>
			</v-container>

      <!-- Language Toggle -->
      <v-menu offset-y offset-x>
        <template v-slot:activator="{ on }">
          <v-row style="width:125px" align="center" justify="center" v-on="on">
            <v-btn id="btnUserProfile" icon style="background:none !important;border:0 !important;margin-left:10px;margin-right:-10px">
              <LanguageFlag v-model="selectedLocale" />
            </v-btn>
            <img src="@/assets/icons/arrow-down.svg" width="24px" height="24px" style="cursor:pointer"/>
          </v-row>
        </template>
        <v-list style="width:200px">
          <v-list-item style="cursor:pointer" @click="setLocale('de')">
            <v-list-item-title data-cy="changeLanguage-de">
              <v-row align="center">
                <LanguageFlag value="de" style="cursor:pointer;margin-right:5px" />
                {{ $t('text.German') }}
              </v-row>
            </v-list-item-title>
          </v-list-item>
          <v-list-item style="cursor:pointer" @click="setLocale('en')">
            <v-list-item-title data-cy="changeLanguage-en">
              <v-row align="center">
                <LanguageFlag value="en" style="cursor:pointer;margin-right:5px" />
                {{ $t('text.English') }}
              </v-row>
            </v-list-item-title>
          </v-list-item>
          <v-list-item style="cursor:pointer" @click="setLocale('fr')">
            <v-list-item-title data-cy="changeLanguage-fr">
              <v-row align="center">
                <LanguageFlag value="fr" style="cursor:pointer;margin-right:5px" />
                {{ $t('text.French') }}
              </v-row>
            </v-list-item-title>
          </v-list-item>
          <v-list-item style="cursor:pointer" @click="setLocale('it')">
            <v-list-item-title data-cy="changeLanguage-it">
              <v-row align="center">
                <LanguageFlag value="it" style="cursor:pointer;margin-right:5px" />
                {{ $t('text.Italian') }}
              </v-row>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

		</v-app-bar>

		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

		<!-- Step 1 -->
		<v-row justify="center" v-show="step === 1">
			<v-card id="card" class="elevation-4">
				<v-card-text style="margin:0px;padding:0px">
					<h1>{{$t('text.registrationTitle')}}</h1>
					<p class="subTitle" v-html="$t('text.registrationSubTitle')" style="margin-left:0px"></p>
					<v-form>
						<div class="field left-border" v-if="clientRequired">
							<v-label>{{ $t('text.marketplace') }} <span class="error-text">({{$t('text.required')}})</span></v-label>
							<v-select outlined dense
								autocomplete="off"
								:placeholder="$t('text.marketplace')"
								v-model="serviceProvider.marketplace"
								:error-messages="marketplaceErrors"
								:items="clients"
								item-text="fields.title.de"
								item-value="fields.title.de"
								data-cy="marketplace"/>
						</div>
						<div class="field left-border">
							<v-label>{{$t('text.personalName')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
							<v-text-field outlined required autocomplete="name"
                :hide-details="contactPersonNameErrors.length===0"
								:error-messages="contactPersonNameErrors"
								@input="$v.contactInfoName.fields.contactDetail.de.$touch()"
								@blur="$v.contactInfoName.fields.contactDetail.de.$touch()"
								v-model="contactInfoName.fields.contactDetail.de"
								data-cy="personalName"/>
						</div>
						<div class="field left-border">
							<v-label>{{$t('text.email')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
							<v-text-field outlined required type="email" autocomplete="email"
                :hide-details="contactPersonEmailErrors.length===0"
								:error-messages="contactPersonEmailErrors"
								@input="$v.serviceProvider.fields.contactPersonEmail.de.$touch()"
								@blur="checkEmailExists"
								v-model="serviceProvider.fields.contactPersonEmail.de"
								data-cy="email"/>
              <p class="helpText" v-html="$t('text.registrationEmailHelp')"/>
            </div>
						<div class="field left-border">
							<v-label>{{$t('text.password')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
							<v-text-field outlined dense required
								autocomplete="new-password"
								type="password"
								v-model="serviceProvider.password"
								:error-messages="passwordErrors"
                :hide-details="passwordErrors.length===0"
								@input="$v.serviceProvider.password.$touch()"
								@blur="$v.serviceProvider.password.$touch()"
								data-cy="password"/>
						</div>
						<div class="field left-border">
							<v-label>{{$t('text.repeatPassword')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
							<v-text-field type="password" outlined dense required sameAs
								autocomplete="new-password"
								v-model="serviceProvider.repeatPassword"
								:error-messages="repeatPasswordErrors"
                :hide-details="repeatPasswordErrors.length === 0"
								@input="$v.serviceProvider.repeatPassword.$touch()"
								@blur="$v.serviceProvider.repeatPassword.$touch()"
								data-cy="repeatPassword"/>
						</div>
						<div class="field left-border">
							<v-label>{{$t('text.contactPersonPhone', { format: phoneFormat})}}</v-label>
							<v-text-field outlined required hide-details
								v-model="serviceProvider.fields.contactPersonPhone.de"
                type="tel"
                autocomplete="tel"
								data-cy="contactPersonPhone"/>
						</div>
						<v-row align="center" justify="center" style="margin-top:20px">
							<v-btn data-cy="btn-step-2" depressed dark class="activeButton" @click.prevent="next()">{{$t('text.nextStep')}}</v-btn>
						</v-row>
					</v-form>
				</v-card-text>
			</v-card>
		</v-row>

		<!-- Step 2 -->
		<v-row justify="center" v-show="step === 2">
			<v-card id="card" class="elevation-4">
				<v-card-text style="margin:0px;padding:0px">
					<h1>{{$t('text.businessInfoTitle')}}</h1><br/>
					<v-form>
						<div class="field left-border">
							<v-label>{{$t('text.businessName')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
							<v-text-field outlined required autocomplete="organization"
								:hide-details="businessNameErrors.length===0"
								v-model="serviceProvider.fields.title.de"
								:error-messages="businessNameErrors"
								@input="$v.serviceProvider.fields.title.de.$touch()"
								@blur="checkBusinessNameExists"
								data-cy="businessName"/>
							<p class="helpText" v-html="$t('text.businessNameHelp')"/>
						</div>
						<!-- Address -->
						<div class="field left-border">
              <v-label>{{$t('text.businessAddress')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
							<v-text-field outlined autocomplete="street-address"
								:hide-details="streetAddressErrors.length===0"
								:placeholder="$t('text.placeHolderAddress')"
								:error-messages="streetAddressErrors"
								v-model="contactInfoAddress.fields.contactAddresses.de[0].fields.streetAddress.de"
								data-cy="streetAddress"
              />
							<v-row style="width:100%">
								<v-text-field outlined autocomplete="postal-code"
									:hide-details="postalCodeErrors.length===0"
									:placeholder="$t('text.placeHolderPostCode')"
									:error-messages="postalCodeErrors"
									v-model="contactInfoAddress.fields.contactAddresses.de[0].fields.zipCode.de"
                  style="margin-right:10px"
									data-cy="zipCode"
								/>
								<v-text-field outlined autocomplete="address-level2"
									:hide-details="cityErrors.length===0"
									:placeholder="$t('text.placeHolderCityTown')"
									:error-messages="cityErrors"
									v-model="contactInfoAddress.fields.contactAddresses.de[0].fields.city.de"
									data-cy="city"
								/>
							</v-row>
							<v-select outlined dense return-object autocomplete="country-name"
								:placeholder="$t('text.placeHolderCountry')"
								:error-messages="countryErrors"
                :hide-details="countryErrors.length === 0"
								:items="countries"
								:item-text="item => item.name ? item.name[selectedLocale] : ''"
								v-model="contactInfoAddress.fields.contactAddresses.de[0].fields.country"
								data-cy="country"
							/>
						</div>
						<div class="field left-border">
							<v-label>{{$t('text.businessPhone', { format: phoneFormat})}}</v-label>
							<v-text-field outlined hide-details type="tel" autocomplete="tel"
								v-model="contactInfoPhone.fields.contactDetail.de"
								data-cy="businessPhone"
							/>
						</div>
						<div class="field left-border">
              <v-label>{{$t('text.businessEmail')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
							<v-text-field outlined autocomplete="email" type="email"
								v-model="contactInfoEmail.fields.contactDetail.de"
								:error-messages="businessEmailErrors"
                 :hide-details="businessEmailErrors.length===0"
								data-cy="businessEmail"
							/>
						</div>
						<div class="field left-border">
							<v-label>{{$t('text.accountNumber')}}</v-label>
							<v-text-field outlined dense hide-details
								v-model="serviceProvider.fields.accountNumber.de"
                data-cy="accountNumber"
							/>
						</div>
						<v-row align="center" justify="center" style="margin-top:20px">
							<v-btn data-cy="btn-step-1" depressed dark class="activeButton" style="margin-right:20px" @click.prevent="prev()">{{$t('text.previousStep')}}</v-btn>
							<v-btn id="btnSubmit" depressed dark class="activeButton" @click="submit" data-cy="complete">{{$t('text.completeRegistration')}}</v-btn>
						</v-row>
					</v-form>
				</v-card-text>
			</v-card>
		</v-row>
  </v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import { required, minLength, maxLength, email, sameAs } from "vuelidate/lib/validators"
import LanguageFlag from '@/components/common/LanguageFlag.vue'
import Alert from '@/components/common/Alert.vue'
import Common from '@/mixins/Common.vue'

export default {
	name: 'Registration',
	components: { Loading, LanguageFlag, Alert },
  mixins: [ Common ],
	validations: {
		serviceProvider: {
			marketplace: { required },
			password: { required, minLength: minLength(8) },
			repeatPassword: { required, minLength: minLength(8), sameAsPassword: sameAs('password') },
			fields: {
				title: { de: { required, minLength: minLength(4), maxLength: maxLength(128) } },
				contactPersonEmail: { de: { required, email, minLength: minLength(2), maxLength: maxLength(256) } },
				businessEmail: { de: { required, email, minLength: minLength(2), maxLength: maxLength(256) } }
			},
		},
		contactInfoName: {
			fields: {
				contactDetail: { de: { required, minLength: minLength(4), maxLength: maxLength(128) } }
			}
		},
    contactInfoEmail: {
      fields: {
        contactDetail: { de: { required, email, minLength: minLength(2), maxLength: maxLength(256) } }
      }
    },
  
	},
  data() {
		return {
    loading: false,
    errorTitle: '',
    errorDetail: '',
    successTitle: '',
    successDetail: '',
    value: 0,
    query: false,
    persistHints: false,

    times: [],
    showPickerFrom: false,
    showPickerTo: false,

    countries: this.getCountries(),
    clients:null,
    clientRequired: false,
    activeBtn:'',

    businessNameAllowed: true,
    emailIsUnique: true,
    step: 1,
    runStep2Validation: false,

    serviceProvider: {
      marketplace: '',
      clientId: '',
      password:'',
      repeatPassword:'',

      fields:{
        title: {de:''},
        contactPersonName: {de: ''},
        contactPersonEmail: {de: ''},
        contactPersonPhone: {de: ''},
        accountNumber: {de: ''},
        language: {de: ''}
      }
    },
    contactInfoName: {sys: {id:'id_1'}, fields:{contactInfoType: {de:{fields:{type:{de:'Name'}}}},contactDetail:{de:''}}},
    contactInfoPhone: {sys: {id:'id_1'}, fields:{contactInfoType: {de:{fields:{type:{de:'Phone'}}}},contactDetail:{de:''}}},
    contactInfoEmail: {sys: {id:'id_2'}, fields:{contactInfoType: {de:{fields:{type:{de:'E-Mail'}}}},contactDetail:{de:''}}},
    contactInfoWebsite: {sys: {id:'id_3'}, fields:{contactInfoType: {de:{fields:{type:{de:'Website'}}}},contactDetail:{de:''}}},
    contactInfoAddress: {
      sys: {id:'id_4'},
      fields:{
        contactInfoType: {de: {fields:{type:{de:'Address'}}}},
        contactAddresses:{
          de:[{
            sys: {id:'id_5'},
            fields: {
              streetAddress:{de:''},
              zipCode:{de:''},
              city:{de:''},
              country:{},
            }
          }]
        }
      }
    },
    alignmentsAvailable: [ 'start', 'center', 'end', 'baseline', 'stretch' ],
    alignment: 'center',
    dense: false,
    justifyAvailable: [ 'start', 'center', 'end', 'space-around', 'space-between' ],
    justify: 'center'
  }},
  computed: {
    marketplaceErrors() {
      const errors = []
      if (!this.$v.serviceProvider.marketplace.$dirty) return errors
      !this.$v.serviceProvider.marketplace.required && errors.push(this.$t('text.marketplaceRequiredError'))
      return errors
    },
     businessNameErrors() {
      if (!this.runStep2Validation) return []
      const errors = []
      if (!this.$v.serviceProvider.fields.title.de.$dirty) return errors
      !this.$v.serviceProvider.fields.title.de.required && errors.push(this.$t('text.businessNameRequired'))
      !this.$v.serviceProvider.fields.title.de.minLength && errors.push(this.$t('text.businessNameMinChars'))
      !this.$v.serviceProvider.fields.title.de.maxLength && errors.push(this.$t('text.businessNameMaxChars'))
      !this.businessNameAllowed && errors.push(this.$t('text.businessNameExists'))
      return errors
    },
    contactPersonNameErrors () {
      if (this.step === 2 && !this.runStep2Validation) return []
      const errors = []
      if (!this.$v.contactInfoName.fields.contactDetail.de.$dirty) return errors
      !this.$v.contactInfoName.fields.contactDetail.de.required && errors.push(this.$t('text.nameRequired'))
      !this.$v.contactInfoName.fields.contactDetail.de.minLength && errors.push(this.$t('text.nameMinChars'))
      !this.$v.contactInfoName.fields.contactDetail.de.maxLength && errors.push(this.$t('text.nameMaxChars'))
      return errors
    },
    contactPersonEmailErrors () {
      if (this.step === 2 && !this.runStep2Validation) return []
      const errors = []
      if (this.$v.serviceProvider.fields) {
        if (!this.$v.serviceProvider.fields.contactPersonEmail.de.$dirty) return errors
        !this.$v.serviceProvider.fields.contactPersonEmail.de.email && errors.push(this.$t('text.emailInvalid'))
        !this.$v.serviceProvider.fields.contactPersonEmail.de.required && errors.push(this.$t('text.emailRequired'))
        !this.$v.serviceProvider.fields.contactPersonEmail.de.minLength && errors.push(this.$t('text.emailMinChars'))
        !this.$v.serviceProvider.fields.contactPersonEmail.de.maxLength && errors.push(this.$t('text.emailMaxChars'))
        !this.emailIsUnique && errors.push(this.$t('text.registrationEmailExistsError'))
      }
      return errors
    },
    businessEmailErrors() {
      if (!this.runStep2Validation) return []
      const errors = []
       if (this.$v.contactInfoEmail) {
        if (!this.$v.contactInfoEmail.fields.contactDetail.de.$dirty) return errors
        !this.$v.contactInfoEmail.fields.contactDetail.de.email && errors.push(this.$t('text.emailInvalid'))
        !this.$v.contactInfoEmail.fields.contactDetail.de.required && errors.push(this.$t('text.emailRequired'))
        !this.$v.contactInfoEmail.fields.contactDetail.de.minLength && errors.push(this.$t('text.emailMinChars'))
        !this.$v.contactInfoEmail.fields.contactDetail.de.maxLength && errors.push(this.$t('text.emailMaxChars'))
      }
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.serviceProvider.password.$dirty) return errors
      !this.$v.serviceProvider.password.required && errors.push(this.$t('text.passwordRequired'))
      !this.$v.serviceProvider.password.minLength && errors.push(this.$t('text.passwordMinChars'))
      return errors
    },
    repeatPasswordErrors() {
      const errors = []
      if (!this.$v.serviceProvider.repeatPassword.$dirty) return errors
      !this.$v.serviceProvider.repeatPassword.sameAsPassword && errors.push(this.$t('text.repeatPasswordSame'))
      !this.$v.serviceProvider.repeatPassword.required && errors.push(this.$t('text.repeatPasswordRequired'))
      !this.$v.serviceProvider.repeatPassword.minLength && errors.push(this.$t('text.repeatPasswordMinChars'))
      return errors
    },
    streetAddressErrors() {
      if (!this.runStep2Validation) return []
      const errors = []
      if (this.contactInfoAddress && this.contactInfoAddress.fields.contactAddresses.de.length > 0) {
        if (this.contactInfoAddress.fields.contactAddresses.de[0].fields.streetAddress.de.length === 0) {
          errors.push(this.$t('text.businessAddressRequiredError'))
        }
      }
      return errors
    },
    postalCodeErrors() {
      if (!this.runStep2Validation) return []
      const errors = []
      if (this.contactInfoAddress && this.contactInfoAddress.fields.contactAddresses.de.length > 0) {
        if (this.contactInfoAddress.fields.contactAddresses.de[0].fields.zipCode.de.length === 0) {
          errors.push(this.$t('text.businessAddressRequiredError'))
        }
      }
      return errors
    },
    cityErrors() {
      if (!this.runStep2Validation) return []
      const errors = []
      if (this.contactInfoAddress && this.contactInfoAddress.fields.contactAddresses.de.length > 0) {
        if (this.contactInfoAddress.fields.contactAddresses.de[0].fields.city.de.length === 0) {
          errors.push(this.$t('text.businessAddressRequiredError'))
        }
      }
      return errors
    },
    countryErrors() {
      if (!this.runStep2Validation) return []
      const errors = []
      if (this.contactInfoAddress && this.contactInfoAddress.fields.contactAddresses.de.length > 0) {
        if (Object.keys(this.contactInfoAddress.fields.contactAddresses.de[0].fields.country).length === 0 || !this.contactInfoAddress.fields.contactAddresses.de[0].fields.country) {
          errors.push(this.$t('text.businessAddressRequiredError'))
        }
      }
      return errors
    },
  },
  watch: {
    selectedLocale() {
      if (this.countries) {this.countries.sort(this.compareCountry)}
    }
  },

  mounted() {      
    this.getHostClient();
  },
  methods: {
    async setLocale(locale) {
      this.selectedLocale = locale
      this.$root.$i18n.locale = locale
      this.$vuetify.lang.current = locale
    },
    prev() {
      this.clear();
      this.step--;
    },
    next() {
      this.clear();

      if (this.step === 1) {
        this.$v.$touch()
        
        if (!this.contactPersonNameErrors.length &&
        !this.contactPersonEmailErrors.length &&
        !this.passwordErrors.length &&
        !this.repeatPasswordErrors.length) {
          
          this.step++;
        }

      } else if (this.step === 2) {
        this.runStep2Validation = true
        if (!this.businessNameErrors.length && 
            !this.businessEmailErrors.length && 
            !this.streetAddressErrors.length && 
            !this.postalCodeErrors.length && 
            !this.cityErrors.length && 
            !this.countryErrors.length) {

            this.step++;
          }
      }
    },
    clear() {
      this.errorTitle = "";
      this.errorDetail = "";
      this.successTitle = "";
      this.successDetail = "";
    },
    /* Server Functions */
    getHostClient() {
      // allow setting of the clientId through the url query
      const params = window.location.search.replace(/^\?/, '')
      this.$httpGet(`/host-client?${params}&hostUrl=${window.location.hostname}`)
      .then(res => {
        var hasDefaultClient = false;

        if (res) {
          hasDefaultClient = true;
          var client = res.client

          if (client) {
            this.serviceProvider.clientId = client.sys.id
          }
        }

        if (!hasDefaultClient) {
          this.clientRequired = true;
        }
      }).catch(error => {
        this.errorTitle = this.$t('text.ERROR');

        if (error.response) {
          this.errorDetail = error.response.data.error;
        } else {
          this.errorDetail = error;
        }
      });
    },
    async getClients() {
			try {
				const res = await this.$httpGet(`/clients`)
				this.clients = res.clients
			}
			catch (error) {
				this.showError(error.response ? error.response?.data.error : error)
			}
		},
		showError(detail, title) {
			this.errorTitle = title ?? this.$t('text.ERROR')
			this.errorDetail = detail
		},
    async submit() {
      // this.$v.$touch()
      this.next()
      if (this.step !== 3) return

      if (this.clientRequired && this.serviceProvider.marketplace === "") {
        this.errorTitle = this.$t('text.ERROR');
        this.errorDetail = this.$t('text.marketplaceRequiredError');

      } else {
          this.loading = true

          //Contact Details
          this.serviceProvider.fields.contactInfos = {
            de: [
              this.contactInfoName,
              this.contactInfoPhone,
              this.contactInfoEmail,
              this.contactInfoWebsite,
              this.contactInfoAddress
            ]
          }

          this.serviceProvider.fields.contactPersonName.de = this.contactInfoName.fields.contactDetail.de
          // detect browser language and send the first two characters to the server (instead of en-GB -> en)
          this.serviceProvider.fields.language.de = navigator.language.substring(0, 2)
          
          var data = {
            serviceProvider: this.serviceProvider,
            clientId: this.serviceProvider.clientId,
            language: this.selectedLocale,
            selectedLocale: this.selectedLocale,
            autolinkUser: false,
          };
          
          try {
            await this.$httpPost('/registration', data)
    
            this.loading = false

            this.successTitle = this.$t('text.ACCOUNT_CREATED')
            this.successDetail = this.$t('text.registrationSuccess')
            
            this.$emit('show-login')
          } catch(error) {
            this.loading = false;
            this.errorTitle = this.$t('text.ERROR');

            // TODO: we currently also show ACCOUNT CREATED if it was not actually created!!!
            if (error.response) {
              if (error.response.data.error === 'Service provider is already registered') {
                this.errorDetail = this.$t('text.registrationProviderExistsError');
              } else if (error.response.data.error === 'E-mail Address is already associated with an account') {
                this.errorDetail = this.$t('text.registrationEmailExistsError');
              } else {
                this.errorDetail = error.response.data;
              }
            } else if (error.message) {
              if (error.message === 'Service provider is already registered') {
                this.errorDetail = this.$t('text.registrationProviderExistsError');
              } else if (error.message === 'E-mail Address is already associated with an account') {
                this.errorDetail = this.$t('text.registrationEmailExistsError');
              } else {
                this.errorDetail = error.message;
              }
            }
            else {
              this.errorDetail = error.error ? error.error : error
            }
          }
      }
    },
		async checkBusinessNameExists() {
			this.loading = true
      const res = await this.$httpGet(`/check-businessname?title=${this.serviceProvider.fields.title.de}`)
      this.businessNameAllowed = res.businessNameAllowed
			this.loading = false
		},
    async checkEmailExists() {
      this.loading = true
      this.$httpGet(`/check-email?email=${this.serviceProvider.fields.contactPersonEmail.de}`)
      .then(res => {
        this.loading = false
        if (res.emailExists) {
          this.emailIsUnique = false
        } else if (res.emailExists === false) {
          this.emailIsUnique = true
        }
      }).catch()
    }
  }
}
</script>

<style scoped>
.error { color: red; }
.button {
  height: 48px !important;
  background: linear-gradient(0deg, #efefef 0%, #ffffff 100%) !important;
  border: thin #dddddd solid !important;
  display: flex !important;
  /*justify-content: space-around !important;*/
  align-items: center !important;
  border-radius: 5px !important;
  font-family: 'Inter', sans-serif;
  font-size: 12pt !important;
  text-transform: none !important;
  letter-spacing: 0 !important;
}
.activeButton {
  height: 48px !important;
  background: #62c522 !important;
  border: thin #58b01e solid !important;
  display: flex !important;
  justify-content: space-around !important;
  align-items: center !important;
  border-radius: 5px !important;
  font-family: 'Inter', sans-serif;
  font-size: 12pt !important;
  font-weight: normal !important;
  letter-spacing: 0 !important;
  text-transform: none !important;
}
.headline {
  color: #999999;
  font-weight: bold;
  font-size: 16pt !important;
}
#rounded-card {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 0;
}
.stepLabel { font-size: 10pt; }
.oval {
  width: 16px;
  height: 16px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #f4f4f4;
  margin-right: 3px !important;
  margin-left: -10px !important;
}
.ovalActive {
  width: 16px;
  height: 16px;
  border: solid 1px #008ec3;
  background-color: #009dd7;
  margin-right: 3px !important;
  margin-left: -10px !important;
}
.activityActive {
  background-color: #00aeef !important;
  box-shadow: none !important;

  border-radius: 30px !important;
  border: solid 1px #009cd6 !important;
  font-size: 14pt !important;
  color: #ffffff !important;
  height: 32px !important;
  margin: 5px;
  text-transform: none !important;
  letter-spacing: 0 !important;
}
.activityInactive {
  background: #ffffff !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  border: thin #dddddd solid !important;

  border-radius: 30px !important;
  font-size: 14pt !important;
  color:#000000 !important;
  height: 32px !important;
  margin: 5px;
  text-transform: none !important;
  letter-spacing: 0 !important;
}
.v-list-item__title {
  font-size: 14pt !important;
}
.navbarComponent {
  width:100%;
  height: 100%;
  padding:0;
  border-left: 1px solid rgb(180, 195, 202) !important;
  border-right: 1px solid rgb(180, 195, 202) !important;
}
#componentContainer {
  width: 260px;
  min-width: 260px;
  background-color:transparent !important;
  margin-top:-7px;
  margin-left:0;
}
.mainStyle-nocomp {
  color: #999999;
  font-weight: normal;
  font-size: 16pt !important;
}
.listItemStyle-nocomp {
  padding-top: 10px;
  position: relative;
  float: left;
}
.input-group--disabled.checkbox .input-group__input { color: #000 !important; }
.input-group--disabled.input-group--select label { color: #000 !important; }
</style>

<style>
body .v-stepper__header { height: 60px; }
body .v-stepper__step { padding: 0 20px; }
</style>
