<template>
    <div style="width:100%;padding-left:50px;padding-right:50px;z-index:999">
        <p class="helpText" v-html="$t('text.openingTimesHelp')"/>
        <div :style="error && error.message && error.message.length > 0 ? 'opacity:1;margin-top:20px;padding-bottom:20px' : 'opacity:0'">
            <div ref="errorsView" class="error" style="border-radius:10px;padding:10px">
                <v-row v-if="error">
                    <span style="color:#ffffff">{{error.message}}</span>
                </v-row>
            </div>
        </div>

        <!-- "Normal" Opening Times-->
        <div v-if="!businessHoursLocal.fields.isException || businessHoursLocal.fields.isException.de===false">
            <v-row style="padding-left:0px;padding-right:0px">
                <v-col style="padding-left:0px !important">
                    <v-label>{{$t('text.validFrom')}}</v-label>
                    <v-menu offset-y
                        v-model="fromMenuOpened"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        max-width="290px"
                        min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field readonly outlined dense hide-details
                                v-model="businessHoursLocal.fields.validFromDate.de" 
                                v-on="on"/>
                        </template>
                        <v-date-picker no-title 
                            :first-day-of-week="1"
                            :click:date="closeFromDatePicker()"
                            v-model="businessHoursLocal.fields.validFromDate.de"
                            color="green lighten-1"/>
                    </v-menu>
                </v-col>
                <v-col style="padding-right:0px;margin-right:0px">
                    <v-label>{{$t('text.validUntil')}}</v-label>
                    <v-menu offset-y
                        v-model="toMenuOpened"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        max-width="290px"
                        min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field readonly outlined dense hide-details
                                v-model="businessHoursLocal.fields.validToDate.de"
                                v-on="on"/>
                        </template>
                        <v-date-picker no-title 
                            :first-day-of-week="1"
                            :click:date="closeToDatePicker()"
                            v-model="businessHoursLocal.fields.validToDate.de"
                            :min="businessHoursLocal.fields.validFromDate.de"
                            color="green lighten-1"/>
                    </v-menu>
                </v-col>
            </v-row>

            <hr/>
            
            <div :ref="openingTimesIndex" v-for="(openingTimes, openingTimesIndex) in businessHoursLocal.displayArray" :key="openingTimes.key">
                <div :class="error&&error.displayIndex&&error.displayIndex>-1 && error.displayIndex===openingTimesIndex ? 'error-card' : 'card'" v-if="!holidayTime(openingTimes)">
                    <!-- Week days -->
                    <v-row style="width:100%;padding:10px" align="center" v-if="!businessHoursLocal.fields.isException || businessHoursLocal.fields.isException.de===false">
                        <span v-for="weekday in weekdays" :key="'chip_' + (weekday)">
                            <v-btn
                                @click="toggleDay(openingTimesIndex, weekday)"
                                :class="isDaySelected(openingTimes, weekday) ? 'active' :  'inactive'">
                                <v-list-item-avatar width="20px" height="20px" min-height="20px" min-width="20px" class="check">
                                    <v-icon color="#ffffff" size="16px">mdi-check-bold</v-icon>
                                </v-list-item-avatar>
                                {{ $t('text.'+weekday).substring(0,3) }}
                            </v-btn>
                        </span>
                        <v-spacer/>
                    </v-row>

                    <hr/>

                    <!-- Mark Days as Closed -->
                    <v-row style="width:100%;padding:10px" align="center">
                        <span style="font-size:14pt">{{$t('text.markClosedDays')}}</span>
                        <v-spacer/>
                        <v-switch
                            v-model="openingTimes.closed"
                            :value="openingTimes.closed"
                            inset label=""
                            color="#009dd7"
                            @change="toggleClosed(openingTimesIndex)" 
                            hide-details
                            style="margin-right:10px;margin-top:0px">
                        </v-switch> 
                    </v-row>

                    <hr/>

                    <!-- Opening Hours -->
                    <div style="width:100%;padding:10px" v-if="!openingTimes.closed">
                        <div>
                            <v-row style="padding: 0" align="center">
                                <v-col style="padding:0px; margin-right:10px; min-width: 35%; max-width: 35%">
                                    <v-label><span class="ofrom-label">{{$t('text.openFrom')}}</span></v-label>
                                </v-col>
                                <v-col style="padding:0px; margin-right:10px; min-width: 35%; max-width: 35%">
                                    <v-label>{{$t('text.openTo')}}</v-label>
                                </v-col>
                                <div class="row" style="padding:0px; min-width: 25%; max-width: 25%">
                                </div>
                            </v-row>
                            <v-row v-for="(businessTimes, businessTimeIndex) of openingTimes.times" :key="businessTimeIndex" style="padding: 0;margin-bottom:10px" align="center">
                                <v-text-field style="padding:0px !important; margin-right:10px; min-width: 35%; max-width: 35%" outlined dense hide-details placeholder="00:00" 
                                v-model="businessTimes.openTime" :rules="hoursRules" :error-messages="businessTimes.errors"
                                @keyup="timeInputValidate($event, businessTimes)" @blur="timeInputValidate($event, businessTimes)"/>
                                <v-text-field style="padding:0px !important; margin-right:10px; min-width: 35%; max-width: 35%" outlined dense hide-details placeholder="00:00" 
                                v-model="businessTimes.closeTime" :rules="hoursRules" :error-messages="businessTimes.errors"
                                @keyup="timeInputValidate($event, businessTimes)" @blur="timeInputValidate($event, businessTimes)"/>
            
                                <div class="row" style="padding:0px; min-width: 25%; max-width: 25%">
                                    <v-btn v-if="businessTimeIndex === 0" style="margin-right:10px" class="gradientButton" elevation="0" @mousedown="clearTimeRow(openingTimesIndex)"><v-icon>mdi-close</v-icon></v-btn>
                                    <v-btn v-if="businessTimeIndex > 0" style="margin-right:10px" class="gradientButton" elevation="0" @click="removeTimeRow(openingTimesIndex, businessTimeIndex)"><v-icon color="#fb3640">mdi-delete</v-icon></v-btn>
                                </div>
                            </v-row>
                        </div>
                    </div>

                    <hr v-if="!openingTimes.closed"/>

                    <div style="width:100%;padding:10px">
                        <v-row>
                            <v-btn v-if="!openingTimes.closed" class="gradientButton" style="margin-right:10px" elevation="0" @click="addTimeRow(openingTimesIndex)"><v-icon>mdi-plus</v-icon> {{ $t('text.addHours')}}</v-btn>
                            <v-btn v-if="openingTimesIndex > 0" class="gradientButton" elevation="0" @click="removeOpeningTime(openingTimesIndex)"><v-icon color="#fb3640">mdi-delete</v-icon> {{ $t('text.deleteBlock')}}</v-btn>
                        </v-row>
                    </div>
                </div>
                <br/>
            </div>

            <br/>
        </div>

        <!-- Exceptions -->
        <div v-if="businessHoursLocal.fields.isException && businessHoursLocal.fields.isException.de===true">
                <v-label>{{ $t('text.dateRepititionType') }}</v-label>
                <v-select outlined dense return-object placeholder="" hide-details
                    v-model="repititionType"
                    :items="dateRepititionTypes" 
                    :item-text="item => item.text ? item.text[selectedLocale] : ''"
                    @change="exceptionDates=[]"
                />      
            
                <v-date-picker 
                    :first-day-of-week="1"
                    v-if="!repititionType.id || repititionType.id===0"
                    multiple flat
                    no-title full-width 
                    v-model="exceptionDates"
                    color="green lighten-1"/>
                
                <v-date-picker 
                    :first-day-of-week="1"
                    v-if="repititionType.id && repititionType.id===1" 
                    range flat
                    no-title full-width 
                    v-model="exceptionDates"
                    color="green lighten-1"/>

                <br/>
                
                <div :ref="openingTimesIndex" v-for="(openingTimes, openingTimesIndex) in businessHoursLocal.displayArray" :key="openingTimes.key">
                <div :class="error&&error.displayIndex&&error.displayIndex>-1 && error.displayIndex===openingTimesIndex ? 'error-card' : 'card'" v-if="!holidayTime(openingTimes)">
                    <!-- Week days -->
                    <v-row style="width:100%;padding:10px" align="center" v-if="!businessHoursLocal.fields.isException || businessHoursLocal.fields.isException.de===false">
                        <span v-for="weekday in weekdays" :key="'chip_' + (weekday)">
                            <v-btn
                                @click="toggleDay(openingTimesIndex, weekday)"
                                :class="isDaySelected(openingTimes, weekday) ? 'active' :  'inactive'">
                                <v-list-item-avatar width="20px" height="20px" min-height="20px" min-width="20px" class="check">
                                    <v-icon color="#ffffff" size="16px">mdi-check-bold</v-icon>
                                </v-list-item-avatar>
                                {{ $t('text.'+weekday).substring(0,3) }}
                            </v-btn>
                        </span>
                        <v-spacer/>
                    </v-row>

                    <hr/>

                    <!-- Mark Days as Closed -->
                    <v-row style="width:100%;padding:10px" align="center">
                        <span style="font-size:14pt">{{$t('text.markClosedDays')}}</span>
                        <v-spacer/>
                        <v-switch
                            v-model="openingTimes.closed"
                            :value="openingTimes.closed"
                            inset label=""
                            color="#009dd7"
                            @change="toggleClosed(openingTimesIndex)" 
                            hide-details
                            style="margin-right:10px;margin-top:0px">
                        </v-switch> 
                    </v-row>

                    <hr/>

                    <!-- Opening Hours -->
                    <div style="width:100%;padding:10px" v-if="!openingTimes.closed">
                        <div>
                            <v-row style="padding: 0" align="center">
                                <v-col style="padding:0px; margin-right:10px; min-width: 35%; max-width: 35%">
                                    <v-label><span class="ofrom-label">{{$t('text.openFrom')}}</span></v-label>
                                </v-col>
                                <v-col style="padding:0px; margin-right:10px; min-width: 35%; max-width: 35%">
                                    <v-label>{{$t('text.openTo')}}</v-label>
                                </v-col>
                                <div class="row" style="padding:0px; min-width: 25%; max-width: 25%">
                                </div>
                            </v-row>
                            <v-row v-for="(businessTimes, businessTimeIndex) of openingTimes.times" :key="businessTimeIndex" style="padding: 0;margin-bottom:10px" align="center">
                                <v-text-field style="padding:0px !important; margin-right:10px; min-width: 35%; max-width: 35%" outlined dense hide-details placeholder="00:00" 
                                v-model="businessTimes.openTime" :rules="hoursRules" :error-messages="businessTimes.errors"
                                @keyup="timeInputValidate($event)" @blur="timeInputValidate($event)"/>
                                <v-text-field style="padding:0px !important; margin-right:10px; min-width: 35%; max-width: 35%" outlined dense hide-details placeholder="00:00" 
                                v-model="businessTimes.closeTime" :rules="hoursRules" :error-messages="businessTimes.errors"
                                @keyup="timeInputValidate($event)" @blur="timeInputValidate($event)"/>
            
                                <div class="row" style="padding:0px; min-width: 25%; max-width: 25%">
                                    <v-btn v-if="businessTimeIndex === 0" style="margin-right:10px" class="gradientButton" elevation="0" @mousedown="clearTimeRow(openingTimesIndex)"><v-icon>mdi-close</v-icon></v-btn>
                                    <v-btn v-if="businessTimeIndex > 0" style="margin-right:10px" class="gradientButton" elevation="0" @click="removeTimeRow(openingTimesIndex, businessTimeIndex)"><v-icon color="#fb3640">mdi-delete</v-icon></v-btn>
                                </div>
                            </v-row>
                        </div>
                    </div>

                    <hr v-if="!openingTimes.closed"/>

                    <div style="width:100%;padding:10px">
                        <v-row>
                            <v-btn v-if="!openingTimes.closed" class="gradientButton" style="margin-right:10px" elevation="0" @click="addTimeRow(openingTimesIndex)"><v-icon>mdi-plus</v-icon> {{ $t('text.addHours')}}</v-btn>
                            <v-btn v-if="openingTimesIndex > 0" class="gradientButton" elevation="0" @click="removeOpeningTime(openingTimesIndex)"><v-icon color="#fb3640">mdi-delete</v-icon> {{ $t('text.deleteBlock')}}</v-btn>
                        </v-row>
                    </div>
                </div>
                <br/>
            </div>

            <br/>
        </div>

        <v-btn class="gradientButton" elevation="0" style="margin-right:10px" @click="addOpeningTime()"><v-icon>mdi-plus</v-icon>{{$t('text.addOtherTimes')}}</v-btn>
    </div>
</template>

<script>
import Common from '@/mixins/Common.vue'

export default {
    
    name: 'OpeningTimes',
    mixins: [ Common ],

    props: {
        businessHours: Object,
        businessHoursExceptions: Array,
        selectedTime: Number,
        error: Object
    },

    data() {
		return {
			fromMenuOpened: false,
			toMenuOpened: false,
			weekdays: ['monday','tuesday','wednesday','thursday','friday','saturday','sunday'],
			hoursRules: [
				v => /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'Invalid time!'
			],
			displayArrayKey: 999,
			repititionType: {},
			dateRepititionTypes: [
				{
					id: 0,
					text: {
						de: 'Wähle einen oder mehrere Tage für die Ausnahme aus',
						en: 'Select one or more specific days for the exception',
						fr: 'Choisis un ou plusieurs jours pour l\'exception',
						it: 'Seleziona uno o più giorni per l\'eccezione'
					}
				},
				{
					id: 1,
					text: {
						de: 'Wähle einen Zeitraum für die Ausnahme aus',
						en: 'Select a date range for the exception',
						fr: 'Choisis une période pour l\'exception',
						it: 'Selezionare un periodo per l\'eccezione'
					}
				}
			],
			businessHoursLocal: this.businessHours,
			exceptionDates: []
		}
    },
    watch: {
        error() {
            const el = this.$refs['errorsView']
            el.scrollIntoView({ behavior: 'smooth'})
        },
        businessHoursLocal: {
            handler: function (val) {
                this.$emit('business-hours-changed', val)
            },
            deep: true
        },
    },
    created() {
        // this.businessHoursLocal = JSON.parse(JSON.stringify(this.businessHours))
        this.businessHoursLocal.fields.validFromDate.de = this.businessHours.fields.validFromDate.de.split("T")[0]
        this.businessHoursLocal.fields.validToDate.de = this.businessHours.fields.validToDate.de.split("T")[0]
        // this.exceptionDates = this.businessHoursExceptions
    },
    mounted() {
        this.scrollToSelectedTime()

        if (this.businessHours.repititionType==='range') {
            this.repititionType = this.dateRepititionTypes[1]
            this.exceptionDates.push(this.businessHoursLocal.fields.validFromDate.de)
            this.exceptionDates.push(this.businessHoursLocal.fields.validToDate.de)
        } else {
            this.repititionType = this.dateRepititionTypes[0]
            this.exceptionDates.push(this.businessHoursLocal.fields.validFromDate.de)
        }

        this.$forceUpdate()
    },
    methods: {
        scrollToSelectedTime() {
            if (this.selectedTime > 0) {
                const el = this.$refs[this.selectedTime]
                if (el) { el[0].scrollIntoView({ behavior: 'smooth'}) }
            }
        },
        closeFromDatePicker() {
            this.fromMenuOpened = false
        },
        closeToDatePicker() {
            this.toMenuOpened = false
        },
        isDaySelected(openingTimes, weekday) { 
            if (openingTimes.days && openingTimes.days.findIndex(x => x === weekday) > -1) {
                return true
            } else {
                return false
            }
        },
        timeInputValidate(event, openingTime) {
            let key = event.keyCode || event.charCode
            
            if( key !== 8 && key !== 46 ) {
                if (event.target.value.length === 2) {
                    event.target.value = event.target.value + ':'
                } else if (event.target.value.length >= 5) {
                    event.target.value = event.target.value.substring(0,5)
                }
            }

            openingTime.errors = ""
        },
        addTimeRow(openingTimesIndex) { 
            this.businessHoursLocal.displayArray[openingTimesIndex].times.push({openTime:'',closeTime:''})
            this.$forceUpdate()
        },
        clearTimeRow(openingTimesIndex) {
            this.displayArrayKey++

            this.businessHoursLocal.displayArray[openingTimesIndex].times[0] = {openTime:'',closeTime:''}
            this.businessHoursLocal.displayArray[openingTimesIndex].key = this.displayArrayKey
            this.$forceUpdate()
        },
        removeTimeRow(openingTimesIndex, businessTimeIndex) {
            this.businessHoursLocal.displayArray[openingTimesIndex].times.splice(businessTimeIndex,1)
            this.$forceUpdate()
        },
        addOpeningTime() {  
            this.businessHoursLocal.displayArray.push({daysLabel: "", days:[], timeLabels:"00:00-00:00", times:[{openTime:'',closeTime:''}], closed:false})
            this.$forceUpdate()
        },
        removeOpeningTime(openingTimesIndex) {
            this.businessHoursLocal.displayArray.splice(openingTimesIndex,1)
            this.$forceUpdate()
        },
        toggleClosed(openingTimesIndex) {
            this.businessHoursLocal.displayArray[openingTimesIndex].times = [{openTime:'',closeTime:''}]
            this.$forceUpdate()
        },
        toggleDay(openingTimesIndex, day) {
            let dayIndex = this.businessHoursLocal.displayArray[openingTimesIndex].days.findIndex(x => x === day)
            if (dayIndex < 0) {
                this.businessHoursLocal.displayArray[openingTimesIndex].days.push(day)
            } else {
                this.businessHoursLocal.displayArray[openingTimesIndex].days.splice(dayIndex, 1)
            }

            //If day is already specified in another opening time, remove day from other opening time
            for (let i=0; i < this.businessHoursLocal.displayArray.length; i++) {
                if (i !== openingTimesIndex) {
                    let existingDayIndex = this.businessHoursLocal.displayArray[i].days.findIndex(x => x === day)
                    if (existingDayIndex > -1) {
                        this.businessHoursLocal.displayArray[i].days.splice(existingDayIndex, 1)
                    }
                }
            }
            this.$forceUpdate()
        },
        holidayTime(openingTimes) {
            if (openingTimes.days?.length === 1 && openingTimes.days[0] === 'holidays') {
                return true
            } else {
                return false
            }
        }
    }
}
</script>

<style scoped>
.card {
    border:1px solid #c0c0c0;
    border-radius: 10px;
}
.error-card {
    border:1px solid red;
    border-radius: 10px;
}
hr { border: 1px solid #cccccc !important; height: 1px; }
.v-text-field > .v-input__control > .v-input__slot::before { border-style: none !important;}
.v-text-field { padding-bottom: 12px !important; }
.v-chip.v-size--default { 
    border-radius: 5px !important;
    background-color: #ffffff !important;
    border: thin #dddddd solid !important;
    text-decoration: none !important;
    font-weight: normal !important;
}
.active, .inactive {
    background-color: #00aeef !important;
    box-shadow: none !important;

    border-radius: 30px !important;
    border: solid 1px #009cd6 !important;
    font-size: 14pt !important;
    color: #ffffff !important;
    height: 32px !important;
    margin: 5px;
    text-transform: none !important;
    letter-spacing: 0px !important;
}
.inactive {
    background: #ffffff !important;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    border: thin #dddddd solid !important;
    color: #000000 !important;
}
.inactive .check,
.active .check {
    width: 16px;
    height: 16px;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: #f4f4f4;
    margin-right: 3px !important;
    margin-left: -10px !important;
}
.active .check {
    border-color: #008ec3;
    background-color: #009dd7;
}
</style>
