const HttpUtil = {
	install(Vue, options) {
		Vue.prototype.$httpFetch = async function(method, url, body) {
			try {
				if (url.indexOf('/') == 0 && url.indexOf('/api') == -1) url = '/api' + url
				let response = await fetch(url, {
					method: method,
					headers: {
						Authorization: 'Bearer ' + this.$store.state.loggedInUser.kc_token,
						Accept: 'application/json, text/plain, */*',
						'Content-Type': 'application/json',
						'mys-user-id': this.$store.state.loggedInUser?.sys?.id ? this.$store.state.loggedInUser.sys.id : '',
						'mys-user-email': this.$store.state.loggedInUser?.fields?.email?.de ? this.$store.state.loggedInUser.fields.email.de : '',
						'mys-is-impersonation': this.$store.state.isImpersonation && this.$store.state.isImpersonation === true ? true : false,
						'mys-impersonation-start': this.$store.state.impersonationStartTime ?  this.$store.state.impersonationStartTime : '',
						'mys-impersonated-sp-id': this.$store.state.impersonatedServiceProvider?.sys?.id ? this.$store.state.impersonatedServiceProvider.sys.id : '',
						'mys-impersonated-sp-name': this.$store.state.impersonatedServiceProvider?.fields?.title?.de ? this.$store.state.impersonatedServiceProvider.fields.title.de.replace(/[^a-zA-Z0-9-]/g, '') : '',
						'mys-client-id': this.$store.state.selectedClient?.sys?.id ? this.$store.state.selectedClient.sys.id : '',
						'mys-selected-sp': this.$store.state.selectedServiceProvider?.sys?.id ? this.$store.state.selectedServiceProvider.sys.id : '',
						'mys-selected-product': this.$store.state.selectedProduct?.sys?.id ? this.$store.state.selectedProduct.sys.id : '',
					},
					body:
						body === undefined ? body :
						typeof body == 'string' ? body :
						JSON.stringify(body),
				})

				if (response.status === 204) return null
				if (response.status === 401 || response.status === 403) { 
					this.$root.app.showLogin()
				}

				let result
				try {
					result = await response.clone().json()
				}
				catch (e) {
					// TODO: certain bodies may be allowed, like OK
					if (response.status === 200) {
						result = {}
					} else {
						throw new Error('could not read response', e)
					}
				}

				if (result.isError && result.status >= 400) throw result
				if (response.status >= 400) throw await response.text()
				return result
			}
			catch (e) {
				let errorObject = typeof e === 'string' ? JSON.parse(e) : e
				
				if (errorObject?.status === 401 && this.$route.path !== '/') {
					// TODO: refresh the token instead?
					// TODO: make a message instead with a logot button!
					//       otherwise a programming error on the api may lead to a logout
					//       so the user still has the screen to make a grab or sth.
					this.$root.app.showLogin()
					return
				}
				// TODO: handle other codes specifically as well?
				// 403 -> you are not allowed here
				throw errorObject
			}
		}
		Vue.prototype.$httpGet = async function(url, query) {
			if (!query) query = {}
			url += url.indexOf('?') < 0 ? '?' : '&'
			return await this.$httpFetch('GET', url + new URLSearchParams(query).toString())
		}
		Vue.prototype.$httpPut = async function(url, object) {
			return await this.$httpFetch('PUT', url, object)
		}
		Vue.prototype.$httpPost = async function(url, object) {
			return await this.$httpFetch('POST', url, object)
		}
		Vue.prototype.$httpDelete = async function(url) {
			return await this.$httpFetch('DELETE', url)
		}
	},
}

export default HttpUtil