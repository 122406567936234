import VueI18n from 'vue-i18n'

export class TranslationUtil {
	async init(store) {
		let translations = store.state.translations

		if (!store.state.translations || store.state.translations === null) {
			const res = await fetch(`${window.location.origin}/api/translations`)
			translations = await res.json()

			await store.commit('setTranslations', translations)
		}
		
		let text = {}

		for (const translation of translations) {
			text[translation.key] = {text: translation.value}
		}

		const i18n = new VueI18n({
			locale: 'en',
			messages: text,
		})

		return i18n
	}
}

export const translationUtil = new TranslationUtil()