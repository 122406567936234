import { render, staticRenderFns } from "./TagManagerView.vue?vue&type=template&id=137c84c6&scoped=true&"
import script from "./TagManagerView.vue?vue&type=script&lang=js&"
export * from "./TagManagerView.vue?vue&type=script&lang=js&"
import style0 from "./TagManagerView.vue?vue&type=style&index=0&id=137c84c6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "137c84c6",
  null
  
)

export default component.exports