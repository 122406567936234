<template>
  <v-container fluid class="pa-0 mb-12" :style="isSideBarFixed ? 'padding-left: 380px !important;' : 'padding-left: 40px !important;'">
    <div class="navbar">
      <v-row align="center" class="flex-nowrap" style="white-space:nowrap; overflow:hidden;">
        <v-btn id="btnBack" elevation="0" style="background-color:transparent;" @click="goBack">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-row justify="center">
          <v-btn-toggle class="ml-2">
            <v-btn id="btnMobileApp" :class="'firstButton_active'" style="width:200px" elevation="0" @click="toggleView">
              <span class="d-none d-lg-inline">{{$t('text.mobileApp')}}</span>
            </v-btn>
            <v-btn id="btnWebApp" :class="'lastButton'" style="width:200px" elevation="0" @click="toggleView">
              <span class="d-none d-lg-inline">{{$t('text.webApp')}}</span>
            </v-btn>
          </v-btn-toggle>
        </v-row>
      </v-row>
    </div>

    <div :style="showImpersonationInfo ? 'padding-top: 24px !important;' : 'padding-top:0px'">
      <SideBar>
        <ul id="navlist" :style="showImpersonationInfo ? 'padding-top: 24px !important;' : 'padding-top:0px'">
          <a v-for="section of sections" :key="section"
            :href="'#' + section">
            <li @click="setActive(section)" :class="{ active: section === activeLink }">
              {{ $t('text.' + section) }}
            </li>
          </a>
        </ul>
      </SideBar>

      <div class="pa-3" style="width: 100%">
        <!-- What is the Validity Checker -->
        <v-row id="howToUseMobileApp">
          <Disclosure :title="$t('text.howToUseMobileApp')" ref="SECTION_howToUseMobileApp">
            <div class="field left-border">
              <p class="override-default">{{ $t('text.validateTickets') }}</p>
              <p class="helpText">{{ $t('text.validateTicketsDescription') }}</p>
              <v-carousel
                  v-show="sliderImages.length > 0"
                  cycle
                  height="auto"
                  hide-delimiter-background
                  show-arrows-on-hover
                  class="mt-3"
              >
                <v-carousel-item
                    v-for="(image, idx) in sliderImages"
                    :key="idx"
                    :src="image"
                >
                </v-carousel-item>
              </v-carousel>
            </div>
          </Disclosure>
        </v-row>

        <br/>

        <!-- How do I use the Validity Checker -->
        <v-row id="setupValidityChecker">
          <Disclosure :title="$t('text.setupValidityChecker')" ref="SECTION_setupValidityChecker" :expanded="true">
            <div class="field left-border">
              <p class="override-default">{{ $t('text.downloadConsumptionChecker') }}</p>
              <p>{{ $t('text.downloadConsumptionCheckerDescription') }}</p>
              <v-row align="center">
                <!-- Apple Store -->
                <a class="ma-3" target="_blank"
                  href="https://apps.apple.com/at/app/alturos-validity-checker/id1444260979?itsct=apps_box_badge&amp;itscg=30200"
                  style="width: 150px; height: 50px;">
                  <v-img
                      :src="'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/' + this.selectedLocale"
                      :alt="$t('text.downloadOnTheAppStore')" width="150px" height="50px"
                      style="border-radius: 13px;"></v-img>
                </a>

                <!-- Google Store -->
                <a target="_blank"
                  href="https://play.google.com/store/apps/details?id=app.alturos.consume">
                  <v-img width="200px" :alt="$t('text.getItOnGooglePlay')"
                        :src="'https://play.google.com/intl/' + this.selectedLocale + '/badges/static/images/badges/' + this.selectedLocale + '_badge_web_generic.png'"></v-img>
                </a>
              </v-row>

            </div>

            <div class="field left-border">
              <loading :active.sync="loading" :is-full-page="false" color="#4caf50"></loading>
              <p class="override-default">{{ $t('text.scanConfigurationCode') }}</p>
              <p class="helpText">{{ $t('text.scanConfigurationCodeDescription') }}</p>

              <v-row>
                <v-col v-for="qrCode of qrCodes" :key="qrCode.gateId">
                  <v-row align="center" class="flex-column" style="max-width:300px">
                    <a :download="'QR-Code_' + qrCode.gateId" :href="qrCode.qrCodeData"
                      :title="$t('text.downloadQRCodes')">
                      <v-img
                          :src="qrCode.qrCodeData"
                          max-width="300px"
                          alt="QR-Code">
                      </v-img>
                    </a>
                    <v-btn class="mb-3" @click="share(qrCode.gateId, qrCode.qrCodeData)" v-if="isWebShareApiAvailable">
                      <v-icon left>mdi-share-variant</v-icon>
                      {{ $t('text.share') }}
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
              <v-alert class="mt-3" v-show="hasError" type="warning">{{ $t('text.errorQRCodeLoading') }}</v-alert>
              <v-alert class="mt-3" v-show="qrCodes.length === 0 && !hasError && !loading" type="info">
                {{ $t('text.infoNoQRCodes') }}
              </v-alert>
              <p class="mt-3" v-show="qrCodes.length > 0">{{ $t('text.validityCheckerQRCodeDescription') }}</p>
            </div>

            <div class="field left-border">
              <p class="override-default">{{ $t('text.startScanning') }}</p>
              <p class="helpText">{{ $t('text.startScanningDescription') }}</p>
            </div>
          </Disclosure>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Common from '@/mixins/Common.vue'
import SideBar from "@/components/common/Sidebar"
import Disclosure from "@/components/common/Disclosure"

export default {
  name: 'MobileAppView',
  components: {Disclosure, SideBar, Loading},
  mixins: [Common],
  props: {},

  data() {
    return {
      loading: false,
      hasError: false,
      sliderImages: [],
      qrCodes: [],
      activeLink: '',
      sections: [
        'howToUseMobileApp',
        'setupValidityChecker'
      ],
      isWebShareApiAvailable: navigator.canShare,
    }
  },

  mounted() {
	  this.getSliderImages()
	  this.getQRCodes()
  },

  methods: {
    async getSliderImages() {
      try {
        this.sliderImages = await this.$httpGet(`/validitychecker/slider-images/${this.$store.state.selectedComponent.sys.id}`)
      } catch (error) {
        console.log(error)
      }
    },
    async getQRCodes() {
      this.loading = true
      try {
        this.$store.state.ticketServiceData = null

        const response = await this.$httpGet('/validitychecker/qr-codes',
            {
              'serviceProviderId': this.$store.state.selectedServiceProvider?.sys?.id,
            })

        this.qrCodes = response.qrCodes?.length ? response.qrCodes : []
        this.$store.state.ticketServiceData = response

      } catch (e) {
        this.hasError = true
      }
      this.loading = false
    },
    setActive(key) {
      this.activeLink = key
      if (this.$refs['SECTION_' + key]) {
        this.$refs['SECTION_' + key].open()
      }
    },
    async share(gateId, qrCodeData) {
      const response = await fetch(qrCodeData);
      const blob = await response.blob();
      const filesArray = [new File([blob], gateId + '.png', {type: "image/png", lastModified: new Date().getTime()})];
      const shareData = {
        files: filesArray,
      };
      await navigator.share(shareData)
    },
    toggleView() {
      this.$emit('toggle-view')
    },
    goBack() {
      this.$router.push('/')
    }
  },
}
</script>

<style scoped>
.title {
  font-size: 24pt;
  color: #000000;
  font-weight: bold;
}
.override-default {
  color: black !important;
  font-weight: bold;
  margin-bottom: 8px;
  font-size: 1.1rem !important;
}
</style>