<template>
	<v-container fluid class="pa-0 mb-12" :style="isSideBarFixed ? 'padding-left: 380px !important;' : 'padding-left: 40px !important;'">
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

		<!-- Navbar -->
		<div class="navbar">
			<v-row align="center" class="flex-nowrap" style="white-space:nowrap; overflow:hidden;">
				<v-btn id="btnBack" elevation="0" style="background-color:transparent;" @click="goback()"><v-icon>mdi-chevron-left</v-icon></v-btn>
				<v-row justify="center">
					<h1>{{ productTitle }}</h1>
				</v-row>
			</v-row>
		</div>

		<SideBar ref="sidebar">
			<div :style="showImpersonationInfo ? 'padding-top: 24px !important;' : 'padding-top:0px'">
				<!-- Service Provider -->
				<div v-if="!userIsOperator || !appIsServiceManager">
					<v-btn block class="gradientButton mb-3" elevation="0" @click="togglePreview()" data-cy="preview"><v-icon left>mdi-eye</v-icon>{{ showPreview ? $t('text.hidePreview') : $t('text.showPreview') }}</v-btn>
					<v-btn v-if="!productIsExternal" block class="gradientButton mb-3" elevation="0" @click="duplicateService()" :disabled="isWaiting" data-cy="duplicate"><v-icon>mdi-content-copy</v-icon>{{$t('text.duplicateService')}}</v-btn>
					<v-btn block class="gradientButton mb-3" elevation="0" @click="submit('save')" :disabled="isWaiting" data-cy="save"><v-icon left>mdi-check</v-icon>{{$t('text.saveChanges')}}</v-btn>
					<v-btn block class="greenButton mb-3" elevation="0" dark v-if="showActivate" @click="submit('activate')" :disabled="isWaiting" data-cy="activate"><v-icon left color="#ffffff">mdi-arrow-up-circle</v-icon>{{$t('text.publishService')}}</v-btn>
					<v-btn block class="redButton mb-3" elevation="0" dark v-if="statusMap['active']" @click="deactivateProduct('deactivate')" :disabled="isWaiting" data-cy="deactivate"><v-icon left color="#ffffff">mdi-close-circle</v-icon>{{$t('text.deactivateService')}}</v-btn>
					<v-btn block class="redButton mb-3" elevation="0" dark :disabled="isWaiting" v-if="product.sys.id" data-cy="delete" @click="$refs.deleteDialog.show=true"><v-icon left>mdi-delete</v-icon>{{$t('text.deleteService')}}</v-btn>
					<v-card class="info mb-3" flat dark v-if="serviceProvider.addl && serviceProvider.addl.statusClient != 'approved'">{{$t('text.accountPendingInfo')}}</v-card>
				</div>

				<!-- Home Operator -->
				<div v-if="userIsHomeOperator && appIsServiceManager">
					<v-btn class="gradientButton mb-3" block elevation="0" :disabled="isWaiting" data-cy="preview" @click="togglePreview()"><v-icon>mdi-eye</v-icon>{{ showPreview ? $t('text.hidePreview') : $t('text.showPreview') }}</v-btn>
					<v-btn class="gradientButton mb-3" block elevation="0" :disabled="isWaiting" data-cy="save" @click="submit('save')"><v-icon>mdi-check</v-icon>{{$t('text.saveChanges')}}</v-btn>
					<v-btn class="greenButton mb-3" block elevation="0" dark :disabled="isWaiting" data-cy="approve" v-if="status != 'active' && (!serviceProvider.addl || serviceProvider.addl.statusClient == 'approved')" @click="submit('approve')"><v-icon color="#ffffff">mdi-arrow-up-circle</v-icon>{{$t('text.publishService')}}</v-btn>
					<v-card class="info mb-3" elevation="0" dark :disabled="isWaiting" v-if="serviceProvider.addl && serviceProvider.addl.statusClient != 'approved'" style="margin-bottom: 10px;">{{$t('text.accountPendingInfoOp')}}</v-card>
					<v-btn class="redButton mb-3" block elevation="0" dark :disabled="isWaiting" data-cy="deactivate" v-if="status == 'pending' || status == 'active'" @click="deactivateProduct('deactivate')"  ><v-icon color="#ffffff">mdi-close-circle</v-icon>{{$t('text.deactivateService')}}</v-btn>
					<v-btn class="redButton mb-3" block elevation="0" dark :disabled="isWaiting" data-cy="archive" v-if="status!='pending' && status!='archived'" @click="deactivateProduct('archive')"><v-icon color="#ffffff">mdi-close-circle</v-icon>{{$t('text.archiveService')}}</v-btn>
					<v-btn class="redButton mb-3" block elevation="0" dark :disabled="isWaiting" data-cy="delete" v-if="userIsHomeOperator && Object.keys(statusMap).length>0 && product.sys.id" @click="$refs.deleteDialog.show=true"><v-icon>mdi-delete</v-icon>{{$t('text.deleteService')}}</v-btn>
				</div>

				<!-- External Operator -->
				<div v-if="userIsExternalOperator && appIsServiceManager">
					<v-btn class="gradientButton mb-3" block elevation="0" @click="togglePreview()" data-cy="preview"><v-icon>mdi-eye</v-icon>{{ showPreview ? $t('text.hidePreview') : $t('text.showPreview') }}</v-btn>
					<v-btn class="gradientButton mb-3" block elevation="0" @click="submit('save')" :disabled="isWaiting" data-cy="save"><v-icon>mdi-check</v-icon>{{$t('text.saveChanges')}}</v-btn>
					<v-btn class="greenButton mb-3" block elevation="0" dark v-if="status != 'active' && (!serviceProvider.addl || serviceProvider.addl.statusClient == 'approved')" @click="submit('approve')" :disabled="isWaiting" data-cy="approve"><v-icon color="#ffffff">mdi-arrow-up-circle</v-icon>{{$t('text.publishService')}}</v-btn>
					<v-card class="info mb-3" flat dark v-if="serviceProvider.addl && serviceProvider.addl.statusClient != 'approved'" style="margin-bottom: 10px;">{{$t('text.accountPendingInfoOp')}}</v-card>
					<v-btn class="redButton mb-3" block elevation="0" dark v-if="status === 'pending' || status === 'active'" @click="deactivateProduct('deactivate')" :disabled="isWaiting" data-cy="deactivate"><v-icon color="#ffffff">mdi-close-circle</v-icon>{{$t('text.deactivateService')}}</v-btn>
				</div>
				
				<Status :status="status" v-if="!(statusClientHasMultiple || userIsHomeOperator) && status != 'INVALID'" class="mb-3" />
				<div v-if="statusClientHasMultiple || userIsHomeOperator" class="mb-3">
					<span v-for="ca of product.fields.clientAssignments.de" :key="ca.fields.client.de.sys.id"
					:class="{
						clientAssignmentChip: true,
						[ca.fields.status.de]: true,
					}"
					:title="ca.fields.status.de"
					>{{ ca.fields.client.de.fields.clientId.de }}</span>
				</div>

				<v-select outlined dense required hide-details
					class="mb-3"
					v-model="serviceLocale"
					:items="locales"
					:item-text="locale => $t('text.' + locale.name)"
					:item-value="locale => locale.code" 
					@input="showProductTitle"
				></v-select>

				<!-- Index: each Disclosure has a ref and we collect them here -->
				<ul id="navlist">
					<a v-for="section of sections" :key="section"
					:href="'#' + section">
					<li @click="setActive(section)" :class="{ active: section == activeLink }">
						{{ $t('text.' + section + 'Title') }}
					</li>
					</a>
				</ul>
			</div>
		</SideBar>

		<v-row>
			<v-col cols="12" :lg="showPreview ? 6 : 12">
				<!-- Checklist -->
				<div v-if="userIsOperator && featureEnabled('service-provider-checklist')">
					<span id="checklistInfo"/>
						<Checklist ref="checklistRef" :serviceProvider="serviceProvider" component="ServiceDesigner" />
				</div><br/>

				<!-- Public Information -->
				<div v-if="hasSection('publicInfo')">
					<span id="publicInfo"/>
					<PublicInfo
						ref="publicInfo"
						v-if="productIsLoaded"
						:product="product"
						:updateModel="updateModel"
						:productIsExternal="productIsExternal"
						@update-product-title="showProductTitle"
					/>
				</div>

				<!-- Contact Information -->
				<div v-if="hasSection('contactInfo')">
					<span id="contactInfo"/><br/>
					<ContactInfo ref="contactInfo" v-if="productIsLoaded"
						:product="product"
						:updateModel="updateModel"
						:serviceProvider="serviceProvider"
						:status="status"
					/>
				</div>

				<!-- General Information -->
				<div v-if="hasSection('generalInfo')">
					<span id="generalInfo"/><br/>
					<GeneralInfo ref="generalInfo" v-if="productIsLoaded"
					:product="product"
					:updateModel="updateModel"/>
				</div>

				<!-- Business Info -->
				<div v-if="hasSection('businessInfo')">
					<span id="businessInfo"/><br/>
					<BusinessHours v-if="productIsLoaded"
					ref="businessInfo"
					:showCopyOption="true" 
					:businessHours="buildBusinessHours(false)" 
					:businessHoursExceptions="buildBusinessHours(true)" 
					:copyBusinessHours="product.fields.copyBusinessHours.de" 
					app="ServiceDetail" 
					@copy-business-hours="copyBusinessHours(true)" 
					@new-business-hours="copyBusinessHours(false)" 
					@toggle-business-hours="toggleHaveBusinessHours"/>
				</div>

				<!-- Categories -->
				<div v-if="hasSection('categoryInfo')">
					<span id="categoryInfo"/><br/>
					<ProductCategoriesSelector v-if="productIsLoaded" app="ServiceDetail"
						ref="categoryInfo" 
						:data-payload="product"
						:service-provider="serviceProvider"
						:workflow-status="getStatusClient(product)"
						:updateModel="updateModel"
						data-cy="productCategories"
					/>
				</div>

				<!-- Tickets -->
				<div v-if="hasSection('ticketInfo')">
					<span id="ticketInfo"/><br/>
					<TicketInfo
						v-if="productIsLoaded"
						ref="ticketInfo"
						:product="product"
						:updateModel="updateModel"
						:productIsExternal="productIsExternal"
					>
					</TicketInfo>
				</div>

				<!-- Availability -->
				<div v-if="hasSection('availabilityInfo')">
					<span id="availabilityInfo"/><br/>
					<Availability
						v-if="productIsLoaded"
						ref="availabilityInfo"
						:product="product"
						:updateModel="updateAvailabilityModel"
						:productIsExternal="productIsExternal"
						:csError="csError" 
						:csDeletedValidityError="csDeletedValidityError"
					/>
				</div>

				<!-- Personalisation -->
				<div v-if="hasSection('ticketSettingsInfo')">
					<span id="ticketSettingsInfo"/><br/>
					<TicketPersonalisations
						ref="ticketSettingsInfo"
						v-if="productIsLoaded && productHasTicket"
						:product="product"
						:updateModel="updateModel"
						:productIsExternal="productIsExternal"
					/>
				</div>

				<!-- Sales Channels -->
				<div v-if="hasSection('salesChannelsInfo')">
					<span id="salesChannelsInfo"/><br/>
					<ClientAssignmentsField
						v-if="productIsLoaded"
						ref="salesChannelsInfo"
						data-cy="clientAssignments"
						app="ServiceDetail"
						:propClientAssignments="product.fields.clientAssignments.de"
						:serviceProvider="serviceProvider"
						:clientAssignmentLocked="productIsExternal"
						:disclosureTitle="$t('text.salesChannelsInfoTitle')"
						:isWaiting="isWaiting" 
						:updateModel="updateModel"
					/>
				</div>

				<!-- Contingent Monitor -->
				<div v-if="featureEnabled('contingent-monitor')">
					<span id="contingentMonitor"/><br/>
					<ContingentMonitor :product="product" v-if="productIsLoaded"/> 
				</div>

				<!-- System Info -->
				<div v-if="(userIsOperator || userIsImpersonating) && productIsLoaded">
					<span id="systemInfo"/><br/>
					<SystemInfo :product="product" :serviceProvider="serviceProvider" :productIsExternal="productIsExternal" :updateModel="updateModel"/>
				</div>
				
			</v-col>

			<v-col v-if="showPreview" :lg="showPreview ? 6 : 12" :style="showImpersonationInfo ? 'padding-top: 36px !important;' : 'padding-top:10px'">
				<ServicePreview :product="product" :serviceProvider="serviceProvider" :showPreview="showPreview" v-on:scroll-to="scrollToPreview" />
			</v-col>
		</v-row>

		<!-- Confirm Delete -->
		<Dialog ref="deleteDialog"
            :confirmLabel="$t('text.delete')"
            :cancelLabel="$t('text.cancel')"
            :confirm-handler="onDeleteConfirmed"
            :cancel-handler="onDeleteCancelled"
			      :showClose="false"
            :title="$t('text.deleteService')"
			:height="'300px'"
            :width="'800px'">
			<template #content>
				<v-row justify="center" align="center" style="padding:10px">
					<v-col cols="1"><img src="@/assets/icons/icon-warning.svg" style="width:32px;height:32px"/></v-col>
					<v-col cols="8"><span class="title">{{$t('text.confirmServiceDelete')}}</span></v-col>
				</v-row>
			</template>
		</Dialog>

		<!-- Confirm going back -->
		<Dialog ref="unsavedChangesDialog"
			data-cy="unsavedChangesDialog"
            :confirmLabel="$t('text.confirm')"
            :cancelLabel="$t('text.cancel')"
            :confirm-handler="confirmUnsavedChanges"
            :cancel-handler="cancelUnsavedChanges"
			:showClose="false"
            :title="$t('text.unsavedChanges')"
            :height="'300px'"
            :width="'800px'">
			<template #content>
				<v-row justify="center" align="center" style="padding:10px">
					<v-col cols="1"><img src="@/assets/icons/icon-warning.svg" style="width:32px;height:32px"/></v-col>
					<v-col cols="8"><span class="title">{{$t('text.unsavedChangesText')}}</span></v-col>
				</v-row>
			</template>
		</Dialog>

		<!-- Confirm Duplicate Product -->
		<Dialog ref="duplicateProductDialog"
            :confirmLabel="$t('text.viewDuplicateService')"
            :cancelLabel="$t('text.viewCurrentService')"
            :confirm-handler="onViewDuplicateConfirmed"
            :cancel-handler="onViewDuplicateCancelled"
			      :showClose="false"
            :title="$t('text.duplicateService')" 
			      :height="'200px'">
			<template #content>
				<v-row justify="center">
					<span class="title">{{$t('text.duplicateServiceText')}}</span>
				</v-row>
			</template>
		</Dialog>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import ProductCategoriesSelector from '@/components/categories/ProductCategoriesSelector.vue'
import FreshdeskWidget from '@/utils/FreshdeskWidget.js'
import Availability from '@/components/serviceDesigner/Availability.vue'
import ClientAssignmentsField from '@/components/clientAssignment/ClientAssignmentsField.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import Common from '@/mixins/Common.vue'
import Status from '@/components/common/Status.vue'
import ServicePreview from './ServicePreview.vue'
import LanguageFlag from '@/components/common/LanguageFlag.vue'
import Alert from '@/components/common/Alert.vue'
import Dialog from '@/components/common/Dialog.vue'
import TicketPersonalisations from '@/components/serviceDesigner/TicketPersonalisations.vue'
import TicketInfo from '@/components/serviceDesigner/TicketInfo.vue'
import BusinessHours from '@/components/businesshours/BusinessHours.vue'
import SideBar from "@/components/common/Sidebar.vue"
import Checklist from '@/components/checklist/Checklist.vue'
import PublicInfo from '@/components/serviceDesigner/PublicInfo.vue'
import ContactInfo from '@/components/serviceDesigner/ContactInfo.vue'
import GeneralInfo from '@/components/serviceDesigner/GeneralInfo.vue'
import ContingentMonitor from '@/components/serviceDesigner/contingentMonitor/ContingentCalendar.vue'
import SystemInfo from '@/components/serviceDesigner/SystemInfo.vue'

export default {
	name: "ServiceDesigner",
	components: {SideBar, Loading, Disclosure, Status, ServicePreview, LanguageFlag, Alert, Dialog,
				Checklist, PublicInfo, ContactInfo, GeneralInfo, ProductCategoriesSelector, ClientAssignmentsField, BusinessHours, Availability, 
				TicketInfo, TicketPersonalisations, ContingentMonitor, SystemInfo},
	mixins: [ Common ],

	data() {
		return {
			sections: [],
			activeLink: '',
			gobackToDashboard: false,
			selectedServiceType: {},

			status: null,
			statusMap: {}, // statusName => true
			statusClientHasMultiple: false,

			counter: 0,

			loading: false,
			errorTitle: null,
			errorDetail: null,
			successTitle: null,
			successDetail: null,
			runValidation: false,

			productIsLoaded: false,
			updateModel: false,
			updateAvailabilityModel: false,

			csError: null,
			csDeletedValidityError: false,

			product: {
			addl: {},
			sys: { id: '' },
			fields: {
				title: {de:'',en:''},
				longDescription: {de:''},
				shortDescription: {de:''},
				importantInformation: {de:''},
				faqGroup: {de:''},
				images: {de:[]},
				taxClass: {de:{}},
				productCategories: {de:[]},
				clients: {de:[]},
				clientAssignments: {de:[]},
				meetingPoint: {de:''},
				vouchers: {de: [{
				sys: {id: 'id_v_0'},
				fields:{generalInfo: {de:''}}
				}]},
				averageDurationOfStayInMinutes: {de:0},
				startAdvertisingDate: {de:''},
				leadTimeDays: {de: 0},
				leadTimeHours: {de: 0},
				leadTimeWeeks: {de: 0},
				leadTimeMonths: {de: 0},
				personalisationType: { de: 'No personalisation' },
				location: {de: {lat:'',lon:''}},
				notificationEmails: {de: ['']},
				topProduct: { de: false },
				freeProduct: { de: false },
				ticket: {
				de: {
					sys: { id: 0 },
					fields: {
					ticketOptions: {
						de:[{
						sys: { id: 0 },
						fields: {
							ticketType: { de: null },
							reduction: { de: null },
							minimumTickets: { de: 1 },
							maximumTickets: { de: 6 },
							contingentQuantity: { de: 1 },
							price: { de: '' },
							fromPriceOption: { de: false },
							ticketPersonalisations: { de: [] },
							selected: false
						}
						}]
					}
					}
				}
				},
				productAvailabilities: {
				de: [{
					sys: {id: 'id_a_1'},
					fields: {
					validFromDate: { de: '' },
					validToDate: {de:''},
					productContingents: {
						de: [{
						sys: { id: 'id_c_1' },
						fields: {
							minimumContingent: { de: 1 },
							maximumContingent: { de: 0 },
							timeSlot: { de: '' }
						}
						}]
					},
					exceptions: {
						de: [],
						type: ''
					}
					}
				}]
				},
				contactInfoGroups: {
				de: [{
					sys: { id: 'id_12' },
					fields: {
					contactInfos: { de: [] }
					}
				}]
				},
				copyContactInfos: { de: true },
				copyBusinessHours: { de: true},
				businessHours: { de:[] }
			}
			},

			serviceProvider: this.$store.state.selectedServiceProvider,

			businessHours: [],

			productCategories: [],
			duplicateProductId: '',

			showPreview: false,
			showLongDescription: false,
			showMediaViewer: false,

			productTitle: '',
			availabilityToReplace: null
		}
	},

	computed: {
		showActivate() {
      		return this.serviceProvider?.addl?.statusClient === 'approved' && this.status !== 'active'
		},
		spaceId() {
			return this.product.fields.ticket?.de?.fields?.ticketOptions?.de?.[0]?.fields?.spaceId?.de
		},
		productIsExternal() {
			return this.product?.addl?.isExternal
		},
		appIsServiceManager() {
			return this.$store.state.selectedComponent?.fields?.title?.en === 'Manage Services'
		},
		// used for disabling buttons
		isWaiting() {
			return this.loading || !!this.successTitle || !!this.errorTitle
		},
		productHasTicket() {
			return this.product.fields.ticket?.de?.fields?.ticketOptions?.de?.length > 0
		},
		
	},

	created() {
		FreshdeskWidget.prefill('MyServices')
	},

	async mounted () {
		this.loading = true

		this.currency = this.$store.state.selectedClient.fields.currency.de
		this.selectedServiceType = this.$store.state.selectedServiceType

        if (!this.$store.state.coreConfig) {
          await this.loadCoreConfig()
        }

		if (!this.$store.state.locales?.length) {
			await this.$root.app.getLocales()
		}

		if (Object.keys(this.$store.state.selectedProduct).length > 0) {
			//Existing Product
			await this.getProduct()
		} else {
			//New Product
			this.status = "new"
			this.statusMap = { new: true }

			const product = Object.assign({}, this.product)
			this.product = product

			this.product.fields.serviceProvider = { de: this.serviceProvider }
			this.product.fields.serviceType = { de: this.$store.state.selectedServiceType }

			//Remove fields depending on service type
			//TODO: remove the fields more elegantly
			if (!this.hasSection('ticketInfo')) {
				delete this.product.fields.ticket
				delete this.product.fields.taxClass
			}
			if (!this.hasSection('availabilityInfo')) {
				delete this.product.fields.productAvailabilities
			}
			if (!this.hasField('availabilityInfo','startAdvertisingDate', this.product)) {
				delete this.product.fields.startAdvertisingDate
			}
			if (!this.hasField('availabilityInfo','leadTime', this.product)) {
				delete this.product.fields.leadTimeDays
				delete this.product.fields.leadTimeHours
				delete this.product.fields.leadTimeMonths
				delete this.product.fields.leadTimeWeeks
			}
			if (!this.hasField('contactInfo','notificationEmails', this.product)) {
				delete this.product.fields.notificationEmails
			}
			if (!this.hasField('contactInfo','meetingPoint', this.product)) {
				delete this.product.fields.meetingPoint
			}
			if (!this.hasField('contactInfo','postPurchaseInfo', this.product)) {
				delete this.product.fields.vouchers
			}
			if (!this.hasField('ticketSettingsInfo','personalisations', this.product)) {
				delete this.product.fields.personalisationType
			}

			this.loading = false
		}

		// we need this flag because we need to only render the child components when the product is loaded, otherwise they do not get the data
		this.productIsLoaded = true
		this.showProductTitle()
	},
	methods: {
		showProductTitle(val) {
			if (val && typeof val === 'Object' && Object.keys(val).length) {
				this.productTitle = val[this.serviceLocale]
			} else {
				this.productTitle = this.product?.fields?.title?.[this.serviceLocale] ? this.product.fields.title[this.serviceLocale] : this.$refs.publicInfo?.model?.fields?.title?.[this.serviceLocale] ? this.$refs.publicInfo.model.fields.title[this.serviceLocale] : ''
			}
		},
		updateProductFromChildren() {
			const publicInfoData = this.$refs['publicInfo']?.sendData()
			const contactInfoData = this.$refs['contactInfo']?.sendData()
			const generalInfoData = this.$refs['generalInfo']?.sendData()
			const businessHoursData = this.$refs['businessInfo']?.sendData()
			const categoryInfo = this.$refs['categoryInfo']?.sendData()
			const ticketInfo = this.$refs['ticketInfo']?.sendData()
			const availabilityInfo = this.$refs['availabilityInfo']?.sendData()
			const salesChannelsInfo = this.$refs['salesChannelsInfo']?.sendData()

			// add to data array when the child component is enabled
			const data = [publicInfoData, contactInfoData, generalInfoData, businessHoursData, salesChannelsInfo, availabilityInfo, ticketInfo].filter(data => data !== undefined)

			// loop over the data array and loop over all its keys
			for (const el of data) {
				Object.keys(el).forEach(key => {
					this.product.fields[key] = JSON.parse(JSON.stringify(el[key]))
				})
			}

			//Set changed sections
			this.product.addl = {
				publicInfoChanged: publicInfoData?.changed || this.product.sys.id === '' ? true : false,
				contactInfoChanged: contactInfoData?.changed || this.product.sys.id === '' ? true : false,
				generalInfoChanged: generalInfoData?.changed || this.product.sys.id === '' ? true : false,
				businessHoursChanged: businessHoursData?.changed || this.product.sys.id === '' ? true : false,
				categoryInfoChanged: categoryInfo?.changed || this.product.sys.id === '' ? true : false,
				ticketInfoChanged: ticketInfo?.changed ? true : false,
				availabilityInfoChanged: availabilityInfo?.changed ? true : false,
				salesChannelsChanged: salesChannelsInfo?.changed || this.product.sys.id === '' ? true : false
			}

			//Set ClientAssignment productCategories
			if (categoryInfo?.productCategories?.de?.length) {
				const clientCA = salesChannelsInfo.clientAssignments.de.find(x => x.fields.client.de.sys.id === this.$store.state.selectedClient.sys.id)
				if (clientCA) {
					clientCA.fields.productCategories = {de: categoryInfo.productCategories.de }
				} else {
					//New product without clientAssignments
					this.product.fields.productCategories = {de: categoryInfo.productCategories.de}
				}
			}
		},

		scrollToPreview() {
			if (this.showPreview && !this.isSideBarFixed) {
				this.$refs.sidebar.closeSidebar();
				this.$nextTick(() => { document.getElementById('preview').scrollIntoView({ behavior: 'smooth'}) })
			}
		},
		hasSection(sectionName) {
			//New Product
			if (this.product.sys.id === '') {
				if (this.selectedServiceType?.fields?.template?.de) {
					if (this.selectedServiceType.fields.template.de[sectionName]) {
						if (!this.sections.find(x => x === sectionName)) {
							this.sections.push(sectionName)
						}
						return true
					} else {
						return false
					}
				} else {
					return false
				}
			} else {
				//Existing Product
				if (this.product.fields.serviceType?.de?.fields?.template?.de) {
					if (this.product.fields.serviceType.de.fields.template.de[sectionName]) {
						if (!this.sections.find(x => x === sectionName)) {
							this.sections.push(sectionName)
						}
						return true
					} else {
						return false
					}
				} else {
					return false
				}
			}
		},
		goback(gobackToDashboard) {
			this.gobackToDashboard = gobackToDashboard ?? false
			const publicInfoData = this.$refs['publicInfo']?.sendData()
			const contactInfoData = this.$refs['contactInfo']?.sendData()
			const generalInfoData = this.$refs['generalInfo']?.sendData()
			const businessHoursData = this.$refs['businessInfo']?.sendData()
			const categoryInfo = this.$refs['categoryInfo']?.sendData()
			const ticketInfo = this.$refs['ticketInfo']?.sendData()
			const availabilityInfo = this.$refs['availabilityInfo']?.sendData()
			const salesChannelsInfo = this.$refs['salesChannelsInfo']?.sendData()

console.log('publicInfoData.changed: ' + publicInfoData?.changed)
console.log('contactInfoData.changed: ' + contactInfoData?.changed)
console.log('generalInfoData.changed: ' + generalInfoData?.changed)
console.log('businessHoursData.changed: ' + businessHoursData?.changed)
console.log('categoryInfo.changed: ' + categoryInfo?.changed)
console.log('salesChannelsInfo.changed: ' + salesChannelsInfo?.changed)
console.log('availabilityInfo.changed: ' + availabilityInfo?.changed)
console.log('ticketInfo.changed: ' + ticketInfo?.changed)

			if (publicInfoData?.changed === true || 
				contactInfoData?.changed === true || 
				generalInfoData?.changed === true || 
				businessHoursData?.changed === true || 
				categoryInfo?.changed === true || 
				salesChannelsInfo?.changed === true || 
				availabilityInfo?.changed === true || 
				ticketInfo?.changed === true) {

				this.$refs.unsavedChangesDialog.show=true
			} else {
				this.$router.push("/services")
			}
		},
		toggleHaveBusinessHours(event) {
			if  (!event  || event === false) {
				this.product.fields.businessHours.de = []
				this.businessHours = []
			} else {
				this.product.fields.businessHours.de = [this.$refs.businessInfo.getNewBusinessHours(false)]
				this.businessHours  = [this.$refs.businessInfo.getNewBusinessHours(false)]
			}
		},
		buildBusinessHours(isException) {
			let businessHours = []
			if (this.businessHours?.length > 0) {
				for (const businessHour of this.businessHours) {
					if (businessHour.fields) {
						if (businessHour.fields.isException?.de) {
							if (businessHour.fields.isException?.de === isException) {
								businessHours.push(businessHour)
							}
						} else {
							if (!isException) {
								businessHours.push(businessHour)
							}
						}
					}
				}
			}

			return businessHours
		},
		getNewBusinessHours(counter) {
			if (!counter) counter = Math.random()
			return {
				sys: { id: 'id_bh_' + counter },
				fields: {
					validFromDate: { de: new Date().toISOString().split('T')[0] },
					validToDate: { de: new Date().toISOString().split('T')[0] },
					businessTimes: {
						de: {
							monday:    { times: [{ openTime: '', closeTime: '' }] },
							tuesday:   { times: [{ openTime: '', closeTime: '' }] },
							wednesday: { times: [{ openTime: '', closeTime: '' }] },
							thursday:  { times: [{ openTime: '', closeTime: '' }] },
							friday:    { times: [{ openTime: '', closeTime: '' }] },
							saturday:  { times: [{ openTime: '', closeTime: '' }] },
							sunday:    { times: [{ openTime: '', closeTime: '' }] },
							holidays:  { times: [{ openTime: '', closeTime: '' }] },
						},
					},
				},
			}
		},
		copyBusinessHours(isCopy) {
			if (this.$refs.businessInfo.haveBusinessHours === true) {
				if (this.serviceProvider?.fields?.businessHours?.de?.length > 0) {
					this.product.fields.copyBusinessHours.de = isCopy

					if (isCopy) {
						this.businessHours = JSON.parse(JSON.stringify(this.serviceProvider.fields.businessHours.de))

						for (var businessHours of this.businessHours) {
							if (businessHours.fields) {
								businessHours.fields.validFromDate.de = businessHours.fields.validFromDate.de.split("T")[0]
								businessHours.fields.validToDate.de = businessHours.fields.validToDate.de.split("T")[0]
							}
						}
					} else {
						this.businessHours = [this.getNewBusinessHours()]
					}
				}
			}
		},
		onDeleteConfirmed() {
			this.$refs.deleteDialog.show = false
			this.deleteService()
			return true
		},
		onDeleteCancelled() {
			this.$refs.deleteDialog.show = false
		},
		confirmUnsavedChanges() {
			this.$refs.unsavedChangesDialog.show = false

			if (this.userIsOperator) {
				this.$store.commit('setSelectedProduct', null)
			}

			if (this.gobackToDashboard === true) {
				this.$router.push("/")
				this.$emit('show-dashboard')
			} else {
				this.$router.push("/services")
			}

			return true
		},
		cancelUnsavedChanges() {
			this.$refs.unsavedChangesDialog.show = false
			this.gobackToDashboard = false
		},
		setActive(key) {
			this.activeLink = key
			if (this.$refs['SECTION_' + key]) {
				this.$refs['SECTION_' + key].open()
				this.$refs.sidebar.closeSidebar()
      		} else if (this.$refs[key]) {
				// open the disclosures from child components
				this.$refs[key].$refs['SECTION_' + key].open()
				this.$refs.sidebar.closeSidebar()
			}
		},
		togglePreview() {
			this.showPreview = !this.showPreview
		},
		setChanged() {
			this.submitButtonTheme = "blueButton white--text"
		},
		async submit(action) {
			this.updateModel = false
			this.updateAvailabilityModel = false

			// we need to sync the product with the data in the children components
			this.updateProductFromChildren()

			if ((action === 'save' && this.status === 'active') && (((this.userIsOperator && !this.userIsImpersonating) ||
				this.serviceProvider.fields.trustLevel.de === 3) || (this.serviceProvider?.fields?.trustLevel?.de === 2 && this.product.sys.id !== ''))) {
				action = 'activate'
			}

			if (this.product.fields?.importantInformation?.de) {
				this.product.fields.importantInformation.de = this.convertLink(this.product?.fields?.importantInformation?.de)
			}

			if (this.product.fields.topProduct || this.product.fields.freeProduct) {
				this.product.fields.topProduct = {de: this.product.fields.topProduct.de }
				this.product.fields.freeProduct = {de: this.product.fields.freeProduct.de }
			}

			//Remove unnecessary data before submit
			if (this.product.fields.faqGroup?.de?.fields) {
				this.product.fields.faqGroup.de.fields.faqs = [];
			}

			if (this.hasSection('contactInfo')) this.$refs['contactInfo'].resetContactInfoIds()

			//Set exception type if it is in german/french
			if (!this.productIsExternal) {
				if (this.hasField('availabilityInfo','leadTime', this.product)) {
					if (!this.product.fields.leadTimeHours.de) this.product.fields.leadTimeHours.de = 0
					if (!this.product.fields.leadTimeDays.de) this.product.fields.leadTimeDays.de = 0
					if (!this.product.fields.leadTimeWeeks.de) this.product.fields.leadTimeWeeks.de = 0
					if (!this.product.fields.leadTimeMonths.de) this.product.fields.leadTimeMonths.de = 0
				}

				this.product.fields.ticket?.de?.fields?.ticketOptions?.de?.forEach(el => {
					el.fields.maximumTickets.de = parseInt(el.fields.maximumTickets.de)
					el.fields.minimumTickets.de = parseInt(el.fields.minimumTickets.de)
					el.fields.contingentQuantity.de = parseInt(el.fields.contingentQuantity.de)
				})
			}

			this.product.fields.productCategories = { de: [] }

			//Business Hours
			//Merge business hours and exceptions
			if (this.$refs.businessInfo) {
				this.product.fields.copyBusinessHours.de = this.$refs.businessInfo.copy

				if (this.product.fields.copyBusinessHours.de === false) {
					if (this.$refs.businessInfo.haveBusinessHours === true) {
						this.product.fields.businessHours.de = [...this.$refs.businessInfo.businessHours, ...this.$refs.businessInfo.businessHoursExceptions]
					} else {
						this.product.fields.businessHours.de = []
					}
				}
			}

			//Checklist
			if (this.$refs?.checklistRef?.checklist) {
				this.serviceProvider.fields.checklist = this.$refs.checklistRef.checklist
			}

			//Check Validation
			try {
				if (!this.validate(action)) {
					return this.showError(this.$t('text.missingFieldsError'))
				}
				//Always check title even if runValidation is false
				if (!this.validateRefOnSave('publicInfo', this.$refs, this.runValidation)) {
					return this.showError(this.$t('text.titleRequiredError'))
				}
			} catch (e) {
				return this.showError(e)
			}

			if (this.product.sys.id !== "") {
				await this.updateProduct(action)
			} else {
				await this.createProduct(action)
			}
		},
		async updateProduct(action) {
			this.loading = true
			this.csError = null

			try {
				const data = {
					product: this.product,
					serviceProvider: this.$store.state.selectedServiceProvider,
					clientId: this.$store.state.selectedClient.sys.id,
					action: action,
					isOperator: this.$store.state.loggedInUser.fields?.type?.de === 'operator' && !this.$store.state.isImpersonation,
				}

				const res = await this.$httpPut('/product', data)

				this.successTitle = "SERVICE DESIGNER"
				this.successDetail = this.$t('text.serviceUpdateSuccessMsg')

				await this.$store.commit('setSelectedProduct', {sys: {id: res.productId}})
				await this.getProduct()

				this.updateAvailabilityModel = true
			}
			catch (error) {
				if (error.response?.status === 401) this.$emit("show-login")

				if (error.code === 447) {
					this.handleContingentServiceError(error)
				} else {
					this.showError(error)
				}
			}
		},
		handleContingentServiceError(error) {
			this.loading = false

			let detail = error?.messageForEndUser[this.selectedLocale]
			
			this.csError = error
			this.errorDetail = detail
			this.errorTitle = this.$t('text.ERROR')

			if (!this.product.fields.productAvailabilities.de.find(pa => pa.sys.id === this.csError.data.availabilityId)) {
				this.csDeletedValidityError = true

				//Replace first new availabiity with deleted availability
				this.availabilityToReplace = this.$refs.availabilityInfo.initData.productAvailabilities.de.find(init => init.sys.id === this.csError.data.availabilityId)
				let index = this.product.fields.productAvailabilities.de.findIndex(pa => pa.sys.id.indexOf('id_') > -1) 
				this.product.fields.productAvailabilities.de[index] = this.availabilityToReplace

				this.updateAvailabilityModel = true
			}
		},
		async createProduct(action) {
			this.loading = true
			try {
				const data = {
					product: this.product,
					serviceProvider: this.$store.state.selectedServiceProvider,
					clientId: this.$store.state.selectedClient.sys.id,
					action: action,
					isOperator: this.$store.state.loggedInUser.fields?.type?.de === 'operator' && !this.$store.state.isImpersonation
				}
				let res = await this.$httpPost('/product', data)
				
				this.successTitle = "SERVICE DESIGNER"
				this.successDetail = this.$t('text.serviceCreateSuccessMsg')

				await this.$store.commit('setSelectedProduct', {sys: {id: res.productId}})
				await this.getProduct()

				this.updateAvailabilityModel = true
			}
			catch (error) {
				if (error.response?.status === 401) this.$emit("show-login")
				this.showError(error)
			}
		},
		async deactivateProduct(action) {
			this.loading = true
			try {
				const data = {
					productId: this.product.sys.id,
					serviceProviderId: this.$store.state.selectedServiceProvider.sys.id,
					action: action
				}
				const res = await this.$httpPost('/deactivate-product', data)

				await this.getProduct('update')
			}
			catch (error) {
				if (error.response?.status === 401) this.$emit("show-login")
				if (error.response) {
					this.showError(error.response.data?.error)

				}
				else {
					this.successTitle = "SERVICE DESIGNER"
					this.successDetail = this.$t('text.serviceUpdateSuccessMsg')
					this.loading = false
				}
			}

			this.emitNoChanges(500)
		},
		async duplicateService() {
			this.loading = true;

			this.$httpPost(`/duplicate-product/${this.$store.state.selectedServiceProvider.sys.id}/${this.$store.state.selectedProduct.sys.id}`, {}).then(async res => {
				this.loading = false
				this.duplicateProductId = res.productId
				this.$refs.duplicateProductDialog.show = true
			}).catch(error => {
				if (error.response.status === 401) {
				this.$emit("show-login")
				}
				this.showError(error)
			})
		},
		async onViewDuplicateConfirmed() {
			await this.$store.commit('setSelectedProduct',  { sys: { id: this.duplicateProductId } })
			this.$refs.duplicateProductDialog.show = false
			// MYS-3202: reload page to get new product properly because the client assignment in the duplicated product is still the original one
			window.location.reload()
		},
		onViewDuplicateCancelled() {
			this.$refs.duplicateProductDialog.show = false
		},
		async deleteService() {
			this.loading = true

			try {
				const resDelete = await this.axios.delete(`/product`,
				{
					headers: this.requestHeaders(),
					data: {
						productId: this.$store.state.selectedProduct.sys.id,
						serviceProviderId: this.$store.state.selectedServiceProvider.sys.id,
						dryRun: false
					}
				})

				this.successTitle = this.$t('text.SERVICE_DELETED')
				this.successDetail = this.$t('text.serviceDeletedSuccess')

				this.loading = false

				this.sleep(2000).then(() => { this.$router.push("/services") });
			}
			catch (error) {
				this.showError(error.response ? error.response?.data.error : error)
			}
			this.loading = false
		},
		validate(action) {
			this.runValidation = false

			//Check if Validation should run
			if (action === 'activate' || action === 'approve') {
				this.runValidation = true
			} else {
				if (this.product.sys.id !== '') {
					//Updating an existing product
					for (const clientAssignment of this.product.fields.clientAssignments.de) {
						if (clientAssignment.fields?.status?.de === 'pending' || clientAssignment.fields?.status?.de === 'active') {
							this.runValidation = true
							break
						}
					}
				}
			}
			
     	  let allValid = !this.runValidation ||
			// the first parameter of the validateRefOnActivate function needs to be the same as the ref on the target custom component being rendered
          (
			  this.validateRefOnActivate('publicInfo', this.$refs) &&
              this.validateRefOnActivate('contactInfo', this.$refs) &&
              this.validateRefOnActivate('generalInfo', this.$refs) &&
			  this.validateRefOnActivate('categoryInfo', this.$refs) &&
              this.validateRefOnActivate('ticketInfo', this.$refs) &&
              this.validateRefOnActivate('availabilityInfo', this.$refs) &&
              this.validateRefOnActivate('salesChannelsInfo', this.$refs)
		  )

			if (allValid) {
				this.updateProductFromChildren()
			}
		
		  return allValid
		},
		async getProduct() {
			this.loading = true
			try {
				let res = await this.$httpGet(`/product/${this.$store.state.selectedProduct.sys.id}?serviceProviderId=${this.$store.state.selectedServiceProvider.sys.id}`)
				this.setProductDetails(res.product)

			} catch (error) {
				if (error.response && error.response.status === 401) this.$emit("show-login")
				this.showError(error)
			}
		},
		async setProductDetails(product) {
			if (!product.fields.clientAssignments) product.fields.clientAssignments = { de: [] }
			if (!product.fields.notificationEmails) product.fields.notificationEmails = this.notificationEmails
			if (!product.fields.importantInformation) product.fields.importantInformation = {de:'',en:'',fr:'',it:''}
			this.businessHours = product.fields.businessHours?.de ?? []

			
			this.statusMap = this.getStatusMap(product)
			this.statusClientHasMultiple = Object.keys(this.statusMap).length > 1
			if (!this.userIsOperator) {
				// the service provider gets a list of all status
				this.status = Object.keys(this.statusMap).join(',') ?? null
			}
			else {
				// the operator gets the status for his client only
				this.status = this.getStatusClient(product)
			}
			
			this.product = product
			this.serviceProvider = product.fields.serviceProvider.de
			
			if (this.hasSection('ticketInfo') && !this.product.fields.ticket) {
				this.product.fields["ticket"] = {
					de: {
						sys: { id: 0 },
						fields: {
							ticketOptions: {
								de:[{
								sys: { id: 0 },
								fields: {
									ticketType: { de: null },
									reduction: { de: null },
									minimumTickets: { de: 1 },
									maximumTickets: { de: 6 },
									contingentQuantity: { de: 1 },
									price: { de: '' },
									fromPriceOption: { de: false },
									ticketPersonalisations: { de: [] },
									selected: false
								}
								}]
							}
						}
					}
				}
			}

			this.updateModel = true
			this.loading = false

			await this.$store.commit('setSelectedProduct', this.product)
			
			this.productIsLoaded = true
			this.showProductTitle()
		},
		showError(error) {
			this.loading = false
			const detail = error?.response?.data?.error ?? error

			this.errorTitle = this.$t('text.ERROR')
			this.errorDetail = detail
		},
		sleep(ms) {
			return new Promise(resolve => setTimeout(resolve, ms));
		},
	}
}
</script>

<style>
hr {
  color: #dddddd;
  opacity: 0.3 !important;
}
.v-input--switch {
  padding-top: 10px;
  padding-left:10px;
}
.v-text-field__slot .v-label {
  color: #999999 !important;
  font-size: 12pt !important;
}

.v-select__slot .v-label {
  color: #999999 !important;
  font-size: 12pt !important;
}

</style>

<style scoped lang="scss">
[v-cloak] {
  display: none;
}
a { text-decoration: none; }
hr { color: #efefef; }
.theme--light.v-card {  box-shadow: none !important; }
.v-input__slot {
  border: none !important;
}
.roundButton {
  border-radius: 30px !important;
  border: solid 1px rgba(0, 0, 0, 0.1) !important;
  box-shadow: none !important;
  background: #f2f2f2 !important;
  font-size: 14pt !important;
  color: rgba(0,0,0,.38) !important;
  height: 32px !important;
  margin: 5px;
  text-transform: none !important;
  letter-spacing: 0 !important;
}
.oval {
  width: 16px;
  height: 16px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #f4f4f4;
  margin-right: 3px !important;
  margin-left: -10px !important;
}
.ovalActive {
  width: 16px;
  height: 16px;
  border: solid 1px #008ec3;
  background-color: #009dd7;
  margin-right: 3px !important;
  margin-left: -10px !important;
}
.v-input__control {
  background-color: #ffffff !important;
}
.v-application .success--text {
    color: #00aeef !important;
    caret-color: #00aeef !important;
}
.dialogDiv {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width:1100px;
    height:700px;
    max-width: calc(100vw - 20px);

    background-color: #ffffff;
    border-radius: 10px;
    box-shadow:1px 1px 15px 0 rgba(0,0,0,0.2);
    z-index: 999;
}
.info {
  background-color: #00aeef;
  color: #ffffff;
  border-radius: 5px;
  padding: 10px;
  font-weight: bold;
}
.infoImageWrapper { border-radius: 10px; background-color: #ffffff; }
.infoImageWrapper img { border-radius: 10px !important; }
.infoImageWrapper .removeImg { position: absolute; right: 2px; top: 2px; }
.infoImageWrapper:hover .removeImg { color: #000; }
.infoImageWrapper:hover .removeImg:active { color: #5a5a5a; }
.serviceLocale { position: absolute; z-index: 10; margin-top: 32px; margin-left: -32px; zoom: 0.8; }
</style>