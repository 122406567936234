<template>
<div>
	<v-select outlined hide-details return-object style="background-color:#ffffff"
		:items="values"
		:item-text="itemText"
		v-model="model"
		@focus="$emit('focus', $event)"
		:data-cy="dataCy"
		@input="showAddDialog($event)"
	></v-select>

	
</div>
</template>

<script>
import Common from '@/mixins/Common.vue'
// TODO: searchable

export default {
	mixins: [ Common ],

	props: {
		value: Object, // CfObject<ticketType|reduction>
		serviceProviderOptions: Array,
		clientOptions: Array,
		dataCy: String,
		canAddNew: Boolean,
	},
	data() {
		return {
		options: {},
		model: null,
		originalValue: null
	}},
	watch: {
		value(n) {
			this.model = n
			if (!this.originalValue) this.originalValue = this.value
		},
		model(n) { this.$emit('input', n) },
		serviceProviderOptions: {
			// the callback will be called immediately after the start of the observation
			immediate: true, 
			handler (val, oldVal) {
				// do your stuff
				// console.log('new val: ', val)
				// console.log('old val: ', oldVal)
				this.updateOptions()
			}
		},
		clientOptions: {
			// the callback will be called immediately after the start of the observation
			immediate: true, 
			handler (val, oldVal) {
				// do your stuff
				// console.log('new val: ', val)
				// console.log('old val: ', oldVal)
				this.updateOptions()
			}
		}
	},
	mounted() {
		this.model = this.value
		this.originalValue = this.value
		if (this.originalValue) {
			this.originalValue.source = 'ORIGINAL'
		}
		this.updateOptions()
	},
	methods: {
		itemText(item) {
			return item?.fields?.title?.[this.serviceLocale]
				?? item?.fields?.title?.['de']
				?? ''
		},
	     	
		showAddDialog(event) {
			if (event.sys.id === '') {
				this.$emit('show-add-option')
			}
		},
		updateOptions() {
			const options = {...this.options}

			// merge in the serviceProvider options
			for (let option of this.serviceProviderOptions) {
				if (!option.source) option.source = 'SERVICEPROVIDER'
				options[option.sys.id] = option
			}

			// merge in the defaults from the client
			for (let option of this.clientOptions) {
			//	if (!option.defaultAssignment) continue
				if (this.options[option.sys.id]) continue
				if (!option.source) option.source = 'CLIENT'
				options[option.sys.id] = option
			}

			// console.log('options', options)
			this.options = options
			// console.log('this.options', this.options)
		}
	},
	computed: {
		addOptionTitle(){
			// Maybe we want to send the type as a prop(ticketType or reduction) and render the title differently based on the type -> Add new ticket type / Add new reduction
			return '+ ' + this.$t('text.addNewOption')
		},
		values() {
			// copy from sp and client
			let options = {...this.options}

			// add the value originally set on the object
			// we do this because the value may not be assigned to the SP any longer
			// but it still is on the product
			// TODO: should we show a warning when this happens to nudge the user to change it?
			//       but the value may be allowed in a different SP..
			if (this.originalValue?.sys?.id && !options[this.originalValue?.sys?.id]) {
				options[this.originalValue?.sys?.id] = this.originalValue
			}

			// sort by title
			let r = Object.values(options)

			// remove bad objects from the array as these values will be shown on the dropdown in frontend and if they have errors they will be shown as empty selections
			for (let o of r) {
				if (o.fields?.title) {
					continue
				} else {
					r.splice(r.indexOf(o), 1)
				}
			}

			r.sort((a, b) => {
				if (!a.fields?.title || !b.fields?.title) return 0
				if (!a.fields?.title[this.serviceLocale]) a.fields.title[this.serviceLocale] = a.fields.title.de
				if (!b.fields?.title[this.serviceLocale]) b.fields.title[this.serviceLocale] = b.fields.title.de
				
				return a?.fields?.title[this.serviceLocale].localeCompare(b?.fields?.title[this.serviceLocale])
			})
			if (this.canAddNew) {
				r.unshift(
					{
					sys: {id: ''},
					fields: { 
						title: {
							de: this.addOptionTitle,
							en: this.addOptionTitle,
							fr: this.addOptionTitle,
							it: this.addOptionTitle
						}}
					})
			}
			return r
		},
		errors() {
			const errors = []
			if (this.error) {
				errors.push(this.$t('text.missingFieldsError'))
			}
			return errors
		}
	},
}
</script>

<style scoped>
</style>