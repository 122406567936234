<template>
	<v-container fluid>
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<Loading :active.sync="loading" :is-full-page="true" color="#4caf50"></Loading>
		<iframe ref="iframe"></iframe>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Alert from '@/components/common/Alert.vue'
import Mys from './MYS.js'

export default {
	name: "AppHost",
	components: { Loading, Alert },
	props: {
		navbarValue: Object,
		locale: String,
	},
	data() {
		return {
		loading: false,
		errorTitle: '',
		errorDetail: '',
		successTitle: '',
		successDetail: '',
		messageListener: null,
		mys: null,
		app: null,
		appPath: null,
	}},
	watch: {
		locale(v) {
			// TODO: only send events in after the plugin has initialized
			this.mys.localeChanged(v)
		},
	},
	mounted() {
		this.appPath = this.$route.params.appName
		this.app = this.$store.state.selectedComponent

		// test app: 'https://admin.skiline.cc/php/tools/proto-mys-plugin/2/TestApp.html'
		const url = this.app.fields.appConfig.de.url
		if (!url) throw new Error('App url not found in config!')

		this.mys = new Mys(url, this, this.$refs.iframe.contentWindow)

		// TODO: bootstrap: how to auth the app itself?
		//       a s2s call?
		//       api key is insecure since it would be visible in the client
		//       host -(challenge)-> guest -(challenge)-> guest server -> -(solution)-> mys server
		//                                   <-(solution)-'
		//       can a pure browser-app be authenticated safely at all?
		// TODO: limit guest urls - how?
		// TODO: require auth with every call + signature verification (both sides)
		// TODO: pass down location parameters and appPath?
		this.open(url)
	},
	beforeDestroy () {
		this.mys.destroy()
	},
	methods: {
		open(url) {
			this.$refs.iframe.src = url
		},
		// mys api implementation
		getClient(param) {
			return this.$store.state.selectedClient
		},
		getAppComponent(param) {
			return this.app
		},
		getAppPathName(param) {
			return this.$route.params.appName
		},
		getQuery(param) {
			return window.location.search
		},
		getIdentity(param) {
			return this.$store.state.loggedInUser.kc_token
		},
		getLocale(param) {
			return this.locale
		}
	},

}
</script>

<style scoped>
iframe { position: absolute; left: 0; top: 0; width: 100%; height: 100%; border: 0; }
</style>
