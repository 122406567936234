<template>
	<v-row justify="center">
		<Disclosure :title="$t('text.bankingInfoTitle')" :lock="!userMayEditFields" data-cy="bankingInfo" ref="SECTION_bankingInfo">
			<div class="field left-border">
				<v-label>{{$t('text.accountNumber')}}</v-label>
				<v-text-field outlined hide-details
					v-model="model.fields.accountNumber.de"
				/>
			</div>
			<div class="field left-border">
				<v-label>{{$t('text.bic')}}</v-label>
				<v-text-field outlined hide-details
					v-model="model.fields.bic.de"
				/>
			</div>
			<div class="field left-border">
				<v-label>{{$t('text.iban')}}</v-label>
				<v-text-field outlined hide-details
					v-model="model.fields.iban.de"
				/>
			</div>
		</Disclosure>
	</v-row>
</template>

<script>
	import Common from '@/mixins/Common'
	import Disclosure from '@/components/common/Disclosure'
	import { isEqual } from 'lodash'
	
	export default {
	  name: "BankingInfo",
	  components: {
		Disclosure
	  },
	  mixins: [Common],
	 
	  props: {
		serviceProvider: {
		  type: Object,
		  required: true
	 	 },
		 updateModel: Boolean
	  },
	  data() {
		return {
		  model: {},
		  initData: {}
		}
	  },
	  created() {
		this.model = this.valueToModel(this.serviceProvider)
		this.setInitData()
	  },
	  watch: {
		updateModel() {
			this.model = this.valueToModel(this.serviceProvider)
			this.setInitData()
		}
	  },
	  methods: {
		valueToModel(v) {
			return JSON.parse(JSON.stringify(v ?? {}))
		},
		setInitData() {
			const initModel = JSON.parse(JSON.stringify(this.model))
			this.initData = {
				accountNumber : initModel.fields.accountNumber,
				bic : initModel.fields.bic,
				iban : initModel.fields.iban
			}
		},
		sendData() {
			let data = {
				accountNumber : this.model.fields.accountNumber,
				bic : this.model.fields.bic,
				iban : this.model.fields.iban
			}

			data.changed = !isEqual(data, this.initData)
			return data
		},
	  }
	}
	</script>
	
	<style scoped>
	
	</style>
	
	