<template>
	<v-app id="app">
		<v-main fluid>
			<NavBar ref="navbar"
				:navbar="navbar"
				:active-link="activeLink"
        :hide-nav="hideNav"
				@show-login="showLogin"
				@show-dashboard="showDashboard"
				@show-component="showComponent"
        @show-messages="showMessages"
				v-model="navbarValue"
				@event="navbarEvent"
			/>
			<router-view ref="view"
				@view-component="viewComponent"
				@show-login="showLogin"
				@show-dashboard="showDashboard"
				@load-navbar="loadNavbar"
        @hide-navbar="hideNavbar"
        @show-navbar="showNavbar"
				:navbar-value="navbarValue"
			/>
			<Footer :selected-component="selectedComponent" ref="footer"/>
		</v-main>
	</v-app>
</template>

<script>
import Vue from 'vue'

import Vuetify from "vuetify"
Vue.use(Vuetify)

import HttpUtil from "./plugins/HttpUtil.js"
Vue.use(HttpUtil)

import 'vue-loading-overlay/dist/vue-loading.css'
import NavBar from './components/common/NavBar.vue'
import Footer from './components/common/Footer.vue'
import FreshdeskWidget from '@/utils/FreshdeskWidget.js'
import Common from '@/mixins/Common.vue'

export default {
	name: 'App',
	components: {
		NavBar,
		Footer
	},
  mixins: [Common],

	data() {
		return {
		selectedComponent: {},
		activeLink: null,
    hideNav: false,
    navbar: Vue.component('navbar', {
			template: `<div><template><v-spacer/></template></div>`
		}),
		navbarValue: null,
	}},

  created() {
		FreshdeskWidget.install(this.$store)
		if (!this.$store.state.loggedInUser) {
			this.showLogin()
		}
	},  

  async beforeMount() {
    try {
      // we allow setting the home client id manually, this is mostly useful for testing on the generic url
      if (location.toString().indexOf("?clientId=") > -1) {
        await this.$store.commit('setClientId', location.toString().split("?clientId=")[1]);
      }

      this.haveUser = false;
      this.haveComponent = false;
      this.selectedComponent = {};

      var browserLocale = navigator.language || navigator.userLanguage; 
      
      if (browserLocale.split("-")[0] !== "en" && browserLocale.split("-")[0] !== "de") {
        browserLocale = "en";
      }
      
      if (!this.$store.state.selectedLocale) {
        this.$root.$i18n.locale = browserLocale.split("-")[0];
        this.$vuetify.lang.current = browserLocale.split("-")[0]
        await this.$store.commit('setSelectedLocale', browserLocale.split("-")[0]);
        await this.$store.commit('setUserSpecificGerman', "de");

        if (browserLocale.split("-")[0] === "de") {
          if (browserLocale.split("-").length > 1) {
            await this.$store.commit('setUserSpecificGerman', browserLocale.split("-")[1].toLowerCase());
          } else {
            await this.$store.commit('setUserSpecificGerman', browserLocale.toLowerCase());
          }
        }
      }
      
    } catch (error) {
      console.error(error)
    }
  },

	async mounted() {
		this.$root.app = this
    this.getLocales()
	},
	
  methods: {
    loadNavbar(navbar) {
      this.activeLink = navbar.activeLink;
      this.navbarValue = null
      this.navbar = navbar
    },

    hideNavbar() {
      this.$refs.navbar.hideNav = true
    },
    showNavbar() {
      this.$refs.navbar.hideNav = false
    },
    navbarEvent(e) {
      if (!this.$refs.view.navbarEvent) {
        console.error('view does not support navbar events! event:', e)
        return
      }
      this.$refs.view.navbarEvent(e)
    },

    viewComponent() {
      if (this.$store.state.selectedComponent?.fields?.urlLink?.de?.length) {
        window.open(this.$store.state.selectedComponent.fields.urlLink.de)
      } else {
        this.$refs.navbar.selectedComponent = this.$store.state.selectedComponent;
        this.selectedComponent = this.$store.state.selectedComponent;
        this.$router.push({path: this.$store.state.selectedComponent.fields.link.de})
      }
    },
    showMessages() {
      this.$router.push('/messages')
    },
    showComponent() {
      this.$refs.footer.selectedComponent = this.$store.state.selectedComponent;
      
      if (this.$store.state.selectedComponent.link) {
        this.$router.push({path: this.$store.state.selectedComponent.link})
      }
    },
    async showDashboard(event) {
      // Validate Token
      if (this.$store.state.loggedInUser?.kc_token) {
        if (event === 'exitImpersonation') {
          this.loadDashboard()
        } else {
          if (this.$route.path === '/service-designer' || this.$route.path === '/businessProfile') {
            this.$refs.view.goback(true)
          } else {
            this.loadDashboard()
          }
        }
      }
    },
    async loadDashboard() {
      this.resetView()

      if (this.$route.path === "/dashboard") {
        this.$router.go()
      } else {
        this.$router.push("/dashboard")
      }

      this.$refs.navbar.haveUser = Object.keys(this.$store.state.loggedInUser).length > 0

      if (!this.$store.state.coreConfig) {
        await this.loadCoreConfig()
      }
    },
    async resetView() {
      this.$refs.navbar.haveComponent = false
      this.$refs.navbar.selectedComponent = {}

      this.selectedComponent = {};
      await this.$store.commit('setSelectedComponent', {});
      await this.$store.commit('setOffset', 0)
      await this.$store.commit('setSearchString', '')
    },
    async showLogin() {
      FreshdeskWidget.identify('', '')

      localStorage.clear();
      await this.$store.commit('clear')
      await this.$store.commit("setLoggedInUser", {})
      await this.$store.commit("setSelectedComponent", {})
      await this.$store.commit("setIsImpersonation", false)
      this.haveUser = false
      this.haveComponent = false
      this.selectedComponent = {}

      if (this.$refs.navbar) {
        this.$refs.navbar.haveUser = false;
        this.$refs.navbar.haveComponent = false;
        this.$refs.navbar.selectedComponent = {};
      }

      if (this.$route.path !== "/") {
        this.$router.push("/")
      }
    },
    async getLocales() {
      if (!this.$store.state.locales?.length) {
        this.locales = await this.$httpGet(`/locales`)
        this.defaultLocale = this.locales[0]
        this.serviceLocale = this.defaultLocale.code
        await this.$store.commit('setLocales', this.locales)
      }
		},
  }
}
</script>

<style>
body {
	--status-pending-color: #ff9e21;
	--status-reapprove-color: #ffb400;
	--status-saved-color: #589ae6;
	--status-active-color: #66bb6a;
	--status-active_saved-color: #589ae6;
	--status-deactivated-color: #f34545;
	--status-declined-color: #f34545;
	--status-approved-color: #64c823;
  --status-expired-color: #737373;
	--status-unknown-color: #cccccc;
}
@font-face {
  font-family: 'Inter', sans-serif;
  src: url("assets/fonts/Inter-VariableFont.ttf");
}
#app {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black !important;
  font-size: 12pt;
  background-color: #f2f2f2;
}
#card {
  max-width: 840px;
  padding: 32px 48px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15), 0 0 1px 0 rgba(0, 0, 0, 0.2) !important;
}
#panel {
  width: 840px;
  padding: 0;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15), 0 0 1px 0 rgba(0, 0, 0, 0.2) !important;
}
body {
  font-family: 'Inter', sans-serif;
}
.subTitle {
  font-family: 'Inter', sans-serif;
  color: #999999;
  font-size: 13pt;
  font-style: normal;
  font-weight: normal;
}
.fieldCaption {
  font-size: 12pt !important;
  color: #666666;
}
.v-label {
  color: #999999 !important;
  font-size: 12pt !important;
}
.v-main {
  background-color: #f2f2f2 !important;
}
.activeButton {
  background: #01c900 !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(0, 0, 0, 0.1);
  font-size: 14pt !important;
  color: #ffffff !important;
}
.vti__input {
  font-size: 14pt !important;
  margin-top: 5px;
}
.main {
  background-color: #f2f2f2;
  background-size: cover;
  height: 100%;
  width: 100%;
  color: black;
}
h1 {
  font-family: 'Inter', sans-serif;
  font-size: 20pt;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 36pt;
  letter-spacing: normal;
  color: #353535;
}
h2 {
  color: #999999;
  font-size: 14pt;
  line-height: 50px;
}
h3 {
  color: black;
  font-size: 14pt;
  line-height: 40px;
}
p {
  color: #999999;
  font-size: 12pt;
  margin-bottom: 0 !important;
}
a { text-decoration: none; }
.headline {
  color: #999999;
  font-weight: bold;
  font-size: 18pt !important;
}
.v-application .error--text {
  font-size: 11pt;
}
.theme--light.v-divider {
  border-right-width: 3px;
}
.theme--light.v-data-table {
  border-radius: 5px;
}
.theme--light.v-data-table.v-data-table--fixed-header thead th {
  background-color: #808080 !important;
  color: #ffffff !important;
  font-size: 14pt;
  font-weight: normal;
}
.v-data-table-header th.sortable {
  color: #ffffff !important;
}
.v-data-table tr {
  cursor: pointer;
}
.v-data-table td {
  font-size: 14pt !important;
}
.v-chip.v-size--default {
  font-size: 12pt !important;
  font-weight: bold;
}
.theme--light.v-datatable .v-datatable__actions {
  position: fixed;
  bottom: 0;
  width: 100%;
}
.v-simple-checkbox {
  margin-top:-5px;
}
/* .v-input__slot {
  font-size: 14pt !important;
  border: 1px solid rgba(0,0,0,0.1) !important;

}  maybe need to put this back*/
.v-text-field input {
  font-size: 14pt !important;
  height: 37px !important;
}
.v-toolbar__content {
  padding: 0 !important;
}
.v-btn:not(.v-btn--text):not(.v-btn--outlined).v-btn--active::before {
    opacity: .03;
}
.gradientButton {
  min-height: 48px !important;
  background: linear-gradient(0deg, #efefef 0%, #ffffff 100%) !important;
  border: thin #dddddd solid !important;
  display: flex !important;
  /*justify-content: space-around !important;*/
  align-items: center !important;
  border-radius: 5px !important;
  font-family: 'Inter', sans-serif;
  font-size: 12pt !important;
  text-transform: none !important;
  letter-spacing: 0 !important;
}
.orangeButton {
  height: 48px !important;
  background: linear-gradient(0deg, #f27826 0%, #fe8739 100%) !important;
  border: thin #e47a33 solid !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 5px !important;
  font-family: 'Inter', sans-serif;
  font-size: 12pt !important;
  font-weight: normal !important;
  letter-spacing: 0 !important;
  text-transform: none !important;
}
.formatButton {
  background-color: transparent !important;
  padding: 0 !important;
  margin: 0 !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 5px !important;
  font-family: 'Inter', sans-serif;
  font-size: 12pt !important;
  text-transform: none !important;
  letter-spacing: 0 !important;
}
.clearButton {
  min-height: 48px !important;
  background-color: #ffffff !important;
  border: thin #dddddd solid !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 5px !important;
  font-family: 'Inter', sans-serif;
  font-size: 12pt !important;
  text-transform: none !important;
  letter-spacing: 0 !important;
}

.sidebarTitle {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.01px;
  color: #999999;
}
#navlist {
	padding: 0;
}
#navlist li:hover {
	background-color:#f4f4f4;
}
#navlist li {
	list-style-type: none;
	width: 100%;
	padding: 10px 10px 10px 20px;
	align-items: middle;
	font-family: 'Inter', sans-serif;
	font-size: 17px;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: -0.25px;
	font-weight: 500;
}
#navlist a {
	color: #000000;
}
#navlist .active { 
	border-top: 1px solid #ededed;
	border-bottom: 1px solid #ededed; 
	background-color: #ffffff;
	color: #00aeef !important;
}
.v-list-item__title {
  font-size: 12pt !important;
}
.v-overlay__scrim {
  background-color: transparent !important;
}
.firstButton {
  background: linear-gradient(0deg, #efefef 0%, #ffffff 100%) !important;
  border:1px solid #c2c2c2 !important;
  padding-left:15px !important;
  padding-right:15px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right-width: 0 !important;
  font-size: 14pt !important;
  text-transform: none !important;
  letter-spacing: 0 !important;
  height: 48px !important;
}
.middleButton {
  background: linear-gradient(0deg, #efefef 0%, #ffffff 100%) !important;
  border:1px solid #c2c2c2 !important;
  padding-left:15px !important;
  padding-right:15px !important;
  border-radius: 0 !important;
  border-right-width: 0 !important;
  font-size: 14pt !important;
  text-transform: none !important;
  letter-spacing: 0 !important;
  height: 48px !important;
}
.lastButton {
  background: linear-gradient(0deg, #efefef 0%, #ffffff 100%) !important;
  border:1px solid #c2c2c2 !important;
  padding-left:15px !important;
  padding-right:15px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  font-size: 14pt !important;
  text-transform: none !important;
  letter-spacing: 0 !important;
  height: 48px !important;
}
.firstButton_active {
  background: linear-gradient(0deg, #66BB6A 0%, #66BB6A 100%) !important;
  border:1px solid #c2c2c2 !important;
  padding-left:15px !important;
  padding-right:15px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right-width: 0 !important;
  font-size: 14pt !important;
  color: #ffffff !important;
  text-transform: none !important;
  letter-spacing: 0 !important;
  height: 48px !important;
}
.middleButton_active {
  background: linear-gradient(0deg, #66BB6A 0%, #66BB6A 100%) !important;
  border:1px solid #c2c2c2 !important;
  padding-left:15px !important;
  padding-right:15px !important;
  border-radius: 0 !important;
  border-right-width: 0 !important;
  font-size: 14pt !important;
  color: #ffffff !important;
  text-transform: none !important;
  letter-spacing: 0 !important;
  height: 48px !important;
}
.lastButton_active {
  background: linear-gradient(0deg, #66BB6A 0%, #66BB6A 100%) !important;
  border:1px solid #c2c2c2 !important;
  padding-left:15px !important;
  padding-right:15px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  font-size: 14pt !important;
  color: #ffffff !important;
  text-transform: none !important;
  letter-spacing: 0 !important;
  height: 48px !important;
}
.notification {
  position: fixed !important;
  top: 0 !important;
  left: 0;
  width: 100%;
  z-index: 999;
  white-space: pre-line;
  line-height: 20px !important;
  text-transform: none !important;
  letter-spacing: 0 !important;
}
.active {
  background-color:#66BB6A;
  color: #ffffff !important;
}
ul {
  color: black;
  font-size: 14pt;
}
.v-text-field--filled:not(.v-text-field--single-line) input {
  margin-top: 5px !important;
}
.row {
  margin: 0 !important;
}
.roundButton {
  border-radius: 30px !important;
  border: solid 1px rgba(0, 0, 0, 0.1) !important;
  box-shadow: none !important;
  background: #f2f2f2 !important;
  font-size: 14pt !important;
  color: rgba(0,0,0,.38) !important;
  height: 32px !important;
  margin: 5px;
  text-transform: none !important;
  letter-spacing: 0 !important;
}
.weekdayCard {
  background-color:#f2f2f2 !important;
  padding-left:10px !important;
  height:48px !important;
  width:100% !important;
  font-size: 14pt !important;
  line-height: 48px !important;
}
.v-badge {
  padding-left: 220px !important;
}
.v-badge__badge {
  border-radius: 20px !important;
  color: #fff;
  font-size: 14px;
  height: 36px !important;
  min-width: 36px !important;
  letter-spacing: 0;
  padding: 4px 6px;
  pointer-events: auto;
  position: absolute;
  text-align: center;
  text-indent: 0;
  top: auto;
  -webkit-transition: .3s cubic-bezier(.25,.8,.5,1);
  transition: .3s cubic-bezier(.25,.8,.5,1);
  white-space: nowrap;
  z-index: 998;
}
.v-badge, .v-badge__badge {
  display: inline-block;
  line-height: 1.5 !important;
  font-size: 14pt !important;
}
.v-badge--bordered .v-badge__badge::after {
  border-width: 3px !important
}
.greenButton {
  height: 48px !important;
  background: linear-gradient(0deg, #62c522 0%, #62c522 100%) !important;
  border: thin #58b01e solid !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 5px !important;
  font-family: 'Inter', sans-serif;
  font-size: 12pt !important;
  font-weight: normal !important;
  letter-spacing: 0 !important;
  text-transform: none !important;
}
.blueButton {
  height: 48px !important;
  background: linear-gradient(0deg, #0ba7e1 0%, #00adee 100%) !important;
  border: thin #088ec0 solid !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 5px !important;
  font-family: 'Inter', sans-serif;
  font-size: 12pt !important;
  font-weight: normal !important;
  letter-spacing: 0 !important;
  text-transform: none !important;
}
.blueButtonMin {
  background: linear-gradient(0deg, #0ba7e1 0%, #00adee 100%) !important;
  border: thin #088ec0 solid !important;
  display: flex !important;
  justify-content: space-around !important;
  align-items: center !important;
  border-radius: 5px !important;
  font-family: 'Inter', sans-serif;
  font-size: 12pt !important;
  font-weight: normal !important;
  letter-spacing: 0 !important;
  text-transform: none !important;
}
.redButton {
  height: 48px !important;
  background: linear-gradient(0deg, #ff4848 0%, #f34545 100%) !important;
  border: thin #e74d4c solid !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 5px !important;
  font-family: 'Inter', sans-serif;
  font-size: 12pt !important;
  font-weight: normal !important;
  letter-spacing: 0 !important;
  text-transform: none !important;
}
.defaultButton {
  height: 48px !important;
  background: linear-gradient(0deg, #f2f2f2 0%, #f2f2f2 100%) !important;
  border: thin #f2f2f2 solid !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 5px !important;
  font-family: 'Inter', sans-serif;
  font-size: 12pt !important;
  font-weight: normal !important;
  letter-spacing: 0 !important;
  text-transform: none !important;
}
.v-alert {
  color: #ffffff !important
}
input[type=checkbox] {
    width: 40px;
    height: 40px;
}
.clientAssignmentChip {
	display: inline-block;
	max-width: 150px;
	margin: 0 5px 0 0;
	padding: 2px 10px;
	color: #000;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
	border: thin #dddddd solid !important;
	border-radius: 6px;
	background: rgb(245, 245, 245);
	background: linear-gradient(0deg, rgba(245, 245, 245, 1) 0%, rgba(254, 254, 254, 1) 100%);
	cursor: pointer;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	line-height: initial;
	font-size: 12pt;
}
.v-ripple__container {
  opacity: 0 !important;
}
.v-list-item--link::before {background-color: #ffffff !important;}
.v-list-item--link {cursor:default !important}

.clientAssignmentChip.pending { border-left: 3px solid var(--status-pending-color) !important; }
.clientAssignmentChip.reapprove { border-left: 3px solid var(--status-reapprove-color) !important; }
.clientAssignmentChip.saved { border-left: 3px solid var(--status-saved-color) !important; }
.clientAssignmentChip.active { border-left: 3px solid var(--status-active-color) !important; color: black !important; /* TODO: the .active class is used in another place blankly. we should fix that and then remove the black here. */ }
.clientAssignmentChip.active_saved { border-left: 3px solid var(--status-active_saved-color) !important; }
.clientAssignmentChip.deactivated { border-left: 3px solid var(--status-deactivated-color) !important; }
.clientAssignmentChip.declined { border-left: 3px solid var(--status-declined-color) !important; }
.clientAssignmentChip.approved { border-left: 3px solid var(--status-approved-color) !important; }
.clientAssignmentChip.expired { border-left: 3px solid var(--status-expired-color) !important; }

.tableCard { border-radius: 5px; overflow: hidden; }
.tableCard span { white-space: nowrap; text-overflow: ellipsis; }
.tableFooter { box-shadow: none !important; background-color:#f4f4f4 !important; }
.truncate { max-width: 1px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }

div.field { position: relative; margin-bottom: 10px; padding-bottom: 10px; margin-top: 20px; padding-top: 20px; padding-left: 15px; }
div.field .v-input { margin-top: 12px; }
div.field .v-label { display: block; }
.field.left-border:before { content: ""; position: absolute; left: 0; top: 15px; bottom: 0px;  width: 100%; border-left:3px solid #ddd; }

.v-messages__message {
  font-size: 12pt !important;
  line-height: normal !important;
}
.v-text-field__details { padding: 0 !important; margin: 0 !important }
.helpText { padding-top:5px; position:relative; z-index: 1;}

.gradient-button-align {
  min-height: 48px !important;
  background: linear-gradient(0deg, #efefef 0%, #ffffff 100%) !important;
  border: thin #dddddd solid !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 5px !important;
  font-family: 'Inter', sans-serif;
  font-size: 12pt !important;
  text-transform: none !important;
  letter-spacing: 0 !important;
  margin-top: 12px !important;
}

.error-text { font-size: 12pt !important; color: #f34545 !important}
.navbar {
  position: fixed;
  display: flex;
  top: 0;
  z-index: 900; 
  left: 380px; 
  width: calc(100% - 466px);
  padding: 7px; 
  height: 64px;
}
.system-bar { position: fixed;
  display: flex;
  top: 64;
  z-index: 900; width:100%;  height:28px !important; }
.v-chip .v-chip__content {
  font-family: 'Inter', sans-serif;
}
.flag { position: absolute; z-index: 10; margin-top: 30px; margin-left: -26px; zoom: 0.8; }
</style>