<template>
	<div>
		<v-data-table 
            :headers="headers"
            :items="items" 
            item-key="entry_id"
            style="margin-top:10px;"
			:items-per-page="1000"
			fixed-header hide-default-footer disable-sort
        >
			<template v-slot:top>
				<v-toolbar flat color="white">
				<v-toolbar-title>Contentful Sync</v-toolbar-title>
				<v-spacer></v-spacer>
				</v-toolbar>
			</template>
			<template v-slot:item="{ item }">
                <tr style="cursor: auto">
                  <td style="text-align: left;">{{item.entry_id}}</td>
                  <td v-if="item.content_type">{{item.content_type}}</td>
				  <td v-else><v-icon color="primary">mdi-help-circle</v-icon></td>
				  <td v-if="item.synched" style="backgroundColor: #E3FCEF">{{item.updated_at | formatDateTime}}</td>
				  <td v-else style="backgroundColor: #FFEBE6">{{translateMessage(item.sync_failure_reason)}}</td>
                </tr>
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import Common from '@/mixins/Common'

	export default {
	name: "ContentfulInfo",
	mixins: [Common],
	props: {
		linkedEntries: {
			type: Array,
			required: true
		}
	},

	data() {
		return {
		model: {},
		sectionMessage: {
			error: false,
			message: ''
		},
		items: [],
		headers: [
			{
				text: 'Contentful ID',
				value: "entry_id",
				align: 'center',
				width: '40%'
			},
			{
				text: 'Content Type',
				value: "content_type",
				align: 'center',
				width: '20%'
			},
			{
				text: 'Sync Status',
				value: "updated_at",
				align: 'center',
			},
		],

		}
	},

	created() {
		this.items = this.linkedEntries
	},
	methods: {
		translateMessage(reason) {
			if (reason) {
				if (reason === "Entry not found in Content Manager") {
					return this.$t('text.entryNotFoundInCM')
				} else {
					// if the string contains 'Unprocessable Entity" then return that part because the original error message is too long and complex
					// TODO: maybe add a translation for this as well
					if (reason.indexOf('Unprocessable Entity') > -1) {
						return 'Unprocessable Entity'
					} else {
						return reason
					}
					
				}
			} else {
				return this.$t('text.noFailureReasonFromCM')
			}
		}
	},
	}

</script>

<style scoped>
table,
table td {
  border: 1px solid #cccccc;
}
td {
  height: 80px;
  width: 160px;
  text-align: center;
  vertical-align: middle;
}
</style>