<template>
  <v-container class="main" fluid fill-height>
    <v-alert :value="errorTitle!==''" :v-if="errorTitle!==''" type="error" dismissible flat class="notification" prominent v-model="errorAlertVisible"><span v-html="errorMessages"></span></v-alert> 
    <v-alert :value="successTitle!==''" :v-if="successTitle!==''" type="success" dismissible flat class="notification" prominent v-model="successAlertVisible"><span v-html="successMessages"></span></v-alert> 

    <loading :active.sync="isLoading" 
      :is-full-page="fullPage"
      color="#4caf50">
    </loading>
    
    <v-row align="center" justify="center">
      <v-card id="card" class="elevation-12">
        <v-card-text style="margin:0px;padding:0px">
          <h1>{{$t('text.forgotPassword')}}</h1>
          <p class="subTitle" v-html="$t('text.forgotPasswordText')"/>

          <v-row class="field left-border flex-column">
            <div>
              <v-card elevation="0">
                <v-label>{{$t('text.email')}}</v-label>
                <v-text-field outlined dense
                  v-model="email"
                  :error-messages="emailErrors"
                  @keyup.enter="forgotPassword()"
                  required></v-text-field>
              </v-card>
            </div>
            <div>
              <v-btn id="btnLogin" depressed dark color="#64c823" style="height:48px;font-size:12pt" @click="forgotPassword()">{{$t('text.sendToMe')}}</v-btn>
            </div>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import { required, email } from "vuelidate/lib/validators"

export default {
  name: 'ForgotPassword',

  components: {
    Loading
  },

  validations: {
    email: { required, email },
    password: { required }
  },

  data() {
		return {
    showLogin: false,
    selectedLocale: '',

    isLoading: false,
    fullPage: true,

    errorTitle: '',
    errorDetail: '',
    successTitle: '',
    successDetail: '',
    errorAlertVisible: false,
    successAlertVisible: false,

    email: '',
    password: ''
  }},

  computed: {
    errorMessages() {
      return ' <span style="font-size:14pt;font-weight:bold;"> ' + this.errorTitle + ' </span><br> ' + this.errorDetail;
    },
    successMessages() {
      return ' <span style="font-size:14pt;font-weight:bold;"> ' + this.successTitle + ' </span><br> ' + this.successDetail;
    },
    emailErrors () {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push(this.$t('text.emailInvalid'))
      !this.$v.email.required && errors.push(this.$t('text.emailRequired'))

      return errors
    }
  },
  
  watch: {
    errorAlertVisible() {
      if (this.errorAlertVisible) {
        setTimeout(this.closeAlert, 3000)
      }
    },
    successAlertVisible() {
      if (this.successAlertVisible) {
        setTimeout(this.closeAlert, 3000)
      }
    }
  },

  mounted() {
    this.showLogin = false
  },

  beforeDestroy () {
    this.$root.$off('show-dashboard')
  },

  methods: {
    closeAlert() {
      if (this.errorAlertVisible) {
        this.errorAlertVisible = false;
      } else if (this.successAlertVisible) {
        this.successAlertVisible = false;
        this.$router.push("/");
      }
    },

    /* Server Functions */
    forgotPassword() {
      
      if (this.emailErrors.length === 0) {
        this.isLoading = true;

        this.$httpPost(`/forgot-password?email=${this.email}`)
        .then(res => {
          this.isLoading = false;
          this.successTitle = this.$t('text.SUCCESS'); 
          this.successDetail = this.$t('text.forgotPasswordSuccess'); 
          this.successAlertVisible = true;
        }).catch(error => {
          this.isLoading = false;

          this.errorTitle = this.$t('text.ERROR');  
      
          if (error.response) {
            if (error.response.data.code === "1003") {
              this.errorTitle = this.$t('text.loginPendingErrorTitle'); 
              this.errorDetail = this.$t('text.loginPendingErrorDetail'); 
            } else {
              if (error.response.data.error) {
                if (error.response.data.error === "Invalid E-mail Address and/or Password") {
                  this.errorDetail = this.$t('text.loginInvalidCredentialsError'); 
                } else {
                  this.errorDetail = error.response.data.error;
                }
              } else {
                this.errorDetail = this.$t('text.generalError'); 
              }
            } 
          } else {
            this.errorDetail = error;
          }

          this.errorAlertVisible = true;
        });
      }
    }
  }
};
</script>

<style scoped>
/* !! DON'T move the button style to the App.vue - it will mess up all the other screens !! */
button {
  height: 48px !important;
  background: #62c522 !important;
  border: thin #58b01e solid !important;
  display: flex !important;
  justify-content: space-around !important;
  align-items: center !important;
  border-radius: 5px !important;
  font-family: "Inter";
  font-size: 12pt !important;
  font-weight: normal !important;
  letter-spacing: 0 !important;
  text-transform: none !important;
}
.main {
  background-size: cover !important;
}
</style>
