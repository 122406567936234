<template>
	<v-container fluid class="mb-14">
		<v-card class="tableCard">
			<v-data-table fixed-header hide-default-footer disable-sort disable-pagination
				:headers="headers"
				:items="items"
				:items-per-page="100"
        @click:row="select"
        :mobile-breakpoint="750"
			>
        <template v-slot:item.sales_channel="{ item }">
          {{item.fields.title[selectedLocale]}}
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip :color="getColor(item)" dark>{{getText(item)}}</v-chip>
        </template>
			</v-data-table>
			<Dialog ref="dialog"
				:confirm-label="$t('text.save')"
				:cancel-label="$t('text.cancel')"
				:confirm-handler="dialogConfirm"
				:cancel-handler="dialogCancel"
				:title="selectedItem ? selectedItem.fields.title.de : ''">
				<template #content>
					<div v-if="selectedItem && selectedItem.fields.mandatory">
						<!-- TODO: i18n for title field? -->
						<v-text-field outlined required
							counter="255"
							v-model="selectedItem.fields.title.de"
						/>
						<label>{{ $t('text.salesChannelMandatoryHelp') }}</label>
						<v-switch inset
							v-model="selectedItem.fields.mandatory.de"
							:label="$t('mandatory')"
						/>
					</div>
				</template>
			</Dialog>
		</v-card>
	</v-container>
</template>

<script>
import Dialog from '@/components/common/Dialog.vue'
import Common from '@/mixins/Common.vue'

export default {
	name: "MarketplaceSalesChannelsView",
	components: { Dialog },
	mixins: [ Common ],
	data() {
		return {
		items: [],
		selectedItem: null,
	}},
	computed: {
		headers () {
			return [
				{ text: this.$t('text.code'), value: "fields.code.de" },
				{ text: this.$t('text.salesChannel'), value: "sales_channel" },
				{ text: this.$t('text.url'), value: "fields.shopUrl.de" },
				{ text: this.$t('text.status'), width: "200", value: "status" },
			]
		},
	},
	watch: {
		selectedItem(item) {
			this.$refs.dialog.show = !!item
		},
	},
	mounted() {
		this.load()
	},
	methods: {
		load() {
			this.items = this.$store.state.selectedClient?.fields.salesChannels?.de ?? []
		},
		getColor(item) {
			if (item.fields.mandatory?.de) return '#33CC66'
			return '#999999'
		},
		getText(item) {
			return item.fields.mandatory?.de ? this.$t('text.mandatory').toUpperCase() : this.$t('text.optional').toUpperCase()
		},
		select(item) {
			this.selectedItem = JSON.parse(JSON.stringify(item))
		},
		async dialogConfirm() {
			let clientId = this.$store.state.selectedClient.sys.id
			await this.$httpPut('/client/' + clientId + '/saleschannel/' + this.selectedItem.sys.id, this.selectedItem)
			this.selectedItem = null
			let client = await this.$httpGet('/client/' + clientId)
			// TODO: this weirdness should be sorted out at the server - but we'd also need to track down all other dependent places
			client = client.client
			// TODO: at this point we should also write into the loggedInUser
			//       because if she is an admin, he can switch clients and that will take the client from the user
			await this.$store.commit('setSelectedClient', client)
			this.load()
			return true
		},
		dialogCancel() {
			// TODO: ?
		},
	},
}
</script>