<template>
	<v-container style="padding:0px" fluid>
		<div class="pl-6">
			<div class="field left-border">
				<v-label>{{ $t('text.tagAssignment') }}</v-label>
				<p class="helpText" v-html="$t('text.tagAssignmentHelp')" style="margin-left:0px"/>
				<br/><br/>

				<div class="tag-list">
					<v-row class="pa-0">
						<v-col cols="10"><h4 class="pl-2">{{ $t('text.tag') }}</h4></v-col>
						<v-col cols="2" class='d-flex justify-center'><h4>{{ $t('text.canManage') }}</h4></v-col>
					</v-row>
					<v-divider style="border-color:#646464"/>

					<div v-for="tag of availableTags" :key="tag.value">
						<v-row class="pa-0">
							<v-col cols="10">
								<v-list-item class="pa-0">
									<v-list-item-title>
										<v-row align="center">
											<button class="tag" elevation="0" width="32px" :style="`border:solid 3px ${tag.colour} !important; color:${tag.colour} !important;`">{{ tag.label }}</button>
											<span style="font-size: 14pt !important; padding-left:10px">{{tag.value}}</span>
										</v-row>
									</v-list-item-title>
								</v-list-item>
							</v-col>
							<v-col cols="2" class='d-flex justify-center'>
								
								<v-checkbox v-model="tag.selected"/>
					
							</v-col>
						</v-row>
						<v-divider style="border-color:#646464"/>
					</div>
				</div>
			</div>
		</div>
	</v-container>
</template>

<script>
import Common from '@/mixins/Common.vue'

export default {
	name: 'TagAssignment',
	mixins: [ Common ],
	props: {
		user: Object,
		availableTags: Array
	},

	mounted() {
		if (this.availableTags?.length && this.user.fields.canManageTags?.de?.length) {
			for (const tag of this.availableTags) {
				tag.selected = this.user.fields.canManageTags.de.find(assignedTag => assignedTag === tag.value) ? true : false
			}
		}
		this.$forceUpdate()
	},
	
	methods: {
		getTagStyle(tag) {
			const colour = this.uniqueTags.find(uniqueTag => uniqueTag.label === tag)?.colour
			return `border:solid 3px ${colour} !important; color:${colour} !important;`
		}
	},
}
</script>

<style scoped>
.tag-list { line-height: 2; padding: 10px; }
.tag { padding: 5px; border-radius: 45%; height:32px; white-space: nowrap; border:solid 3px gray; margin-right: 5px; font-weight: bold; background-color: #ffffff !important; }
</style>