<template>
	<v-row justify="center">
		<Disclosure :title="$t('text.myInfoTitle')" :error="sectionMessage.error" :message="sectionMessage.message" :lock="!userMayEditFields" :expanded="true" data-cy="myInfo" ref="SECTION_myInfo">
			<p class="subTitle" v-html="$t('text.myInfoSubTitle')"/>
			<div class="field left-border">
				<v-label>{{$t('text.businessName')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
				<LanguageFlag v-model="serviceLocale" class="serviceLocale" style="margin-top:10px !important"/>
				<v-text-field outlined required
					data-cy="businessName"
					:hide-details="businessNameErrors.length===0"
					v-model.trim="model.fields.title[serviceLocale]"
					:error-messages="businessNameErrors"
					@input="validateBusinessName"
					@blur="checkBusinessNameExists"
				/>
				<p class="helpText" v-html="$t('text.businessNameHelp')"/>
			</div>

			<!-- Contact Details -->
			<div class="field left-border" v-if="contactInfoAddress">
				<v-label>{{$t('text.businessAddress')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
				<v-text-field outlined
					hide-details
					:placeholder="$t('text.placeHolderAddress')"
					:error-messages="streetAddressErrors"
					@input="validateStreetAddress"
					v-model="contactInfoAddress.fields.contactAddresses.de[0].fields.streetAddress.de"
				/>
				<v-row style="width:100%; column-gap: 10px;">
					<v-text-field outlined
						hide-details
						:placeholder="$t('text.placeHolderPostCode')"
						:error-messages="postalCodeErrors"
						@input="validatePostalCode"
						v-model="contactInfoAddress.fields.contactAddresses.de[0].fields.zipCode.de"
					/>
					<v-text-field outlined
						hide-details
						:placeholder="$t('text.placeHolderCityTown')"
						:error-messages="cityErrors"
						@input="validateCity"
						v-model="contactInfoAddress.fields.contactAddresses.de[0].fields.city.de"
					/>
				</v-row>
				<v-select outlined dense return-object
					hide-details
					:placeholder="$t('text.placeHolderCountry')"
					:error-messages="countryErrors"
					:items="countries"
					:item-text="item => item.name[selectedLocale]"
					@input="validateCountry"
					v-model="contactInfoAddress.fields.contactAddresses.de[0].fields.country[selectedLocale]"
				/>
			</div>

			<div class="field left-border">
				<p class="subTitle" v-html="$t('text.locationDesc')"/><br/>
				<LocationField ref="location"
					v-model="model.fields.location.de"
					:address="contactInfoAddress.fields.contactAddresses.de[0]"
				/>
			</div>
			<div class="field left-border">
				<v-label>{{$t('text.businessPhone', { format: phoneFormat})}}</v-label>
					<v-text-field
						v-if="contactInfoPhone"
						outlined hide-details
						v-model="contactInfoPhone.fields.contactDetail.de"
					/>
			</div>
			<div class="field left-border">
				<v-label>{{$t('text.businessEmail')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
				<v-text-field
					outlined
					v-if="contactInfoEmail"
					:hide-details="businessEmailErrors.length===0"
					v-model="contactInfoEmail.fields.contactDetail.de"
					@input="validateBusinessEmail"
					:error-messages="businessEmailErrors"
				/>
			</div>
			<div class="field left-border">
				<v-label>{{$t('text.website')}}</v-label>
				<v-text-field outlined
					v-if="contactInfoWebsite"
					:hide-details="urlErrors.length===0"
					v-model="contactInfoWebsite.fields.contactDetail.de"
					@input="validateUrl"
					:error-messages="urlErrors"
				/>
			</div>
		</Disclosure>
	</v-row>
</template>

<script>
import Common from '@/mixins/Common'
import Disclosure from '@/components/common/Disclosure'
import LocationField from '@/components/common/LocationField.vue'
import LanguageFlag from '@/components/common/LanguageFlag.vue'
import {eventBus} from "@/main"
import { isEqual } from 'lodash'

export default {
  name: "MyInformation",
  components: {
    Disclosure,
	LocationField,
	LanguageFlag
  },
  mixins: [Common],
 
  props: {
	serviceProvider: {
		  type: Object,
		  required: true
	},
	updateModel: Boolean
  },
  data() {
    return {
		model: {},
		initData: {},
		sectionMessage: {
			error: false,
			message: ''
		},
		countries: this.getCountries(),
		businessNameAllowed: true,
		contactInfoPhone: {sys: {id:'id_1'}, fields:{contactInfoType: {de:{fields:{type:{de:'Phone'}}}},contactDetail:{de:''}}},
		contactInfoEmail: {sys: {id:'id_2'}, fields:{contactInfoType: {de:{fields:{type:{de:'E-Mail'}}}},contactDetail:{de:''}}},
		contactInfoWebsite: {sys: {id:'id_3'}, fields:{contactInfoType: {de:{fields:{type:{de:'Website'}}}},contactDetail:{de:''}}},
		contactInfoAddress: {
			sys: {id:'id_4'},
			fields:{
			contactInfoType: {de:{fields:{type:{de:'Address'}}}},
			contactAddresses:{
				de:[{
				sys: {id:'id_5'},
				fields: {
					streetAddress:{de:''},
					zipCode:{de:''},
					city:{de:''},
					country:{},
				}
				}]
			}
			}
		},
		streetAddressErrors: [],
		postalCodeErrors: [],
		cityErrors: [],
		countryErrors: [],
		businessEmailErrors: [],
		businessNameErrors: [],
		urlErrors: [],
    }
  },
    watch: {
		updateModel() {
			this.model = this.valueToModel(this.serviceProvider)
			this.setInitData()
			this.setContactInfoDetails()
		},
		'businessNameAllowed': {
			handler: function (newVal) {
				newVal === false ? this.validateBusinessName() : this.businessNameErrors = []
			},
		},
  },
  created() {
	  	this.model = this.valueToModel(this.serviceProvider)
		this.setInitData()
		this.setContactInfoDetails()

		eventBus.$on("contact-info-name-changed", (contactInfoName) => {
			let index = this.model.fields.contactInfos.de.findIndex(contactInfo => contactInfo.fields.contactInfoType.de.fields.type.de === "Name")
			if (index > -1) {
				this.model.fields.contactInfos.de[index] = contactInfoName
			} else {
				this.model.fields.contactInfos.de.unshift(contactInfoName)
			}
		})
  },
  beforeDestroy () {
    eventBus.$off('contact-info-name-changed')
  },
  methods: {
	valueToModel(v) {
      return JSON.parse(JSON.stringify(v ?? {}))
    },
	setInitData() {
		const initModel = JSON.parse(JSON.stringify(this.model))

		this.initData = {
			title: initModel.fields.title,
			contactInfos: initModel.fields.contactInfos,
			location: initModel.fields.location
		}
	},
	sendData() {
		let data = {
			title: this.model.fields.title,
			contactInfos: this.model.fields.contactInfos,
			location: this.model.fields.location
		}

		data.changed = !isEqual(data, this.initData)
		return data
    },
	validateAllFields() {
		let allFieldsAreValid = true
		this.resetSectionError(this.sectionMessage)

		let locationError = LocationField.static.validate(this.model.fields.location.de)

		const isBusinessNameValid = this.validateBusinessName()
		const isStreetAddressValid = this.validateStreetAddress()
		const isPostalCodeValid = this.validatePostalCode()
		const isCityValid = this.validateCity()
		const isCountryValid = this.validateCountry()
		const isBusinessEmailValid = this.validateBusinessEmail()
		const isUrlValid = this.validateUrl()

		if (!isBusinessNameValid || !isStreetAddressValid || !isPostalCodeValid || !isCityValid || !isCountryValid || !isBusinessEmailValid || !isUrlValid || locationError) {
			allFieldsAreValid = false
			this.setSectionError(this.sectionMessage, this.$t('text.missingFieldsError'))
			return false
		}

		return allFieldsAreValid
	},
	validateBusinessName() {
		let isValid = true
		this.businessNameErrors = []

		if (!this.validateTextLocales(this.model.fields.title)) {
			isValid = false
			this.businessNameErrors.push(this.$t('text.businessNameRequired'))
		}
		if (this.model.fields.title.de.length > 128) {
			isValid = false
			this.businessNameErrors.push(this.$t('text.businessNameMaxChars'))
		}
		if (!this.businessNameAllowed) {
			isValid = false
			this.businessNameErrors.push(this.$t('text.businessNameExists'))
		}
		return isValid
	},
	validateBusinessEmail() {
      let isValid = true
      this.businessEmailErrors = []
      if (this.contactInfoEmail.fields?.contactDetail?.de && !this.validateEmail(this.contactInfoEmail.fields.contactDetail.de)) {
        isValid = false
        this.businessEmailErrors.push(this.$t('text.invalidEmail'))
      } else if (this.contactInfoEmail.fields?.contactDetail?.de === '') {
		isValid = false
		this.businessEmailErrors.push(this.$t('text.emailRequired'))
	  }
      return isValid
    },
	validateEmail(email) {
      let re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/
      return re.test(String(email).toLowerCase());
    },
	validateStreetAddress() {
		let isValid = true
     	this.streetAddressErrors = []
		if (this.contactInfoAddress.fields.contactAddresses?.de[0]?.fields?.streetAddress?.de?.length === 0) {
			isValid = false
			this.streetAddressErrors.push(this.$t('text.businessAddressRequiredError'))
		}
		return isValid
	},
	validatePostalCode() {
		let isValid = true
      	this.postalCodeErrors = []
		if (this.contactInfoAddress?.fields?.contactAddresses?.de[0]?.fields?.zipCode?.de?.length === 0) {
			isValid = false
			this.postalCodeErrors.push(this.$t('text.businessAddressRequiredError'))
		}
		return isValid
	},
	validateCity() {
		let isValid = true
      	this.cityErrors = []
		if (!this.contactInfoAddress?.fields?.contactAddresses?.de[0]?.fields?.city?.de || this.contactInfoAddress?.fields?.contactAddresses?.de[0]?.fields?.city?.de?.length === 0) {
			isValid = false
			this.cityErrors.push(this.$t('text.businessAddressRequiredError'))
		}
		return isValid
	},
	validateCountry() {
		let isValid = true
		this.countryErrors = []
		if (!this.contactInfoAddress?.fields?.contactAddresses?.de[0]?.fields?.country || Object.keys(this.contactInfoAddress.fields.contactAddresses.de[0].fields.country).length === 0) {
			isValid = false
			this.countryErrors.push(this.$t('text.businessAddressRequiredError'))
		}
		return isValid
	},
	validateUrl() {
		let isValid = true
		this.urlErrors = []
		if (this.contactInfoWebsite?.fields?.contactDetail?.de) {
				var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
				'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
				'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
				'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
				'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
				'(\\#[-a-z\\d_]*)?$','i'); // fragment locator

				if (!pattern.test(this.contactInfoWebsite.fields.contactDetail.de)) {
					isValid = false
					this.urlErrors.push(this.$t('text.urlInvalid'))
			}
		}			

		return isValid
	},
	
	setContactInfoDetails() {
		const sp = this.model.fields

		if (sp.contactInfos?.de?.length > 0) {
			for (let contactInfo of sp.contactInfos?.de ?? []) {
				const type = contactInfo.fields.contactInfoType.de.fields.type.de
				if (type === "Name") {
				this.contactInfoName = contactInfo ?? { de: '' }
				} else 
				if (type === "Phone") {
				this.contactInfoPhone = contactInfo ?? { de: '' }
				} else if (type === "E-Mail") {
				this.contactInfoEmail = contactInfo ?? { de: '' }
				} else if (type === "Website") {
				// ATT: we are fixing a data error here - maybe we should do this for every type?
				if (!contactInfo.fields.contactDetail) contactInfo.fields.contactDetail = { de: '' }
				this.contactInfoWebsite = contactInfo ?? { de: '' }
				} else if (type === "Address") {
				if (contactInfo.fields.contactAddresses && contactInfo.fields.contactAddresses.de) {
					if (!contactInfo.fields.contactAddresses.de[0].fields.streetAddress) {
					contactInfo.fields.contactAddresses.de[0].fields["streetAddress"] = {de:{}}
					}
					if (!contactInfo.fields.contactAddresses.de[0].fields.city) {
					contactInfo.fields.contactAddresses.de[0].fields["city"] = {de:''}
					}
					if (!contactInfo.fields.contactAddresses.de[0].fields.zipCode) {
					contactInfo.fields.contactAddresses.de[0].fields["zipCode"] = {de:''}
					}
					if (!contactInfo.fields.contactAddresses.de[0].fields.country) {
					contactInfo.fields.contactAddresses.de[0].fields["country"] = {}
					}
				}
				this.contactInfoAddress = contactInfo
				}
				}
		} else {
			sp.contactInfos = { de: [] }
		}

		 // We need a reference from internal data to the model data
			sp.contactInfos.de = [
			this.contactInfoPhone,
			this.contactInfoEmail,
			this.contactInfoWebsite,
			this.contactInfoAddress,
			]
		 
		 return sp.contactInfos
	},
	async checkBusinessNameExists() {
		try {
			const res = await this.$httpGet(`/check-businessname?title=${this.model.fields.title.de}&id=${this.model.sys.id}`)
			this.businessNameAllowed = res.businessNameAllowed
		}
		catch (e) {
			// nothing
		}
	},
  }
}
</script>

<style scoped>
.serviceLocale { position: absolute; z-index: 10; margin-top: 32px; margin-left: -32px; zoom: 0.8; }
</style>

