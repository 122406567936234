<script>
export default {
	data() {
		return {
			state: '',
			activeLink: '',
			offset: 0,
    		limit: 15,
			searchString: '',
			serviceProviderClicked: false,
		}
	},
	computed: {
		isOperator() {
			return this.$store.state.loggedInUser?.fields?.type?.de === 'operator' && !this.$store.state.isImpersonation
		},
		states() {
			if (this.userIsOperator) {
				return ["serviceProviders", "ticketType", "reduction", "checklist", "categories", "marketplaceSettings"]
			} else {
				return ["ticketType", "reduction"]
			}
		},
	},
	methods: {
		changeState(state) {
			this.state = state
			this.itemType = state
			this.offset = 0
			this.searchString = ""
		},
		setActive(key) {
			this.activeLink = key
			this.state = key
			this.itemType = key
			this.serviceProviderClicked = false
			
			if (key === 'checklist') {
				this.$router.push('/checklist')
			} else if (key === 'categories') {
				this.$router.push('/productCategory')
			}
			else {
				// avoid redundant navigation to current location
				if (this.$router.currentRoute.path !== '/settingsView') {
					this.$router.push({ path: '/settingsView', query: { viewType: key } })
				}
			}	
		},
	}
}
</script>
