<template>
  <v-container fluid style="line-height:0px;margin-top:20px;width:98% !important">
    <v-alert :value="errorTitle!==''" :v-if="errorTitle!==''" type="error" dismissible flat class="notification" prominent><span v-html="errorMessages"></span></v-alert>
    <loading :active.sync="isLoading" :is-full-page="fullPage" color="#4caf50"></loading>

    <!-- navbar -->
    <div class="navbar">
      <v-row class="flex-nowrap">
        <div class="search-container">
          <div>
            <v-menu offset-y bottom left origin="top right" style="width:275px !important;min-width:275px !important;max-width:275px !important" :close-on-content-click="false" v-model="isSearchOpened">
              <template v-slot:activator="{ on }" style="width:275px !important;min-width:275px !important;max-width:275px !important">
                <v-row align="center" justify="end" v-on="on">
                  <v-btn class="gradientButton" elevation="0" style="height:40px;width:275px;font-size:12pt">
                    <v-icon>mdi-magnify</v-icon>
                    <span class="d-none d-md-inline" v-if="searchType===''">{{$t('text.search')}}</span>
                    <span class="d-none d-md-inline" v-if="searchType!==''">{{$t('text.'+searchType)}}</span>
                    <v-icon style="cursor:pointer;">mdi-menu-down</v-icon>
                  </v-btn>
                  <div v-if="filter" class="dot-container stack-top"><span class="dot"></span></div>
                </v-row>
              </template>

              <v-list style="width:275px !important;min-width:275px !important;max-width:275px !important;padding:0px">
                <v-list-item style="width:275px !important;min-width:275px !important;max-width:275px !important;cursor:pointer" 
                  v-for="searchType of searchTypes" :key="searchType">
                    <div style="width:100%" @click="setSelectedSearchType(searchType)">{{$t('text.'+searchType)}}</div>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>

          <v-text-field flat solo dense clearable hide-details height="48"
            data-cy="search"
            :placeholder="$t('text.searchOrders')"
            v-model="searchString"
            @keyup.enter="getOrders()"
            @click:clear="clearSearch()" />
        </div>

          <!-- Filter -->
          <div>
            <v-menu offset-y bottom left origin="top right" style="width:500px !important;min-width:500px !important;max-width:500px !important" :close-on-content-click="false" v-model="isOpened">
              <template v-slot:activator="{ on }" style="width:500px !important;min-width:500px !important;max-width:500px !important">
                <v-row style="width:75px" align="center" justify="end" v-on="on"> 
                  <v-btn class="gradientButton" elevation="0" style="height:40px;font-size:12pt">
                    <v-icon>mdi-filter</v-icon>
                    <span class="d-none d-md-inline">{{$t('text.filter')}}</span>
                    <v-icon style="cursor:pointer;">mdi-menu-down</v-icon>
                  </v-btn>
                  <div v-if="filter" class="dot-container stack-top"><span class="dot"></span></div>
                </v-row>
              </template>

              <v-list style="width:500px !important;min-width:500px !important;max-width:500px !important;padding:0px">
                <v-list-item style="width:500px !important;min-width:500px !important;max-width:500px !important;padding-top:20px">
                  <v-list-item-title>
                    <div style="padding: 0 10px" @click="closeDropDown('vSelectStatus')">
                      <span style="width:88%">{{$t('text.status')}}</span>
                      <v-select
                        ref="vSelectStatus" 
                        :placeholder="$t('text.allLabel')" 
                        filled dense solo 
                        :menu-props="{ offsetY: true  }"
                        v-model="selectedStatuses"
                        :items="statuses"
                        :item-text="item => $t('text.' + item.id)"
                        :item-value="item => item.id"
                        multiple small-chips hide-details
                        class="gradientButton"
                        style="min-height: initial !important;">
                      <template #selection="{ item }">
                          <v-chip :color="item.color" dark>{{$t('text.' + item.id)}}</v-chip>
                        </template>
                      </v-select>
                    </div>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item style="width:500px !important;min-width:500px !important;max-width:500px !important;padding-top:20px">
                  <v-list-item-title>
                    <div style="padding: 0 10px">
                      <span style="width:88%">{{$t('text.eventDate')}}</span>
                      <br/><br/>

                      <v-row >
                        <v-btn class="gradientButton" filled dense solo elevation="0" style="width:48%;margin-right:4%" @click="toggleDatePicker('from')">{{validFromText}}</v-btn>
                        <v-btn class="gradientButton" filled dense solo elevation="0" style="width:48%" @click="toggleDatePicker('to')">{{validToText}}</v-btn>
                      </v-row>
                      <br/>
                      <p align="center">
                        <v-date-picker v-if="showFromDate" no-title v-model="eventFromDate" color="green lighten-1" @input="resetDates()"/>
                        <v-date-picker v-if="showToDate" no-title v-model="eventToDate" color="green lighten-1" :min="eventFromDate" @input="showToDate=false"/>
                      </p>
                      
                    </div>
                  </v-list-item-title>
                </v-list-item>

                <br/>
                <v-divider/>
                
                <v-row style="padding:20px">
                  <v-btn class="gradientButton" elevation="0" style="width:48%;margin-right:4%" @click="clearFilter()">{{$t('text.clearFilter')}}</v-btn>
                  <v-btn class="greenButton" elevation="0" dark style="width:48%;" @click="applyFilter()">{{$t('text.applyFilter')}}</v-btn>
                </v-row>
              </v-list>
            </v-menu>
          </div>
      </v-row>
   </div>

    <div>
      <v-row align="center" justify="center">
        <v-col>
          <v-card class="tableCard">
            <br/>

            <v-data-table
              :headers="headers"
              :items="orders"
              item-key="subId"
              class="elevation-0 "
              :items-per-page="limit"
              :mobile-breakpoint="850"
              fixed-header hide-default-footer disable-sort>

              <template v-slot:item="{ item }">
                <tr @click="showOrderDetail(item)">
                  <td>{{item.order_id}}</td>
                  <td>{{item.order_date | formatDate}}</td>
                  <td>{{item.client_name}}</td>
                  <td>{{item.store.name}}</td>
                  <td>{{item.customer_name}}</td>
                  <td align="center">{{item.item_count}}</td>
                  <td align="right">{{formatPrice(item.order_total)}}</td>
                  <td align="center">
                    <v-chip :color="getStatusColor(item.status)" dark>{{getStatusLabel(item.status)}}</v-chip>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-card class="tableFooter">
              <v-card-actions>
                <TablePaginator v-model="offset" :total="ordersCount" :limit="limit" :results="orders" @input="getOrders()" />
              </v-card-actions>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import TablePaginator from '@/components/common/TablePaginator.vue'

export default {
  name: 'Orders',
  components: {  Loading, TablePaginator },

  props: {
		viewType: String
	},

  data() {
    return {
      isLoading: false,
      fullPage: true,
      errorTitle: '',
      errorDetail: '',
      searchString: '',

      ordersCount: 0,
      isOpened: false,
      isSearchOpened: false,
      statuses: [
        {id:'Ordered', color:'green'}, 
        {id:'Canceled', color:'red'}
      ],
      selectedStatuses: this.filter ? this.filter.statuses : [],
      showFromDate: false,
			showToDate: false,
      eventFromDate: this.filter ? this.filter.eventFromDate : '',
			eventToDate: this.filter ? this.filter.eventToDate : '',

      filter: null,

      searchSubmitted: false,
      offset: 0,
		  limit: 15,

      orders: [],
      searchType: 'orderId',
      searchTypes: ['orderId','serviceName','keycard','permissionId','pickupNumber','customerName','ticketHolderName','email']
    }
  },

  computed: {
    headers () {
      return [
        { text: this.$t('text.id'), value: 'id', sortable: true, width: '15%', cellClass: "truncate" },
        { text: this.$t('text.purchaseDate'), value: "purchase_date", width:'10%', cellClass: "truncate" },
        { text: this.$t('text.marketplace'), value: "marketplace", width:'10%', cellClass: "truncate" },
        { text: this.$t('text.shop'), value: "storeName", width:'20%', cellClass: "truncate" },
        { text: this.$t('text.customerName'), value: "customer_name", width:'15%'},
        { text: this.$t('text.people'), value: "noOfItems", width:'10%', align:'center', cellClass: "truncate" },
        { text: this.$t('text.total'), value: "total", width:'10%', align:'right', cellClass: "truncate" },
        { text: this.$t('text.status'), value: "status", width:'10%', align:'center'}
      ]
    },
    errorMessages() {
      return ' <span style="font-size:14pt;font-weight:bold;"> ' + this.errorTitle + ' </span><br> ' + this.errorDetail;
    },
    validFromText() {
      return this.eventFromDate !== '' ? this.eventFromDate : this.$t('text.dateFrom')
    },
    validToText() {
      return this.eventToDate !== '' ? this.eventToDate : this.$t('text.dateTo')
    },
  },

  async mounted() {
    this.getOrders()
  },

  methods: {
    setSelectedSearchType(type) {
      this.searchType = type
      this.isSearchOpened = false
    },
    getStatusColor (status) {
      if (parseInt(status.refunded) === 1) {
        return '#f34545'
      } else if (parseInt(status.canceled) === 1) {
        return '#f34545'
      } else if (parseInt(status.shipped) === 1) {
        return '#64c823'
      } else if (parseInt(status.invoiced) === 1) {
        return '#64c823'
      } else if (parseInt(status.ordered) === 1) {
        return '#64c823'
      }
    },
    getStatusLabel(status) {
      if (parseInt(status.refunded) === 1) {
        return this.$t('text.Refunded')
      } else if (parseInt(status.canceled) === 1) {
        return this.$t('text.Canceled')
      } else if (parseInt(status.shipped) === 1) {
        return this.$t('text.Shipped')
      } else if (parseInt(status.invoiced) === 1) {
        return this.$t('text.Invoiced')
      } else if (parseInt(status.ordered) === 1) {
        return this.$t('text.Ordered')
      }
    },
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    closeDropDown(ref) {
        if (!this.dropdownOpen) {
          this.$refs[ref].focus()
          this.dropdownOpen = true
        } else {
          this.$refs[ref].blur()
          this.dropdownOpen = false
        }
		},
    async clearSearch() {
      this.searchString= ''
      this.searchType = 'orderId'
			this.getOrders()
		},
    toggleDatePicker(pickerToToggle) {
      if (pickerToToggle === 'from') {
        this.showToDate = false
        this.showFromDate = true
      } else {
        this.showFromDate = false
        this.showToDate = true
      }
    },
    resetDates() {
      this.eventToDate = ''
      this.showFromDate = false
    },
    applyFilter() {
      if (this.selectedStatuses.length > 0 || this.eventFromDate.length > 0 || this.eventToDate.length > 0) {
        //Add Refunded status when 'Canceled' is selected

        if (this.selectedStatuses.find(x => x === 'Canceled')) {
          this.selectedStatuses.push('Refunded')
        }

        this.isOpened = false
        this.offset = 0
        this.filter = {
          statuses: this.selectedStatuses,
          eventFromDate: this.eventFromDate,
          eventToDate: this.eventToDate
        }

        this.getOrders()
      }
      else {
        this.clearFilter()
      }
    },
    clearFilter() {
      this.isOpened = false
      this.selectedStatuses = []
      this.eventFromDate = ''
      this.eventToDate = ''

      this.offset = 0
			this.filter = null
			this.getOrders()
    },
    async showOrderDetail(item) {
      await this.$store.commit('setSelectedOrder', item)
      this.$router.push("/orderDetail")
    },
    async getOrders() {
      this.isLoading = true
      let url = ''

      if (this.viewType === 'client') {
        url = `/client-orders?clientId=${this.$store.state.selectedClient.sys.id}&category=SKIPASS&skip=${this.offset}&limit=${this.limit}&searchString=${this.searchString}&searchType=${this.searchType}`
      } else {
        url = `/orders/${this.$store.state.selectedServiceProvider.sys.id}?skip=${this.offset}&limit=${this.limit}&searchString=${this.searchString}&searchType=${this.searchType}`
      }
       
      if (this.filter) {
					url += `&filter=${JSON.stringify(this.filter)}`
			}

      const res = await this.$httpGet(url)

      this.orders = res.orders
      this.ordersCount = res.total

      this.isLoading = false
    }
  }
}
</script>

<style scoped>
.search-container {
  display: flex;
  background: rgb(255, 255, 255);
  border: 1px solid #dddddd;
  border-radius: 6px;
  height: 48px;
  overflow: hidden; 
  width: 100%;
  margin-right: 55px;
}

.container { padding: 0px !important; width:100% !important; }
.tableCard { border-radius:5px; background-color:#808080 !important; top: 0px; }
.tableCard span { white-space: nowrap; text-overflow: ellipsis; }
.dot-container { width: 75px; height: 75px; position: absolute; top: 0; right: 0; }
.stack-top { width: 14px; height: 14px; z-index: 9; margin: 0px; padding:0px; }
.dot { height: 14px; width: 14px; background-color: #ff0022; border-radius: 50%; display: inline-block; position: absolute; top: -1px; right: -1px; }
.v-text-field.v-text-field--enclosed .v-input__prepend-inner { margin-top: 12px !important; }
</style>
