<template>
  <Disclosure :title="disclosureTitle" :error="sectionMessage.error" :message="sectionMessage.message" data-cy="salesChannelsInfo" ref="SECTION_salesChannelsInfo" 
  :lock="clientAssignmentLocked">
    <div v-if="!isWaiting">
      <span class="error-text">({{ $t('text.required') }})</span>
      <ChipsList @click="openDialog" :items="chips" :data-cy="dataCy"></ChipsList>
      <Dialog ref="dialog"
              :confirmLabel="$t('text.ok')"
			  :cancelLabel="$t('text.cancel')"
              :confirmHandler="emitUpdate"
			  :cancelHandler="cancelSelection"
			  :showClose="false"
              :title="$t('text.clientAssignments')"
      >
        <template #content>
          <div style="display: flex; flex-direction: row; position: absolute; top: 65px; bottom: 60px; margin: 0 -12px; width: 100%;">
            <NavList v-if="clientsSorted" :items="clients" v-model="selectedClient" v-slot:default="itemProps" :loading="loading" style="flex-shrink: 0;">
              <input type="checkbox"
                     :disabled="clientIsHomebase(itemProps.item) && !allowUncheckingHomebase || clientAssignmentLocked"
                     :checked="model[itemProps.item.sys.id]"
                     @click="toggleClient(itemProps.item)"
              />
            </NavList>
            <ClientAssignmentEditor v-if="selectedClientAssignment && clientsSorted"
				v-model="selectedClientAssignment"
				:client="selectedClient"
				style="flex-grow: 1; height: 100%; overflow-y: auto;"
            />
            <div v-if="!selectedClientAssignment" style="margin: 50px; color: gray;">
              <p class="helpText" v-if="clients.length > 0" v-html="help"></p>
              <br />
              <p class="helpText" v-html="reason"></p>
            </div>
          </div>
        </template>
      </Dialog>
    </div>
  </Disclosure>
</template>

<script>
import Dialog from '@/components/common/Dialog'
import NavList from '@/components/common/selectors/NavList.vue'
import ClientAssignmentEditor from './ClientAssignmentEditor.vue'
import ChipsList from '@/components/common/selectors/ChipsList.vue'
import Disclosure from '@/components/common/Disclosure'
import Common from '@/mixins/Common.vue'
import { isEqual } from 'lodash'

export default {
	name: 'ClientAssignmentsField',
	components: { Dialog, NavList, ClientAssignmentEditor, ChipsList, Disclosure },
	mixins: [ Common ],
	props: {
		propClientAssignments: Array,
		// in case the field is used on a product, the serviceProvider of that product is filled
		serviceProvider: Object, // CFObject<serviceProvider> serviceProvider.clientAssignments expanded
		// in case the field is used on a serviceProvider, the client is filled
		dataCy: String,
		app: String, // name of the app this is placed on (BusinessProfile | ServiceDetail)
		clientAssignmentLocked: Boolean, // external products may not be shared to other clients
    	disclosureTitle: String,
		isWaiting: Boolean,
		locked: Boolean,
		updateModel: Boolean
	},
	data() {
		return {
		loading: false,
		model: {}, // lookup clientId -> ClientAssignment
		initData: {},
		modelBackup: {},
		clients: [],
		selectedClient: null,
		selectedClientAssignment: null,
		reason: '',
		help: '',
		allowUncheckingHomebase: false,
		sectionMessage: {
			error: false,
			message: ''
		},
		chips: [],
		clientsSorted: false,
		salesChannelSelectionEnabled: true
	}},
	computed: {
	},
	watch: {
		updateModel() {
			this.model = this.valueToModel(this.propClientAssignments)
			this.setInitData()
			this.updateChips()
			this.salesChannelSelectionEnabled = this.spEnabledSetting(this.$store.state.selectedClient.fields.marketplaceSettings?.de, 'spSalesChannelSelectionEnabled')
		},
		selectedClient(client) {
			if (!client) {
				this.selectedClientAssignment = null
				return
			}
			this.selectedClientAssignment = this.model[client.sys.id]
		},
		async serviceProvider(n) {
			this.clients = await this.loadClients()
		}
	},
	created() {
		this.model = this.valueToModel(this.propClientAssignments)
		this.setInitData()
		this.updateChips()
		this.salesChannelSelectionEnabled = this.spEnabledSetting(this.$store.state.selectedClient.fields.marketplaceSettings?.de, 'spSalesChannelSelectionEnabled')
	},
	methods: {
		valueToModel(v) {
			const m = {}
			for (const o of v ?? {}) {
				m[o.fields.client.de.sys.id] = o
			}
			return m
		},
		modelToValue(m) {
			let v = []
			for (const i in m ?? []) {
				v.push(m[i])
			}
			return v
		},
		setInitData() {
			const initModel = JSON.parse(JSON.stringify(this.modelToValue(this.model)))

			this.initData = {
				clientAssignments: {
					de: initModel
				}
			}
    	},
		sendData() {
			const data = {
				clientAssignments: {
					de: this.modelToValue(this.model)
				}
			}

			data.changed = !isEqual(data, this.initData)

			return data
    	},
		cancelSelection() {
			this.model = this.modelBackup
			this.updateChips()
		},
		updateChips() {
			this.chips = []
			for (const [key, value] of Object.entries(this.model)) {
				if (value) {
					this.chips.push(value.fields.client.de?.fields?.title?.de)
				}
			}
		},
		validateAllFields() {
			let allFieldsAreValid = true
			let salesChannelsValid = true
			if (this.clientAssignmentLocked) return allFieldsAreValid

			this.resetSectionError(this.sectionMessage)

			if (Object.keys(this.model).length === 0) {
				allFieldsAreValid = false
			} else {
				let modelArray = []
				for (let clientAssignment of Object.values(this.model)) {
					modelArray.push(clientAssignment)
				}

				for (const clientAssignment of modelArray) {
					if (this.salesChannelSelectionEnabled === true && clientAssignment?.fields?.salesChannelAssignments?.de?.length === 0) {
						salesChannelsValid = false
					}
				}
			}
			if (!allFieldsAreValid) {
				this.setSectionError(this.sectionMessage, this.$t('text.missingFieldsError'))
			}
			if (!salesChannelsValid) {
				this.setSectionError(this.sectionMessage, this.$t('text.selectClientAssignmentsError'))
			}

			return allFieldsAreValid && salesChannelsValid
		},
		emitUpdate() {
			this.updateChips()
			this.validateAllFields()
			this.$emit("update-assignment")
			return true
		},
		clientIsHomebase(client) {
			return this.model[client.sys.id]?.fields.isHomebase?.de
		},
		async openDialog() {
			this.$refs.dialog.show = true;
			this.clients = await this.loadClients()
			this.modelBackup = JSON.parse(JSON.stringify(this.model))
		},
		findHomeClient(serviceProvider) {
			if (!serviceProvider.fields?.clientAssignments) throw new Error('serviceProvider has no clientAssignments (probably include level problem)!', serviceProvider)
			for (let ca of serviceProvider.fields.clientAssignments.de) {
				if (!ca.fields) throw new Error('serviceProvider has to have included clientAssignments!', serviceProvider)
				if (!ca.fields.isHomebase) continue
				return ca.fields.client.de
			}
		},
		async getMarketplaceClientsForClient(client) {
			this.clientsSorted = false
			this.loading = true
			let clients = []
			const clientSysId = client.sys.id
			let marketplaces = await this.$httpGet('/marketplace?clientSysId=' + clientSysId)
			let mp = marketplaces?.[0]
			let lookup = {}
			for (let client of mp.fields.externalClients.de) {
				clients.push(client)
				lookup[client.sys.id] = client
			}
			// add homeClient if it wasnt in the external clients
			if (!lookup[mp.fields.homeClient.de.sys.id])
				clients.push(mp.fields.homeClient.de)
			this.loading = false
			this.sortClients(clients, mp)
			this.clientsSorted = true

			return clients
		},
		async loadClients() {
			let selectedClient = this.$store.state.selectedClient

			// TODO: by now the 2 main cases are almost the same, we should remove the repetition
			if (this.app == 'BusinessProfile') {
				// ATT: only the home op may assign the SP to anything else than his own client(s)
				const clients = await this.getMarketplaceClientsForClient(selectedClient)
				if (!clients?.length) throw new Error('no marketplace clients found for sp client', client)

				if (this.userIsHomeOperator) {
					this.help = this.$t('text.caClientsHelp_bp_hop')
					this.reason = this.$t('text.caClientsReason_bp_hop')
					return this.sortMarketplaces(clients, this.serviceProvider.fields.clientAssignments)

				}

				if (this.userIsOperator) {
					this.help = this.$t('text.caClientsHelp_bp_xop')
					this.reason = this.$t('text.caClientsReason_bp_xop')
					return clients
				}

				// the user is serviceProvider

				// we start by finding the home client of the serviceProvider
				let client = this.findHomeClient(this.serviceProvider)
				if (!client) throw new Error('home client not found for serviceProvider', this.serviceProvider)

				this.help = this.$t('text.caClientsHelp_bp_sp')
				this.reason = this.$t('text.caClientsReason_bp_sp')
				return clients
			}

			if (this.app == 'ServiceDetail') {
				const clients = await this.getMarketplaceClientsForClient(selectedClient)
				if (!clients?.length) throw new Error('no marketplace clients found for sp client', client)

				if (this.userIsHomeOperator) {
					this.allowUncheckingHomebase = true
					this.help = this.$t('text.caClientsHelp_sd_hop')
					this.reason = this.$t('text.caClientsReason_sd_hop')
					return clients
				}
				if (this.userIsOperator) {
					this.allowUncheckingHomebase = false
					this.help = this.$t('text.caClientsHelp_sd_xop')
					this.reason = this.$t('text.caClientsReason_sd_xop')
					return clients
				}

				// the user is serviceProvider

				// TODO: also allow unchecking home CA
				// TODO: when checking again we need to set the isHomebase flag based on whats on the SP

				// we start by finding the home client of the serviceProvider
				let client = this.findHomeClient(this.serviceProvider)
				if (!client) throw new Error('home client not found for serviceProvider', this.serviceProvider)

				this.allowUncheckingHomebase = true
				this.help = this.$t('text.caClientsHelp_sd_sp')
				this.reason = this.$t('text.caClientsReason_sd_sp')
				return clients
			}
		},
		getNewClientAssignment(client) {
			return {
				sys: { id: 'MUI-CA-' + new Date().getTime() + '_' + Math.floor(Math.random() * 99999) + '-' + client.sys.id },
				fields: {
					title: { de: 'CA' },
					// TODO: for saving we actually need links here, but that should happen right before saving (?)
					//       otherwise the ui cant show the titles..
					//client: { de: { sys: { id: client.sys.id, type: 'Link', linkType: 'Entry' } } },
					client: { de: client },
					regions: { de: [] },
					salesChannelAssignments: { de: [] },
					taxClass: { de: null },
					status: { de: 'pending' },
					isHomebase: { de: this.isHomebase(this.serviceProvider, client.sys.id) },
					// TODO: other props?
				},
				addl: {
					isNew: true,
				},
			}
		},
		toggleClient(client) {
			if (this.model[client.sys.id]) {
				this.modelBackup[client.sys.id] = this.model[client.sys.id]
				delete this.model[client.sys.id]
				this.afterToggleClient()
				return
			}
			if (this.modelBackup[client.sys.id]) {
				this.model[client.sys.id] = this.modelBackup[client.sys.id]
				this.afterToggleClient()
				return
			}
			this.model[client.sys.id] = this.getNewClientAssignment(client)
			this.afterToggleClient()
		},
		isHomebase(object, clientId) {
			if (this.app == 'BusinessProfile') {
				// TODO: can we derive the home base for a serviceProvider without the CA?
				//       should we offer an option to set the homebase flag when we cant find one?
				return false
			}
			if (this.app == 'ServiceDetail') {
				for (const ca of object.fields.clientAssignments.de) {
					if (ca.fields.client.de.sys.id != clientId) continue
					return ca.fields.isHomebase?.de
				}
				return false
			}
		},
		afterToggleClient(client) {
			this.selectedClient = client
			this.selectedClientAssignment = client ? this.model[client.sys.id] : null

			this.updateChips()
			this.$forceUpdate()
		},
		sortMarketplaces(clients, clientAssignments) {
			let sortedClients = []
			let homebaseClient

			if (clientAssignments?.de?.length > 0) {
				for (let clientAssignment of clientAssignments.de) {
					if (!this.clientIsHomebase(clientAssignment.fields.client.de)) {
						sortedClients.push(clientAssignment.fields.client.de)
						sortedClients.sort(this.compare);
					} else {
						homebaseClient = clientAssignment.fields.client.de
					}

					let index = clients.findIndex(x => x.sys.id === clientAssignment.fields.client.de.sys.id);

					if (index > -1) {
						clients.splice(index, 1)
					}
				}
			}

			if (clients?.length > 0) {
				clients.sort(this.compare);
			}

			sortedClients.push(...clients)

			if (homebaseClient) {
				sortedClients.unshift(homebaseClient)
			}

			return sortedClients
		}
		
	},
	static: {
		validate(clientAssignments) {
			if (clientAssignments?.length === 0) throw 'text.selectClientAssignmentsError'
			if (this.salesChannelSelectionEnabled === false) return true

			for (let clientAssignment of clientAssignments) {
				if (clientAssignment.fields.isHomebase.de === true) {
					// check if the homebase has sales channel assignments, it is not possible to add sales channel assignments to external clients in this interface

						if (clientAssignment.fields?.salesChannelAssignments?.de?.length)
							return true

					throw 'text.selectClientAssignmentsError'
				}
			}

		},
	}
}
</script>