<template>
  <v-container style="padding:0px" fluid >
    <v-alert :value="errorTitle!==''" :v-if="errorTitle!==''" type="error" dismissible flat class="notification" prominent v-model="errorAlertVisible"><span v-html="errorMessages"></span></v-alert> 
    <v-alert :value="successTitle!==''" :v-if="successTitle!==''" type="success" dismissible flat class="notification" prominent v-model="successAlertVisible"><span v-html="successMessages"></span></v-alert> 

    <loading :active.sync="isLoading" 
        :is-full-page="fullPage"
        color="#4caf50">
    </loading>

    <!-- Navbar -->
		<div class="navbar">
			<v-row align="center" class="flex-nowrap" style="white-space:nowrap; overflow:hidden;">
			</v-row>
		</div>

    <!-- Floating Menu -->
    <div>
      <div class="sidebar">
        
      </div>

    </div>

    <!-- Content -->
    <v-container style="margin-left:380px">
      <div>
        <video id="video" width="800" height="600" controls></video>
      </div>
    </v-container>

  </v-container>
</template>

<script>
import Loading from 'vue-loading-overlay';
import Common from '@/mixins/Common'

export default {
  name: 'ViedoPlayer',
  components: {
    Loading
  },
  mixins: [Common],
  data () {
    return {
      activeLink: '',

      isLoading: false,
      fullPage: true,
      isAdmin: false,

      errorTitle: '',
      errorDetail: '',
      successTitle: '',
      successDetail: '',
      errorAlertVisible: false,
      successAlertVisible: false,

      videoMedia: {},
    }
  },

  computed: {
    errorMessages() {
      return ' <span style="font-size:14pt;font-weight:bold;"> ' + this.errorTitle + ' </span><br> ' + this.errorDetail;
    },
    successMessages() {
      return ' <span style="font-size:14pt;font-weight:bold;"> ' + this.successTitle + ' </span><br> ' + this.successDetail;
    },
  },

  watch: {
    errorAlertVisible() {
      if (this.errorAlertVisible) {
        setTimeout(this.closeAlert, 3000);
      }
    },
    successAlertVisible() {
      if (this.successAlertVisible) {
        setTimeout(this.closeAlert, 3000);
      }
    }
  },
  async mounted() {
    this.videoMedia = this.$store.state.media;
    
    var video = document.getElementById('video');
    var source = document.createElement('source');

    source.setAttribute('src', 'https://videos.ctfassets.net/5phdyg0pvupp/74Qlsz1aeQPqOlqEuNBcMw/f0821d6cc37bdbc1694df097ebe020c3/1_Register_as_new_service_provider.mov');

    video.appendChild(source);

  },


}
</script>

<style scoped>
.container-grid {
  margin-left: 380px;
  padding: 20px;
}

</style>