<template>
	<v-container
		fluid
		class="pa-0 mb-12"
		:style="isSideBarFixed ? 'padding-left: 380px !important;' : 'padding-left: 40px !important;'">
		
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50" />

		<!-- Navbar -->
		<div class="navbar">
			<v-row align="center" class="flex-nowrap" style="white-space:nowrap; overflow:hidden;">
				<v-btn id="btnBack" elevation="0" style="background-color:transparent;" @click="goback()">
					<v-icon>mdi-chevron-left</v-icon>
				</v-btn>
				<v-row justify="center">
					<h1 v-if="$refs && $refs['myInfo']">{{ $refs['myInfo'].model.fields.title[serviceLocale] }}</h1>
				</v-row>
			</v-row>
		</div>

		<SideBar>
			<div :style="showImpersonationInfo ? 'padding-top: 24px !important;' : 'padding-top:0px'">
				<!-- Service Provider Action Buttons -->
				<div v-if="!userIsOperator">
				<v-btn block :class="submitButtonTheme" elevation="0" :disabled="isWaiting" @click="updateProfile('update')" data-cy="save"><v-icon>mdi-check</v-icon>{{$t('text.saveInformation')}}</v-btn>
				<v-btn block class="greenButton" dark elevation="0" :disabled="isWaiting" v-if="!!statusMap['unpublished']" @click="updateProfile('activate')" data-cy="activate"><v-icon>mdi-close-circle</v-icon>{{$t('text.activateAccount')}}</v-btn>
				</div>

				<!-- Operator Action Buttons -->
				<div class="buttonBlock" v-if="userIsOperator">
				<v-btn class="gradientButton mt-3" block elevation="0" :disabled="isWaiting" v-if="statusClient != null" @click="updateProfile('update')" data-cy="save"><v-icon style="margin-right:0px">mdi-check</v-icon>{{$t('text.saveInformation')}}</v-btn>
				<v-btn class="gradientButton mt-3" block elevation="0" :disabled="isWaiting" v-if="statusClient == null" @click="updateProfile('approve')" data-cy="save-approve"><v-icon style="margin-right:0px">mdi-check</v-icon>{{$t('text.saveInformation')}}</v-btn>
				<v-btn class="greenButton mt-3" block dark elevation="0" :disabled="isWaiting" v-if="!!statusMap['pending'] || !!statusMap['declined'] || !!statusMap['reapprove']" @click="updateProfile('approve')" data-cy="approve"><v-icon>mdi-check</v-icon>{{$t('text.approveRequest')}}</v-btn>
				<v-btn class="redButton mt-3" block dark elevation="0" :disabled="isWaiting" v-if="!!statusMap['pending'] || !!statusMap['reapprove']" @click="updateProfile('decline')" data-cy="decline"><v-icon>mdi-close-circle</v-icon>{{$t('text.declineRequest')}}</v-btn>
				<v-btn class="redButton mt-3" block dark elevation="0" :disabled="isWaiting" v-if="!!statusMap['approved']" @click="updateProfile('deactivate')" data-cy="deactivate"><v-icon>mdi-close-circle</v-icon>{{$t('text.deactivateAccount')}}</v-btn>
				<v-btn class="greenButton mt-3" block dark elevation="0" :disabled="isWaiting" v-if="!!statusMap['deactivated'] || !!statusMap['unpublished']" @click="updateProfile('activate')" data-cy="activate"><v-icon>mdi-check-circle</v-icon>{{$t('text.activateAccount')}}</v-btn>
				<v-btn class="redButton mt-3" block dark elevation="0" :disabled="isWaiting" v-if="userIsHomeOperator && Object.keys(statusMap).length>0" data-cy="delete" @click="$refs.deleteDialog.show=true"><v-icon>mdi-delete</v-icon>{{$t('text.deleteServiceProvider')}}</v-btn>
				<v-btn class="blueButton mt-3" block dark elevation="0" :disabled="isWaiting" v-if="userIsHomeOperator && isHomebase() && serviceProvider.fields.mainAccountId && serviceProvider.fields.mainAccountId.de" @click.stop="impersonate(serviceProvider)">{{$t('text.operateAs')}}</v-btn>
				<v-btn class="gradientButton mt-3" block elevation="0" :disabled="isWaiting" v-if="userIsHomeOperator && isHomebase()" @click.stop="showChangeHomebaseDialog()"><v-icon>mdi-home-city-outline</v-icon>{{$t('text.changeHomebase')}}</v-btn>
				</div>

				<Status :status="statusClient" v-if="!statusClientHasMultiple && statusClient !== 'INVALID'" class="mt-3" />
				<div v-if="statusClientHasMultiple" class="mt-3">
						<span v-for="ca of serviceProvider.fields.clientAssignments.de" :key="ca.fields.client.de.fields.clientId.de"
						:class="{
								clientAssignmentChip: true,
								[ca.fields.status.de]: true,
							}"
						:title="ca.fields.status.de"
						:data-cy="ca.fields.client.de.fields.clientId.de"
				>{{ ca.fields.client.de.fields.clientId.de }}</span>
				</div>

				<v-select outlined dense required hide-details class="mt-3"
						v-model="serviceLocale"
						:items="locales"
						:item-text="locale => $t('text.' + locale.name)"
						:item-value="locale => locale.code"
				/>

				<!-- Index: each Disclosure has a ref and we collect them here -->
				<ul v-if="serviceProviderIsLoaded" id="navlist">
				<a  v-for="section of sections" :key="section"
					:href="'#' + section">
					<li @click="setActive(section)" :class="{ active: section == activeLink }">
					{{ $t('text.' + section + 'Title') }}
					</li>
				</a>
				</ul>
			</div>
		</SideBar>

		<div class="pa-3" style="width: 100%">
			<!-- Checklist -->
			<span v-if="userIsOperator && featureEnabled('service-provider-checklist')" id="checklistInfo" />
			<Checklist v-if="serviceProviderIsLoaded" ref="checklistRef" :serviceProvider="serviceProvider" component="BusinessProfile" />
			
			<!-- My Information -->
			<span id="myInfo" /><br/>
			<MyInformation v-if="serviceProviderIsLoaded" ref="myInfo" :serviceProvider="serviceProvider" :updateModel="updateModel"/>

			<!-- Contact Person Info -->
			<span id="contactPersonInfo"/><br/>
			<ContactPersonInfo v-if="serviceProviderIsLoaded" ref="contactPersonInfo" :serviceProvider="serviceProvider" :updateModel="updateModel"/>

			<!-- Business Info -->
			<div class="pa-0">
				<span id="businessInfo"/><br/>
				<BusinessHours 
					v-if="serviceProviderIsLoaded"
					ref="businessInfo"
					:showCopyOption="false" 
					:businessHours="buildBusinessHours(false)" 
					:businessHoursExceptions="buildBusinessHours(true)" 
					:averageDurationOfStayInMinutes="serviceProvider.fields.averageDurationOfStayInMinutes.de"
					:updateModel="updateModel"
					app="BusinessProfile" 
					@toggle-business-hours="toggleHaveBusinessHours" 
					@average-duration-changed="averageDurationChanged"/>
			</div>

			<!-- Categories -->
			<div class="pa-0">
				<span id="categoryInfo"/><br/>
					<ProductCategoriesSelector v-if="serviceProviderIsLoaded" app="BusinessProfile" ref="categoryInfo" style="padding-top:12px;padding-bottom:8px"
						:data-payload="serviceProvider"
						:service-provider="serviceProvider"
						:workflow-status="statusClient"
						:updateModel="updateModel"
						data-cy="productCategories"
					/>
			</div>

			<!-- Banking Info -->
			<span id="bankingInfo"/><br/>
			<BankingInfo v-if="serviceProviderIsLoaded" ref="bankingInfo" :serviceProvider="serviceProvider" :updateModel="updateModel"/>

			<!-- Public Information -->
			<span id="publicInfo"/><br/>
			<PublicInfo v-if="serviceProviderIsLoaded" ref="publicInfo" :serviceProvider="serviceProvider" :updateModel="updateModel"/>

			<!-- Offer Menu -->
			<span id="offerMenu" /><br />
			<OfferMenu v-if="serviceProviderIsLoaded" @show-offer-menu="showOfferMenu" :updateModel="updateModel" ref="offerMenu"></OfferMenu>

			<!-- User Account -->
			<div v-if="userAccountVisible">
				<span id="mainUserAccount" /><br />
				<UserAccount v-if="serviceProviderIsLoaded" ref="mainUserAccount" :serviceProvider="serviceProvider" :updateModel="updateModel" />
			</div>

			<!-- Application Access -->
			<div v-if="userIsOperator">
				<span id="applicationAccessInfo" /><br />
				<ApplicationComponentWrapper v-if="serviceProviderIsLoaded" ref="applicationAccessInfo" :serviceProvider="serviceProvider" :updateModel="updateModel" />
			</div>

			<!-- Trust Level -->
			<div v-if="userIsOperator && serviceProviderIsLoaded">
				<span id="trustLevel" /><br />
				<TrustLevel data-cy="trustLevel" ref="trustLevel" :serviceProvider="serviceProvider" :updateModel="updateModel" />
			</div>

			<!-- Link Service Providers -->
			<div v-if="userIsOperator && serviceProviderIsLoaded">
				<span id="linkServiceProviders" /><br />
				<ServiceProviderLink ref="linkServiceProviders" :serviceProvider="serviceProvider" @loading="(value) => loading = value" :updateModel="updateModel"/>
			</div>

			<!-- Sales Channels -->
			<div>
				<span id="salesChannelsInfo"/><br/>
				<v-row justify="center">
					<ClientAssignmentsField
						v-if="serviceProviderIsLoaded"
						ref="salesChannelsInfo"
						data-cy="clientAssignments"
						app="BusinessProfile"
						:propClientAssignments="serviceProvider.fields.clientAssignments.de"
						:serviceProvider="serviceProvider"
						 :updateModel="updateModel"
						:disclosureTitle="$t('text.salesChannels')"
						@update-assignment="setChanged()" />
				</v-row>
			</div>

			<v-row v-if="(userIsOperator || userIsImpersonating) && serviceProviderIsLoaded" class="mt-7">
				ID: {{ serviceProvider.sys ? serviceProvider.sys.id : '' }}<br/>
			</v-row>
		</div>

		<!-- Confirm Delete -->
		<Dialog ref="deleteDialog"
            :confirmLabel="$t('text.delete')"
            :cancelLabel="$t('text.cancel')"
            :confirm-handler="onDeleteConfirmed"
            :cancel-handler="onDeleteCancelled"
			:showClose="false"
            :title="$t('text.deleteServiceProvider')"
			:height="'300px'"
			:width="'800px'">
			<template #content>
				<v-row justify="center" align="center" style="padding:10px">
					<v-col cols="1"><img src="@/assets/icons/icon-warning.svg" style="width:32px;height:32px"/></v-col>
					<v-col cols="8"><span class="title">{{$t('text.confirmServiceProviderDelete')}}</span></v-col>
				</v-row>
			</template>
		</Dialog>

		   <!-- Confirm going back -->
		   <Dialog ref="unsavedChangesDialog"
			data-cy="unsavedChangesDialog"
            :confirmLabel="$t('text.confirm')"
            :cancelLabel="$t('text.cancel')"
            :confirm-handler="confirmUnsavedChanges"
            :cancel-handler="cancelUnsavedChanges"
			:showClose="false"
            :title="$t('text.unsavedChanges')"
            :height="'300px'"
            :width="'800px'">
			<template #content>
				<v-row justify="center" align="center" style="padding:10px">
					<v-col cols="1"><img src="@/assets/icons/icon-warning.svg" style="width:32px;height:32px"/></v-col>
					<v-col cols="8"><span class="title">{{$t('text.unsavedChangesText')}}</span></v-col>
				</v-row>
			</template>
		</Dialog>

		<!-- Change Homebase -->
		<Dialog ref="changeHomebaseDialog"
            :confirmLabel="$t('text.confirm')"
            :cancelLabel="$t('text.cancel')"
            :confirm-handler="confirmHomebaseChange"
            :cancel-handler="cancelHomebaseChange"
			      :showClose="false"
            :title="$t('text.changeHomebase')"
            :height="'600px'"
            :width="'800px'"
            :is-valid="isHomebaseClientSelected"
    >
			<template #content>
				<HomebaseSelector ref="homebaseSelector" data-cy="homeBase" @client-selected="() => isHomebaseClientSelected = true"/>
			</template>
		</Dialog>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import OfferMenu from '@/components/offerMenu/OfferMenu.vue'
import ApplicationComponent from '@/components/common/ApplicationComponent.vue'
import ProductCategoriesSelector from '@/components/categories/ProductCategoriesSelector.vue'
import ClientAssignmentsField from '@/components/clientAssignment/ClientAssignmentsField.vue'
import Common from '@/mixins/Common.vue'
import Status from '@/components/common/Status.vue'
import Alert from '@/components/common/Alert.vue'
import LanguageFlag from '@/components/common/LanguageFlag.vue'
import Dialog from '@/components/common/Dialog.vue'
import BusinessHours from '@/components/businesshours/BusinessHours.vue'
import HomebaseSelector from '@/components/businessProfile/HomebaseSelector.vue'
import SideBar from "@/components/common/Sidebar"
import Checklist from '@/components/checklist/Checklist.vue'
import MyInformation from '@/components/businessProfile/MyInformation.vue'
import ContactPersonInfo from '@/components/businessProfile/ContactPersonInfo.vue'
import BankingInfo from '@/components/businessProfile/BankingInfo.vue'
import PublicInfo from '@/components/businessProfile/PublicInfo.vue'
import ApplicationComponentWrapper from '@/components/businessProfile/ApplicationComponentWrapper.vue'
import UserAccount from '@/components/businessProfile/UserAccount.vue'
import TrustLevel from '@/components/businessProfile/TrustLevel.vue'
import ServiceProviderLink from '@/components/businessProfile/ServiceProviderLink.vue'
import { isEqual } from 'lodash'

export default {
	name: 'BusinessProfile',
	components: { SideBar, Loading, OfferMenu, ApplicationComponent, ProductCategoriesSelector,
				 ClientAssignmentsField, Status, Alert, LanguageFlag, Dialog, BusinessHours, HomebaseSelector,
				 Checklist, MyInformation, ContactPersonInfo, BankingInfo, PublicInfo, ApplicationComponentWrapper,
				 UserAccount, TrustLevel, ServiceProviderLink },
	mixins: [ Common ],

	data() {
		return {
			activeLink: '',
			gobackToDashboard: false,
			submitButtonTheme: 'gradientButton',
			
			loading: false,
			errorTitle: '',
			errorDetail: '',
			successTitle: '',
			successDetail: '',
			runValidation: false,
			updateModel: false,

			serviceProviderIsLoaded: false,

			isApproval: false,
			statusMap: {},
			statusClient: null,
			statusClientHasMultiple: false,
			manuallyUnRestricted: false,

			isHomebaseClientSelected: false,

			originalServiceProvider: {},

			serviceProvider: {
				sys: {
					id: ''
				},
				fields:{
					title: {de:''},
					faqGroup: {de:''},
					longDescription:{de:''},
					shortDescription:{de:''},
					importantInformation:{de: ''},
					location: {de: {lat:'',lon:''}},
					contactPersonName: {de: ''},
					contactPersonEmail: {de: ''},
					contactPersonPhone: {de: ''},
					language: {de: ''},
					clientAssignments: { de: [] },
					accountNumber: {de: ''},
					bic: {de: ''},
					iban: {de: ''},
					trustLevel: {de: '1'},
					workflowStatus: {de: ''},
					businessHours: {
						de:[{
						sys: {id: ''},
						fields: {
							validFromDate: {de:''},
							validToDate: {de:''},
							businessTimes: {
							de: {
								monday: { times:[{openTime: '', closeTime: ''}] },
								tuesday: { times:[{openTime: '', closeTime: ''}] },
								wednesday: { times:[{openTime: '', closeTime: ''}] },
								thursday: { times:[{openTime: '', closeTime: ''}] },
								friday: { times:[{openTime: '', closeTime: ''}] },
								saturday: { times:[{openTime: '', closeTime: ''}] },
								sunday: { times:[{openTime: '', closeTime: ''}] },
								holidays: { times:[{openTime: '', closeTime: ''}] }
							}
							}
						}
						}]
					},
					imageGallery: {de:[]},
					mainUserAccount: {},
					averageDurationOfStayInMinutes: {de:0},
				}
			},

			activeBtn:'',

			offerMenuVisible: false,

			times: [],
			fromMenuOpened: [false],
			toMenuOpened: [false],

			businessHours: {
				de:[]
			},

			showPickerFrom: false,
			showPickerTo: false,
			showMediaViewer: false,
			idCounter: 1,
		}
	},

	computed: {
		isWaiting() {
			return this.loading || !!this.successTitle || !!this.errorTitle
		},
    sections() {
      let sections = []
      //TODO: refactor
      if (this.userIsOperator) {
        if (this.offerMenuVisible) {
          if (this.userAccountVisible) {
            sections = ['checklistInfo','myInfo','contactPersonInfo','businessInfo','categoryInfo','bankingInfo','publicInfo','offerMenu','mainUserAccount','applicationAccessInfo','trustLevel','linkServiceProviders','salesChannelsInfo']
          } else {
            sections = ['checklistInfo','myInfo','contactPersonInfo','businessInfo','categoryInfo','bankingInfo','publicInfo','offerMenu','applicationAccessInfo','trustLevel','linkServiceProviders','salesChannelsInfo']
          }

        } else {
          if (this.userAccountVisible) {
            sections = ['checklistInfo','myInfo','contactPersonInfo','businessInfo','categoryInfo','bankingInfo','publicInfo','mainUserAccount','applicationAccessInfo','trustLevel','linkServiceProviders','salesChannelsInfo']
          } else {
            sections = ['checklistInfo','myInfo','contactPersonInfo','businessInfo','categoryInfo','bankingInfo','publicInfo','applicationAccessInfo','trustLevel','linkServiceProviders','salesChannelsInfo']
          }
        }
      } else {
        if (this.offerMenuVisible) {
          sections = ['myInfo','contactPersonInfo','businessInfo','categoryInfo','bankingInfo','publicInfo','offerMenu','salesChannelsInfo']
        } else {
          sections = ['myInfo','contactPersonInfo','businessInfo','categoryInfo','bankingInfo','publicInfo','salesChannelsInfo']
        }
      }

      return sections
    },
		isCreate() {
			return !this.$store.state.selectedServiceProvider?.sys?.id
		},
		userAccountVisible() {
			if (!this.userIsOperator) return false
			if (this.isCreate) return true
			return !this.serviceProvider.fields.mainUserAccount
		},
	},

	async mounted() {
		this.loading = true

		if (!this.$store.state.locales?.length) {
			await this.$root.app.getLocales()
		}

		// existing SP
		if (this.$store.state.selectedServiceProvider?.sys?.id) {
			if (this.$store.state.loggedInUser.sys.id !== this.$store.state.selectedServiceProvider.sys.id) {
				await this.getServiceProviderDetail()
			}
			// new SP
			else {
				await this.setServiceProviderDetails(this.$store.state.selectedServiceProvider)
			}
		}
		else {
			// New Service Provider
			this.manuallyUnRestricted = true
		}

		this.serviceProviderIsLoaded = true
		this.loading = false
	},
	async beforeDestroy() {
		this.$root.$off('confirm-delete')
		this.$root.$off('close-delete')

		if (this.$store.state.isImpersonation) return
		if (this.$store.state.isLinkedProvider) return
		if (!this.$store.state.loggedInUser?.fields?.type?.de === 'operator') return
		if (this.$store.state.loggedInUser?.fields?.type?.de != 'serviceprovider') return
		await this.$store.commit('setSelectedServiceProvider', this.$store.state.loggedInUser.fields.serviceProvider.de)
	},
	methods: {
		updateServiceProviderFromChildren() {
			const contactInfo = this.$refs['contactPersonInfo']?.sendData()
			// myInfo needs to be called after contactPersonInfo because it depends on one property of the contactPersonInfo sent via the eventBus
			const myInfo = this.$refs['myInfo']?.sendData()
			const businessHoursData = this.$refs['businessInfo']?.sendData()
			const categoryInfo = this.$refs['categoryInfo']?.sendData()
			const bankingInfo = this.$refs['bankingInfo']?.sendData()
			const publicInfo = this.$refs['publicInfo']?.sendData()
			const offerMenu = this.$refs['offerMenu']?.sendData()
			const mainUserAccountInfo = this.$refs['mainUserAccount']?.sendData()
			const applicationAccessInfo = this.$refs['applicationAccessInfo']?.sendData()
			const trustLevel = this.$refs['trustLevel']?.sendData()
			const linkServiceProvidersInfo = this.$refs['linkServiceProviders']?.sendData()
			const salesChannelsInfo = this.$refs['salesChannelsInfo']?.sendData()

			//Set ClientAssignment productCategories
			if (categoryInfo) {
				const clientCA = salesChannelsInfo.clientAssignments.de.find(x => x.fields.client.de.sys.id === this.$store.state.selectedClient.sys.id)
				if (clientCA) {
					clientCA.fields.productCategories = {de: categoryInfo.productCategories.de }
				}
			}
			
			const data = [myInfo, businessHoursData, salesChannelsInfo, contactInfo, bankingInfo, publicInfo, trustLevel].filter(data => data !== undefined)
			for (const el of data) {
				Object.keys(el).forEach(key => {
					this.serviceProvider.fields[key] = JSON.parse(JSON.stringify(el[key]))
				})
			}

			//Set changed sections
			this.serviceProvider.addl = {
				contactInfoChanged: contactInfo?.changed || this.serviceProvider.sys.id === '' ? true : false,
				myInfoChanged: myInfo?.changed || this.serviceProvider.sys.id === '' ? true : false,
				businessHoursChanged: businessHoursData?.changed || this.serviceProvider.sys.id === '' ? true : false,
				categoryInfoChanged: categoryInfo?.changed || this.serviceProvider.sys.id === '' ? true : false,
				bankingInfoChanged: bankingInfo?.changed || this.serviceProvider.sys.id === '' ? true : false,
				publicInfoChanged: publicInfo?.changed || this.serviceProvider.sys.id === '' ? true : false,
				offerMenuChanged: offerMenu?.changed || this.serviceProvider.sys.id === '' ? true : false,
				mainUserAccountChanged: mainUserAccountInfo?.changed || this.serviceProvider.sys.id === '' ? true : false,
				applicationAccessChanged: applicationAccessInfo?.changed || this.serviceProvider.sys.id === '' ? true : false,
				trustLevelChanged: trustLevel?.changed || this.serviceProvider.sys.id === '' ? true : false,
				linkServiceProvidersChanged: linkServiceProvidersInfo?.changed || this.serviceProvider.sys.id === '' ? true : false,
				salesChannelsChanged: salesChannelsInfo?.changed || this.serviceProvider.sys.id === '' ? true : false
			}
		},
		averageDurationChanged(value) {
			this.serviceProvider.fields.averageDurationOfStayInMinutes.de = parseInt(value)
			this.setChanged()
		},
		toggleHaveBusinessHours(event) {
			if  (!event  || event === false) {
				this.serviceProvider.fields.businessHours.de = []
			} else {
				this.serviceProvider.fields.businessHours.de = [this.$refs.businessInfo.getNewBusinessHours(false)]

			}
			this.setChanged()
		},
		buildBusinessHours(isException) {
			let businessHours = []
			if (this.serviceProvider.fields.businessHours?.de?.length > 0) {
				for (const businessHour of this.serviceProvider.fields.businessHours.de) {
					if (businessHour.fields.isException?.de) {
						if (businessHour.fields.isException?.de === isException) {
							businessHours.push(businessHour)
						}
					} else {
						if (!isException) {
							businessHours.push(businessHour)
						}
					}

				}
			}

			return businessHours
		},
		isHomebase() {
			if (this.serviceProvider.fields.clientAssignments?.de?.length > 0) {
				const clientAssignment = this.serviceProvider.fields.clientAssignments.de.find(spClientAssignment => spClientAssignment.fields.client.de.sys.id === this.$store.state.selectedClient.sys.id)

				if (clientAssignment?.fields?.isHomebase) {
					return clientAssignment.fields.isHomebase.de
				}
			}

			return false
		},
		onDeleteConfirmed() {
			this.$refs.deleteDialog.show = false
			this.deleteServiceProvider()
			return true
		},
		onDeleteCancelled() {
			this.$refs.deleteDialog.show = false
		},
		confirmUnsavedChanges() {
			this.$refs.unsavedChangesDialog.show = false

			if (this.userIsOperator) {
				this.$store.commit('setSelectedServiceProvider', null)
			}

			if (this.gobackToDashboard === true || !this.userIsOperator) {
				this.$router.push("/")
				this.$emit('show-dashboard')
			} else {
				this.$router.push("/serviceProviders")
			}

			return true
		},
		cancelUnsavedChanges() {
			this.$refs.unsavedChangesDialog.show = false
		},
		showOfferMenu() {
			if (this.$refs.offerMenu) {
				this.offerMenuVisible = true
			}
		},
		setChanged(section) {
			this.submitButtonTheme = "blueButton white--text"
		},
		setActive(key) {
			this.activeLink = key
			if (this.$refs['SECTION_' + key]) {
				this.$refs['SECTION_' + key].open()
			} else if (this.$refs[key]) {
				// open the disclosures from child components
				this.$refs[key].$refs['SECTION_' + key].open()
			}
		},
		showError(detail, title) {
			this.errorTitle = title ?? this.$t('text.ERROR')
			this.errorDetail = detail
		},
		async getServiceProviderDetail(action) {
			var id = this.$store.state.selectedServiceProvider?.sys?.id
			if (!id) return

			this.loading = true
			try {
				const res = await this.$httpGet(`/serviceprovider/${id}`)
				const serviceProvider = res.serviceProvider

				this.setServiceProviderDetails(serviceProvider)
				this.setActive('top')


				if (action == 'create') {
					this.successTitle = this.$t('text.BUSINESS_PROFILE_CREATED')
					this.successDetail = this.$t('text.createServiceProviderSuccess')
				}
				if (action == 'update') {
					this.successTitle = this.$t('text.BUSINESS_PROFILE_UPDATED')
					this.successDetail = this.$t('text.profileUpdatedSuccess')
				}
				if (action == 'approve') {
					this.successTitle = this.$t('text.ACCOUNT_APPROVED')
					this.successDetail = this.$t('text.accountApprovedSuccess')
				}
				if (action == 'activate') {
					this.successTitle = this.$t('text.BUSINESS_PROFILE_UPDATED')
					this.successDetail = this.$t('text.accountApprovedSuccess')
				}
				if (action == 'unpublish') {
					this.successTitle = this.$t('text.BUSINESS_PROFILE_UPDATED')
					this.successDetail = this.$t('text.profileUpdatedSuccess')
				}
				if (action == 'decline') {
					this.successTitle = "ACCOUNT DECLINED"
					this.successDetail = "The service provider account has been declined"
				}
				if (action == 'deactivate') {
					this.successTitle = "ACCOUNT DEACTIVATED"
					this.successDetail = "The service provider account has been deactivated"
				}
				if (action == 'changeHomebase') {
					this.successTitle = this.$t('text.HOMEBASE_CHANGED')
					this.successDetail = this.$t('text.homebaseChangeSuccess')
				}
			}
			catch (error) {
				if (error.response?.status === 401) return this.$emit("show-login")
				console.error(error)
				this.showError(error.response ? error.response.data.error : error)
			}
			this.loading = false
			this.updateModel = true
		},
		async setServiceProviderDetails(data) {
			this.serviceProvider = data
			this.bindStatus(this.serviceProvider)

			if (!this.serviceProvider.fields.language) {
				this.serviceProvider.fields.language = { de: '' }
			}

			if (!this.serviceProvider.fields.location) {
				this.serviceProvider.fields.location = { de: { lat:'', lon:'' } }
			}

			if (!this.serviceProvider.fields?.faqGroup?.de) {
				this.serviceProvider.fields["faqGroup"] = {de: ""}
			}

			if (!this.serviceProvider.fields.shortDescription.de) {
				this.serviceProvider.fields.shortDescription.de = "";
			}

			if (!this.serviceProvider.fields.longDescription?.de) {
				this.serviceProvider.fields.longDescription = { de: "" }
			}

			if (!this.serviceProvider.fields.importantInformation) {
				this.serviceProvider.fields["importantInformation"] = {de: ""}
			}

			if (this.serviceProvider.fields.businessHours && this.serviceProvider.fields.businessHours.length > 0) {
				this.serviceProvider.fields.businessHours["de"] = this.serviceProvider.fields.businessHours;

				for (var businessHours of this.serviceProvider.fields.businessHours.de) {
					businessHours.fields.validFromDate.de = businessHours.fields.validFromDate.de.split("T")[0]
					businessHours.fields.validToDate.de = businessHours.fields.validToDate.de.split("T")[0]
				}
			}

			//Set ClientAssignment productCategories
			const clientCA = this.serviceProvider.fields.clientAssignments.de.find(x => x.fields.client.de.sys.id === this.$store.state.selectedClient.sys.id)
			this.serviceProvider.fields.productCategories = {de: clientCA?.fields?.productCategories?.de?.length ? clientCA.fields.productCategories.de : [] }

			await this.$store.commit('setSelectedServiceProvider', this.serviceProvider);

			if (this.$store.state.loggedInUser.fields?.type?.de === 'operator') {
				if (this.statusMap['approved']) {
					this.isApproval = false
				} else {
					this.isApproval = true
				}
			}

			this.originalServiceProvider = JSON.parse(JSON.stringify(this.serviceProvider.fields))

			this.sleep(1000).then(() => { 
				this.$refs['myInfo']?.validateAllFields()
				this.$refs['contactPersonInfo']?.validateAllFields()
				this.$refs['businessInfo']?.validateAllFields()
				this.$refs['mainUserAccount']?.validateAllFields()
				this.$refs['applicationAccessInfo']?.validateAllFields()
				this.$refs['salesChannelsInfo']?.validateAllFields()
				this.$refs['categoryInfo']?.validateAllFields()
			 })

		},
		async updateProfile(action) {
			this.updateModel = false
			this.updateServiceProviderFromChildren()
			this.runValidation = true
			// ATT: these actions update without validation because the services are not visible after this anyway
			//      and they will have to go through a validation process later to become visible again.
			if (action === 'deactivate' || action === 'decline' || action === 'unpublish') {
				if (action === 'deactivate') {
				this.updateStatusAndBind('deactivated')
				}
				if (action === 'decline') {
				this.updateStatusAndBind('declined')
				}
				if (action === 'unpublish') {
				this.updateStatusAndBind('unpublished')
				}

				return await this.updateServiceProvider(action)
			}

			//Merge business hours and exceptions
			if (this.$refs.businessInfo) {
				if (this.$refs.businessInfo.haveBusinessHours === true) {
					this.serviceProvider.fields.businessHours.de = [...this.$refs.businessInfo.businessHoursLocal, ...this.$refs.businessInfo.businessHoursExceptions]
				} else {
					this.serviceProvider.fields.businessHours.de = []
				}
			}

			try {
				const isValid = this.validate(action)
				if (isValid === false) {
					return this.showError(this.$t('text.missingFieldsError'))
				}
			} catch (e) {
				return this.showError(e)
			}

			if (action === 'approve' || action === 'activate') {
				this.updateStatusAndBind('approved')
			}
			else if (action === 'update') {
				// here the state can only be 'approved' we keep that.
			}
			else {
				throw new Error('unsupported action ' + action)
			}

			// prepare request payload
			if (this.$refs['applicationAccessInfo']?.selectedComponents?.length) {
				this.serviceProvider.enabled = true
				this.serviceProvider.roles = this.$refs['applicationAccessInfo'].selectedComponents
				if (!this.serviceProvider.mainUserAccount && this.$refs.mainUserAccount) {
					this.serviceProvider.mainUserAccount = {
						name: this.$refs.mainUserAccount.mainUserAccount.name,
						email: this.$refs.mainUserAccount.mainUserAccount.email,
						password: this.$refs.mainUserAccount.mainUserAccount.password,
						repeatPassword: this.$refs.mainUserAccount.mainUserAccount.repeatPassword
					}
				}
				
			} else if (Object.keys(this.serviceProvider.fields.mainUserAccount).length > 0) {
				this.serviceProvider.enabled = true
				this.serviceProvider.roles = this.serviceProvider.fields.mainUserAccount.fields.applications?.de

				if (!this.serviceProvider.mainUserAccount) {
					this.serviceProvider.mainUserAccount = {
						name:'',
						email:'',
						password:'',
						repeatPassword:''
					}
				}
			}

			this.serviceProvider.selectedLocale = this.selectedLocale
			this.serviceProvider.fields.linkedServiceProviders = { de: this.$refs['linkServiceProviders']?.selectedServiceProviders ?? [] }

			this.serviceProvider.fields.imageGallery.de = this.serviceProvider.fields.imageGallery.de?.map(image => {
				if (image?.fields?.media?.de?.sys) {
					return image.fields.media.de.sys.id
				}
				return image?.sys?.id
			}) ?? []

			//Remove unnecessary data before submit
			if (this.serviceProvider.fields.faqGroup && this.serviceProvider.fields.faqGroup?.de && this.serviceProvider.fields.faqGroup.de?.fields) {
				this.serviceProvider.fields.faqGroup.de.fields.faqs = [];
			}

			if (this.serviceProvider.fields.importantInformation?.de) {
				this.serviceProvider.fields.importantInformation.de = this.convertLink(this.serviceProvider?.fields?.importantInformation?.de)
			}


			// Offer Menu
			if (this.$refs.offerMenu) {
				if (!this.serviceProvider?.fields?.offerMenu?.de) {
					this.serviceProvider.fields["offerMenu"] = {de: this.$refs.offerMenu.offerMenu}
				} else {
					this.serviceProvider.fields.offerMenu.de = this.$refs.offerMenu.offerMenu
				}
			}
			
			if (this.$refs.offerMenu?.showOfferMenu === false && this.serviceProvider.fields.offerMenu?.de?.sys) {
				this.serviceProvider.fields.offerMenu.de.fields.offerMenuItems = {de:[]}
			}

			if (this.isCreate) {
				await this.createServiceProvider("create")
			}
			else {
				await this.updateServiceProvider(action)
			}
		},
		async updateServiceProvider(action) {
			// Optimise Payload
			delete this.serviceProvider.fields.clients
			delete this.serviceProvider.fields.reductions
			delete this.serviceProvider.fields.ticketTypes

			if (this.$refs?.checklistRef?.checklist) {
				this.serviceProvider.fields.checklist = this.$refs.checklistRef.checklist
			}
			
			var data = {
				action: action,
				serviceProvider: this.serviceProvider,
				clientId: this.$store.state.selectedClient.sys.id,
				isOperator: this.$store.state.loggedInUser.fields?.type?.de === 'operator' && !this.$store.state.isImpersonation,
			}
			data.serviceProvider = this.cfoClean(data.serviceProvider)
			// serviceprovider also contains some other keys that are being cleaned out by cfoClean
			for (const key in this.serviceProvider) {
				if (key === 'sys' || key === 'fields') continue
				data.serviceProvider[key] = this.serviceProvider[key]
			}

			data.serviceProvider.roles = this.$refs['applicationAccessInfo']?.selectedComponents ?? this.serviceProvider.fields.mainUserAccount.fields.applications?.de

			this.loading = true
			try {
				const res = await this.$httpPut('/serviceprovider', data)
				this.submitButtonTheme = "gradientButton"

				await this.getServiceProviderDetail(action)
			}
			catch (error) {
				this.showError(error?.error)
			}
			this.loading = false
		},
		async createServiceProvider(action) {
			// Optimise Payload
			delete this.serviceProvider.fields.clients
			delete this.serviceProvider.fields.reductions
			delete this.serviceProvider.fields.ticketTypes

			this.serviceProvider.mainUserAccount = this.$refs['mainUserAccount'].mainUserAccount

			var data = {
				serviceProvider: this.serviceProvider,
				clientId: this.$store.state.selectedClient.sys.id,
				autolinkUser: true,
				mainUserAccount: this.$refs['mainUserAccount'].mainUserAccount,
				roles: this.$refs['applicationAccessInfo'].selectedComponents,
				language: this.selectedLocale,
				images: this.images,
				tags: this.$store.state.loggedInUser.fields.canSeeObjectsWithTags?.de ? this.$store.state.loggedInUser.fields.canSeeObjectsWithTags.de : []
			}

			this.loading = true
			try {
				const res = await this.$httpPost('/serviceprovider', data)
				this.submitButtonTheme = "gradientButton"

				this.serviceProvider.sys.id = res.serviceProviderId
				await this.$store.commit('setSelectedServiceProvider', this.serviceProvider)

				await this.getServiceProviderDetail(action)
			}
			catch (error) {
				this.showError(error.response ? error.response?.data.error : error)
			}
			this.loading = false
		},
		async deleteServiceProvider() {
			this.loading = true

			try {
				if (!this.statusMap.declined && !this.statusMap.deactivated) {
					//Deactivate account before delete if it is approved/pending
					// Optimise Payload
					delete this.serviceProvider.fields.clients
					delete this.serviceProvider.fields.reductions
					delete this.serviceProvider.fields.ticketTypes

					var data = {
						action: "deactivate",
						serviceProvider: this.serviceProvider,
						clientId: this.$store.state.selectedClient.sys.id,
					}

					try {
						const resDeactivate = await this.$httpPut('/serviceprovider', data)
					}
					catch (error) {
						this.showError(error.response ? error.response?.data.error : error)
					}
				}

				const resDelete = await this.axios.delete(`/serviceProvider`,
				{
					headers: this.requestHeaders(),
					data: {
						serviceProviderId: this.serviceProvider.sys.id,
						clientId: this.$store.state.selectedClient.sys.id,
						dryRun: false
					}
				})

				this.successTitle = this.$t('text.SERVICE_PROVIDER_DELETED')
				this.successDetail = this.$t('text.serviceProviderDeletedSuccess')

				this.loading = false

				this.sleep(2000).then(() => { this.$router.push("/serviceProviders") });
			}
			catch (error) {
				this.showError(error.response ? error.response?.data.error : error)
			}
			this.loading = false
		},
 		validate(action) {
			const isValidGeneralInfo = this.$refs['myInfo']?.validateAllFields()
			const isValidContactPersonInfo = this.$refs['contactPersonInfo']?.validateAllFields()
			const isValidBusinessInfo = this.$refs['businessInfo']?.validateAllFields()
			const isValidUserAccount = this.userAccountVisible ? this.$refs['mainUserAccount']?.validateAllFields() : true
			const isValidApplicationComponent = this.userIsOperator ? this.$refs['applicationAccessInfo']?.validateAllFields() : true
			const isValidSalesChannels = this.$refs['salesChannelsInfo']?.validateAllFields()
			const isValidCategories = this.$refs['categoryInfo']?.validateAllFields(action)

			const allValid = !this.runValidation ||
			(	isValidGeneralInfo &&
				isValidContactPersonInfo &&
				isValidBusinessInfo &&
				isValidUserAccount &&
				isValidApplicationComponent &&
				isValidCategories &&
				isValidSalesChannels
			)

			if (allValid) {
				this.updateServiceProviderFromChildren()
			}

			return allValid

		},
		updateStatusAndBind(status) {
			const object = this.serviceProvider
			this.updateStatus(object, status)
			this.bindStatus(object)
		},
		bindStatus(object) {
			this.statusMap = this.getStatusMap(object)
			this.statusClient = this.getStatusClient(object)
			this.statusClientHasMultiple = Object.keys(this.statusMap).length > 1
		},
		goback(gobackToDashboard) {
			this.gobackToDashboard = gobackToDashboard ?? false
			const contactInfo = this.$refs['contactPersonInfo']?.sendData()
			// myInfo needs to be called after contactPersonInfo because it depends on one property of the contactPersonInfo sent via the eventBus
			const myInfo = this.$refs['myInfo']?.sendData()
			const businessHoursData = this.$refs['businessInfo']?.sendData()
			const categoryInfo = this.$refs['categoryInfo']?.sendData()
			const bankingInfo = this.$refs['bankingInfo']?.sendData()
			const publicInfo = this.$refs['publicInfo']?.sendData()
			const offerMenu = this.$refs['offerMenu']?.sendData()
			const mainUserAccountInfo = this.$refs['mainUserAccount']?.sendData()
			const applicationAccessInfo = this.$refs['applicationAccessInfo']?.sendData()
			const trustLevel = this.$refs['trustLevel']?.sendData()
			const linkServiceProvidersInfo = this.$refs['linkServiceProviders']?.sendData()
			const salesChannelsInfo = this.$refs['salesChannelsInfo']?.sendData()

console.log('myInfo.changed: ' + myInfo?.changed)
console.log('businessHoursData.changed: ' + businessHoursData?.changed)
console.log('categoryInfo.changed: ' + categoryInfo?.changed)
console.log('bankingInfo.changed: ' + bankingInfo?.changed)
console.log('publicInfo.changed: ' + publicInfo?.changed)
console.log('offerMenu.changed: ' + offerMenu?.changed)
console.log('mainUserAccountInfo.changed: ' + mainUserAccountInfo?.changed)
console.log('applicationAccessInfo.changed: ' + applicationAccessInfo?.changed)
console.log('trustLevel.changed: ' + trustLevel?.changed)
console.log('linkServiceProvidersInfo.changed: ' + linkServiceProvidersInfo?.changed)
console.log('salesChannelsInfo.changed: ' + salesChannelsInfo?.changed)

			if (myInfo?.changed === true || 
				contactInfo?.changed === true || 
				businessHoursData?.changed === true || 
				categoryInfo?.changed === true || 
				bankingInfo?.changed === true || 
				publicInfo?.changed === true || 
				offerMenu?.changed === true ||
				mainUserAccountInfo?.changed === true ||
				applicationAccessInfo?.changed === true ||
				trustLevel?.changed === true ||
				linkServiceProvidersInfo?.changed === true ||
				salesChannelsInfo?.changed === true) {

				this.$refs.unsavedChangesDialog.show=true
			} else {
				if (!this.userIsOperator) {
					this.$router.push("/dashboard")
				} else {
					this.$router.push("/serviceProviders")
				}
			}
		},
		sleep(ms) {
			return new Promise(resolve => setTimeout(resolve, ms));
		},
		showChangeHomebaseDialog() {
			this.$refs.changeHomebaseDialog.show = true
		},
		async confirmHomebaseChange() {
			const data = {
				serviceProviderId: this.serviceProvider.sys.id,
				newHomebase: this.$refs.homebaseSelector.selectedClient.sys.id,
				loggedInUser: this.$store.state.loggedInUser.fields.email.de
			}

			this.loading = true
			try {
				await this.$httpPost('/homebase', data)

				await this.getServiceProviderDetail("changeHomebase")
			}
			catch (error) {
				this.showError(error.response ? error.response?.data.error : error)
			}
			this.loading = false
			this.$refs.changeHomebaseDialog.show = false

		},
		cancelHomebaseChange() {
      		this.isHomebaseClientSelected = false
			this.$refs.changeHomebaseDialog.show = false
		},
	},

}
</script>

<style scoped >
.container-grid { margin-left: 380px; padding: 20px; }
hr { margin-top: -20px; color: #efefef; }
a { text-decoration: none; }
.error { color: #ff0022; }
.weekdayCard {
	width: 100% !important;
	height: 48px !important;
	line-height: 48px !important;
	background-color: #f2f2f2 !important;
	padding-left: 10px !important;
	font-size: 14pt !important;
}
.theme--light.v-card { box-shadow: none !important; }
.ofrom-label {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	display: block;
	padding: 3px;
}
.serviceLocale { position: absolute; z-index: 10; margin-top: 32px; margin-left: -32px; zoom: 0.8; }
</style>