<template>
	<v-footer ref="footer" absolute padless class="footer">
		<v-row class=content align="center" justify="center">
			<v-spacer />
			<ul>
				<li v-for="item in menuItems" :key="item.id">
					<a :href="item.fields.urlLink.de" :target="item.fields.openInNewWindow.de">{{ item.fields.title[selectedLocale] }}</a>
				</li>
			</ul>
			<v-spacer />
      <label>{{ $store.getters.appVersion }} {{ $store.getters.featureBranch }}</label>
		</v-row>
	</v-footer>
</template>

<script>
import Common from "@/mixins/Common";

export default {
  name: "Footer",
  mixins: [ Common ],
  props: {
    selectedComponent: Object
  },

  data() {
    return {
      menuItems: [
        {
          id: "item-1",
          fields: {
            title: {
              de: "Imprint",
              en: "Imprint",
              fr: "Imprint",
              it: "Imprint"
            },
            urlLink: { de: "https://www.alturos.com/" },
            openInNewWindow: { de: true }
          }
        },
        {
          id: "item-2",
          fields: {
            title: {
              de: "Privacy Policy",
              en: "Privacy Policy",
              fr: "Privacy Policy",
              it: "Privacy Policy"
            },
            urlLink: { de: "http://www.alturos.com/privacy-statement/" },
            openInNewWindow: { de: true }
          }
        }
      ]
    }
  },

  created() {
    this.$nextTick(() => {
      if (document.getElementsByClassName("sidebar").length > 0 && this.isSideBarFixed) {
        this.$refs.footer.$el.style.paddingLeft = "380px";
      } else {
        this.$refs.footer.$el.style.paddingLeft = "0px";
      }
    });
  },

  watch: {
    selectedComponent() {
      this.$nextTick(() => {
        if (document.getElementsByClassName("sidebar").length > 0 && this.isSideBarFixed) {
          this.$refs.footer.$el.style.paddingLeft = "380px";
        } else {
          this.$refs.footer.$el.style.paddingLeft = "0px";
        }
      });
    },
    $route(to) {
      if (to.path.includes("/content")) {
        this.$refs.footer.$el.style.paddingLeft = "0px";
      }
    }
  },

};
</script>

<style scoped>
.footer {
	bottom: 0;
	z-index: 5;
	background: transparent !important;
}
.footer .content {
  background: rgba(255,255,255,.50) !important;
  height: 35px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
ul li {
	list-style: none;
	display: inline;
	font-size: 14px;
	background-color: transparent !important;

}
ul li {
	list-style: none;
	display: inline;
	color: grey;
	font-size: 14px;
}
ul li a {
	color: black;
	text-decoration: none;
}
ul li:after {
	content: "\2022";
	margin: 0 8px;
	color: black;
}
ul li:last-child:after {
	content: none;
}
</style>
