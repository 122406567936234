<template>
  <v-container fluid class="pa-0 mb-12" :style="isSideBarFixed ? 'padding-left: 380px !important;' : 'padding-left: 40px !important;'">
    <Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
    <loading :active.sync="isLoading" :is-full-page="fullPage" color="#4caf50"></loading>

    <!-- navbar -->
		<div class="navbar">
      <v-row v-if="itemType!=='marketplaceSettings'">
					<v-text-field outlined dense clearable hide-details height="48"
          v-if="!serviceProviderClicked"
						prepend-inner-icon="mdi-magnify"
						:placeholder="$t('text.search')"
						style="max-width:33%" 
						v-model="searchString" 
						@keyup.enter="offset=0;search()"
						@click:clear="clearSearch()"
            data-cy="search"
					/>
					<v-spacer/>
          <v-row justify="end">
					  <v-btn v-if="itemType!=='serviceProviders'" data-cy="addOptionButton" small class="gradientButton" elevation="0" @click="showAddOptionDialog()">
              <v-icon>mdi-plus</v-icon>
              <span class="d-none d-md-inline">{{addOptionTitle}}</span>
            </v-btn>
          </v-row>
			</v-row>
		</div>

    <SideBar>
      <div :style="showImpersonationInfo ? 'padding-top: 24px !important;' : 'padding-top:0px'">
      <ul id="navlist">
        <a v-for="state of states" :key="state" @click="changeState(state)">
          <li @click="setActive(state)" :class="{ active: state == activeLink }">
            {{ $t('text.' + state) }}
          </li>
        </a>
      </ul>
      </div>
    </SideBar>

		<div :style="showImpersonationInfo ? 'padding-top: 24px !important;' : 'padding-top:0px'">
      <ServiceOptionsForProvider 
          v-if="serviceProviderClicked && isOperator" 
          :title="currentServiceProviderTitle" 
          @go-back="goBack()" >
      </ServiceOptionsForProvider>

      <!-- Service Providers (for operator) -->
      <template v-if="serviceProviderView()">
        <div class="pa-3">
          <v-card class="tableCard">
            <v-data-table fixed-header hide-default-footer disable-sort
                          :headers="optionHeaders"
                          :items="serviceProviders"
                          :items-per-page="limit"
                          class="elevation-0"
                          style="border-radius:0">

              <template v-slot:item="{ item }">
                <tr @click="showProviderOptions(item)">
                  <td>{{ item.fields.title[selectedLocale] }}</td>
                  <td><Status :status="getClientStatus(item)" /></td>
                </tr>
              </template>
            </v-data-table>

            <v-card class="tableFooter">
              <v-card-actions>
                <TablePaginator v-model="offset" :limit="limit" :total="serviceProviderCount" @input="getServiceProviders()" :results="serviceProviders" />
              </v-card-actions>
            </v-card>
          </v-card>
        </div>
      </template>

      <!-- Service Options for operator -->
      <template v-if="serviceOptionsView('operator')">
        <div class="pa-3">
          <v-card class="tableCard">
            <v-data-table fixed-header hide-default-footer disable-sort
                          :headers="optionHeaders"
                          :items="serviceOptions"
                          :items-per-page="limit"
                          class="elevation-0"
                          style="border-radius:0"
                          @click:row="showEditOptionDialog">

              <template v-slot:item.item_type="{ item }">
                {{ item.fields.title[selectedLocale] }}
              </template>
              <template v-slot:item.status="{ item }">
                <v-switch
                    v-model="item.defaultAssignment"
                    :label="$t('text.default')"
                    :value="item.defaultAssignment"
                    inset
                    color="success"
                    @change="isChangeDefault=true;changeDefault(item)"
                    hide-details
                    class="mt-0">
                </v-switch>
              </template>
            </v-data-table>

            <v-card class="tableFooter">
              <v-card-actions>
                <TablePaginator v-model="offset" :limit="limit" :results="serviceOptions" :total="optionCount" @input="getServiceOptions()"/>
              </v-card-actions>
            </v-card>
          </v-card>
        </div>
      </template>

      <!-- Service Options for service provider -->
      <template v-if="serviceOptionsView('serviceProvider')">
        <div class="pa-3">
          <v-card class="tableCard">
            <v-data-table fixed-header hide-default-footer disable-sort
                          :headers="optionHeaders"
                          :items="serviceOptions"
                          :items-per-page="limit"
                          :item-class="clientOption"
                          class="elevation-0"
                          style="border-radius:0"
                          @click:row="showEditOptionDialog">

              <template v-slot:item.item_type="{ item }">
                {{ item.fields.title[selectedLocale] }}
              </template>
              <template v-slot:item.status="{ item }">
                <v-chip v-if="item.fields.status" style="margin-right:10px" :color="getColor(item.fields.status)" dark>{{$t(`text.${item.fields.status}`)}}</v-chip>
                <v-chip v-else style="margin-right:10px" :color="getColor('active')" dark>{{$t(`text.active`)}}</v-chip>
              </template>
            </v-data-table>

            <v-card class="tableFooter">
              <v-card-actions>
                <TablePaginator v-model="offset" :limit="limit" :results="serviceOptions" :total="optionCount" @input="getServiceOptions()"/>
              </v-card-actions>
            </v-card>
          </v-card>
        </div>
      </template>

       <template v-if="itemType==='marketplaceSettings'">
        <div class="pa-3">
          <Disclosure :title="$t('text.marketplaceSettings')" :expanded="true" :error="sectionMessage.marketplaceSettings.error" :message="sectionMessage.marketplaceSettings.message">
            <div class="field left-border">
              <p class="helpText" v-html="$t('text.marketplaceSettingsHelp')"/>
              <br/><br/><br/><br/>
              <v-switch
                v-model="spSalesChannelSelectionEnabled"
                :label="$t('text.spSalesChannelSelectionEnabled')"
              />
            </div>
          </Disclosure>
        </div>
       </template>
      <br/>
    </div>

    <Dialog  ref="serviceAddOptionDialog"
      :title="addOptionTitle"
      :confirmLabel="$t('text.addOption')"
      :cancelLabel="$t('text.discardChanges')"
      :confirm-handler="addOption"
      :cancel-handler="resetOption" 
      :show-close="false">
      <template #content>
        <v-row>
          <v-col>
            <v-row v-for="(value, propertyName) in name" :key="propertyName">
              <div class="col-2 col-lg-1 pt-0 pl-0 pb-0"><img :src="getFlagIcon(propertyName)" width="24px" height="24px" style="margin-top:12px;margin-right:5px"/></div>
              <v-text-field data-cy="addOptionField" class="col-10 col-lg-11" outlined v-model="name[propertyName]"></v-text-field>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </Dialog>

    <Dialog ref="serviceEditOptionDialog"
      :title="editOptionTitle"
      :confirmLabel="$t('text.saveChanges')"
      :cancelLabel="$t('text.discardChanges')"
      :deleteLabel="$t('text.deleteOption')"
      :confirm-handler="editOption"
      :cancel-handler="resetOption"
      :delete-handler="deleteOption" 
      :show-close="false">
      <template #content>
        <v-row>
          <v-col>
            <v-row v-for="(value, propertyName) in editItem.fields.title" :key="propertyName">
              <div class="col-2 col-lg-1 pt-0 pl-0 pb-0"><img :src="getFlagIcon(propertyName)" width="24px" height="24px" style="margin-top:12px;margin-right:5px"/></div>
              <v-text-field class="col-10 col-lg-11" data-cy="editOptionField" outlined v-model="editItem.fields.title[propertyName]"></v-text-field>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </Dialog>

    <br/>
  </v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Dialog from '@/components/common/Dialog'
import Alert from '@/components/common/Alert.vue'
import Common from '@/mixins/Common.vue'
import SettingsMixin from '@/mixins/SettingsMixin.vue'
import ServiceOptionsForProvider from './ServiceOptionsForProvider.vue'
import TablePaginator from '@/components/common/TablePaginator.vue'
import Status from '@/components/common/Status.vue'
import SideBar from "@/components/common/Sidebar"
import Disclosure from '@/components/common/Disclosure.vue'

export default {
  name: 'SettingsView',
  components: {SideBar, Loading, Alert, Dialog, TablePaginator, Status, Disclosure, ServiceOptionsForProvider},
  mixins: [ Common, SettingsMixin ],
  props: {
    viewType: {
      type: String,
      default: 'serviceProviders'
    }
  },

  data() {
		return {
      isLoading: false,
      fullPage: true,

      errorTitle: '',
      errorDetail: '',
      successTitle: '',
      successDetail: '',

      itemType: 'serviceProviders',
      name: {},
      editItem: {},
      originalItem: {},

      serviceOptions: [],
      isChangeDefault: false,
      optionCount: 0,

      serviceProviders: [],
      serviceProviderCount: 0,
      currentServiceProviderTitle: '',
      searchSubmitted: false,

      headers: [
        {
          text: 'Name',
          align: 'left',
          sortable: false,
          value: 'name'
        }
      ],

      //Marketplace Settings
      spSalesChannelSelectionEnabled: this.$store.state.selectedClient.fields.marketplaceSettings?.de?.find(x => x.id === 'spSalesChannelSelectionEnabled')?.enabled,

			sectionMessage: {
				marketplaceSettings: {error:false,warning:false,message:''}
			},
  }},

  computed: {
    optionHeaders () {
      let typeText = this.itemType !== 'serviceProviders' ? this.$t('text.'+this.itemType+'s') : this.$t('text.serviceProviders')
      let headers = [
        { text: typeText, sortable: true, value: 'item_type' }
      ];
      if (this.isOperator && this.state !== "serviceProviders") {
        headers.push(
            { text: '', width:'100px', value: "status" }
        )
      } else {
        headers.push(
            { text: 'Status', width:'100px', value: "status" }
        )
      }

      return headers;
		},
    addOptionTitle() {
      switch (this.itemType) {
        case "ticketType":  return this.$t('text.addTicketType')
        case "reduction":   return this.$t('text.addReduction')
      }
      return ""
    },
    editOptionTitle() {
      switch (this.itemType) {
        case "ticketType":  return this.$t('text.editTicketType')
        case "reduction":   return this.$t('text.editReduction')
      }
      return ""
    },
    errorMessages() {
      return ' <span style="font-size:14pt;font-weight:bold;"> ' + this.errorTitle + ' </span><br> ' + this.errorDetail;
    },
    successMessages() {
      return ' <span style="font-size:14pt;font-weight:bold;"> ' + this.successTitle + ' </span><br> ' + this.successDetail;
    }
  },

  watch: {
    state() {
      this.getServiceOptions()
    },
    spSalesChannelSelectionEnabled() {
      this.updateMarketplaceSettings('spSalesChannelSelectionEnabled', this.spSalesChannelSelectionEnabled)
    }
  },

  async mounted() {
    this.serviceProviderClicked = false

    if (this.isOperator) {
      this.viewType ? this.setActive(this.viewType) : this.setActive('serviceProviders')
      this.getServiceOptions()
    } else {
      this.setActive("ticketType")
      this.itemType = "ticketType"
      this.getProviderOptions()
    }
  },

  methods: {
    async updateMarketplaceSettings(setting, enabled) {
      this.loading = true

      const data = {
				clientId: this.$store.state.selectedClient.sys.id,
				enabled: enabled,
				setting: setting
			}

      try {
        const client = await this.$httpPost(`/marketplaceSettings`, data)
        await this.$store.commit("setSelectedClient", client)
        
        this.successTitle = this.$t('text.marketplaceSettings')
        this.successDetail = this.$t('text.marketplaceSettingSuccessTitle')
      } catch (error) {
				this.showError(error.response ? error.response?.data.error : error)
			}
			
			this.loading = false
    },
    getClientStatus(serviceProvider) {
			const currentCA = serviceProvider.fields.clientAssignments.de.find(x => x.fields.client.de.sys.id === this.$store.state.selectedClient.sys.id)
			return currentCA?.fields?.status?.de
		},
    serviceProviderView() {
      return this.isOperator && !this.serviceProviderClicked && this.state==='serviceProviders'
    },
    serviceOptionsView(userType) {
      if (userType === 'operator') {
        return this.isOperator && (this.state === 'ticketType' || this.state === 'reduction')
      } else if (userType === 'serviceProvider') {
        return !this.isOperator && (this.state === 'ticketType' || this.state === 'reduction')
      }
    },
    clientOption(item) {
      if (item.source === 'CLIENT') {
        return 'clientOption'
      }
    },
    goBack() {
      this.serviceProviderClicked = false
    },
    getFlagIcon(locale) {
      if (locale === "de") {
        return this.getGermanFlagIcon()
      } else {
        const images = require.context('@/assets/flags/', false, /\.svg$/)
        return images('./' + locale + ".svg")
      }
    },
    getGermanFlagIcon() {
      var images = require.context('@/assets/flags/', false, /\.svg$/)

      if (this.$store.state.userSpecificGerman && this.$store.state.userSpecificGerman !== "") {
        return images('./' + this.$store.state.userSpecificGerman + ".svg")
      } else {
        return images('./' + "de.svg")
      }
    },
    getColor (status) {
      if (status === "pending")  {
        return '#ff9e21'
      } else if (status === "active") {
        return '#66BB6A'
      }
    },

    async showProviderOptions(item) {
      await this.$store.commit('setSelectedServiceProvider', item)
      this.serviceProviderClicked = true
      this.currentServiceProviderTitle = item.fields.title[this.selectedLocale]
    },
    showAddOptionDialog() {
      this.clear();
      this.$refs.serviceAddOptionDialog.show = true;
    },
    showEditOptionDialog(item) {
      if (item && !item.source && !this.isChangeDefault) {
        //Fill in missing locales
        for (const locale of this.locales) {
          if (!item.fields.title[locale.code]) {
            item.fields.title[locale.code] = ''
          }
        }

        this.originalItem = JSON.parse(JSON.stringify(item));
        this.editItem = JSON.parse(JSON.stringify(item));
        this.$refs.serviceEditOptionDialog.show = true
      }
    },
    clear() {
      this.errorTitle = ""
      this.errorDetail = ""
      this.successTitle = ""
      this.successDetail = ""

      this.requestSubmitted = false

      this.resetOption()
    },
    resetOption() {
      for (const locale of this.locales) {
        this.name[locale.code] = ''
      }
      this.editItem = JSON.parse(JSON.stringify(this.originalItem));
    },
    showError(detail, title) {
			this.errorTitle = title ?? this.$t('text.ERROR')
			this.errorDetail = detail
		},
    getServiceOptions() {
      // anything was searched
      if (this.searchString?.length > 0) {
        this.search()
      } 
      // client clicks the Service Providers tab
      else if (this.state === 'serviceProviders') {
        this.getServiceProviders()
      }
      // client clicks the Ticket Type or Ticket Category tab
      else if (this.isOperator) {
        this.getClientOptions() 
      }
      // service provider clicks the Ticket Type or Ticket Category tab
      else {
        this.getProviderOptions()
      }
    },
    async getServiceProviders() {
			if (this.searchSubmitted) {
				return this.getSearchedServiceProviders()
			}
			this.isLoading = true
			try {
				let url = `/serviceproviders?clientId=${this.$store.state.selectedClient.sys.id}&skip=${this.offset}&limit=${this.limit + 1}`
				if (this.$store.state.isImpersonation === true && this.$store.state.selectedServiceProvider.fields.tags?.de) {
					url += `&tags=${this.$store.state.selectedServiceProvider.fields.tags.de}`
				}
				
				let res = await this.$httpGet(url)
				this.serviceProviders = res.serviceProviders
				this.serviceProviderCount = res.total
			}
			catch (error) {
				if (error.response?.status == 400) {
					this.serviceProviders = []
				}
				else {
					this.errorTitle = this.$t('text.ERROR')
					this.errorDetail = error.response?.error ?? error
				}
			}
			this.isLoading = false
		},
    async getSearchedServiceProviders() {
			this.isLoading = true
			try {
				let res = await this.$httpGet(`/search?client=${this.$store.state.selectedClient.sys.id}&contentType=serviceProvider&skip=${this.offset}&limit=${this.limit + 1}&searchString=${encodeURIComponent(this.searchString)}`)
				
				this.serviceProviders = res?.serviceProviders
				this.serviceProviderCount = res?.total ? res.total : 0
			}
			catch (error) {
				if (error.response?.status == 400) {
					this.serviceProviders = []
				}
				else {
					this.errorTitle = this.$t('text.ERROR')
					this.errorDetail = error.response?.error ?? error
				}
			}
			this.isLoading = false
		},
    getClientOptions() {
      this.isLoading = true

      this.$httpGet(`/client-option?client=${this.$store.state.selectedClient.sys.id}&type=${this.itemType}&locale=${this.selectedLocale}&skip=${this.offset}&limit=${this.limit + 1}`).then(async res => {
        this.serviceOptions = res.options ?? []
        this.optionCount = res.total
        this.isLoading = false
        this.isChangeDefault = false
      }).catch(error => {
        this.isLoading = false
        this.errorAlertVisible = true
        this.errorTitle = this.$t('text.ERROR')
      
        if (error.response) {
          this.errorDetail = error.response.data.error
        } else {
          this.errorDetail = error;
        }
      });
    },
    async getProviderOptions() {
      try {
        this.isLoading = true
        let url = `/provider-option?id=${this.$store.state.selectedServiceProvider.sys.id}&clientId=${this.$store.state.selectedClient.sys.id}&itemType=${this.itemType}&locale=${this.selectedLocale}&skip=${this.offset}&limit=${this.limit + 1}`
        
        let res = await this.$httpGet(url)
        this.serviceOptions = res.options ?? []

        this.optionCount = res.total
        this.isLoading = false
      }
      catch(error) {
         this.isLoading = false
        this.errorTitle = this.$t('text.ERROR');
        if (error.response) {
          this.errorDetail = error.response.data.error;
        }
        else {
          this.errorDetail = error;
        }
      }
    },
    changeDefault(option) {
      this.$refs.serviceEditOptionDialog.show = false
      this.isLoading = true

      if (!option.defaultAssignment) {
        option.defaultAssignment = false;
      }

      var data = {
        clientId: this.$store.state.selectedClient.sys.id,
        optionId: option.sys.id,
        defaultAssignment: option.defaultAssignment
      };

      this.$httpPost('/default-assignment', data).then(async res => { 
        this.isLoading = false
        this.$store.state.selectedClient.fields.defaultAssignments.de = res.client.fields.defaultAssignments.de
        
        if (this.searchString.length > 0) {
          this.search()
        } else {
          this.offset = 0
          this.searchString = ""

          this.getClientOptions()
        }
        
      }).catch(error => {
        this.isLoading = false;

        if (error.response && error.response.status === 401) {
          this.$emit("show-login")
        }

        this.errorTitle = this.$t('text.ERROR');

        if (error.response) {
          this.errorDetail = error.response.data.error;
        } else {
          this.errorDetail = error;
        }
        this.alertVisible = true;
      })
    },
    addOption() {
      this.isLoading = true;
      let data
      // client adds option
      if (this.isOperator) {
        data = {
          itemType: this.itemType,
          name: this.name,
          clientId: this.$store.state.selectedClient.sys.id
        }
      }
      // service provider adds option
      else {
         data = {
          itemType: this.itemType,
          name: this.name,
          serviceProviderId: this.$store.state.selectedServiceProvider.sys.id,
          clientId: this.$store.state.selectedClient.sys.id
        }
      }

      this.$httpPost(`/option`, data).then(res => {
        this.clear()

        this.$refs.serviceAddOptionDialog.show = false
        this.offset = 0

        if (this.isOperator) {
          this.itemType === 'ticketType' ? this.$store.state.selectedClient.fields.ticketTypes.de = this.serviceOptions : this.$store.state.selectedClient.fields.reductions.de = this.serviceOptions
        }
        else {
          this.itemType === 'ticketType' ? this.$store.state.selectedServiceProvider.fields.ticketTypes.de = this.serviceOptions : this.$store.state.selectedServiceProvider.fields.reductions.de = this.serviceOptions
        }
        
        this.getServiceProvider()
        this.sleep(2000).then(() => { this.isOperator ? this.getClientOptions() : this.getProviderOptions()})
      }).catch(error => {
        this.isLoading = false;
        this.errorAlertVisible = true;
        this.$refs.serviceAddOptionDialog.show = false;

        this.errorTitle = this.$t('text.ERROR');

        if (error?.message === 'You already have this option (client default)') {
          this.errorDetail = this.$t('text.optionAlreadyAssignedError')
        } else if (error?.message === 'This service option is already assigned to your profile') {
          this.errorDetail = this.$t('text.alreadyAssignedError')
        }  else {
          if (error.response) {
            if (error.response.data.error) {
              this.errorDetail = error.response.data.error;
            }
          } else {
            this.errorDetail = error.message;
          }
        }
      });
    },
    editOption() {
      this.isLoading = true;

      let data
        // client edits option
      if (this.isOperator) {
        data = {
          item: this.editItem,
          clientId: this.$store.state.selectedClient.sys.id
        };
      } 
        // service provider edits option
      else {
        data = {
           item: this.editItem,
           clientId: this.$store.state.selectedClient.sys.id,
           serviceProviderId: this.$store.state.selectedServiceProvider.sys.id
        }
      }

      this.$httpPut(`/option`, data).then(res => {
        this.clear()

        this.$refs.serviceEditOptionDialog.show = false
        this.offset = 0

        this.successTitle = this.$t('text.SERVICE_OPTION_UPDATED')
				this.successDetail = this.$t('text.serviceOptionUpdatedSuccess')
        
        if (this.isOperator) {
          this.itemType === 'ticketType' ? this.$store.state.selectedClient.fields.ticketTypes.de = this.serviceOptions : this.$store.state.selectedClient.fields.reductions.de = this.serviceOptions
        }
        else {
          this.itemType === 'ticketType' ? this.$store.state.selectedServiceProvider.fields.ticketTypes.de = this.serviceOptions : this.$store.state.selectedServiceProvider.fields.reductions.de = this.serviceOptions
        }

        this.getServiceProvider()

        this.sleep(2000).then(() => { this.isOperator ? this.getClientOptions() : this.getProviderOptions()})

        return true
      
      }).catch(error => {
        if (error.response?.status == 401) return this.$emit("show-login")
				this.showError(error.response ? error.response?.data.error : error)
      })
    },
    deleteOption() {
      this.isLoading = true

      this.axios.delete(`/option`, {
        headers: this.requestHeaders(),
         data: this.isOperator ? {
           option: this.originalItem,
           clientId: this.$store.state.selectedClient.sys.id
         } : {
           option: this.originalItem,
           clientId: this.$store.state.selectedClient.sys.id,
           serviceProviderId: this.$store.state.selectedServiceProvider.sys.id
        }
      }).then(res => {
        this.isLoading = false
        this.$refs.serviceEditOptionDialog.show = false
       
        if (res?.data?.message === 'cannot delete option') {
          this.errorTitle = this.$t('text.ERROR')
          this.errorDetail = this.$t('text.serviceOptionInUseError')
        } else {
          this.successTitle = this.$t('text.OPTION_REQUEST')
          this.successDetail = this.$t('text.optionRequestDeleteSuccess')
          if (this.isOperator) {
            // Do we need to commit here? this.$store.commit('setSelectedClient', await this.$store.commit('setSelectedServiceProvider ?? - we only have the serviceOptions and not the entire client/sp ?
            this.itemType === 'ticketType' ? this.$store.state.selectedClient.fields.ticketTypes.de = this.serviceOptions : this.$store.state.selectedClient.fields.reductions.de = this.serviceOptions
          }
          else {
            this.itemType === 'ticketType' ? this.$store.state.selectedServiceProvider.fields.ticketTypes.de = this.serviceOptions : this.$store.state.selectedServiceProvider.fields.reductions.de = this.serviceOptions
          }

          this.getServiceProvider()
          this.isOperator ? this.getClientOptions() : this.getProviderOptions()
        }

      }).catch(error => {
        if (error.response?.status == 401) return this.$emit("show-login")
				this.showError(error.response ? error.response?.data.error : error)
      });
    },
    async getServiceProvider() {
			var id = this.$store.state.selectedServiceProvider?.sys?.id
			if (!id) return

			try {
				const res = await this.$httpGet(`/serviceprovider/${id}`)
				
				await this.$store.commit('setSelectedServiceProvider', res.serviceProvider)
			}
			catch (error) {
				if (error.response?.status === 401) return this.$emit("show-login")
				console.error(error)
				this.showError(error.response ? error.response.data.error : error)
			}
			this.loading = false
		},
    async search() {
      if (this.state === "serviceProviders") {
        this.offset = 0
        if (this.searchString && this.searchString !== '') {
          this.searchSubmitted = true
          this.getSearchedServiceProviders()
        }
        else {
          this.searchSubmitted = false
          this.searchString = ''
          this.getServiceProviders()
        }
      } else if (this.isOperator) {
        let url = `/search-options?clientId=${this.$store.state.selectedClient.sys.id}`
        url += `&type=${this.itemType}`
        url += `&locale=${this.selectedLocale}`
        url += `&skip=${this.offset}`
        url += `&limit=${this.limit + 1}`
        url += `&searchString=${encodeURIComponent(this.searchString)}`
        
        let res = await this.$httpGet(url)

        this.serviceOptions = res?.options
			  this.optionCount = res?.total ? res.total : 0
      }
      // get options for service provider
      else {
        let url = `/search-options?serviceProviderId=${this.$store.state.selectedServiceProvider.sys.id}`
        url += `&type=${this.itemType}`
        url += `&locale=${this.selectedLocale}`
        url += `&searchString=${encodeURIComponent(this.searchString)}`

        let res = await this.$httpGet(url)

        this.serviceOptions = res?.options
			  this.optionCount = res?.total ? res.total : 0
      }
			
    },
    clearSearch() {
      this.searchString = ""
      this.offset = 0

      if (this.state === "serviceProviders") {
        this.searchSubmitted = false
        this.getServiceProviders()
      } else if (this.isOperator) {
        this.getClientOptions()
      }
      //get options for service provider
      else {
        this.getProviderOptions()
      }
    },
    showServices() {
      this.$router.push("/services")
    },
    showFAQs() {
      this.$router.push("/faq-list")
    },
    sleep(ms) {
			return new Promise(resolve => setTimeout(resolve, ms));
		}
  },
};
</script>


<style scoped>
.tableCard {
  border-radius: 5px;
  background-color:#808080 !important;
  top: 0;
}
.clientOption {
  cursor: not-allowed;
  background-color: #f2f2f2 !important;
}
.dialogDiv {
  position:absolute;
  right:70px;
  top:55px;
  width:465px;
  max-width: calc(100vw - 20px);
  background:#ffffff;
  border-radius:5px;
  border:thin solid #cfcfcf;
  box-shadow:1px 1px 15px 0 rgba(0,0,0,0.2);
  padding:20px;
  padding-bottom:0px;
  color:#acacac;
  z-index: 999;
}
.optionRow {
  min-height: 42px;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #f2f2f2;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>