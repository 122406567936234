<template>
  <v-row justify="center">
    <Disclosure :title="$t('text.generalInfoTitle')" :error="sectionMessage.error" :message="sectionMessage.message"
                :lock="!userMayEditFields" data-cy="generalInfo" ref="SECTION_generalInfo">
      <div
          v-if="(hasField('generalInfo','topProduct', model) || hasField('generalInfo','freeProduct', model)) && userIsOperator"
          class="field left-border">
        <v-label v-if="hasField('generalInfo','topProduct', model)">{{ $t('text.topProduct') }}</v-label>
        <v-row v-if="hasField('generalInfo','topProduct', model)" class="mb-3 mt-3">
          <v-radio-group v-model="model.fields.topProduct.de" row>
            <v-radio :label="$t('text.radioOptionYes')" :value="true" @change="toggleTop()"></v-radio>
            <v-radio :label="$t('text.radioOptionNo')" :value="false" @change="toggleTop()"></v-radio>
          </v-radio-group>
        </v-row>
        <v-label v-if="hasField('generalInfo','freeProduct', model)">{{ $t('text.freeProduct') }}</v-label>
        <v-row v-if="hasField('generalInfo','freeProduct', model)" class="mb-3 mt-3">
          <v-radio-group v-model="model.fields.freeProduct.de" row>
            <v-radio :label="$t('text.radioOptionYes')" :value="true" @change="toggleFree()"></v-radio>
            <v-radio :label="$t('text.radioOptionNo')" :value="false" @change="toggleFree()"></v-radio>
          </v-radio-group>
        </v-row>
        <p class="helpText" v-html="$t('text.productFlagHelp')"/>
      </div>
      <div v-if="hasField('generalInfo','images', model)" class="field left-border">
        <v-label>{{ $t('text.images') }} <span class="error-text">({{ $t('text.required') }})</span></v-label>
        <v-row class="mb-3 mt-3">
          <v-card width="100px" height="100px" flat
                  style="border-radius:10px;border:1px solid rgba(0,0,0,.2);background-color:#ffffff;margin-right:10px"
                  @click="$refs.mediaViewer.show===true ? $refs.mediaViewer.show=false : $refs.mediaViewer.show=true"
                  data-cy="addImage">
            <v-row justify="center" align="center" style="height: 100%">
              <v-icon size="48px" color="#9da0a5">mdi-plus</v-icon>
            </v-row>
          </v-card>
          <v-row v-if="model.fields.images">
            <draggable v-model="model.fields.images.de" group="model.fields.images.de" @start="drag=true"
                       @end="drag=false" style="display:flex;flex-direction:row;flex-wrap:wrap;">
              <v-card v-for="image in model.fields.images.de" :key="image.id" draggable
                      class="infoImageWrapper mr-2 mt-1" width="100px" height="100px" flat>
                <img :src="imgSource(image)" width="100px" height="100px">
                <v-icon class="removeImg"
                        @click="image.imageURL ? removeImage(image.fields.media ? (image.fields.media.de.fields.file.de ? image.fields.media.de.fields.file.de.url : image.fields.media.de.fields.file.url ? image.fields.media.de.fields.file.url.de : image) : (image.fields.file.de.url ? image.fields.file.de.url : image)) : removeImage(image)">
                  mdi-close-circle
                </v-icon>
              </v-card>
            </draggable>
          </v-row>
        </v-row>
        <p v-if="imageErrors.length" class="error-text">{{ imageErrors[0] }}</p>
        <p class="helpText" v-html="$t('text.imagesDesc')"/>
      </div>
      <div v-if="hasField('generalInfo','faqGroup', model)" class="field left-border">
        <v-label>{{ $t('text.faq') }}</v-label>
        <v-select outlined dense return-object
                  v-model="model.fields.faqGroup.de"
                  :items="faqGroups"
                  :item-text="item => item && item.fields && item.fields.title ? item.fields.title[selectedLocale] : ''"
        />
        <p class="helpText" v-html="$t('text.faqDesc')"/>
      </div>
      <div v-if="hasField('generalInfo','averageDurationOfStay', model)" class="field left-border">
        <v-label>{{ $t('text.averageDurationOfStayInMinutes') }}</v-label>
        <v-text-field outlined dense hide-details v-mask="'####'"
                      v-model="model.fields.averageDurationOfStayInMinutes.de"
                      style="width:50%;margin-bottom:12px;padding-right:20px"
        />
        <p class="helpText" v-html="$t('text.averageDurationOfStayInMinutesDesc')"/>
      </div>
    </Disclosure>
    <MediaViewer ref="mediaViewer" @add-media="addMedia"/>
  </v-row>
</template>

<script>
import Common from '@/mixins/Common'
import Disclosure from '@/components/common/Disclosure'
import draggable from 'vuedraggable'
import MediaViewer from '@/components/media/MediaViewer'
import { isEqual } from 'lodash'

export default {
  name: "GeneralInfo",
  components: {
    Disclosure,
    draggable,
    MediaViewer
  },
  mixins: [Common],
  props: {
    product: Object,
    updateModel: Boolean,
  },
  data() {
    return {
      model: {},
      initData: {},
      sectionMessage: {
        error: false,
        message: ''
      },
      imageErrors: [],
      faqGroups: [],
    }
  },

  watch: {
    imageErrors(n) {
      if (n.length > 0) {
        this.setSectionError(this.sectionMessage, this.$t('text.missingFieldsError'))
      } else {
        this.resetSectionError(this.sectionMessage)
      }
    },
    updateModel() {
      this.model = this.valueToModel(this.product)
      this.setInitData()
    },
  },

  created() {
    this.model = this.valueToModel(this.product)
    this.setInitData()
  },

  mounted() {
    this.getFAQs()
  },

  methods: {
    valueToModel(v) {
      return JSON.parse(JSON.stringify(v ?? {}))
    },
    setInitData() {
      const initModel = JSON.parse(JSON.stringify(this.model))
      if (this.hasField('generalInfo','topProduct', this.model)) {
        this.initData.topProduct = initModel.fields.topProduct
      }
      if (this.hasField('generalInfo','freeProduct', this.model)) {
        this.initData.freeProduct = initModel.fields.freeProduct
      }
      if (this.hasField('generalInfo','images', this.model)) {
        this.initData.images = initModel.fields.images
      }
      if (this.hasField('generalInfo','averageDurationOfStay', this.model)) {
        this.initData.averageDurationOfStayInMinutes = initModel.fields.averageDurationOfStayInMinutes
      }
      if (this.hasField('generalInfo','faqGroup', this.model)) {
        this.initData.faqGroup = initModel.fields.faqGroup
      }
    },
    sendData() {
      let data = {}

      if (this.hasField('generalInfo','topProduct', this.model)) {
        data.topProduct = this.model.fields.topProduct
      }
      if (this.hasField('generalInfo','freeProduct', this.model)) {
        data.freeProduct = this.model.fields.freeProduct
      }
      if (this.hasField('generalInfo','images', this.model)) {
        data.images = this.model.fields.images
      }
      if (this.hasField('generalInfo','averageDurationOfStay', this.model)) {
        data.averageDurationOfStayInMinutes = this.model.fields.averageDurationOfStayInMinutes
      }
      if (this.hasField('generalInfo','faqGroup', this.model)) {
        data.faqGroup = this.model.fields.faqGroup
      }

      data.changed = !isEqual(data, this.initData)
      
      return data
    },
    validateAllFields() {
      let allFieldsAreValid = true
      this.resetSectionError(this.sectionMessage)

      if (!this.validateImage()) {
        allFieldsAreValid = false
        this.setSectionError(this.sectionMessage, this.$t('text.missingFieldsError'))
      }

      return allFieldsAreValid
    },
    validateImage() {
      let isValid = true
      this.imageErrors = []
      if (this.model.fields.images?.de?.length === 0) {
        isValid = false
        this.imageErrors.push(this.$t('text.missingFieldsError'))
      }

      return isValid
    },
    
    toggleFree() {
      this.model.fields.freeProduct.de = true
      this.model.fields.topProduct.de = false
    },
    toggleTop() {
      this.model.fields.topProduct.de = true
      this.model.fields.freeProduct.de = false
    },
    addMedia() {
      if (this.$refs.mediaViewer.$data.selectedMedia) {
        let selectedMedia = this.$refs.mediaViewer.$data.selectedMedia

        if (selectedMedia && selectedMedia.length > 0) {
          for (var media of selectedMedia) {
            const imageIndex = this.model.fields.images.de.findIndex(x => x.sys.id === media.sys.id)
            if (imageIndex === -1) {
              this.model.fields.images.de.push(media)
            }
          }
        }

        this.$refs.mediaViewer.show = false
        this.validateImage()
      }
    },
    removeImage(imageUrl) {
      if (this.model.fields.images && this.model.fields.images.de.length > 0) {
        for (var i = 0; i < this.model.fields.images.de.length; i++) {
          //check if new or update
          if (this.model.fields.images.de[i].imageURL) {
            if (this.model.fields.images.de[i].imageURL === imageUrl) {
              this.model.fields.images.de.splice(i, 1);
            }
          } else if (this.model.fields.images.de[i] === imageUrl) {
            this.model.fields.images.de.splice(i, 1);
          }
        }
      }
      this.validateImage()
    },
    imgSource(image) {
      if (image.fields?.media?.de?.fields?.file?.de?.url) {
        return image.fields.media.de.fields.file.de.url
      } else if (image.fields?.media?.de?.fields?.file?.url) {
        return image.fields.media.de.fields.file.url
      } else if (image.fields?.file?.de?.url) {
        return image.fields.file.de.url
      } else if (image.fields?.file?.url?.de) {
        return image.fields.file.de.url.de
      } else {
        return image
      }
    },
    
    async getFAQs() {
      try {
        let res = await this.$httpGet(`/faqs?serviceProviderId=${this.$store.state.selectedServiceProvider.sys.id}`)
        this.faqGroups = res.faqGroups

        if (this.faqGroups) {
          this.faqGroups.sort(this.compare)
        }

        //add an empty object to the top to make possible a deselect for v-select as v-select does not have a built-in way to select a blank option
        this.faqGroups.unshift({})
      } catch (error) {
        this.showError(error)
      }
    },
  }
}
</script>

<style scoped>
.info {
  background-color: #00aeef;
  color: #ffffff;
  border-radius: 5px;
  padding: 10px;
  font-weight: bold;
}

.infoImageWrapper {
  border-radius: 10px;
  background-color: #ffffff;
}

.infoImageWrapper img {
  border-radius: 10px !important;
}

.infoImageWrapper .removeImg {
  position: absolute;
  right: 2px;
  top: 2px;
}

.infoImageWrapper:hover .removeImg {
  color: #000;
}

.infoImageWrapper:hover .removeImg:active {
  color: #5a5a5a;
}

.serviceLocale {
  position: absolute;
  z-index: 10;
  margin-top: 32px;
  margin-left: -32px;
  zoom: 0.8;
}

</style>