<template>
	<div class="ClientAssignmentEditor">
		<div v-if="model && model.fields && client">
			<v-label v-if="client.fields.salesChannels">{{$t('text.salesChannels')}}</v-label>
			<SalesChannelAssignmentsField 
				v-if="client.fields.salesChannels"
				v-model="model.fields.salesChannelAssignments.de"
				:items="client.fields.salesChannels.de"
			/>

			<div style="margin-top: 20px;">
				<ResellerCategorySelector 
					v-if="userIsOperator && hasResellerChannel"
					v-model="model.fields.salesChannelAssignments.de"
					:items="resellerCategories"
				/>
			</div>

			<div style="display: flex; margin-top: 20px;">
				<Status
					v-if="model.fields.status && (!model.addl || !model.addl.isNew)"
					:status="model.fields.status.de"
				/>
				<div v-if="model.fields.isHomebase && model.fields.isHomebase.de" style="margin-left: 10px;">
					<v-icon size="42px">mdi-home-city-outline</v-icon>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SalesChannelAssignmentsField from './SalesChannelAssignmentsField.vue'
import ResellerCategorySelector from './ResellerCategorySelector.vue'
import Status from '@/components/common/Status.vue'
import Common from '@/mixins/Common.vue'

export default {
	name: 'ClientAssignmentEditor',
	components: { SalesChannelAssignmentsField, Status, ResellerCategorySelector },
	mixins: [ Common ],
	props: {
		value: Object, // CfObject<clientAssignment>
		client: Object,
	},
	data() {
		return {
		model: {},
		}},
	computed: {
		hasResellerChannel() {
			return this.client.fields.salesChannels.de.find(x => x.fields.isResellerChannel?.de === true) ? true : false
		},
		resellerCategories() {
			return this.client.fields.salesChannels.de.find(x => x.fields.resellerCategories?.de?.length > 0)?.fields?.resellerCategories?.de
		}
	},
	watch: {
		value(n) { this.model = n },
		model(n) { this.$emit('input', n) }
	},
	mounted() {
		this.model = this.value
	},
}
</script>

<style scoped>
.ClientAssignmentEditor { padding: 15px; }
</style>