<template>
	<div>
		<v-btn elevation="0" style="color:blue;background-color:transparent" @click="geocode(address)" v-if="addressString">
      <v-icon size="14" left>mdi-crosshairs</v-icon>
      {{$t('text.find', {address: addressString})}}
    </v-btn>

		<div class="mapWrap">
			<div ref="map" class="map" />
			<div class="crosshair" />
		</div>
		<div style="display: flex; width: 100%; padding-top:15px">
			<div style="width: 50%">
				<v-icon v-if="disabled" size="24px" color="gray">mdi-lock-outline</v-icon> 
				{{ $t('text.placeholderLatitude') }}
				<v-text-field type="number" outlined
					style="padding-right:10px;"
					:placeholder="$t('text.placeholderLatitude')"
					:error-messages="latitudeError"
					:hide-details="latitudeError.length===0"
					v-model="value.lat"
					@focus="$emit('focus', $event)"
					@keyup="centerMap(); isLatitude($event)"
					@blur="centerMap(); isLatitude($event)" 
					@keypress="isDecimal(value.lat, $event)"
					@paste="forcePasteToDecimal($event)"
					:disabled="disabled"
					:data-cy="dataCy + '-latitude'" 
					id="latitude"
				/>
			</div>
			<div style="width: 50%">
				<v-icon v-if="disabled" size="24px" color="gray">mdi-lock-outline</v-icon> 
				{{ $t('text.placeholderLongitude') }}
				<v-text-field type="number" outlined
					style="padding-bottom:10px"
					:placeholder="$t('text.placeholderLongitude')"
					:hide-details="longitudeError.length===0"
					:error-messages="longitudeError"
					v-model="value.lon"
					@focus="$emit('focus', $event)"
					@keyup="centerMap(); isLongitude($event)"
					@blur="centerMap(); isLongitude($event)"
					@keypress="isDecimal(value.lat, $event)"
					@paste="forcePasteToDecimal($event)"
					:disabled="disabled"
					:data-cy="dataCy + '-longitude'"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Common from '@/mixins/Common.vue'

// TODO: key from env
const API_KEY = 'AIzaSyAxeE-DWlZZrrvf8lZRjEimC2iJfDKjviA'
let initialized = !!window.google
let resolveInitPromise
let rejectInitPromise

const initPromise = new Promise((resolve, reject) => {
	resolveInitPromise = resolve
	rejectInitPromise = reject
})

function gmapsInit() {
	if (initialized) return initPromise
	initialized = true
	window.initMap = () => resolveInitPromise(window.google)

	const script = document.createElement('script')
	script.async = true
	script.defer = true
	script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&callback=initMap`
	script.onerror = rejectInitPromise
	document.querySelector('head').appendChild(script)
	return initPromise
}

export default {
	name: 'LocationField',
	mixins: [ Common ],
	props: {
		value: Object,
		disabled: Boolean,
		address: Object,
		dataCy: { type: String, default: '' },
	},
	data() {
		return {
		latitudeError: '',
		longitudeError: '',
		errorDetail: null,
		map: null,
		geocoder: null,
		google: null,
		timeout: null,
	}},
	watch: {
		addressString(v, old) {
			// if we have already geocoded once, we dont do it again automatically
			if (old != '') return
			if (this.value.lat && this.value.lon) return
			if (this.timeout) window.clearTimeout(this.timeout)
			this.timeout = window.setTimeout(() => this.geocode(this.addressString), 1000)
			this.geocode(this.addressString)
		},
	},
	computed: {
		addressString() {
			return (this.address.fields.streetAddress.de + ' '
				+ this.address.fields.zipCode.de + ' '
				+ this.address.fields.city.de + ' '
				+ (this.address.fields.country?.de ?? '')).trim()
		}
	},
	methods: {
		forcePasteToDecimal(evt) {
			evt = (evt) ? evt : window.event
			let pastedData = evt.clipboardData.getData('text')
			if (pastedData?.length > 0) {
				pastedData = pastedData.replace(/[^\d.-]/g, '')
				evt.preventDefault()
				evt.target.value = pastedData
				evt.target.id === 'latitude' ? this.value.lat = pastedData : this.value.lon = pastedData
			}
		},
		isLatitude(evt) {
			this.latitudeError = ""
			const stringValue = evt.target.value.toString()
			if (stringValue == '0')
				this.latitudeError = this.$t('text.latitudeError')
			var decimalLength = (stringValue.substring(stringValue.indexOf("."), stringValue.length)).length
			if (decimalLength > 16) 
				this.latitudeError = this.$t('text.locationDecimalError')
			const n = Number(stringValue)
			if (n < -90 || n > 90)
				this.latitudeError = this.$t('text.latitudeError')
		},
		isLongitude(evt) {
			this.longitudeError = ""
			const stringValue = evt.target.value.toString()
			if (stringValue == '0')
				this.longitudeError = this.$t('text.longitudeError')
			var decimalLength = (stringValue.substring(stringValue.indexOf("."), stringValue.length)).length
			if (decimalLength > 16)
				this.longitudeError = this.$t('text.locationDecimalError')
			const n = Number(stringValue)
			if (n < -180 || n > 180)
				this.longitudeError = this.$t('text.longitudeError')
		},
		async geocode(address) {
			if (!address) {
				const google = this.google
				if (this.value.lat && this.value.lon) {
					this.map.setCenter(new google.maps.LatLng(this.value.lat, this.value.lon))
					this.map.setZoom(6)
					return
				}
				this.map.setCenter(new google.maps.LatLng(47, 11))
				this.map.setZoom(3)
				this.value.lat = ''
				this.value.lon = ''
				return
			}
			this.geocoder?.geocode({ address: typeof address !== 'string' ? this.addressString : address }, (results) => {
				if (results?.length > 0) {
					this.map.setCenter(results[0].geometry.location)
					this.map.fitBounds(results[0].geometry.viewport)
				}
			})
		},
		// called on manual coordinate input
		centerMap() {
			//These consts are necessary because if the lat/lon values end in a zero, the map.setCenter function strips the trailing zero
			//This causes issues when users manually enter lat/lon and the zeroes disappear while they're typing
			const persistLatVal = this.value.lat
			const persistLonVal = this.value.lon

			this.map.setCenter({ lat: Number(this.value.lat), lng: Number(this.value.lon) })

			this.value.lat = persistLatVal
			this.value.lon = persistLonVal
		},
	},
	// ATT: this is not according to Vue canon. we need to call this statically on the BusinessProfile
	static: {
		validate(value) {
			var latitude = value.lat.toString() ?? ""
			var longitude = value.lon.toString() ?? ""
			var decimalLength = 0
			if (latitude == '0') return 'text.latitudeError'
			if (longitude == '0') return 'text.longitudeError'
			const latitudeRegex = /^(\+|-)?(?:90(?:(?:[.,]0{1,20})?)|(?:[0-9]|[1-8][0-9])(?:(?:[.,][0-9]{1,20})?))$/
			const longitudeRegex = /^(\+|-)?(?:180(?:(?:[.,]0{1,20})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:[.,][0-9]{1,20})?))$/
			if (latitude && !latitude.match(latitudeRegex)) {
				decimalLength = (latitude.substring(latitude.replace(',', '.').indexOf("."), latitude.length)).length
				if (decimalLength > 20)
					return 'text.locationDecimalError'
				else
					return 'text.latitudeError'
			}
			else if (longitude && !longitude.match(longitudeRegex)) {
				decimalLength = (longitude.substring(longitude.replace(',', '.').indexOf("."), longitude.length)).length
				if (decimalLength > 20)
					return 'text.locationDecimalError'
				else
					return 'text.longitudeError'
			}
			return null
		},
	},
	async mounted() {
		try {
			const google = await gmapsInit()
			this.google = google
			this.geocoder = new google.maps.Geocoder()
			this.map = new google.maps.Map(this.$refs.map)
			this.map.addListener("center_changed", () => {
				const pos = this.map.getCenter()
				this.value.lat = (pos.lat() + '').substring(0, 16)
				this.value.lon = (pos.lng() + '').substring(0, 16)
			})
			this.map.setZoom(12)
			if (!this.value.lat || !this.value.lon)
				this.geocode(this.addressString)
			else
				this.centerMap()
		}
		catch (e) {
			console.error(e)
		}
	},
}
</script>

<style scoped>
div { color: gray; }
.mapWrap { position: relative; }
.map { height: 400px }
.crosshair { position: absolute; width: 1px; height: 60px; background: #000; top: 50%; left: 50%; transform: translate(-50%, -50%); pointer-events: none; }
.crosshair::before { content: ""; position: absolute; width: 60px; height: 1px; background: #000; top: 50%; left: 50%; transform: translate(-50%, -50%); }
</style>