<template>
	<v-row justify="center">
		<Disclosure :title="$t('text.contactPersonInfoTitle')" :error="sectionMessage.error" :message="sectionMessage.message" :lock="!userMayEditFields" data-cy="contactPersonInfo" ref="SECTION_contactPersonInfo">
			<p class="subTitle" v-html="$t('text.contactPersonSubTitle')"/>
			<div class="field left-border">
				<v-label>{{$t('text.contactPersonName')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
				<v-text-field outlined required
					:hide-details="contactPersonNameErrors.length===0"
					:error-messages="contactPersonNameErrors"
					@input="$v.contactInfoName.fields.contactDetail.de.$touch()"
					@blur="$v.contactInfoName.fields.contactDetail.de.$touch()"
					v-model.trim="contactInfoName.fields.contactDetail.de"
				/>
				<p class="helpText" v-html="$t('text.contactPersonNameDesc')"/>
			</div>
			<div class="field left-border">
				<v-label>{{$t('text.contactPersonEmail')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
				<v-text-field outlined required
					:hide-details="contactPersonEmailErrors.length===0"
					:error-messages="contactPersonEmailErrors"
					@input="$v.model.fields.contactPersonEmail.de.$touch()"
					@blur="$v.model.fields.contactPersonEmail.de.$touch()"
					v-model="model.fields.contactPersonEmail.de"
				/>
			</div>
			<div class="field left-border">
				<v-label>{{$t('text.contactPersonPhone', { format: phoneFormat})}}</v-label>
				<v-text-field outlined required
					placeholder=""
					hide-details
					v-model="model.fields.contactPersonPhone.de"
				/>
			</div>
			<div class="field left-border" v-if="userLoginEmail">
				<v-label>{{$t('text.loginInformation')}}</v-label>
				<v-text-field outlined readonly hide-details
					v-model="userLoginEmail"
				/>
				<p class="helpText" v-html="$t('text.loginInformationDesc')"/>
			</div>

			<div class="field left-border" data-cy="notificationMailLanguageDiv" >
				<v-label>{{$t('text.notificationLanguage')}}</v-label>
				<v-select outlined required
					v-model="model.fields.language.de"
					:items="languages"
					:item-text="language => $t('text.' + language.name)"
					item-value="code"
					data-cy="notificationMailLanguage"
				/>
				<p class="helpText" v-html="$t('text.notificationLanguageHelp')"/>
			</div>
		</Disclosure>
	</v-row>

</template>

<script>
	import Common from '@/mixins/Common'
	import Disclosure from '@/components/common/Disclosure'
	import {eventBus} from "@/main"
	import { required, minLength, maxLength, email, sameAs } from "vuelidate/lib/validators"
	import { isEqual } from 'lodash'
	
	export default {
	  name: "ContactPersonInfo",
	  components: {
		Disclosure
	  },
	  mixins: [Common],
	 
	  props: {
		serviceProvider: {
		  type: Object,
		  required: true
	  	},
		updateModel: Boolean
	  },
	  validations: {
		model: {
			fields: {
				contactPersonEmail: {
					de: { required, email, minLength: minLength(2), maxLength: maxLength(256) }
				},
			},
		},
		contactInfoName: {
			fields: {
				contactDetail: {
					de: { required, minLength: minLength(2), maxLength: maxLength(128) }
				}
			}
		},
	  },
	  data() {
		return {
		  model: {},
		  initData: {},
		  sectionMessage: {
			error: false,
			message: ''
		  },
		  userLoginEmail: '',
		  languages: [],
		  contactInfoName: {sys: {id:'id_1'}, fields:{contactInfoType: {de:{fields:{type:{de:'Name'}}}},contactDetail:{de:''}}},contactInfoName: {sys: {id:'id_1'}, fields:{contactInfoType: {de:{fields:{type:{de:'Name'}}}},contactDetail:{de:''}}},
		}
	  },
	  computed: {
		contactPersonNameErrors () {
			let errors = []
			if (!this.$v.contactInfoName.fields.contactDetail.de.$dirty) return errors
			!this.$v.contactInfoName.fields.contactDetail.de.required && errors.push(this.$t('text.nameRequired'))
			!this.$v.contactInfoName.fields.contactDetail.de.minLength && errors.push(this.$t('text.nameMinChars'))
			!this.$v.contactInfoName.fields.contactDetail.de.maxLength && errors.push(this.$t('text.nameMaxChars'))
			return errors
		},


		contactPersonEmailErrors () {
			let errors = []
			if (this.$v.model.fields) {
				if (!this.$v.model.fields.contactPersonEmail.de.$dirty) return errors
				!this.$v.model.fields.contactPersonEmail.de.email && errors.push(this.$t('text.emailInvalid'))
				!this.$v.model.fields.contactPersonEmail.de.required && errors.push(this.$t('text.emailRequired'))
				!this.$v.model.fields.contactPersonEmail.de.minLength && errors.push(this.$t('text.emailMinChars'))
				!this.$v.model.fields.contactPersonEmail.de.maxLength && errors.push(this.$t('text.emailMaxChars'))
			}
			return errors
		},
	  },

	  watch: {
		updateModel() {
			this.model = this.valueToModel(this.serviceProvider)
			this.setContactPersonInfoDetails()
			this.setInitData()
		},
	  },

	created() {
		this.model = this.valueToModel(this.serviceProvider)
		this.setContactPersonInfoDetails()
		this.setInitData()
	  },
	  mounted() {
		// assign locale codes and locale names to this.languages
		this.languages = this.$store.state.locales.map(locale => ({
					code: locale.code,
					name: locale.name
				}))
	  },

	  methods: {
		valueToModel(v) {
      		return JSON.parse(JSON.stringify(v ?? {}))
    	},
		setInitData() {
			const initModel = JSON.parse(JSON.stringify(this.model))
			this.initData = {
				contactPersonEmail: initModel.fields.contactPersonEmail,
				contactPersonPhone: initModel.fields.contactPersonPhone,
				language: initModel.fields.language,
				contactInfoName: JSON.parse(JSON.stringify(this.contactInfoName))
			}
		},
		sendData() {
			eventBus.$emit('contact-info-name-changed', this.contactInfoName)
			const data = {
				contactPersonEmail: this.model.fields.contactPersonEmail,
				contactPersonPhone: this.model.fields.contactPersonPhone,
				language: this.model.fields.language,
				contactInfoName: this.contactInfoName
			}

			data.changed = !isEqual(data, this.initData)

			return data
		},
		validateAllFields() {
			let allFieldsAreValid = true
			this.resetSectionError(this.sectionMessage)

			this.$v.contactInfoName.fields.contactDetail.de.$touch()
			this.$v.model.fields.contactPersonEmail.de.$touch()

			if (this.contactPersonNameErrors.length ||
				this.contactPersonEmailErrors.length) {

				allFieldsAreValid = false
				this.setSectionError(this.sectionMessage, this.$t('text.missingFieldsError'))
			}
			return allFieldsAreValid
		},
		
		setContactPersonInfoDetails() {
			if (this.$store.state.selectedServiceProvider?.sys?.id) {
				this.userLoginEmail = this.$store.state.loggedInUser.fields.email.de
				if (this.$store.state.loggedInUser.sys.id !== this.$store.state.selectedServiceProvider.sys.id) {
					this.userLoginEmail = this.$store.state.selectedServiceProvider.fields?.mainUserAccount?.email
				}
				else {
					// main user account stuff
				}
			}
			if (this.model.fields.mainUserAccount?.fields?.email) {
				this.userLoginEmail = this.model.fields.mainUserAccount.fields.email.de
			}

			const sp = this.model.fields

			for (let contactInfo of this.model.fields.contactInfos?.de ?? []) {
					const type = contactInfo.fields.contactInfoType.de.fields.type.de
					if (type === "Name") {
						this.contactInfoName = contactInfo ?? { de: '' }
					} 
				}

			// We need a reference from internal data to the model data
			if (sp.contactInfos) {
				sp.contactInfos.de = [
					this.contactInfoName
				]
			}
		},
	  }
	}
	</script>
	
	<style scoped>
	
	</style>
	
	