<template>
  <div ref="top" class="row">
    <div class="flex-column items-section" style="width:50%" v-for="(selectedItems, index) in selectedItemsPath" :key="'si-' + index">
      <div v-if="index > 0" class="parentItem"><span class="parentItemTitle">{{selectedParent.fields.title[selectedLocale]}}</span></div>

      <v-list-item v-for="item in selectedItems" :key="item.id" :class="{'item-row': true, 'highlited': item.sys.highlited}">
        <v-list-item-content @click="selectItem(item, index, selectedItems)">

            <v-row>
              <v-col cols="2" class="pr-0">
                <v-checkbox
                    v-model="item.sys.selected"
                    @change="item.sys.selected ? selectParent(items, item, index, selectedItems) : unselectChild(item)"
                    color="secondary"
                    hide-details
                    class="shrink mr-2 mt-0"
                ></v-checkbox>
              </v-col>
              <v-col cols="9" class="pl-0">
                <v-list-item-title>
                  {{ !item.fields ? 'NO_TITLE' : item.fields.title[selectedLocale] ? item.fields.title[selectedLocale] : item.fields.title.de }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="item.fields && item.fields[subLevelName] && item.fields[subLevelName].de.length > 0">{{item.fields[subLevelName].de.length}} {{$t('text.subCategoriesAvailable')}}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="!item.fields || !item.fields[subLevelName] || item.fields[subLevelName].de.length === 0"></v-list-item-subtitle>
              </v-col>
            </v-row>
        </v-list-item-content>
      </v-list-item>
    </div>
  </div>
</template>

<script>
import Common from '@/mixins/Common.vue'

export default {
  name: "InheritedSelector",
  
  mixins: [ Common ],

  props: {
    items: {
      type: Array,
      required: true
    },
    selectedItems: {
      type: Array
    },
    subLevelName: {
      type: String
    }
  },

  data() {
    return {
      selectedCategories: [],
      selectedItemsPath: [],
      selectedParent: {}
    }
  },

  methods: {
    async selectItem(item, key, selectedItems) {
      this.selectedItemsPath = this.selectedItemsPath.slice(0, ++key)

      if (item.sys.selected && item.fields?.[this.subLevelName]?.de?.length > 0) {
        this.selectedParent = item
        this.selectedItemsPath.push(item.fields[this.subLevelName].de)
        this.$refs.top.scrollIntoView({ behavior: 'smooth'})
      } else if (key === 1 && this.selectedItemsPath.length > 0) {
        // Show sublist from other selected parent to avoid an empty sublist when a parent is unchecked
        for (const parentItem of this.selectedItemsPath?.[0]) {
          if (parentItem.sys.selected) {
            this.selectedParent = parentItem
            this.selectedItemsPath.push(parentItem.fields?.[this.subLevelName].de)
            break
          }
        }
      }
    },

    highlighting(selectedItems, item) {
      for (const selectedItem of selectedItems) {
        selectedItem.sys.highlited = false;

        if (selectedItem.fields?.[this.subLevelName]) {
          this.highlighting(selectedItem.fields[this.subLevelName].de)
        }
      }

      if (item) {
        item.sys.highlited = true;
      }

      if (item?.fields?.selected) {
        item.sys.highlited = true;
        if (!item.fields[this.subLevelName]) {
          this.$forceUpdate();
        }
      }
    },

    async selectParent(items, itemInit, index, selectedItems) {
      for (const item of items) {
        if (item.sys.highlited) {
          item.sys.selected = true;
        }

        if (item.fields?.[this.subLevelName] && !itemInit && !index && !selectedItems) {
          this.selectParent(item.fields[this.subLevelName].de)
        }
      }

      this.$forceUpdate();
    },

    unselectChild(items) {
      if (items.fields[this.subLevelName]) {
        for (const item of items.fields[this.subLevelName].de) {
          if (item.fields?.[this.subLevelName]) {
            this.unselectChild(item)
          }

          if (item.sys.selected) {
            item.sys.selected = false;
            this.$forceUpdate();
          }
        }
      }
    },

    initialSelect(items, selectedItems) {
      for (const item of items) {
        if (selectedItems.some(el => el.sys.id === item.sys.id)) {
          item.sys.selected = true;
        }

        if (item.fields?.[this.subLevelName] && selectedItems) {
          this.initialSelect(item.fields[this.subLevelName].de, selectedItems);
        }
      }
      this.selectParent(items);
    }
  },

  mounted() {
    if (this.selectedItems.length && this.items) {
      this.initialSelect(this.items, this.selectedItems);
    }

    this.selectedItemsPath.push(this.items)
  },

  beforeDestroy() {
    this.selectedItemsPath = [];
  }
}
</script>

<style scoped lang="scss">
.selector-wrapper {
  margin: -12px !important;
  cursor: pointer;
  flex: 1 0 auto;
  overflow-x: hidden !important;
}
.items-section {
  border-right: 1px solid rgba(0,0,0,.12);
}
.item-row {
  &:hover, &.highlited {
    background: #04aeef;

    * {
      color: #fff;
    }
  }
}
.v-list-item .v-list-item__subtitle, .v-list-item .v-list-item__title {
  line-height: 2 !important;
}
.parentItem { width:100% !important;height:48px;display:flex;justify-content:center;align-content:center;flex-direction:column;background-color:#06aeef; }
.parentItemTitle {padding-left:20px;font-size:14pt;color:#ffffff}
</style>
