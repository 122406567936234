<template>
	<v-container class="main" fluid fill-width>
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

		<!-- navbar -->
		<div class="navbar">
			<v-row class="flex-nowrap">
				<v-text-field outlined dense clearable hide-details height="48"
					prepend-inner-icon="mdi-magnify"
					:placeholder="$t('text.search')"
					style="padding-right:10px"
					v-model="searchString"
					@keyup.enter="search()"
					@click:clear="clearSearch()">
				</v-text-field>
				
				<!-- Filters -->
				<div>
					<v-menu offset-y bottom left origin="top right" class="filter" :close-on-content-click="false" v-model="isOpened">
						<template v-slot:activator="{ on }" style="width:300px !important;min-width:300px !important;max-width:300px !important">
							<v-row justify="end" v-on="on">
							<v-btn class="gradientButton" data-cy="filterTagManager" elevation="0" x-small>
								<v-icon>mdi-filter</v-icon>
								<span class="d-none d-lg-inline">{{$t('text.filter')}}</span>
								<v-icon>mdi-menu-down</v-icon>
							</v-btn>
							<div v-if="$store.state.filter" class="dot-container stack-top"><span class="dot"></span></div>
							</v-row>
						</template>

						<v-list class="filter">
							<v-list-item class="filter pa-5">
								<v-list-item-title>
									<div @click="closeFilter()">
										<span style="width:100%">{{ $t('text.tags') }}</span>
										<v-select filled solo class="btn"
											ref="filterTags"
											v-model="selectedTags"
											:items="uniqueTags"
											multiple small-chips hide-details
											:menu-props="{ offsetY: true  }">

											<template #selection="{ item }">
												<v-chip :style="`border:solid 3px ${item.colour} !important; color:${item.colour} !important;`" color='#fff'>{{item.label}}</v-chip>
											</template>

											<template v-slot:prepend-item>
												<v-list-item
													ripple
													@mousedown.prevent
													@click="toggle"
													style="cursor:pointer !important"
													>
													<v-list-item-title class="pl-3">
														<v-row align="center" style="font-size: 14pt !important; ">
															<v-checkbox v-model="selectAllTags"/> {{ $t('text.selectAll') }}
														</v-row>
													</v-list-item-title>
												</v-list-item>
												<v-divider class="mt-2"></v-divider>
											</template>
											
											<template #item="{ item }">
												<v-list-item class="pa-3">
													<v-list-item-title>
														<v-row align="center">
															<v-checkbox/>
															<button class="tag" elevation="0" width="32px" :style="`border:solid 3px ${item.colour} !important; color:${item.colour} !important;`">{{ item.label }}</button>
															<span style="font-size: 14pt !important; padding-left:10px">{{item.value}}</span>
														</v-row>
													</v-list-item-title>
												</v-list-item>
												<v-divider style="border-color:#000000 !important"/>
											</template>
										</v-select>
									</div>
								</v-list-item-title>
							</v-list-item>

							<v-divider/>

							<v-row class="pl-6 pr-6 pb-4 pt-6">
								<v-btn class="gradientButton" elevation="0" style="width:48%;margin-right:4%" @click="clearFilter()">{{ $t('text.clearFilter') }}</v-btn>
								<v-btn class="greenButton" elevation="0" dark style="width:48%;" @click="applyFilter()">{{ $t('text.applyFilter') }}</v-btn>
							</v-row>
						</v-list>
					</v-menu>
				</div>
				 
				<!-- Add New User -->
				<!-- <v-btn class="gradientButton" small elevation="0" @click="showAddUser(newUserType)">
					<v-icon>mdi-plus-circle</v-icon>
					<span class="d-none d-md-inline">{{ $t('text.addOperator') }}</span>
				</v-btn> -->
			</v-row>
		</div>

		<!-- Content -->
		<v-card class="tableCard" style="margin-top:10px">
			<v-data-table fixed-header hide-default-footer disable-sort :key="refreshCount"
				v-model="selected"
				:headers="headers"
				:items="users"
				:items-per-page="limit"
				item-key="name"
				class="elevation-0">

				<template v-slot:item="{item}">
				<tr style="padding:10px !important; min-height:65px !important; height:65px !important" @click="showEditPermissions(item)">
					<td><v-icon>mdi-account</v-icon></td>
					<td>{{item.fields.email.de}}</td>
					<td>{{item.fields.name.de}}</td>
					<td>
   						<v-menu offset-y class="menu" open-on-hover>
          					<template v-slot:activator="{ on }" class="tags d-flex flex-wrap fill-height align-center justify-start" >
								<span  v-for="tag of item.fields.canManageTags.de" :key="tag" >
								<button class="tag" elevation="0" v-on="on" :style="getTagStyle(tag.substring(0,3).toUpperCase())">{{ tag.substring(0,3).toUpperCase() }}</button>
								</span>
						 	</template>
							<v-list style="min-width:300px">
								<span v-for="(tag, index) in item.fields.canManageTags.de" :key="tag">
									<v-list-item class="pa-3">
										<v-list-item-title>
											<v-row align="center">
												<button class="tag" elevation="0" width="32px" :style="getTagStyle(tag.substring(0,3).toUpperCase())">{{ tag.substring(0,3).toUpperCase() }}</button>
												<span style="font-size: 14pt !important; padding-left:10px">{{tag}}</span>
											</v-row>
										</v-list-item-title>
									</v-list-item>
									<v-divider v-if="index < item.fields.canManageTags.de.length-1" style="border-color:#000000 !important"/>
								</span>
							</v-list>
						</v-menu>
					</td>
				</tr>
				</template>
			</v-data-table>

			<v-card class="tableFooter">
				<v-card-actions>
					<TablePaginator v-model="offset" :limit="limit" :total="userCount" @input="getUsers()" :results="users" />
				</v-card-actions>
			</v-card>
		</v-card>

		<Dialog ref="editPermissionsDialog"
			:title="dialogTitle"
			:confirm-label="$t('text.confirmSelection')"
			:confirm-handler="dialogConfirm"
			:cancel-label="$t('text.cancel')"
			:showClose="false"
			:cancel-handler="dialogCancel"
			:width="'50%'" 
			:height="'75%'">
			<template #content>
				<TagAssignment ref="tagAssignmentComp" :user="selectedUser" :availableTags="uniqueTags"/>
			</template>
		</Dialog>
	</v-container>
</template>

<script>
import Dialog from '@/components/common/Dialog.vue'
import Loading from 'vue-loading-overlay'
import Alert from '@/components/common/Alert.vue'
import TablePaginator from '@/components/common/TablePaginator.vue'
import Common from '@/mixins/Common.vue'
import { Action } from '@/plugins/enum.js'
import TagAssignment from './TagAssignment.vue'

export default {
	name: 'UserManagerView',
	components: { Loading, Alert, TablePaginator, Dialog, TagAssignment },
	mixins: [ Common ],

	data() {
		return {
			Action: Action,
			
			loading: false,
			limit: 15,
			offset: 0,
			searchString: this.$store.state.searchString ? this.$store.state.searchString : '',
			errorTitle: '',
			errorDetail: '',
			successTitle: '',
			successDetail: '',

			selected: [],
			users: [],
			userCount: 0,
			isOpened: false,
			selectedTags: [],
			showTags: {sys:{id:''}},

			uniqueTags: [],

			tagColors: ['#F85E56', '#74CE2E', '#FFC000', '#0ABFF3', '#FF973E', '#D862C2', '#868686' ],
			
			dialogTitle: '',
			selectedUser: null,
			
			refreshCount: 0,
			selectAllTags: false
		}
	},
	
	computed: {
		headers () {
			return [
				{ text: 'Type', value: "fields.type.de", width: '32' },
				{ text: 'Login E-Mail', value: "fields.email.de", width: '25%', cellClass: "truncate" },
				{ text: 'Name', value: "fields.name.de", width: '25%', cellClass: "truncate" },
				{ text: 'Permissions', value: "tags", width: '50%' },
			]
		},
	},
	
	watch: {
		selectAllTags() {
			this.selectedTags = this.uniqueTags
		}
	},

	mounted() {
		this.getUsers()
	},
	
	methods: {
		resetShowTags() {
			this.showTags = {sys:{id:''}}
		},
		async getUsers() {
			this.loading = true
			try {
				let url = `/users?skip=${this.offset}&limit=${this.limit}&searchString=${encodeURIComponent(this.searchString)}&clientId=${this.$store.state.selectedClient.sys.id}`
				
				if (this.$store.state.filter?.tags?.length) {
					url += `&filter=${JSON.stringify(this.$store.state.filter)}`
				}

				let res = await this.$httpGet(url)
				this.users = res.users?.length ? res.users : []
				this.userCount = res.total 

				if (!this.uniqueTags.length && res.uniqueTags?.length) {
					this.uniqueTags = []
					for (let i=0; i < res.uniqueTags.length; i++) {
						this.uniqueTags.push({
							label: res.uniqueTags[i].substring(0,3).toUpperCase(),
							colour: this.tagColors[i],
							value: res.uniqueTags[i]
						})
					}
				}
				this.refreshCount++
			} catch (error) {
				if (error.response?.status == 401) return this.$emit("show-login")
				this.showError(error)
			}
			this.loading = false
		},
		showEditPermissions(user) {
			this.selectedUser = user
			this.dialogTitle = `${this.$t('text.editPermissions')}<p style="font-size:10pt;color:#646464">${user.fields.email.de}</p>`
			this.$refs.editPermissionsDialog.show = true
		},
		async dialogConfirm() {
			this.loading = true
			const selectedTags = this.$refs.tagAssignmentComp.availableTags.filter(tag => tag.selected === true)
			const tags = selectedTags.length ? selectedTags.map(e => e.value) : []

			const data = {
				userId: this.selectedUser.sys.id,
				tags: tags
			}

			try {
				const res = await this.$httpPut(`/tag-assignments`, data)
				await this.getUsers()
				
				this.successTitle = this.$t('text.SUCCESS')
				this.successDetail = this.$t('text.tagAssignmentSuccess')

			} catch (error) {
				if (error.response?.status == 401) return this.$emit("show-login")
				this.showError(error)
			}

			this.loading = false
		},
		dialogCancel() {
			this.$refs.editPermissionsDialog.show = false
		},
		async search() {
			await this.$store.commit('setSearchString', this.searchString)
			
			if (this.searchString?.length > 0) {
				this.offset = 0
				this.getUsers()
			} else {
				this.clearSearch()
			}
		},
		async clearSearch() {
			this.offset = 0
			this.searchString = ''
			await this.$store.commit('setSearchString', '')
			
			this.getUsers()
		},
		toggle () {
			this.$nextTick(() => {
			if (this.selectAllTags === true) {
				this.selectedTags = this.uniqueTags
			} else {
				this.selectedTags = []
			}
			})
		},
		async applyFilter() {
			this.isOpened = false
			this.offset = 0
			let tags = this.selectedTags

			if (tags[0]?.label) {
				tags = this.selectedTags.map(el => el.value)
			}
			const filter = {
				tags: tags
			}

			await this.$store.commit('setFilter', filter)
			await this.getUsers()
		},
		async clearFilter() {
			this.isOpened = false
			this.selectedTags = []
			this.selectAllTags = false
			this.offset = 0
			await this.$store.commit('setFilter', null)
			await this.getUsers()
		},
		closeFilter() {
			if (!this.dropdownOpen) {
				this.$refs.filterTags.focus()
				this.dropdownOpen = true
			} else {
				this.$refs.filterTags.blur()
				this.dropdownOpen = false
			}
		},
		hash(str, seed = 0) {
			let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
			for (let i = 0, ch; i < str.length; i++) {
				ch = str.charCodeAt(i);
				h1 = Math.imul(h1 ^ ch, 2654435761);
				h2 = Math.imul(h2 ^ ch, 1597334677);
			}
			h1 = Math.imul(h1 ^ (h1>>>16), 2246822507) ^ Math.imul(h2 ^ (h2>>>13), 3266489909);
			h2 = Math.imul(h2 ^ (h2>>>16), 2246822507) ^ Math.imul(h1 ^ (h1>>>13), 3266489909);
			return 4294967296 * (2097151 & h2) + (h1>>>0);
		},
		getColor(tag) {
			return this.tagColors[this.hash(tag) % this.tagColors.length]
		},
		getTagStyle(tag) {
			const colour = this.uniqueTags.find(uniqueTag => uniqueTag.label === tag)?.colour
			return `border:solid 3px ${colour} !important; color:${colour} !important;`
		}
	}
}
</script>

<style scoped>
.v-data-table__mobile-row { word-break: break-all; }
.tags { min-height: 60px !important; height:60px !important }
.tag { padding: 5px; border-radius: 45%; min-width:60px; white-space: nowrap; border:solid 3px gray; margin-right: 5px; font-weight: bold; background-color: #ffffff !important; }
.menu { border:solid 3px gray; z-index:1000; }
.dot-container { width: 75px; height: 75px; position: absolute; top: 0; right: 0; }
.stack-top { width: 14px; height: 14px; z-index: 9; margin: 0px; padding:0px; }
.dot { height: 14px; width: 14px; background-color: #ff0022; border-radius: 50%; display: inline-block; position: absolute; top: -1px; right: -1px; }
</style>
