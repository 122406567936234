<template>
  <div class="row" style="z-index:2; position:relative" :disabled="locked">
    <p class="helpText">{{$t('text.additionalDateSettings')}}</p>
    <div class="chips-selector" ref="selector" @click.stop="openDialog" :data-cy="dataCy">
      <div class="chips-search">
        <div class="selected-chips">
          <span v-for="chip in chips" :key="'selected_exception_' + chip.id">{{ chipsLabel(chip) }}</span>
        </div>
      </div>
    </div>

    <Dialog ref="additionalDateDialog"
      :confirmLabel="$t('text.confirmSelection')"
      :cancelLabel="$t('text.discardChanges')"
      :confirm-handler="confirmDialog"
      :cancel-handler="cancelDialog"
      :showClose="false"
      :width="'800px'"
      :height="'90%'"
      :title="$t('text.additionalDateSettings')">
      
      <template #content>
        <div style="padding-left:50px;padding-right:50px;width:100%">
          <div class="field left-border">
            <p class="helpText" v-html="$t('text.dateSettings')"/>
            <p class="helpText" v-html="$t('text.exceptionsHelp')"/>
          </div>
          
          <!-- Date Picker -->
          <v-col style="padding:0px !important">
            <v-row style="margin-top:12px" :key="renderKey">
              <v-date-picker 
                :first-day-of-week="1"
                :show-current="false"
                full-width no-title multiple
                color="red lighten-1"
                v-model="selectedDates"
                :allowed-dates="allowedDates"
                @update:picker-date="pickerUpdate($event)"
                :min="availability.fields.validFromDate.de"
                :max="availability.fields.validToDate.de"/>
            </v-row>
          </v-col>
            
          <!-- Specific Days -->
          <div class="field left-border">
            <div class="chips-selector" ref="selector2">
              <div class="chips-search">
                <div class="selected-chips">
                  <span v-for="chip in selectedDates" :key="'selected_exception_' + chip">{{ selectedDateChipsLabel(chip) }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Weekdays -->
          <div class="field left-border">
            <v-col style="padding:0px !important">
              <v-row style="margin-top:12px" :key="renderKey">
                <v-btn id="btnEditWeekday" v-for="weekday in daysOfWeek" :key="weekday"
                  @click="toggleWeekday(weekday)"
                  :class="containsWeekday(weekday) ? 'dayActive' :	'dayInactive'">
                  <v-list-item-avatar width="20px" height="20px" min-height="20px" min-width="20px" class="oval" v-bind:style="containsWeekday(weekday) ? 'display:none' : 'margin-left:-10px;margin-right:5px;display:'"></v-list-item-avatar>
                  <v-icon v-bind:style="containsWeekday(weekday) ? 'margin-left:-10px;margin-right:5px;color:#01c900;display:' : 'display:none'">mdi-circle</v-icon>
                  {{$t(`text.${weekday}`)}}
                </v-btn>
              </v-row>
            </v-col>
          </div>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/common/Dialog'
import moment from 'moment'

export default {
  name: "AdditionalDateSelector",

  components: {
    Dialog
  },

  props: {
    availability: Object,
    dataCy: { type: String, default: undefined },
    locked: Boolean
  },

  data() {
    return {
      daysOfWeek: ['monday','tuesday','wednesday','thursday','friday','saturday','sunday'],
      chips: [],
      c: [],
      selectedDays: [],
      selectedDates: [],
      availableDates: [],
      renderKey: 0,
      originalAvailability: Object
    }
  },
  mounted() {
    this.originalAvailability = JSON.parse(JSON.stringify(this.availability))
    this.addChipsIntoInput()
    this.getDateExceptions()
    this.getWeekdayExceptions()
  },
  methods: {
    allowedDates(a) {
      return this.availableDates.includes(a);
    },

    pickerUpdate(val) {
      let totalDay = moment(val, "YYYY-MM").daysInMonth()

      let availableDates = []

      let monthNow = moment().format('M')
      let monthSelected = moment(val).format('M')
      let day

      if (monthNow == monthSelected) {
        day = moment().format('D')
      } else {
        day = 1
      }

      for (let i = day; i <= totalDay ; i++) {
          let date = moment(val).date(i).format("YYYY-MM-DD")
          if (this.selectedDays?.length) {
            for (const selectedDay of this.selectedDays) {
              let dayIndex = this.daysOfWeek.findIndex(x => x === selectedDay.toLowerCase())
              dayIndex = dayIndex < 7 ? dayIndex + 1 : 0
              if (moment(date).isoWeekday() === dayIndex) {
                availableDates.push(date)
              }
            }
          } else {
            availableDates.push(date)
          }
      }
      this.availableDates = availableDates
      this.allowedDates();
    },
    toggleWeekday(item) {
      let haveItem = false
      if (!this.availability) return
      if (!this.selectedDays) this.selectedDays = []

      if (this.selectedDays?.length) {
        for (let i = 0; i < this.selectedDays.length; i++) {
          if (this.selectedDays[i].toLowerCase() === item) {
              haveItem = true
              this.selectedDays.splice(i, 1)
          }
        }
      }

      if (!haveItem) {
        item = item.charAt(0).toUpperCase() + item.slice(1)
        this.selectedDays.push(item)
      }

      //Remove any selected dates that are not in the new weekday selection
      let newSelectedDates = []
      if (this.selectedDates?.length) {
        for (const selectedDate of this.selectedDates) {
          if (this.selectedDays?.length) {
            for (const selectedDay of this.selectedDays) {
              let dayIndex = this.daysOfWeek.findIndex(x => x === selectedDay.toLowerCase())
              dayIndex = dayIndex < 7 ? dayIndex + 1 : 0

              if (moment(selectedDate).day() === dayIndex) {
                newSelectedDates.push(selectedDate)
              }
            }
          } else {
            newSelectedDates.push(selectedDate)
          }
        }
      }
      this.selectedDates = newSelectedDates

      this.renderKey = this.renderKey+1
    },
    containsWeekday(weekday) {
      return this.selectedDays?.find(x => x.toLowerCase() === weekday) ? true : false
    },
    openDialog() {
      if (!this.locked) {
        this.getDateExceptions()
        this.getWeekdayExceptions()
        
        this.$refs.additionalDateDialog.show = true
      }
    },
    getDateExceptions() {
      if (this.availability.fields.exceptions?.de?.length) {
        this.selectedDates = this.availability.fields.exceptions.de.filter(exception => !this.daysOfWeek.includes(exception.toLowerCase()))
        this.selectedDates.sort((a, b) => (a > b) ? 1 : -1)
      }
    },
    getWeekdayExceptions() {
      if (this.availability.fields.exceptions?.de?.length) {
        this.selectedDays = this.availability.fields.exceptions.de.filter(exception => this.daysOfWeek.includes(exception.toLowerCase()))
      }
    },
    chipsLabel(chip) {
      return this.daysOfWeek.includes(chip.id.toLowerCase()) ? this.$t(`text.${chip.title.toLowerCase()}`) : chip.title
    },
    selectedDateChipsLabel(chip) {
      return chip
    },
    addChipsIntoInput() {
      this.chips = []
      let specificDateChips = []
      let weekdayChips = []

      for (const exception of this.availability.fields.exceptions.de) {
        if (this.daysOfWeek.includes(exception.toLowerCase())) {
          weekdayChips.push({ title: exception, id: exception })
        } else {
          specificDateChips.push({ title: exception, id: exception })
        }
      }

      specificDateChips.sort((a, b) => (a.id > b.id) ? 1 : -1)

      const sorter = {
        "monday": 1,
        "tuesday": 2,
        "wednesday": 3,
        "thursday": 4,
        "friday": 5,
        "saturday": 6,
        "sunday": 7
      }

      weekdayChips.sort(function sortByDay(a, b) {
        let day1 = a.title.toLowerCase();
        let day2 = b.title.toLowerCase();
        return sorter[day1] - sorter[day2];
      });

      this.chips = [...specificDateChips, ...weekdayChips]
    },
    cancelDialog() {
      this.availability.fields = JSON.parse(JSON.stringify(this.originalAvailability.fields))
      this.selectedDates = this.getDateExceptions()
      this.selectedDays = this.getWeekdayExceptions()
      this.addChipsIntoInput()
    },
    confirmDialog() {
      this.originalAvailability = JSON.parse(JSON.stringify(this.availability))
      this.availability.fields.exceptions = {de: this.selectedDays.concat(this.selectedDates)}

      this.addChipsIntoInput()
      return true
    },
  },
}
</script>

<style scoped lang="scss">
.dayActive, .dayInactive {
	background-color: #f2f2f2 !important;
	box-shadow: none !important;
	border-radius: 30px !important;
	border: solid 1px rgba(0, 0, 0, 0.1) !important;
	font-size: 14pt !important;
	height: 32px !important;
	margin: 5px;
	text-transform: none !important;
	letter-spacing: 0px !important;
}
.dayInactive {
	background: #ffffff !important;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
	border: thin #dddddd solid !important;
	color:#000000 !important;
}
.chips-selector {
  position: relative;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
  margin-bottom: 0px;
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
  cursor: pointer;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.86);
  }

  .chips-search {
    i {
      position: absolute;
      right: 0;
      top: 0;
      height: 48px;
      width: 48px;
      cursor: pointer;
      transition: all .3s cubic-bezier(.25, .8, .5, 1);

      &.rotated {
        transform: rotate(180deg);
      }
    }
  }

  .selected-chips {
    min-height: 48px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    span {
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
      border: thin #dddddd solid !important;
      color: #000;
      padding: 7px 18px;
      border-radius: 10px;
      margin-left: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
      height: 36px;
      align-items: center;
      background: rgb(245, 245, 245);
      background: linear-gradient(0deg, rgba(245, 245, 245, 1) 0%, rgba(254, 254, 254, 1) 100%);
      cursor: pointer;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
    }
  }
}
</style>
