<template>
  <v-container class="pa-0">
    <!-- New Upload -->
    <div v-if="mediaAsset.fields.file">
      <v-row class="title" align="center" justify="center">
        <span class="ml-2">{{mediaAsset.fields.file.upload.filename}}</span>
        <v-col class="text-right">
          <v-btn icon color="#f34545" style="cursor:pointer" @click="removeFile">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-col>
        <div>
          <div class="thumbnail">
            <img v-if="mediaAsset.fields.file" :src="mediaAsset.fields.file.dataURL" style="width: 100%; height: auto; max-width:350px;border-radius:10px"/>
          </div>

          <div>
            <div>
              <v-label>{{$t('text.title')}}&nbsp;<span class="error-text">({{$t('text.required')}})</span></v-label>
                <LanguageFlag v-model="serviceLocale" class="flag" />
                <v-text-field outlined dense counter="256" maxLength="256"
                id="title"
                v-model.trim="mediaAsset.fields.title[serviceLocale]"
                :error-messages="mediaAsset.titleError"
                ></v-text-field>
            </div><br/>

            <div>
              <v-label>{{$t('text.altText')}}</v-label>
                <LanguageFlag v-model="serviceLocale" class="flag" />
                <v-text-field outlined dense counter="256" maxLength="256"
                v-model="mediaAsset.fields.altText[serviceLocale]" 
                :hint="$t('text.altTextHelp')" persistent-hint></v-text-field>
            </div><br/>

            <div>
              <v-label>{{$t('text.description')}}</v-label>
              <LanguageFlag v-model="serviceLocale" class="flag" />
              <v-textarea outlined counter="180" maxLength="180"
                v-model="mediaAsset.fields.description[serviceLocale]"></v-textarea>
            </div>
          </div>
        </div>
      </v-col>
    </div>

    <!-- Edit Image -->
    <template v-if="mediaAsset.fields.media">
      <v-row>
        <v-col class="thumbnail col-12 col-md-6 pl-0"> <!-- !!Put this back when we support editing the image itself -> style="cursor:pointer" @click="$refs.file.click()">-->
          <input type="file" ref="file" id="fileBrowser" accept="image/jpg, image/jpeg, image/png" style="display: none" @change="chooseFile()">
          <img ref="imageDataRef" :src="imgData" style="width: 100%; height: auto; max-width:350px;border-radius:10px"/>
        </v-col>

        <v-col class="col-12 col-md-6 pr-0">
          <div>
            <div>
              <v-label>{{$t('text.title')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
                <LanguageFlag v-model="serviceLocale" class="flag" />
                <v-text-field outlined dense counter="256" maxLength="256" 
                id="title"
                v-model.trim="mediaAssetTitleModel"
                :error-messages="mediaAsset.titleError"></v-text-field>
            </div><br/>

            <div>
              <v-label>{{$t('text.altText')}}</v-label>
                <LanguageFlag v-model="serviceLocale" class="flag" />
                <v-text-field outlined dense counter="256" maxLength="256"
                v-model="mediaAssetAltTextModel"
                :hint="$t('text.altTextHelp')" persistent-hint></v-text-field>
            </div><br/>

            <div>
              <v-label>{{$t('text.description')}}</v-label>
              <LanguageFlag v-model="serviceLocale" class="flag" />
              <v-textarea outlined counter="180" maxlength="180"
                v-model="mediaAssetDescriptionModel" ></v-textarea>
            </div>
          </div>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import LanguageFlag from '@/components/common/LanguageFlag.vue'
import Common from '@/mixins/Common'

export default {
  name: "MediaItem",
  components: { LanguageFlag },
  mixins: [Common],
  props: {
		mediaAsset: { type: Object },
	},

  data () {
      return {
        imgData: '',
        mediaAssetTitleModel: '',
        mediaAssetAltTextModel: '',
        mediaAssetDescriptionModel: '',
        // mediaAssetTitleModel: this.serviceLocale ? this.mediaAsset.fields.title[this.serviceLocale] : this.mediaAsset.fields.title['de'],
        // mediaAssetAltTextModel: this.serviceLocale ? this.mediaAsset.fields.altText[this.serviceLocale] : this.mediaAsset.fields.altText['de'],
        // mediaAssetDescriptionModel: this.serviceLocale ? this.mediaAsset.fields.description[this.serviceLocale] : this.mediaAsset.fields.description['de'],
      }
  },

  methods: {
    removeFile() {
      this.$root.$emit('remove-media-file', this.mediaAsset.fields.file)
    },
    setImageData(data) {
      let imageData = this.mediaAsset.fields.media.de.fields.file.de.url

      if (data) {
        imageData = data
      }
      return imageData
    },
    chooseFile() {
      const tmpThis  = this

      const fileList = document.getElementById("fileBrowser").files;
      const fileReader = new FileReader();
      if (fileReader && fileList && fileList.length) {
          fileReader.readAsArrayBuffer(fileList[0]);
          fileReader.onload = function () {
            const imageData = fileReader.result;
            tmpThis.imgData = 'data:image/jpg;base64,' + tmpThis.arrayBufferToBase64(imageData)
          };
      }
    },
    arrayBufferToBase64( buffer ) {
      var binary = ''
      const bytes = new Uint8Array( buffer )
      const len = bytes.byteLength

      for (var i = 0; i < len; i++) {
          binary += String.fromCharCode( bytes[ i ] )
      }

      return window.btoa( binary )
    }
  },
  watch: {
    // watch the serviceLocale with immediate true (instead of assigning the values in mounted) and update the model values when the serviceLocale changes
    serviceLocale: {
      handler: function (val, oldVal) {
        this.mediaAssetTitleModel = this.mediaAsset.fields.title[val]
        this.mediaAssetAltTextModel = this.mediaAsset.fields.altText[val]
        this.mediaAssetDescriptionModel = this.mediaAsset.fields.description[val]
      },
      immediate: true
    }
  },
  mounted() {
    this.imgData = this.mediaAsset.fields.media?.de?.fields?.file?.de?.url ? this.mediaAsset.fields.media?.de.fields.file.de.url : ''

    this.mediaAsset.fields["title"] = this.mediaAsset.fields.title ? this.mediaAsset.fields.title : {de:'',en:'',fr:'',it:''}
    this.mediaAsset.fields["description"] = this.mediaAsset.fields.description ? this.mediaAsset.fields.description : {de:'',en:'',fr:'',it:''}
    this.mediaAsset.fields["altText"] = this.mediaAsset.fields.altText ? this.mediaAsset.fields.altText : {de:'',en:'',fr:'',it:''}
  }
}
</script>

<style scoped>
.flag { position: absolute; z-index: 10; margin-top: 20px; margin-left: -15px; transform: scale(0.8); }
.title {
	border-radius: 4px;
	border: solid 1px rgba(0, 0, 0, 0.1);
	background-color: #f2f2f2;
	margin-top: 10px;
	margin-bottom: 10px;
}
</style>
