<template>
  <div v-if="trainingMaterial && trainingMaterial.fields && trainingMaterial.fields.pattern">
   <v-card :img="getPattern(trainingMaterial.fields.pattern.de)" class="card" @mouseover="overlay=true">
      <div class="title">
        <b>{{trainingMaterial.fields.title[selectedLocale]}}</b>
      </div>
      <div class="detail">
        <span><b>{{trainingMaterial.fields.title[selectedLocale] | truncate(35, '...') }}</b></span><br/>
        <span class="sub-title">{{trainingMaterial.fields.category.de.fields.title[selectedLocale]}}</span>
      </div>

      <!-- Play Button -->
      <v-overlay @mouseout="overlay=false" :absolute="true" :value="overlay" @click="viewVideo()"> 
        <svg width="15mm" height="15mm" viewBox="0 0 15 15">
          <g transform="translate(-37.978272,-42.24569)">
            <g transform="matrix(0.6,0,0,0.6,15.191309,16.898276)">
              <circle style="fill:#08adef;fill-opacity:1;stroke:none;stroke-width:11.7206;stroke-opacity:0.89" cx="50.478271" cy="54.745689" r="12.5" />
              <path style="fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.176;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="m 46.536605,50.079 v 9.333378 l 10,-4.666689 z" />
            </g>
          </g>
        </svg>
      </v-overlay>
   </v-card>
  </div>
</template>

<script>
import Common from '@/mixins/Common'

export default {
  name: "TrainingMaterial",
  mixins: [Common],

  props: {
    trainingMaterial: {}
  },

  data() {
    return {
      overlay: false,
    };
  },
  
  methods: {
    viewVideo() {
      this.overlay = false
      this.$emit("view-video", this.trainingMaterial)
    },
  }
}
</script>

<style scoped>
.card { width:300px; height:250px; border-radius: 15px; }
.detail { position:fixed; bottom:0px; height:85px; width:260px; background-color:#ffffff; border-top-left-radius:0px !important; border-top-right-radius:0px !important; padding:10px; overflow:hidden; }
.title { position:fixed; top:10px; height:165px; width:260px; overflow: hidden; text-overflow: ellipsis; padding:10px; font-weight:bold; font-size:30px; color:#ffffff }
.sub-title { font-size:14px; color:#cccccc}
</style>
