<template class="justify-center">
  <v-container>
    <v-card :data-cy="dataCy" :class="cardClass" :color="serviceType.fields.color.de" @click="selectServiceType()">
      <v-img style="opacity:0.25;width:50%;height:50%;margin:auto;padding-top:30px" width="150" height="150" contain :src="getAssetUrl(serviceType.fields.icon.de)"></v-img>

      <div style="height:45%; width:100%; position:absolute; bottom:0">
        <v-card-text class="cardText">
          <p style="font-size:14pt;font-weight:bold;color:#000">{{serviceType.fields.title[selectedLocale]}}</p>
          <p style="color:#000">{{serviceType.fields.shortDescription[selectedLocale]}}</p>
        </v-card-text>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import Common from '@/mixins/Common.vue'

export default {
	name: "ServiceTypeCard",
	mixins: [ Common ],
	props: {
    serviceType: Object,
    dataCy: String,
	},
  data () {
    return {
    }
  },
  computed: {
    cardClass() {
     return this.$store.state.selectedServiceType?.sys?.id===this.serviceType.sys.id ? 'cardActive' : 'card'
    }
  },
  methods: {
    selectServiceType() {
      this.$store.commit('setSelectedServiceType', this.serviceType)
    }
  }
}
</script>

<style scoped>
.container-scoped { position: absolute; margin-top:-130px; font-size: 24pt; color: #fff; font-weight: bold; }
.card {
  min-height: 350px;
  min-width: 300px;
  border-radius:10px;
}
.cardActive {
  min-height: 350px;
  min-width: 300px;
  border-radius:10px;
  border: 3px solid;
}
.cardText {
  background-color:#ffffff;
  padding: 10px;
  height: 100%;
}
</style>