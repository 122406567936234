var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"flex-nowrap mb-16",attrs:{"fluid":""}},[_c('div',{staticClass:"navbar"},[_c('v-row',{staticClass:"flex-nowrap",staticStyle:{"white-space":"nowrap","overflow":"hidden"},attrs:{"align":"center"}},[(_vm.model)?_c('v-row',{staticClass:"buttonGroup"},[_c('v-btn',{class:{
						gradientButton: true,
						active: _vm.model.page == 'salesChannels',
					},attrs:{"elevation":"0","data-cy":"salesChannels"},on:{"click":function($event){_vm.model.page = 'salesChannels'}}},[_c('span',[_vm._v(_vm._s(_vm.$t('text.salesChannelsInfoTitle')))])]),_c('v-btn',{class:{
						gradientButton: true,
						active: _vm.model.page == 'clients',
					},attrs:{"elevation":"0","data-cy":"marketplaces"},on:{"click":function($event){_vm.model.page = 'clients'}}},[_c('span',[_vm._v(_vm._s(_vm.$t('text.clientsTitle')))])])],1):_vm._e()],1)],1),(!_vm.model || _vm.model.page == 'salesChannels')?_c('MarketplaceSalesChannelsView'):_vm._e(),(_vm.model && _vm.model.page == 'clients')?_c('MarketplaceClientsView'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }