<template>
	<v-container
      fluid
      class="pa-0 mb-12"
      :style="isSideBarFixed ? 'padding-left: 380px !important;' : 'padding-left: 40px !important;'"
  >
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

		<!-- Navbar -->
		<div class="navbar">
			<v-row align="center" class="flex-nowrap" style="white-space:nowrap; overflow:hidden;">
				<v-btn id="btnBack" elevation="0" style="background-color:transparent;" @click="goback()">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
				<v-row justify="center">
					<h1>{{ faqGroup.fields.title[serviceLocale] ? faqGroup.fields.title[serviceLocale] : $t('text.titleNewFAQ') }}</h1>
				</v-row>
			</v-row>
		</div>

    <SideBar ref="sidebar">
		<div :style="showImpersonationInfo ? 'padding-top: 24px !important;' : 'padding-top:0px'">
			<v-btn class="gradientButton mb-3" elevation="0" block @click="togglePreview">
				<v-icon>mdi-eye</v-icon>
				{{ showPreview ? $t('text.hidePreview') : $t('text.showPreview') }}
			</v-btn>
			<v-btn class="gradientButton mb-3" elevation="0" block @click="submit()">
				<v-icon>mdi-check</v-icon>
				{{$t('text.saveChanges')}}
			</v-btn>
			<v-btn class="redButton mb-3" dark elevation="0" block v-if="faqGroup.sys.id!==''" @click="$refs.deleteDialog.show=true">
				<v-icon>mdi-delete</v-icon>
				{{$t('text.deleteFAQ')}}
			</v-btn>

			<v-select outlined dense required hide-details
						v-model="serviceLocale"
						:items="locales"
						:item-text="locale => $t('text.' + locale.name)"
						:item-value="locale => locale.code">
			</v-select>
		</div>
    </SideBar>

	<v-row :style="showImpersonationInfo ? 'padding-top: 24px !important;' : 'padding-top:0px'">
			<v-col cols="12" :lg="showPreview ? 6 : 12">
      
        <v-card class="elevation-4 py-8 px-12" style="box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15), 0 0 1px 0 rgba(0, 0, 0, 0.2) !important;">
          <v-card-text class="pa-0">
            <v-form>
              <v-row>
                <v-divider vertical class="mx-1" style="margin-top:10px;margin-bottom:5px"></v-divider>
                <v-col>
                  <v-label>{{$t('text.templateTitle')}} ({{$t('text.required')}})</v-label>
                  <br/>
                  <LanguageFlag v-model="serviceLocale" class="langFlag" />
                  <v-text-field outlined required
                                v-model="faqGroup.fields.title[serviceLocale]"
                                @keydown="changeStatus"
                                :hint="$t('text.FAQHelp')" persistent-hint
                                :error-messages="titleErrors">
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row v-for="(faqComponent, faqComponentIndex) in faqGroup.fields.references.de" :key="faqComponent.sys.id">
                <FAQComponent :faq-component="faqComponent" :index="faqComponentIndex" v-on:status-changed="changeStatus" v-on:remove-question="removeQuestion(faqComponentIndex)"/>
              </v-row>

              <v-row>
                <v-btn id="addQuestion" class="gradientButton" elevation="0" @click="addQuestion">
                  <v-icon left>mdi-plus</v-icon>
                  <span>{{$t('text.addQuestion')}}</span>
                </v-btn>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>

			<v-col cols="12" :lg="showPreview ? 6 : 12" v-if="showPreview">
				<div id="preview" class="content pl-5">
					<h1>{{ faqGroup.fields.title[serviceLocale] }}</h1>
					<v-expansion-panels>
						<v-expansion-panel v-for="faq of faqGroup.fields.references.de" :key="faq.id">
							<v-divider/>
							<v-expansion-panel-header class="pa-0">
								<h3>{{ faq.fields.question[serviceLocale] }}</h3>
							</v-expansion-panel-header>
							<v-expansion-panel-content class="ml-n6">
								<v-row>
									<div style="line-height:30px !important; white-space: pre-wrap;">
										{{ faq.fields.answer[serviceLocale] }}
									</div>
								</v-row>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</div>
			</v-col>
		</v-row>

		<!-- Confirm Delete -->
		<Dialog ref="deleteDialog"
            :confirmLabel="$t('text.delete')"
            :cancelLabel="$t('text.cancel')"
            :confirm-handler="onDeleteConfirmed"
            :cancel-handler="onDeleteCancelled"
			:showClose="false"
            :title="$t('text.deleteFAQ')" 
			:height="'250px'"
			:width="'800px'">
			<template #content>
				<p style="margin:10px">{{$t('text.confirmFAQDelete')}}</p>
			</template>
		</Dialog>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import FAQComponent from './FAQComponent.vue'
import Common from '@/mixins/Common.vue'

import FreshdeskWidget from '@/utils/FreshdeskWidget.js'
import Alert from '@/components/common/Alert.vue'
import Dialog from '@/components/common/Dialog.vue'
import LanguageFlag from '@/components/common/LanguageFlag.vue'
import SideBar from "@/components/common/Sidebar";

export default {
	name: 'FAQDetailView',
	components: {SideBar, Loading, FAQComponent, LanguageFlag, Alert, Dialog },
  mixins: [ Common ],
	data() {
		return {
		title: '',
		loading: false,
		errorTitle: '',
		errorDetail: '',
		successTitle: '',
		successDetail: '',
		status: 'Published',
		statusChanged: false,
		toggle_exclusive: 4,
		faqCounter: 1,
		showPreview: false,

		faqGroup: {
			sys: { id:'' },
			fields: {
				title: {},
				references: { de:[] },
			}
		},

	}},
	computed: {
		titleErrors() {
			this.defaultLocale = this.locales[0]
			if (this.faqGroup.fields.title[this.defaultLocale.code]==='') {
				return this.$t('text.missingFieldsError')
			}
			return ""
		}
	},
	created () {
		FreshdeskWidget.prefill('MyServices')
	},
	mounted() {
		this.defaultLocale = this.locales[0]
		if (this.$store.state.selectedFAQGroup && Object.keys(this.$store.state.selectedFAQGroup).length > 0) {
			this.faqGroup = this.$store.state.selectedFAQGroup;
		}
		else {
			this.faqGroup = {
				sys: { id: '' },
				fields: {
					title: {},
					references: {
						de: [ this.getNewQuestion() ],
					},
				},
			}
		}
	},
	
	methods: {
		onDeleteConfirmed() {
			this.$refs.deleteDialog.show = false
			this.deleteFAQ()
			return  true
		},
		onDeleteCancelled() {
			this.$refs.deleteDialog.show = false
		},
		getColor () {
			const status = this.status.toLowerCase()
			if (status === 'changed') return '#3c80cf'
			if (status === 'saved') return '#589ae6'
			if (status === 'pending') return '#ff9e21'
			if (status === 'published') return '#66BB6A'
		},
		changeStatus() {
			this.status = 'Changed'
			this.statusChanged = true
		},
		togglePreview() {
			this.showPreview = !this.showPreview
      if (this.showPreview && !this.isSideBarFixed) {
        this.$nextTick(() => { document.getElementById('preview').scrollIntoView({ behavior: 'smooth'}) })
        this.$refs.sidebar.closeSidebar();
      }
		},
		getNewQuestion(counter) {
			if (!counter) counter = Math.random()
			return {
				sys: { id: 'id_faq_' + counter },
				fields: {
					question: {},
					answer: {},
				},
			}
		},
		addQuestion() {
			this.changeStatus()
			this.faqCounter++
			if (!this.faqGroup.fields.references.de) {
				this.faqGroup.fields.references.de = []
			}
			this.faqGroup.fields.references.de.push(this.getNewQuestion())
		},
		removeQuestion(index) {
			if (this.faqGroup.fields.references.de.length > 1) {
				this.faqGroup.fields.references.de.splice(index, 1)
			}
			else {
				this.faqGroup.fields.references.de[0].question = ''
				this.faqGroup.fields.references.de[0].answer = ''
			}
		},
		submit() {
			if (this.faqGroup.sys.id !== '') {
				this.updateFAQ()
			}
			else {
				this.createFAQ()
			}
		},
		showError(detail, title) {
			
			this.errorTitle = title ?? this.$t('text.ERROR')
			this.errorDetail = detail
		},
		async updateFAQ() {
			this.loading = true
			this.showPreview = false
			try {
				if (this.validate()) {
					for (var faq of this.faqGroup?.fields?.references?.de ?? []) {
						for (var locale of this.locales) {
							if (!faq.fields.answer[locale.code]) continue
							if (!(faq.fields.answer[locale.code]).indexOf("\r\n") < 0) continue
							faq.fields.answer[locale.code] = (faq.fields.answer[locale.code]).replace("\r\n", "\n").replace("\r", "\n")
						}
					}
					const res = await this.$httpPut('/faq', this.faqGroup)
					await this.getFAQDetail("update", res.faqGroupId)
				}
			}
			catch (error) {
				if (error.response?.status === 401) this.$emit("show-login")
				if (error.response) {
					this.showError(error.response.data?.error)
				}
			}
			this.loading = false
		},
		async createFAQ() {
			this.loading = true;
			this.showPreview = false;

			try {
        const isValid = this.validate()
		if (isValid) {
          for (var faq of this.faqGroup?.fields?.references?.de ?? []) {
		    for (var locale of this.locales) {
              // TODO: why is this line different than in updateFAQ?
              if (!faq.fields.answer[locale.code]) continue
              faq.fields.answer[locale.code] = (faq.fields.answer[locale.code]).replace(/\n\r?/g, '\r\n');
            }
          }
		  const requestData = {
            serviceProviderId: this.$store.state.selectedServiceProvider.sys.id,
            faqGroup: this.faqGroup,
          }
          const res = await this.$httpPost('/faq', requestData)
          await this.getFAQDetail("create", res.faqGroupId)
        }
			}
			catch (error) {
				if (error.response?.status === 401) return this.$emit("show-login")
				console.error(error)
				this.errorTitle = this.$t('text.ERROR')
				this.errorDetail = error.response ? error.response.data.error : error
			}
			this.loading = false
		},
		async getFAQDetail(action, faqGroupId) {
			this.loading = true
			try {
				const res = await this.$httpGet(`/faq?faqId=${faqGroupId}`)
				await this.$store.commit('setSelectedFAQGroup', res.faqGroup)
				this.faqGroup = res.faqGroup
				this.status = 'published'
				this.statusChanged = false

				if (action === 'create') {
					this.successTitle = this.$t('text.FAQ_CREATED')
					this.successDetail = this.$t('text.faqCreateSuccess')
				}
				if (action === 'update') {
					this.successTitle = this.$t('text.FAQ_UPDATED')
					this.successDetail = this.$t('text.faqUpdateSuccess')
				}
			}
			catch (error) {
				if (error.response?.status === 401) this.$emit("show-login")
				console.error(error)
				this.errorTitle = this.$t('text.ERROR')
				this.errorDetail = error.response ? error.response.data.error : error
			}
			this.loading = false
		},
		async deleteFAQ() {
			this.loading = true
		
			try {
				const res = await this.axios.delete('/faq-group', 
				{
					headers: this.requestHeaders(),
					data: {
						serviceProviderId: this.$store.state.selectedServiceProvider.sys.id,
						faqGroupId: this.faqGroup.sys.id
					} 
				})

				if (res?.message === 'cannot delete linked entry') {
					this.errorTitle = this.$t('text.ERROR')
					this.errorDetail = this.$t('text.faqInUseError')
				} else {
					this.successTitle = this.$t('text.FAQ_DELETED')
					this.successDetail = this.$t('text.faqDeletedSuccess')
					this.sleep(2000).then(() => { this.$router.push("/faq-list") });
				}
			}
			catch (error) {
				this.showError(error.response ? error.response?.data.error : error)
			}
			
			this.loading = false
		},
		validate() {
			let isValid = false
			if (this.faqGroup.fields.title[this.defaultLocale.code]?.trim().length > 0) {
				isValid = true
			}
			return isValid
		},
		sleep(ms) {
			return new Promise(resolve => setTimeout(resolve, ms));
		},
		goback() {
			this.$router.back()
		},
	},

}
</script>

<style scoped>
a { font-size: 10pt; }
hr { color: #efefef; }
h3 { color: black; line-height: 40px; }
p { color: dimgray; font-size: 14pt; }
.list-group-item { cursor: move; cursor: -webkit-grabbing; }
#preview {
  background-color: #ffffff;
  border-radius: 10px;
}

.contentCard {
  border-radius: 20px;
  background-color: #ffffff;
  margin-left: 10px;
  margin-right: 20px;
  min-height: 100px;
  padding: 10px;
  text-align: left;
}
.v-expansion-panel-header { width: 98%; }
.v-expansion-panel::before { box-shadow: none !important; }
.enabled-text-style >>> .v-input__control > .v-input__slot { background: #ffffff !important; }
.greenButton {
  height: 48px !important;
  background: #62c522 !important;
  border: thin #58b01e solid !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 5px !important;
  font-family: 'Inter', sans-serif;
  font-size: 12pt !important;
  font-weight: normal !important;
  letter-spacing: 0 !important;
  text-transform: none !important;
}
.langFlag {
  position: absolute;
  margin-top: 18px;
  margin-left: -32px;
  zoom: 0.8;
}
</style>