<template>
  <v-row justify="center">
    <Disclosure :title="$t('text.systemInfoTitle')" :error="sectionMessage.error" :message="sectionMessage.message"
                data-cy="systemInfo" ref="SECTION_systemInfo">

       <div>
          <div>ID: <b>{{ product.sys ? product.sys.id : '' }}</b><br/></div>
					<div v-if="hasField('availabilityInfo','productContingents', product)">SKU: <b>{{ getSKU }}</b><br/></div>
					<div v-if="hasField('availabilityInfo','productContingents', product) && !productIsExternal">Contingent ID: <b>{{ getSpaceId }}</b><br/></div>
          <div v-if="hasField('availabilityInfo','productContingents', product)">Peak ID: <b>{{ getPeakId }}</b><br/></div>
					<div>{{ $t('text.serviceProvider') }}: <b>{{ serviceProviderName }}</b></div>
          <!--<div v-if="getJamesURL"><br/><a :href="getJamesURL" target="_blank">View In JAMES</a></div>-->
      </div>

    </Disclosure>
  </v-row>
</template>

<script>
import Disclosure from '@/components/common/Disclosure'
import Common from '@/mixins/Common'
import PeakInfo from '@/components/serviceDesigner/PeakInfo'
import ContentfulInfo from '@/components/serviceDesigner/ContentfulInfo'

export default {
  name: "SystemInfo",
  components: { Disclosure, PeakInfo, ContentfulInfo },
  mixins: [Common],
  props: {
    product: Object,
    serviceProvider: Object,
    productIsExternal:Boolean,
    updateModel: Boolean
  },

  data() {
    return {
      model: {},
      sectionMessage: {
        error: false,
        message: ''
      },
      linkedEntries: [],
      peakSyncData: [],
      productPeakId: '',
      contentfulDataLoaded: false,
      peakDataLoaded: false,
    }
  },
  computed: {
    serviceProviderName() {
      if (this.serviceProvider?.fields?.title?.[this.serviceLocale]) {
        return this.serviceProvider.fields.title[this.serviceLocale]
      } else {
        if (this.serviceProvider?.fields?.title?.de?.length) {
          return this.serviceProvider.fields.title.de
        }
      }
      return ''
    },
    getSKU() {
			if (this.product?.fields?.clientAssignments?.de?.length > 0) {
				for (const clientAssignment of this.product.fields.clientAssignments.de) {
					if (clientAssignment?.fields?.client?.de?.sys?.id === this.$store.state.selectedClient.sys.id) {
						return clientAssignment.fields.sku?.de ? clientAssignment.fields.sku.de : 'MYSERVICES'
					}
				}
			}
			return "MYSERVICES"
		},
    getPeakId() {
      if (this.product?.fields?.clientAssignments?.de?.length > 0) {
        for (const clientAssignment of this.product.fields.clientAssignments.de) {
          if (clientAssignment?.fields?.client?.de?.sys?.id === this.$store.state.selectedClient.sys.id) {
            return clientAssignment.fields.externalId?.de ? clientAssignment.fields.externalId.de : ''
          }
        }
      }
    },
    getSpaceId() {
      if (this.product?.fields?.ticket?.de?.fields?.ticketOptions?.de?.[0]?.fields?.spaceId) {
        return this.product.fields.ticket.de.fields.ticketOptions.de[0].fields.spaceId.de
      }
      return ''
    },
     getJamesURL() {
      if (this.$store.state.selectedClient?.fields?.jamesUrl?.de) {
        return `${this.$store.state.selectedClient.fields.jamesUrl.de}/de/products/MYS_${this.product.sys.id}`
      }
      return ''
    },
  },
  watch: {
    updateModel() {
      this.model = this.valueToModel(this.product)
    }
  },
  created() {
    this.model = this.valueToModel(this.product)
  },
  async mounted() {
    if (this.featureEnabled('contingent-monitor')) {
   //   await this.getLinkedEntries()
   //   await this.getPeakSync()
    }
  },
  methods: {
    valueToModel(v) {
      return JSON.parse(JSON.stringify(v ?? {}))
    },
    async getLinkedEntries() {
			try {
				const response = await this.$httpGet('/linkedEntries', {
					entryId: this.$store.state.selectedProduct.sys.id,
					clientId: this.$store.state.selectedClient.sys.id
				})
				this.linkedEntries = response
        this.contentfulDataLoaded = true
			} catch (error) {
				this.showError(error)
			}
		},
    async getPeakSync() {
      try {             
          const response = await this.$httpGet('/peakSync', {
            productId: this.$store.state.selectedProduct.sys.id,
            client: this.$store.state.selectedClient.fields.clientId.de,
            clientId: this.$store.state.selectedClient.sys.id
          })
          this.peakSyncData = response
          this.peakDataLoaded = true
      } catch (error) {
        this.showError(error)
      }
    },
  }
}
</script>