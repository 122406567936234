<template>
  <v-data-iterator
      :items="items"
      :hide-default-footer="items.length === 0"
      :items-per-page="itemsPerPage"
      :no-data-text="noDataText"
  >
    <template v-slot:default="props">
      <v-row>
        <!-- TODO: :key with item.sys.id is hardcoded and therefore not dynamically when you use the Pagination component with other items structure -->
        <v-col v-for="item in props.items"
               :key="item.sys.id"
               class="pa-0">
          <slot
              name="item"
              :item="item"
          ></slot>
        </v-col>
      </v-row>
    </template>
  </v-data-iterator>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    items: {
      type: Array,
      default: () => []
    },
    itemsPerPage: {
      type: Number,
      default: 15
    },
    noDataText: {
      type: String
    }
  },
}
</script>

<style scoped>
</style>