<template>
	<div id="preview">
		<h1>{{product.fields.title[selectedLocale]}}</h1>
		
		<v-carousel hide-delimiter-background :show-arrows="false">
			<v-carousel-item v-for="(item,i) in product.fields.images.de" :key="i" :src="item && item.fields && item.fields.media && item.fields.media.de.fields && item.fields.media.de.fields.file.de ? item.fields.media.de.fields.file.de.url : item.field && item.fields.file ? getImageUrl(item.fields.file.de.url) : getImageUrl(item) "></v-carousel-item>
		</v-carousel>

		<div class="content mx-2">
			<div v-if="product.fields.importantInformation[selectedLocale]">
				<v-row align="center">
					<h1 style="padding-left:10px">{{$t('text.importantInfo')}}</h1>
					<v-spacer/>
				</v-row>
				<div class="card" style="padding:25px !important" v-html="this.convertLink(this.product.fields.importantInformation[selectedLocale])"></div>
			</div>

			<v-row align="center" class="mt-5">
				<h1>{{$t('text.description')}}</h1>
				<v-spacer/>
				<a href="#" @click.prevent="toggleDescription()" style="padding-right:10px">{{this.showLongDescription ? $t('text.showLess') : $t('text.showMore') }}</a>
			</v-row>
			<div v-if="!showLongDescription" class="card" style="padding:25px !important" v-html="product.fields.shortDescription[selectedLocale]"></div>
			<div v-if="showLongDescription" class="card" style="padding:25px !important;" v-html="product.fields.longDescription[selectedLocale]"></div>


			<div v-if="product.fields.averageDurationOfStayInMinutes && product.fields.averageDurationOfStayInMinutes.de" class="mt-3">
        <h1>{{$t('text.duration')}}</h1>
				<div class="card" style="padding:25px !important">{{getAverageDurationOfStay()}}</div>
			</div>

			<h1 class="mt-5">{{$t('text.contactInfo')}}</h1>
			<div class="card">
				<div class="cardContent">
					<v-row style="margin:10px" v-if="contactInfoPhone && contactInfoPhone.fields && contactInfoPhone.fields.contactDetail && contactInfoPhone.fields.contactDetail.de">
						<v-col style="max-width:32px;margin-top:3px"><img src="@/assets/icons/phone.png"/></v-col>
						<v-col>{{contactInfoPhone.fields.contactDetail.de}}</v-col>
					</v-row>
					<v-divider/>
					<v-row style="margin:10px" v-if="contactInfoEmail && contactInfoEmail.fields && contactInfoEmail.fields.contactDetail && contactInfoEmail.fields.contactDetail.de">
						<v-col style="max-width:32px;margin-top:3px"><img src="@/assets/icons/mail.png"/></v-col>
						<v-col>{{contactInfoEmail.fields.contactDetail.de}}</v-col>
					</v-row>
					<v-divider/>
					<v-row style="margin:10px" v-if="contactInfoAddress">
						<v-col style="max-width:32px;margin-top:3px"><img src="@/assets/icons/map-pin.png"/></v-col>
						<v-col>
							<p class="wrap">
							{{contactInfoAddress.fields.contactAddresses.de[0].fields.country[selectedLocale] ? contactInfoAddress.fields.contactAddresses.de[0].fields.country[selectedLocale] + ', ' : contactInfoAddress.fields.contactAddresses.de[0].fields.country.name[selectedLocale] ? contactInfoAddress.fields.contactAddresses.de[0].fields.country.name[selectedLocale] + ', ' : ''}}
							{{contactInfoAddress.fields.contactAddresses.de[0].fields.city[selectedLocale] ? contactInfoAddress.fields.contactAddresses.de[0].fields.city[selectedLocale] + ', ' : ''}}
							{{contactInfoAddress.fields.contactAddresses.de[0].fields.streetAddress[selectedLocale] ? contactInfoAddress.fields.contactAddresses.de[0].fields.streetAddress[selectedLocale] + ', ' : ''}}
							{{contactInfoAddress.fields.contactAddresses.de[0].fields.zipCode.de}}
							</p>
						</v-col>
					</v-row>
				</div>
			</div>

			<h1 class="mt-5">{{$t('text.operationHours')}}</h1>
			 <div class="card">
				<v-row align="center" justify="center">
					<img src="@/assets/icons/calendar.png" style="margin-right:10px"/><h2>{{$t('text.todayUntil')}} {{validToDate}}</h2>
				</v-row>
				<v-divider/>
				<v-row>
					<v-list-item>
						<v-list-item-content style="margin-left:-15px">
							<h3>{{displayOpenTime}} - {{displayCloseTime}}</h3>
							<p>{{openStatus}}</p>
						</v-list-item-content>
						<v-list-item-avatar height="16px" width="16px" min-width="16px" :color="color"></v-list-item-avatar>
					</v-list-item>
				</v-row>
			</div>  

      <template v-if="product.fields.faqGroup && product.fields.faqGroup.de && product.fields.faqGroup.de.fields">
        <h1 class="mt-5">{{$t('text.faqs')}}</h1>
        <div class="card">
          <v-expansion-panels flat>
            <v-expansion-panel v-for="faq of product.fields.faqGroup.de.fields.references.de" :key="faq.id">
              <v-expansion-panel-header style="padding:0" v-if="faq.fields && faq.fields.question && faq.fields.answer">
                <template v-slot:default>
                  <h3>{{faq.fields.question[selectedLocale] ? faq.fields.question[selectedLocale] : faq.fields.question.de}}</h3>
                </template>
              </v-expansion-panel-header>

              <v-expansion-panel-content style="margin-left:-25px" v-if="faq.fields && faq.fields.question && faq.fields.answer">
                <v-row style="margin-left: 20px !important;margin-right: 20px !important;">
                  <div style="line-height:30px !important">{{faq.fields.answer[selectedLocale] ? faq.fields.answer[selectedLocale] : faq.fields.answer.de}}</div>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </template>
		</div>
	</div>
</template>

<script>
import moment from 'moment'
import Common from '@/mixins/Common.vue'

export default {
	mixins: [ Common ],
	props: {
		product: Object,
		serviceProvider: Object,
    	showPreview: Boolean
	},
	data() {
		return {
		daysOfWeek: ['monday','tuesday','wednesday','thursday','friday','saturday','sunday'],
		validToDate: '',
		displayOpenTime: '',
		displayCloseTime: '',
		isOpen: false,
		openStatus: '',
		color: '#ffffff',
		currency: '',
		showLongDescription: false,

		// TODO: try to lose these
		contactInfoPhone: null,
		contactInfoEmail: null,
		contactInfoWebsite: null,
		contactInfoAddress: null,
	}},
	watch: {
		showPreview(v) {
		if (v === true && this.$vuetify.breakpoint.mdAndDown) {
			this.$emit('scroll-to')
		}
		}
	},
	mounted() {
		this.buildServiceProviderDetails()
		this.getAverageDurationOfStay()
		this.currency = this.$store.state.selectedClient.fields.currency.de
		this.addInlineStyle()
	},
	methods: {
		getImageUrl(url) {
			let imageUrl = 'https:'

			if (url.fields?.file?.de?.url) {
				if (url.fields.file.de.url.indexOf("https:") === -1) {
					imageUrl+= url.fields.file.de.url
				} else {
					imageUrl= url.fields.file.de.url
				}
				
			} else {
				imageUrl+=url
			}
			return imageUrl
		},
		toggleDescription() {
			this.showLongDescription = !this.showLongDescription
		},
		buildServiceProviderDetails() {
			var today = new Date()
			var displayToday = moment(String(today)).format('YYYY-MM-DD')
			var currentTime = (today.getHours() < 10 ? "0" : "") + today.getHours() + ":" + (today.getMinutes() < 10 ? "0" : "") + today.getMinutes()

			for (var businessHours of this.product?.fields.businessHours?.de ?? []) {
				if (displayToday >= businessHours.fields.validFromDate.de && displayToday <= businessHours.fields.validToDate.de) {
					this.validToDate = moment(businessHours.fields.validToDate.de).format('DD.MM.YYYY');

					this.getOpenTimesForDay(businessHours.fields.businessTimes.de, this.daysOfWeek[today.getDay()-1])
					this.getCloseTimesForDay(businessHours.fields.businessTimes.de, this.daysOfWeek[today.getDay()-1])

					if (this.displayOpenTime < currentTime && this.displayCloseTime > currentTime) {
						this.openStatus = this.$t('text.openNow')
						this.isOpen = true
						this.color = '#66BB6A'
					}
					else {
						this.openStatus = this.$t('text.closedNow')
						this.isOpen = false
						this.color = '#E53935'
					}
				}
			}

			if (this.product?.fields?.contactInfoGroups?.de?.length > 0) {
				for (var contactInfo of this.product?.fields?.contactInfoGroups?.de[0]?.fields?.contactInfos?.de ?? []) {
					switch (contactInfo.fields.contactInfoType.de.fields.type.de) {
						case "Phone":
						this.contactInfoPhone = contactInfo
						break

						case "E-Mail":
						this.contactInfoEmail = contactInfo
						break

						case "Address":
						this.contactInfoAddress = contactInfo
						break

						case "Website":
						this.contactInfoWebsite = contactInfo
						break
					}
				}
			}
		},
		getOpenTimesForDay(businessHours, day) {
			for (var businessTime of businessHours?.[day]?.times ?? []) {
				this.displayOpenTime = businessTime.openTime
				break
			}
		},
		getCloseTimesForDay(businessHours, day) {
			for (var businessTime of businessHours?.[day]?.times ?? []) {
				this.displayCloseTime = businessTime.closeTime
				break
			}
		},
		getTicketOptionName(ticketOption) {
			const to = ticketOption.fields
			let name = to.title[this.selectedLocale] ?? to.title.de
			name = name ?? '>>' + to.reduction.de.fields.title[this.selectedLocale] + ' ' + to.ticketType.de.fields.title[this.selectedLocale]
			return name
		},

		getAverageDurationOfStay() {
			let translationText = ""
			if (this.product.fields.averageDurationOfStayInMinutes?.de) {
				let roundedTime = this.timeConvert(this.product.fields.averageDurationOfStayInMinutes?.de)

				translationText = this.$t('text.averageLengthOfStayPreview')
				
				if (roundedTime.hours && roundedTime.minutes) {
					translationText = translationText.replace("%n%", `${roundedTime.hours} ${this.$t('text.hoursLowercase')} and ${roundedTime.minutes} ${this.$t('text.minutes')}`)
				} else if (roundedTime.hours) { 
					translationText = translationText.replace("%n%", `${roundedTime.hours} ${this.$t('text.hoursLowercase')}`)
				} else { 
					translationText = translationText.replace("%n%", `${roundedTime.minutes} ${this.$t('text.minutes')}`)
				}
			}
			return translationText
		},
		roundTime(hours, minutes, minutesToRound) {
			// Convert hours and minutes to minutes
			let time = (hours * 60) + minutes; 
			let rounded = Math.round(time / minutesToRound) * minutesToRound;

			let roundedHours = Math.floor(rounded / 60)
			let roundedMinutes = rounded % 60

			return { hours: roundedHours, minutes: roundedMinutes }
		},
		timeConvert(n) {
			let num = n;
			let hours = (num / 60);
			let rhours = Math.floor(hours);
			let minutes = (hours - rhours) * 60;
			let rminutes = Math.round(minutes);
			let roundedTime = this.roundTime(rhours, rminutes, 15)
			return roundedTime
			// return num + " minutes = " + rhours + " hour(s) and " + rminutes + " minute(s).";
		},
		addInlineStyle() {
			if (this.product.fields.importantInformation) {
				for (let key of Object.keys(this.product.fields.importantInformation)) {
					this.product.fields.importantInformation[key] = this.product.fields.importantInformation[key].replaceAll('<p>', '<p style="color:black">')
				}
			}
			if (this.product.fields.longDescription) {
				for (let key of Object.keys(this.product.fields.longDescription)) {
					if (this.product.fields.longDescription[key] && typeof this.product.fields.longDescription[key] === 'string') {
						this.product.fields.longDescription[key] = this.product.fields.longDescription[key].replaceAll('<p>', '<p style="color:black">')
					}
				}
			}
		}
	},
}
</script>

<style scoped>
#preview {
	width: 500px;
	background-color: #e6e6e6;
	border-radius: 10px;
	position: relative;
	display: inline-block;
	padding:10px;
	zoom: 0.66;
}

h2 {
	color: #000000;
	font-size: 18pt;
	font-weight: bold;
	text-align: left;
}
h3 {
	color: #000000;
	font-size: 16pt;
	text-align: left;
	font-weight: normal;
	margin-left: 20px;
	margin-top: 20px;
}
h4 {
	color: #000000;
	font-size: 14pt;
	text-align: left;
	font-weight: bold;
}
p {
	margin-left: 20px;
}
.wrap {
	margin-left: 0;
	font-family: 'Inter', sans-serif;
	font-style: normal;
	font-size: 16pt;
	-moz-osx-font-smoothing: grayscale;
	color: black !important;
}
.card {
	border-radius: 20px;
	background-color: #ffffff;
	min-height: 100px;
	padding: 10px;
	text-align: left;
}
.cardContent {
	color: #000000;
	font-size: 16pt;
	text-align: left;
	font-weight: normal;
}
.content {
	background-color: #e6e6e6;
}
.prices { width: 100%; margin-right: 20px; font-size: 16pt; }
.prices td { padding: 10px; vertical-align: top; }
</style>
