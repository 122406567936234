<template>
  <v-container fluid class="pa-0 mb-12">
    <!-- Mobile App View -->
    <div v-if="view==='mobile'">
      <MobileAppView @toggle-view="toggleView"/>
    </div>

    <!-- Web App View -->
    <div v-if="view==='web'">
      <WebAppView @toggle-view="toggleView"/>
    </div>
  </v-container>
</template>

<script>
import MobileAppView from './MobileAppView.vue'
import WebAppView from './WebAppView.vue'
import Common from '@/mixins/Common.vue'

export default {
  name: 'ValidityChecker',
  components: {MobileAppView, WebAppView},
  mixins: [ Common ],

  data() {
    return {
      view: 'mobile'
    }
  },

  methods: {
    toggleView() {
      this.view = this.view === 'mobile' ? 'web' : 'mobile'
    }
  },
}
</script>