import Router from "vue-router"

import Dashboard from '@/views/dashboard/Dashboard.vue'
import Login from '@/views/main/Login.vue'
import ForgotPassword from '@/views/main/ForgotPassword.vue'
import Registration from '@/views/main/Registration.vue'

import ServiceProvidersView from '@/views/applications/serviceProviders/ServiceProvidersView.vue'
import BusinessProfile from '@/views/applications/serviceProviders/BusinessProfile.vue'

import SettingsView from '@/views/applications/settings/SettingsView.vue'
import ChecklistView from '@/views/applications/settings/ChecklistView.vue'
import ProductCategoryView from '@/views/applications/settings/ProductCategoryView.vue'
import ProductCategoryDetailView from '@/views/applications/settings/ProductCategoryDetailView.vue'

import ServicesView from '@/views/applications/serviceDesigner/ServicesView.vue'
import ServiceDesigner from '@/views/applications/serviceDesigner/ServiceDesigner.vue'

import FAQListView from '@/views/applications/faqs/FAQListView.vue'
import FAQDetailView from '@/views/applications/faqs/FAQDetailView.vue'

import OrdersView from '@/views/applications/orders/OrdersView.vue'
import OrderDetailView from '@/views/applications/orders/OrderDetailView.vue'

import MediaGallery from '@/views/applications/mediaGallery/MediaGallery.vue'
import TrainingLibraryView from '@/views/applications/trainingLibrary/TrainingLibraryView.vue'
import VideoPlayer from '@/views/applications/trainingLibrary/VideoPlayer.vue'

import MarketplaceView from '@/views/applications/marketplace/MarketplaceView.vue'
import TagManagerView from '@/views/applications/tagManager/TagManagerView.vue'

import MyOrganisationView from '@/views/applications/myOrganisation/MyOrganisationView.vue'

import DeepLink from '@/components/deepLink/DeepLink.vue'
import AppHost from '@/views/applications/appHost/AppHost.vue'

import LogViewer from '@/views/applications/logViewer/LogViewer.vue'

import ValidityCheckerView from '@/views/applications/validityChecker/ValidityCheckerView.vue'

let routes = [
    { 
        path: "/", 
        component: Login 
    },
    { 
        path: "/forgotPassword", 
        component: ForgotPassword 
    },
    { 
        path: "/dashboard", 
        component: Dashboard 
    },
    { 
        path: "/registration", 
        component: Registration 
    },
    { 
        path: "/videoPlayer", 
        component: VideoPlayer 
    },
    { 
        path: "/d/*", 
        component: DeepLink,
        props: true
    },
    { 
        path: "/app/:appName", 
        component: AppHost,
        props: true
    },
    {
        path: "/settingsView",
        component: SettingsView,
        props: route => ({ viewType: route.query.viewType })
    },
    {
        path: "/serviceProviders",
        component: ServiceProvidersView
    },
    {
        path: "/businessProfile",
        component: BusinessProfile
    },
    {
        path: "/services",
        component: ServicesView
    },
    {
        path: "/service-designer",
        component: ServiceDesigner
    },
    {
        path: "/faq-list",
        component: FAQListView
    },
    {
        path: "/faq-detail",
        component: FAQDetailView
    },
    {
        path: "/orders",
        component: OrdersView,
        props: route => ({ viewType: route.query.viewType })
    },
    {
        path: "/orderDetail",
        component: OrderDetailView
    },
    {
        path: "/mediaGallery",
        component: MediaGallery
    },
    {
        path: "/trainingLibrary",
        component: TrainingLibraryView
    },
    {
        path: "/marketplace",
        component: MarketplaceView
    },
    {
        path: "/tagManager",
        component: TagManagerView
    }, 
    {
        path: "/logs", 
        component: LogViewer, 
    },
    {
        path: "/myOrg",
        component: MyOrganisationView
    },
    {
        path: "/validityChecker",
        component: ValidityCheckerView
    },
    {
        path: "/checklist",
        component: ChecklistView
    },
    {
        path: "/productCategory",
        component: ProductCategoryView
    },
    {
        path: "/productCategoryDetail",
        component: ProductCategoryDetailView,
        props: route => ({ categoryId: route.query.categoryId })
    }
]

export default new Router({
    mode: 'history',
    routes: routes
})
