<template>
	<v-container class="main pb-12" fluid fill-height>
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>
		<v-row align="center" justify="center" v-if="showLogin">
			<v-card id="card" class="elevation-12">
				<v-card-text class="pa-0">
					<h1>{{$t('text.loginTitle')}}</h1>
					<p class="subTitle" v-html="$t('text.loginSubTitle')" />
					<v-row>
						<v-col class="d-flex flex-column field left-border" cols="12" sm="6">
							<h3>{{$t('text.login')}}</h3>
							<v-form>
								<v-label>{{$t('text.email')}}</v-label>
								<v-text-field outlined dense required height="48"
								v-model="email"
								:error-messages="emailErrors"
								@input="$v.email.$touch()"
								@blur="$v.email.$touch()"
								@keyup.enter="login()"
								data-cy="email"
								/>
								<v-label>{{$t('text.password')}}</v-label>
								<v-row class="flex-nowrap">
								<v-text-field :type="type" outlined dense required height="48"
									v-model="password"
									:error-messages="passwordErrors"
									@input="$v.password.$touch()"
									@blur="$v.password.$touch()"
									@keyup.enter="login()"
									data-cy="password"
								/>
								<v-btn class="gradientButton ml-1 mt-3" small depressed :disabled="password.length===0" @click="showPassword()">
									<v-icon v-if="!plainTextPassword">mdi-eye</v-icon>
									<v-icon v-if="plainTextPassword">mdi-eye-off</v-icon>
								</v-btn>
								</v-row>
								<v-row>
									<router-link style="z-index: 99;" to="/forgotPassword">{{$t('text.forgotPassword')}}</router-link>
								</v-row>
							</v-form>
							<v-row align="end">
								<v-btn id="btnLogin" x-large depressed dark class="greenButton" @click.prevent="login()" data-cy="login-button">{{$t('text.btnLogin')}}</v-btn>
							</v-row>
						</v-col>
						<v-col class="field left-border" cols="12" sm="6">
							<h3>{{$t('text.register')}}</h3>
							<ul>
							<li>{{$t('text.registerItem1')}}</li>
							<li>{{$t('text.registerItem2')}}</li>
							<li>{{$t('text.registerItem3')}}</li>
							<li>{{$t('text.registerItem4')}}</li>
							</ul>
							<v-row align="end">
							<v-btn id="btnRegister" class="greenButton" x-large depressed dark @click="$router.push('registration')">{{$t('text.btnRegister')}}</v-btn>
							</v-row>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-row>

		<!-- Maintenance Mode -->
		<v-row align="center" justify="center"  v-if="showMaintenance">
			<v-card id="card" class="elevation-12">
				<v-card-text style="margin:0px;padding:0px">
					<center><img src="@/assets/icons/maintenance.svg" width="128"/></center>
					<p class="maintenance" align="center" v-html="$t('text.maintenance')"/>
				</v-card-text>
			</v-card>
		</v-row>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import { required, email } from "vuelidate/lib/validators"
import Alert from '@/components/common/Alert.vue'
import Common from '@/mixins/Common.vue'

export default {
	name: 'Login',
	components: { Loading, Alert },
	mixins: [ Common ],
	validations: {
		email: { required, email },
		password: { required },
	},
	data() {
		return {
		showLogin: false,
		showMaintenance: false,
		loading: false,
		errorTitle: '',
		errorDetail: '',

		email: '',
		password: '',
		plainTextPassword: false,
		type: 'password'
	}},
	computed: {
		emailErrors() {
			const errors = []
			if (!this.$v.email.$dirty) return errors
			!this.$v.email && errors.push(this.$t('text.emailInvalid'))
			!this.$v.email.required && errors.push(this.$t('text.emailRequired'))
			return errors
		},
		passwordErrors() {
			const errors = []
			if (!this.$v.password.$dirty) return errors
			!this.$v.password.required && errors.push(this.$t('text.passwordRequired'))
			return errors
		},
	},
	async mounted() {
		if (this.$store.state.loggedInUser && Object.entries(this.$store.state.loggedInUser).length > 0) {
			this.$emit("show-dashboard");
		}
		else {
			localStorage.clear();
		
			if (this.$route.path === "/") {
				const maintenanceMode = await this.checkMaintenanceMode()

				if (maintenanceMode === true) {
					this.showMaintenance = true
					this.showLogin = false
				} else {
					this.showLogin = true
					this.showMaintenance = false
				}

			}
			else {
				this.showLogin = false
			}
		}
	},
	beforeDestroy () {
		this.$root.$off('show-dashboard');
	},
	methods: {
		async checkMaintenanceMode() {
			try {
				return await this.$httpGet(`/maintenance-mode`)
			}
			catch (error) {
				console.error(error)
			}
		},
		showPassword() {
			if (this.type === 'password') {
				this.type = 'text'
				this.plainTextPassword = true
			} else {
				this.type = 'password'
				this.plainTextPassword = false
			}
		},
		async login() {
			this.$v.$touch();

			if (this.emailErrors.length !== 0 || this.passwordErrors.length !== 0) {
				return
			}

			this.loading = true;
			try {
				let user = await this.$httpPost('/login', {username:this.email,password:this.password})

				if (!user?.kc_token) this.showError(this.$t('text.loginInvalidCredentialsError'))

				// Sort according to personal dashboard
				const components = user.fields.applications.de

				await this.$store.commit('setComponents', components);
				await this.$store.commit('setLoggedInUser', user);

				if (!user.fields.clients?.de) {
					console.error("user does not have any clients set")
				}

				if (user.fields.type.de === "serviceprovider") {
					await this.$store.commit('setSelectedServiceProvider', user.fields.serviceProvider.de)
					await this.$store.commit('setSelectedClient', user.fields.clients.de[0])
					this.$emit("show-dashboard");
				}
				else if (user.fields.type.de === "operator") {
					await this.$store.commit('setSelectedClient', user.fields.clients.de[0])

					let components = await this.$httpGet(`/components/${this.$store.state.selectedClient.sys.id}/serviceprovider`)
					components.sort((a, b) => (a.fields.title[this.selectedLocale] > b.fields.title[this.selectedLocale]) ? 1 : -1)
					await this.$store.commit('setSPApplications', components)
				
					this.$emit("show-dashboard");
				}
				else if (user.fields.type.de === "admin") {
					await this.$store.commit('setSelectedClient', user.fields.clients.de[0])
					this.$emit("show-dashboard");
				}
				else {
					// TODO: actually log out?
					console.error("received user with unsupported type:", user.fields.type.de)
				}

			} catch(error) {
				if (error.error) {
					this.showError(error.error)
				} else {
					this.showError(this.$t('text.loginInvalidCredentialsError'))
				}
			}
			this.loading = false;
		},
	}
}
</script>

<style scoped>
.main {
	background-image: url('/myservices_background.jpg');
	background-size: cover !important;
}
ul { color: black; font-size: 12pt; }
ul li { margin-bottom: 10px; }
.maintenance { font-size: 16pt; line-height:2 }
</style>