<template>
	<v-container fluid style="padding-bottom: 70px;">
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<loading v-if="loading" :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

		<!-- navbar -->
		<div class="navbar">
			<v-row >
				<v-text-field outlined clearable dense hide-details height="48"
					prepend-inner-icon="mdi-magnify"
					placeholder='Search'
					style="max-width:33%" 
					v-model="searchString" 
					@keyup.enter="getLogs()" 
					@click:clear="clearSearch()"
				/>
				<v-spacer/>

				<!-- Filters -->
				<v-row style="padding:0px;" justify="end">
					<div>
						<v-menu offset-y bottom left transition="scale-transition" origin="top right" style="width:500px !important;min-width:500px !important;max-width:500px !important" :close-on-content-click="false" v-model="isOpened">
							<template v-slot:activator="{ on }" style="width:500px !important;min-width:500px !important;max-width:500px !important">
								<v-row style="width:75px" align="center" justify="end"  v-on="on">
									<v-btn class="gradientButton" elevation="0" style="height:40px;font-size:12pt">
                    <v-icon>mdi-filter</v-icon>
                    <span class="d-none d-md-inline">{{$t('text.filter')}}</span>
										<v-icon style="cursor:pointer;">mdi-menu-down</v-icon>
									</v-btn>
								</v-row>
							</template>

							<v-list style="width:500px !important;min-width:500px !important;max-width:500px !important;padding:0px">
								<v-list-item style="width:500px !important;min-width:500px !important;max-width:500px !important;padding-top:20px">
									<v-list-item-title>
										<div style="padding: 0 10px" data-cy="statusFilter">
											<span style="width:88%">{{$t('text.date')}}</span>

											<v-row >
												<v-btn class="gradientButton" filled dense solo elevation="0" style="width:48%;margin-right:4%" @click="toggleDatePicker('from')">{{validFromText}}</v-btn>
												<v-btn class="gradientButton" filled dense solo elevation="0" style="width:48%" @click="toggleDatePicker('to')">{{validToText}}</v-btn>
											</v-row>
											<br/>
											<p align="center">
												<v-date-picker v-if="showFromDate" no-title v-model="validFromDate" color="green lighten-1" :first-day-of-week="1" @input="resetDates()"/>
												<v-date-picker v-if="showToDate" no-title v-model="validToDate" color="green lighten-1" :first-day-of-week="1" :min="validFromDate" @input="showToDate=false"/>
											</p>
										</div>
									</v-list-item-title>
								</v-list-item>

								<v-list-item style="width:500px !important;min-width:500px !important;max-width:500px !important;padding-top:20px">
									<v-list-item-title>
										<div style="padding: 0 10px" data-cy="userEmailFilter">
											<span>{{$t('text.user')}}</span>
											<v-text-field outlined hide-details 
												v-model="userEmail"
												:placeholder="$t('text.email')"
											/>
										</div>
									</v-list-item-title>
								</v-list-item>

								<v-list-item style="width:500px !important;min-width:500px !important;max-width:500px !important;padding-top:20px">
									<v-list-item-title>
										<div style="padding: 0 10px" data-cy="serviceProviderIdFilter">
											<span>{{$t('text.serviceProvider')}} ID</span>
											<v-text-field outlined hide-details 
												v-model="serviceProviderId"
												:placeholder="$t('text.serviceProvider') + ' ID'"
											/>
										</div>
									</v-list-item-title>
								</v-list-item>
							
								<v-list-item style="width:500px !important;min-width:500px !important;max-width:500px !important;padding-top:20px">
									<v-list-item-title>
										<div style="padding: 0 10px" data-cy="productIdFilter">
											<span>{{$t('text.service')}} ID</span>
											<v-text-field outlined hide-details 
												v-model="productId"
												:placeholder="$t('text.service') + ' ID'"
											/>
										</div>
									</v-list-item-title>
								</v-list-item>

								<v-list-item style="width:500px !important;min-width:500px !important;max-width:500px !important;padding-top:20px; padding-bottom:20px;">
									<div style="padding: 0 10px" data-cy="impersonatedSPFilter">
										<v-checkbox
											v-model="impersonatedSP"
											:label="$t('text.logsWithImpersonatedSp')"
											color="success"
											:value="impersonatedSP"
											hide-details
										></v-checkbox>
									</div>
								</v-list-item>

								<br/>
								<v-divider/>

								<v-row style="padding:20px;">
									<v-btn class="gradientButton" elevation="0" style="width:48%;margin-right:4%" @click="clearFilter()">{{$t('text.clearFilter')}}</v-btn>
									<v-btn class="greenButton" elevation="0" dark style="width:48%;" @click="applyFilter()">{{$t('text.applyFilter')}}</v-btn>
								</v-row>
							</v-list>
						</v-menu>
					</div>
				</v-row>
			</v-row>
		</div>

		<v-card class="logTable">
			<v-data-table fixed-header hide-default-footer disable-sort 
				class="elevation-0"
				style="border-radius: 0"
				:headers="headers"
				:items="logEntries" 
				:single-expand="singleExpand"
    			:expanded.sync="expanded" 
				show-expand 
    			item-key="sys.id">
			
				<template v-slot:expanded-item="{ headers, item }">
					<td  style="margin:0;padding:0" :colspan="headers.length">
						<pre v-if="item.fields.differences"><json-viewer :value="item.fields.differences"></json-viewer></pre>
					</td>
				</template>
				<template v-slot:item.sys.createdAt="{ item }">
					<span>{{ item.sys.createdAt | formatDateTime }}</span>
				</template>
			</v-data-table>
			<v-card class="tableFooter">
				<v-card-actions>
					<TablePaginator v-model="offset" :limit="limit" :total="entryCount" @input="getLogs()" :results="logEntries" />
				</v-card-actions>
			</v-card>
		</v-card>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Alert from '@/components/common/Alert.vue'
import TablePaginator from '@/components/common/TablePaginator.vue'
import JsonViewer from 'vue-json-viewer'

export default {
	name: 'LogViewer',
	components: { Alert, Loading, TablePaginator, JsonViewer },
	data() {
		return {
			loading: false,
			errorTitle: '',
			errorDetail: '',

			searchString: '',
			userEmail: '',
			serviceProviderId: '',
			productId: '',
			impersonatedSP: false,
			showFromDate: false,
			showToDate: false,
      		
			validFromDate: this.$store.state.filter ? this.$store.state.filter.validFromDate : '',
			validToDate: this.$store.state.filter ? this.$store.state.filter.validToDate : '',
			filter: this.$store.state.filter,

			isOpened: false,
			dropdownOpen: false,
			entryCount:  0,
			offset: 0,
			limit: 15,
			logEntries: [],
			expanded: [],
        	singleExpand: false,
		}	
	},
	computed: {
		headers () {
			return [
				{ text: this.$t('text.comment'), align: 'left', sortable: false, value: 'fields.textInfo' },
				{ text: this.$t('text.user'), sortable: false, value: 'fields.loggedInUser.email.de' },
				{ text: this.$t('text.serviceProvider'), sortable: false, value: 'fields.impersonationDetails.impersonatedServiceProvider.name.de' },
				{ text: this.$t('text.createDate'), width: '200', sortable: false, value: 'sys.createdAt' },
				{ text: '', value: 'data-table-expand' }
			]
		},


		validFromText() {
			return this.validFromDate !== '' ? this.validFromDate : this.$t('text.dateFrom')
		},
		validToText() {
			return this.validToDate !== '' ? this.validToDate : this.$t('text.dateTo')
		},
	},
	async mounted() {

		this.getLogs()
	},

	methods: {
		clearSearch() {
			this.searchString = ''
			this.getLogs()
		},
		async getLogs() {
			this.loading = true

			const res = await this.$httpPost(`/logs`, {
					clientId: this.$store.state.selectedClient.sys.id,
					skip: this.offset,
					limit: this.limit + 1,
					searchString: this.searchString,
					filter: JSON.stringify(this.$store.state.filter)
				}
			)

			this.logEntries = res.entries
			this.entryCount = res.total

			this.loading = false
		}, 
		closeDropDown(ref) {
			if (!this.dropdownOpen) {
				this.$refs[ref].focus()
				this.dropdownOpen = true
			} else {
				this.$refs[ref].blur()
				this.dropdownOpen = false
			}
		},
		toggleDatePicker(pickerToToggle) {
			if (pickerToToggle === 'from') {
				this.showToDate = false
				this.showFromDate = true
			} else {
				this.showFromDate = false
				this.showToDate = true
			}
    	},
		resetDates() {
			this.validToDate = ''
			this.showFromDate = false
		},
		applyFilter() {
			this.$store.state.filter = {
				orderBy: 'date',
				fromDate: this.validFromDate,
				toDate: this.validToDate,
				serviceProviderId: this.serviceProviderId,
				productId: this.productId,
				impersonatedSP: this.impersonatedSP,
				userEmail: this.userEmail
			}

			this.filter = this.$store.state.filter
			this.isOpened = false
			this.getLogs()
		},
		clearFilter() {
			this.validFromDate = ''
      		this.validToDate = ''
			this.serviceProviderId = ''
			this.productId = ''
			this.impersonatedSP = false
			this.userEmail = ''
			this.isOpened = false

			this.$store.state.filter = null
			this.filter = this.$store.state.filter
			this.getLogs()
		},
	}
}
</script>