<template>
	<v-container
      fluid
      class="pa-0 mb-12"
      :style="isSideBarFixed ? 'padding-left: 380px !important;' : 'padding-left: 40px !important;'"
  >
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<loading v-if="loading" :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

		<!-- navbar -->
		<div class="navbar">
			<v-row align="center" class="flex-nowrap" style="white-space:nowrap; overflow:hidden;">
				<v-btn id="btnBack" elevation="0" style="background-color:transparent;" @click="goback()">
					<v-icon>mdi-chevron-left</v-icon>
				</v-btn>
				<v-row justify="center">
					<h1>{{ getCategoryTitle() }}</h1>
				</v-row>
			</v-row>
		</div>

		<SideBar>
			<div class="buttonBlock">
				<v-btn block class="greenButton" dark elevation="0" data-cy="save" @click="upsertProductCategory()"><v-icon>mdi-check</v-icon>{{$t('text.saveChanges')}}</v-btn><br/>
				<v-btn v-if="category.sys.id !== '' && category.fields.subProductCategories.de.length === 0"
					block class="redButton" dark elevation="0" data-cy="delete" :disabled="locked(category)" @click="deleteCategory(category.sys.id, 'main')"><v-icon>mdi-delete</v-icon>{{$t('text.delete')}}
				</v-btn>
			</div>

			<v-card class="info mb-3 pa-2" flat dark v-if="category.fields.subProductCategories.de.length > 0">{{$t('text.deleteCategoryInfo')}}</v-card>
		</SideBar>

		<!-- Product Category Detail -->
		<div class="pa-3">
			<Disclosure :title="$t('text.category')" data-cy="categoryInfo" ref="SECTION_categoryInfo" v-if="category && category.fields" :expanded="true">
				<div class="field left-border">
					<v-label>
						<v-icon v-if="locked(category)" size="24px" color="gray">mdi-lock-outline</v-icon>
						{{$t('text.categoryName')}} <span class="error-text">({{$t('text.required')}})</span>
					</v-label>

					<v-text-field
						v-for="locale in locales"
						:key="locale.code"
						outlined required
						:readonly="locked(category)"
						data-cy="categoryName"
						v-model.trim="category.fields.title[locale.code]"
						:error-messages="validateCategoryName(locale.code)"
					>
						<template v-slot:prepend>
							<div class="serviceLocale" style="margin-top:-10px !important"><img :src="getFlagIcon(locale.code)" width="24px" height="24px" style="margin-top:12px;margin-right:5px"/></div>
						</template>
					</v-text-field>
				</div>

				<div class="field left-border">
					<v-label>
						<v-icon v-if="locked(category)" size="24px" color="gray">mdi-lock-outline</v-icon>
						{{$t('text.type')}} <span class="error-text">({{$t('text.required')}})</span>
					</v-label>
					<v-text-field outlined required
						data-cy="type"
						:readonly="locked(category)"
						:hide-details="typeErrors.length===0"
						v-model.trim="category.fields.type.de"
						:error-messages="typeErrors"
						@input="validateType"
					/>
					<p class="helpText" v-html="$t('text.categoryTypeHelp')"/>
				</div>

				<div class="field left-border">
					<v-row class="pa-0">
						<v-col class="pa-0">
							<v-label>
								<v-icon v-if="locked(category)" size="24px" color="gray">mdi-lock-outline</v-icon> 
								{{ $t('text.icon') }}
							</v-label>
							<p class="helpText" v-html="$t('text.iconHelp')"/>
						</v-col>
						<v-spacer/>
						<v-btn v-if="isUpdateIcon" class="gradientButton" elevation="0" dense @click="cancelUpload()">{{ $t('text.cancelUpload') }}</v-btn>
					</v-row>
					
					<MediaCard style="margin-left:0" 
						v-if="!isUpdateIcon && category.fields.icon && category.fields.icon.de && category.fields.icon.de.fields"
						:media="category.fields.icon.de" 
						:canEdit="true" 
						:canSelect="false"
						:showTitle="false"
						@show-media-update="showUpdateIcon(category)"
					/>
					<br/>
					<FileUpload 
						v-if="(isUpdateIcon || (!category.fields.icon || !category.fields.icon.de || !category.fields.icon.de.fields)) && !locked(category)"
						:options="dropOptions" 
						ref="fileUpload" 
						@thumbnail-created="onThumbnailCreated" 
						@file-removed="cancelUpload"
					/>
				</div>

				<div class="field left-border" v-if="category.sys.id !== ''">
					<v-label>
						<v-icon v-if="locked(category)" size="24px" color="gray">mdi-lock-outline</v-icon> 
						{{ $t('text.subCategories') }}
					</v-label><br/>
					<div v-if="category.fields.subProductCategories">
						<div class="entry" v-for="subCategory of category.fields.subProductCategories.de" :key="subCategory.sys.id">
							<v-row>
								<v-col>
									{{subCategory.fields.title[selectedLocale]}}
								</v-col>
								<v-col class="text-right">
									<v-menu rounded="5" offset-y style="float: right;">
										<template v-slot:activator="{ attrs, on }">
											<v-icon v-bind="attrs" v-on="on">mdi-dots-horizontal</v-icon>
										</template>
										<v-list>
											<v-list-item link>
												<v-list-item-title v-text="$t('text.edit')" @click="showEditSubCategory(subCategory)"></v-list-item-title>
											</v-list-item>
											<v-list-item link>
												<v-list-item-title v-text="$t('text.delete')" @click="deleteCategory(subCategory.sys.id, 'sub')"></v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
								</v-col>
							</v-row>
						</div>
					</div>

					<br/>
					<v-row>
						<v-btn elevation="0" class="gradientButton" style="margin-right:10px" @click="showAddSubCategory()">
							<v-icon>mdi-plus</v-icon> {{$t('text.addNewSubCategory')}}
						</v-btn>
					</v-row>
				</div>
			</Disclosure>
		</div>

		<!-- Sub Category Dialog -->
		<Dialog ref="subCatDialog"
			:title="$t('text.subCategory')"
			:confirmLabel="$t('text.saveChanges')"
			:cancelLabel="$t('text.discardChanges')"
			:confirm-handler="upsertSubCategory"
			:cancel-handler="resetSubCategory"
			:show-close="false" 
			:isValid="subCategoryValid"
			width="50%"
			height="90%">
			<template #content>
				<v-col>
					<div class="field left-border">
						<v-label>
							<v-icon v-if="locked(subCategory)" size="24px" color="gray">mdi-lock-outline</v-icon>
							{{$t('text.categoryName')}} <span class="error-text">({{$t('text.required')}})</span>
						</v-label>
						<!-- add a helptext -->
						<p class="helpText" v-html="$t('text.itemMissingLocale')"/>
							<v-text-field
								v-for="locale in locales"
								:key="locale.code"
								data-cy="editSubCatNameField" 
								outlined
								required
								hide-details
								:error-messages="validateSubCategoryName(locale.code)"
								:readonly="locked(subCategory)"
								v-model.trim="subCategory.fields.title[locale.code]">
							>
							<template v-slot:prepend>
								<div class="serviceLocale" style="margin-top:-10px !important"><img :src="getFlagIcon(locale.code)" width="24px" height="24px" style="margin-top:12px;margin-right:5px"/></div>
							</template>
						</v-text-field>
					</div>
				
					<div class="field left-border">
						<v-label>
							<v-icon v-if="locked(subCategory)" size="24px" color="gray">mdi-lock-outline</v-icon>
							{{$t('text.type')}} <span class="error-text">({{$t('text.required')}})</span>
						</v-label>
						<v-text-field outlined required
							data-cy="type"
							:readonly="locked(subCategory)"
							:hide-details="subCatTypeErrors.length===0"
							v-model.trim="subCategory.fields.type.de"
							:error-messages="subCatTypeErrors"
							@input="validateSubCatType"
						/>
					</div>

					<div class="field left-border">
						<v-row style="padding:10px">
							<v-label>{{ $t('text.icon') }}</v-label>
							<v-spacer/>
							<v-btn v-if="isUpdateSubCatIcon" class="gradientButton" elevation="0" dense @click="cancelUpload()">{{ $t('text.cancelUpload') }}</v-btn>
						</v-row>

						<MediaCard 
							v-if="!isUpdateSubCatIcon && subCategory.fields.icon && subCategory.fields.icon.de && subCategory.fields.icon.de.fields"
							:media="subCategory.fields.icon.de" 
							:canEdit="true" 
							:canSelect="false"
							:showTitle="false"
							@show-media-update="showSubCatUpdateIcon(subCategory)"
						/>
						<br/>
						<FileUpload 
							v-if="(isUpdateSubCatIcon || !subCategory.fields.icon || !subCategory.fields.icon.de || !subCategory.fields.icon.de.fields) && !locked(subCategory)"
							:options="dropOptions" 
							ref="fileUploadSubCat" 
							@thumbnail-created="onSubCatThumbnailCreated" 
							@file-removed="cancelSubCatIconUpload"
						/>
					</div>
				</v-col>
			</template>
		</Dialog>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import SideBar from "@/components/common/Sidebar"
import Alert from '@/components/common/Alert.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import LanguageFlag from '@/components/common/LanguageFlag.vue'
import MediaCard from '@/components/media/MediaCard'
import FileUpload from "@/components/media/FileUpload"
import Dialog from '@/components/common/Dialog'
import Common from '@/mixins/Common.vue'

export default {
	name: "ProductCategoryView",
	mixins: [ Common ],
	components: { Loading, SideBar, Alert, Disclosure, LanguageFlag, MediaCard, FileUpload, Dialog },
	props: {
		categoryId: String
	},
	data() {
		return {
			loading: false,
			errorTitle: '',
			errorDetail: '',
			successTitle: '',
			successDetail: '',
			runValidation: false,

			category: {
				sys: {
					id: ''
				},
				fields: {
					title: {},
					type: {de: ''},
					mainCategory: {de: true},
					icon: {de: {}},
					subProductCategories: {de: []}
				}
			},

			shouldValidate: false,

			categoryNameErrors:[],
			typeErrors: [],
			originalIcon: {},
			newIcon: {},
			isUpdateIcon: false,

			subCategory: {
				sys: {
					id: ''
				},
				fields: {
					title: {de:'',en:'',fr:'',it:''},
					type: {de: ''},
					mainCategory: {de: false},
					icon: {de: {}},
					subProductCategories: {de: []}
				}
			},

			originalSubCat: {},
			subCategoryNameErrors:[],
			subCatTypeErrors: [],

			originalSubCatIcon: {},
			newSubCatIcon: {},
			isUpdateSubCatIcon: false,

			dropOptions: {
				acceptedFiles: '.svg',
				addRemoveLinks: true,
				maxFilesize: 10,
				maxFiles: 1
			},
		}
	},

	async mounted() {
		if (this.categoryId) {
			await this.getProductCategoryDetails()
		} 
		else {
			this.category = {
				sys: {
					id: ''
				},
				fields: {
					title: {
						de: '',
						en: '',
						fr: '',
						it: ''
					},
					type: {de: ''},
					mainCategory: {de: true},
					icon: {de: {}},
					subProductCategories: {de: []}
				}
			}
		}
	},

	computed: {
		subCategoryValid() {
			return !(!this.checkRequiredForAllLocales(this.locales, this.subCategory.fields.title) || !this.subCategory.fields.type.de)
			
		},
	},

	methods: {
		goback() {
			this.$router.push('/productCategory')
		},
		locked(category) {
			if (category?.fields?.locked?.de) {
				return category.fields.locked.de
			}
			return false
		},
		getFlagIcon(locale) {
			if (locale === "de") {
				return this.getGermanFlagIcon()
			} else {
				const images = require.context('@/assets/flags/', false, /\.svg$/)
				return images('./' + locale + ".svg")
			}
		},
		getGermanFlagIcon() {
			var images = require.context('@/assets/flags/', false, /\.svg$/)

			if (this.$store.state.userSpecificGerman && this.$store.state.userSpecificGerman !== "") {
				return images('./' + this.$store.state.userSpecificGerman + ".svg")
			} else {
				return images('./' + "de.svg")
			}
		},
		validate() {
			this.runValidation = true
			this.categoryNameErrors = []
			this.typeErrors = []

			//main
			if (!this.checkRequiredForAllLocales(this.locales, this.category.fields.title)) {
				this.categoryNameErrors.push(this.$t('text.itemMissingLocale'))
			}
			if (!this.category.fields.type.de) {
				this.typeErrors.push(this.$t('text.missingFieldsError'))
			}
			if (this.categoryNameErrors.length > 0 || this.typeErrors.length > 0)  {
				this.errorTitle = this.$t('text.ERROR')
				this.errorDetail = this.$t('text.missingFieldsError')
				return false
			}

			return true
		},
		validateSubCategoryName (locale) {
			let errors = []
			if (this.subCategory.fields.title[locale] === '' && this.subCategory.fields.title[locale] !== undefined) {
				errors.push(this.$t('text.itemMissingLocale'))
			}
			return errors
		},
		validateCategoryName(locale) {
			if (!this.shouldValidate) return []
			let errors = []
			if (this.category.fields.title[locale] === '' && this.category.fields.title[locale] !== undefined) {
				errors.push(this.$t('text.itemMissingLocale'))
			}
			return errors
		},
		validateType() {
			let isValid = true
			this.typeErrors = []

			if (!this.category.fields.type) {
				isValid = false
				this.typeErrors.push(this.$t('text.missingFieldsError'))
			}
			return isValid
		},
		showUpdateIcon(category) {
			if (!this.locked(category)) {
				this.isUpdateIcon = true
			}
		},
		onThumbnailCreated(file) {
			this.newIcon = {
				sys: {
					id: this.category.fields.icon?.de?.sys?.id ? this.category.fields.icon.de.sys.id : ''
				},
				fields:{
					title: {},
					altText: {},
					description: {},
					file: file
				}
			}

			for (const locale of this.locales) {
				this.newIcon.fields.title[locale.code] = `App Icon - ${this.category.fields.title[locale.code]}`
				this.newIcon.fields.altText[locale.code] = `App Icon - ${this.category.fields.title[locale.code]}`
				this.newIcon.fields.description[locale.code] = `App Icon - ${this.category.fields.title[locale.code]}`
			}
			//Preview New Icon
			if (this.category.fields.icon?.de?.fields) {
				this.category.fields.icon.de.fields.file.de.url = this.$refs.fileUpload.$refs.fileUploadElement.dropzone.files[0].dataURL
			} else {
				this.category.fields['icon'] = {de: this.newIcon}
			}
		},
		cancelUpload() {
			this.newIcon = {},
			this.isUpdateIcon = false
			this.category.fields.icon.de = JSON.parse(JSON.stringify(this.originalIcon))
		},
		validateSubCatType() {
			let isValid = true
			this.subCatTypeErrors = []

			if (this.subCategory.fields.type.de === '') {
				isValid = false
				this.subCatTypeErrors.push(this.$t('text.missingFieldsError'))
			}
			return isValid
		},
		showSubCatUpdateIcon(subCategory) {
			if (!this.locked(subCategory)) {
				this.isUpdateSubCatIcon = true
			}
		},
		onSubCatThumbnailCreated(file) {
			this.newSubCatIcon = {
				sys: {
					id: this.subCategory.fields.icon?.de?.sys?.id ? this.subCategory.fields.icon.de.sys.id : ''
				},
				fields:{
					title: {},
					altText: {},
					description: {},
					file: file
				}
			}

			for (const locale of this.locales) {
				this.newSubCatIcon.fields.title[locale.code] = `App Icon - ${this.subCategory.fields.title[locale.code]}`
				this.newSubCatIcon.fields.altText[locale.code] = `App Icon - ${this.subCategory.fields.title[locale.code]}`
				this.newSubCatIcon.fields.description[locale.code] = `App Icon - ${this.subCategory.fields.title[locale.code]}`
			}

			//Preview New Icon
			if (this.subCategory.fields.icon?.de?.fields) {
				this.subCategory.fields.icon.de.fields.file.de.url = this.$refs.fileUploadSubCat.$refs.fileUploadElement.dropzone.files[0].dataURL
			} else {
				this.subCategory.fields['icon'] = {de: this.newSubCatIcon}
			}
		},
		cancelSubCatIconUpload() {
			this.newSubCatIcon = {},
			this.isUpdateSubCatIcon = false
			this.subCategory.fields.icon.de = JSON.parse(JSON.stringify(this.originalSubCatIcon))
		},
		getCategoryTitle() {
			return this.category?.fields?.title[this.selectedLocale]
		},
		showAddSubCategory() {
			this.subCategory = {
				sys: {
					id: ''
				},
				fields: {
					title: {de: undefined,en:undefined,fr:undefined,it:undefined},
					type: {de: ''},
					mainCategory: {de: false},
					icon: {de: {}},
					subProductCategories: {de: []}
				}
			}

			this.originalSubCat = JSON.parse(JSON.stringify(this.subCategory))
			this.$refs.subCatDialog.show = true
		},
		showEditSubCategory(item) {
			this.originalSubCat = JSON.parse(JSON.stringify(item))
			this.subCategory = JSON.parse(JSON.stringify(item))
			this.$refs.subCatDialog.show = true
		},
		async upsertSubCategory() {
			// if (this.subCategoryNameErrors.length > 0 || this.subCatTypeErrors.length > 0) return
			// if (!this.validateSubCategoryName() || !this.validateSubCatType()) return

			this.loading = true

			if (Object.keys(this.newSubCatIcon).length > 0) {
				this.subCategory.fields.icon.de = this.newSubCatIcon
			}

			const data = {
				clientId: this.$store.state.selectedClient.sys.id,
				productCategory: this.category,
				subCategory: this.subCategory
			}

			this.category = await this.$httpPost('/product-category', data)

			this.successTitle = this.$t('text.productCategory')
			this.successDetail = this.categoryId ? this.$t('text.productCategoryUpdated') : this.$t('text.productCategoryCreated')

			if (this.isUpdateSubCatIcon === true) {
				await this.sleep(5000) //wait for icon to be updated
			}
			
			this.loading = false

			const res = await this.$httpGet(`/client/${this.$store.state.selectedClient.sys.id}`)
			await this.$store.commit('setSelectedClient', res.client)

			if (this.isUpdateSubCatIcon === true) {
				this.$router.go()
			}
		},
		resetSubCategory() {
			this.subCategory = JSON.parse(JSON.stringify(this.originalSubCat))
		},
		async getProductCategoryDetails() {
			this.category = await this.$httpGet(`/content/entry/${this.categoryId}`)

			if (this.category.fields.subProductCategories?.de?.length) {
				this.category.fields.subProductCategories.de.sort(this.compare)
			}
			
			this.isUpdateIcon = false
			this.isUpdateSubCatIcon = false
			this.isMainCategory = this.category.fields.mainCategory.de
			this.originalIcon = this.category.fields.icon?.de ? JSON.parse(JSON.stringify(this.category.fields.icon.de)) : {}
		},
		async upsertProductCategory() {
			this.shouldValidate = true
			if (!this.validate()) return

			this.loading = true
			if (Object.keys(this.newIcon).length > 0) {
				this.category.fields.icon.de = this.newIcon
			}

			const data = {
				clientId: this.$store.state.selectedClient.sys.id,
				productCategory: this.category
			}

			const category = await this.$httpPost('/product-category', data)
			
			this.successTitle = this.$t('text.productCategory')
			this.successDetail = this.categoryId ? this.$t('text.productCategoryUpdated') : this.$t('text.productCategoryCreated')

			if (this.isUpdateIcon === true) {
				await this.sleep(5000) //wait for icon to be updated
			}
			
			this.loading = false

			const res = await this.$httpGet(`/client/${this.$store.state.selectedClient.sys.id}`)
			await this.$store.commit('setSelectedClient', res.client)

			if (!this.categoryId) {
				this.$router.push({path: this.$route.path, query: {categoryId: category.sys.id}})
				this.$router.go()
			} else if (this.isUpdateIcon === true) {
				this.$router.go()
			}
		},
		async deleteCategory(categoryId, type) {
			this.loading = true
		
			try {
				const res = await this.axios.delete('/product-category', 
				{
					headers: this.requestHeaders(),
					data: {
						clientId: this.$store.state.selectedClient.sys.id,
						categoryId: categoryId
					} 
				})

				if (res?.data?.message === 'category in use') {
					this.errorTitle = this.$t('text.ERROR')
					this.errorDetail = this.$t('text.categoryInUseError')
				} else if (res?.data?.message === 'category in use by product') {
					this.errorTitle = this.$t('text.ERROR')
					this.errorDetail = this.$t('text.categoryInUseByProductError')
				}
				else {
					//Update Client in Local Storage
					const clientRes = await this.$httpGet(`/client/${this.$store.state.selectedClient.sys.id}`)
					await this.$store.commit('setSelectedClient', clientRes.client)
					
					this.successTitle = this.$t('text.CATEGORY_DELETED')
					this.successDetail = this.$t('text.categoryDeletedSuccess')
					
					this.sleep(2000).then(() => { 
						if (type === 'main') {
							this.$router.push('/productCategory')
						} else {
							this.getProductCategoryDetails()
						}
					 })
				}
			}
			catch (error) {
				this.showError(error.response ? error.response?.data.error : error)
			}
			
			this.loading = false
		},
		sleep(ms) {
			return new Promise(resolve => setTimeout(resolve, ms));
		}
	}
}
</script>

<style lang="scss" scoped>
.flag { position: absolute; z-index: 10; margin-top: 30px; margin-left: -26px; zoom: 0.8; }
.entry { border: 1px solid #ccc; margin-bottom: 10px; border-radius: 5px; }
.serviceLocale { position: absolute; z-index: 10; margin-top: 32px; margin-left: -32px; zoom: 0.8; }

</style>