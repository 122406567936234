<template>
  <v-row justify="center">
    <Disclosure :title="$t('text.publicInfoTitle')" :error="sectionMessage.error" :message="sectionMessage.message"
                :lock="!userMayEditFields" :expanded="true" data-cy="publicInfo" ref="SECTION_publicInfo">
      <div v-if="hasField('publicInfo','title', model)" class="field left-border">
        <v-label>{{ $t('text.serviceName') }} <span class="error-text">({{ $t('text.required') }})</span></v-label>
        <LanguageFlag v-model="serviceLocale" class="flag"/>
        <v-text-field
            outlined
            required
            counter="256"
            data-cy="title"
            v-model.trim="model.fields.title[serviceLocale]"
            :disabled="productIsExternal"
            :hide-details="productTitleErrors.length===0"
            :error-messages="productTitleErrors"
            @input="validateTitle(true)"
        />
      </div>
      <div v-if="hasField('publicInfo','longDescription', model)" class="field left-border">
        <v-label>{{ $t('text.longDescription') }} <span class="error-text">({{ $t('text.required') }})</span></v-label>
        <LanguageFlag v-model="serviceLocale" class="flag"/>
        <TextArea
            data-cy="longDescription"
            v-model="model.fields.longDescription[serviceLocale]"
            :disabled="!userMayEditFields"
            :max-length="10000"
            :errorMessages="longDescriptionErrors"
            @input="validateLongDescription()"
        />
        <p class="helpText" v-html="$t('text.longDescriptionHelp')"/>
      </div>
      <div v-if="hasField('publicInfo','shortDescription', model)" class="field left-border">
        <v-label>{{ $t('text.shortDescription') }} <span class="error-text" v-if="!productIsExternal">({{ $t('text.required') }})</span></v-label>
        <LanguageFlag v-model="serviceLocale" class="flag"/>
        <v-textarea
            auto-grow
            outlined
            required
            counter="255"
            maxlength="255"
            data-cy="shortDescription"
            v-model="model.fields.shortDescription[serviceLocale]"
            :error-messages="shortDescriptionErrors"
            @input="validateShortDescription()"
        />
        <p class="helpText" :style="shortDescriptionErrors.length ? '' : 'margin-top:-20px'"
           v-html="$t('text.shortDescriptionHelp')"/>
      </div>
      <div v-if="hasField('publicInfo','importantInfo', model)" class="field left-border">
        <v-label>{{ $t('text.importantInfo') }}</v-label>
        <LanguageFlag v-model="serviceLocale" class="flag"/>
        <ImportantInfo
            data-cy="importantInfo"
            v-model="model.fields.importantInformation[$store.state.serviceLocale]"
            :disabled="!userMayEditFields"
        />
        <p class="helpText" v-html="$t('text.importantInfoHelp')"/>
      </div>
    </Disclosure>
  </v-row>
</template>

<script>
import ImportantInfo from './ImportantInfo'
import LanguageFlag from '@/components/common/LanguageFlag'
import TextArea from '@/components/common/TextArea'
import Common from '@/mixins/Common'
import Disclosure from '@/components/common/Disclosure'
import { isEqual } from 'lodash'

export default {
  name: "PublicInfo",
  components: { ImportantInfo, TextArea, LanguageFlag, Disclosure },
  mixins: [Common],

  props: {
    product: Object,
    updateModel: Boolean,
    productIsExternal: Boolean,
  },

  data() {
    return {
      model: {},
      initData: {},

      sectionMessage: {
        error: false,
        message: ''
      },
      
      productTitleErrors: [],
      longDescriptionErrors: [],
      shortDescriptionErrors: [],

      updateNeeded: true
    }
  },

  watch: {
    updateModel() {
      this.model = this.valueToModel(this.product)
      this.setInitData()
    },
    longDescriptionErrors(n) {
      if (n.length) {
        this.setSectionError(this.sectionMessage, this.$t('text.longDescriptionRequired'))
      }
    },
    shortDescriptionErrors(n) {
      if (!this.productIsExternal && n.length) {
        this.setSectionError(this.sectionMessage, this.$t('text.shortDescriptionRequired'))
      }
    },
    productTitleErrors(n) {
      if (n.length) {
        this.setSectionError(this.sectionMessage, this.$t('text.titleRequiredError'))
      }
    },
    // we need to listen on the title field to update the title when the service is duplicated
    'product.fields.title': {
      handler: function (newTitle) {
        this.model.fields.title = this.valueToModel(newTitle)
      },
      deep: true
    }
  },
  
  created() {
    this.model = this.valueToModel(this.product)
    this.setInitData()
  },

  updated() {
    if (this.updateModel === true) {
      this.updateNeeded = false
      
    }
  },

  methods: {
    validateAllFields() {
      let allFieldsAreValid = true
      this.resetSectionError(this.sectionMessage)

      const isTitleValid = this.validateTitle(true)
      const isLongDescriptionValid = this.validateLongDescription()
      const isShortDescriptionValid = this.validateShortDescription()

      if (!isTitleValid || !isLongDescriptionValid || !isShortDescriptionValid) {
        allFieldsAreValid = false
        this.setSectionError(this.sectionMessage, this.$t('text.missingFieldsError'))
      }

      return allFieldsAreValid
    },
    validateTitle(runValidation = false) {
      let isValid = true
      const atLeastOneLocaleValueIsSet = this.validateTextLocales(this.model.fields.title)
      this.productTitleErrors = []

      if (runValidation && !atLeastOneLocaleValueIsSet) {
        isValid = false
        this.productTitleErrors.push(this.$t('text.nameRequired'))
      } else if (!atLeastOneLocaleValueIsSet) {
        isValid = false
        this.setSectionError(this.sectionMessage, this.$t('text.titleRequiredError'))
      }
      this.$emit('update-product-title', this.model.fields.title)
      return isValid
    },
    validateLongDescription() {
      let isValid = true
      const atLeastOneLocaleValueIsSet = this.validateTextLocales(this.model.fields.longDescription)
      this.longDescriptionErrors = []

      if (!atLeastOneLocaleValueIsSet) {
        isValid = false
        this.longDescriptionErrors.push(this.$t('text.longDescriptionRequired'))
      }

      return isValid
    },
    validateShortDescription() {
      let isValid = true
      const atLeastOneLocaleValueIsSet = this.validateTextLocales(this.model.fields.shortDescription)
      this.shortDescriptionErrors = []

      if (!this.productIsExternal && !atLeastOneLocaleValueIsSet) {
        isValid = false
        this.shortDescriptionErrors.push(this.$t('text.shortDescriptionRequired'))
      }

      return isValid
    },
    valueToModel(v) {
      return JSON.parse(JSON.stringify(v ?? {}))
    },
    setInitData() {
      const initModel = JSON.parse(JSON.stringify(this.model))
      this.initData = {}

      if (this.hasField('publicInfo','title', this.model)) {
        this.initData.title = initModel.fields.title
      }
      if (this.hasField('publicInfo','longDescription', this.model)) {
        this.initData.longDescription = initModel.fields.longDescription
      }
      if (this.hasField('publicInfo','shortDescription', this.model)) {
        this.initData.shortDescription = initModel.fields.shortDescription
      }
      if (this.hasField('publicInfo','importantInfo', this.model)) {
        this.initData.importantInformation = initModel.fields.importantInformation
      }
    },
    sendData() {
      let data = {}
     
      if (this.hasField('publicInfo','title', this.model)) {
        data.title = this.model.fields.title
      }
      if (this.hasField('publicInfo','longDescription', this.model)) {
        data.longDescription = this.model.fields.longDescription
      }
      if (this.hasField('publicInfo','shortDescription', this.model)) {
        data.shortDescription = this.model.fields.shortDescription
      }
      if (this.hasField('publicInfo','importantInfo', this.model)) {
        data.importantInformation = this.model.fields.importantInformation
      }

      data.changed = !isEqual(data, this.initData)
      
      return data
    },
  },

}
</script>