<template>
	<v-container fluid style="padding-bottom: 70px;">
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

		<!-- navbar -->
		<div class="navbar">
			<v-row class="flex-nowrap">
				<v-text-field outlined dense clearable hide-details height="48"
					data-cy="search"
					prepend-inner-icon="mdi-magnify"
					:placeholder="$t('text.search')"
					style="max-width:33%"
					v-model="searchString"
					@keyup.enter="offset=0;search()"
					@click:clear="searchString='';offset=0;load()"/>

				<v-spacer/>

				<v-row justify="end" class="flex-nowrap">
					<v-btn
						x-small
						class="gradientButton mx-2"
						elevation="0"
						data-cy="bulkSet"
						v-if="itemsCheckedCount != 0"
						@click="bulkSet()">
						{{ $t('text.bulkSetTags') }} {{ itemsCheckedCount }}
					</v-btn>

					<v-btn-toggle mandatory class="mr-2">
						<v-btn :class="[tagMode==='UNTAGGED' ? 'firstButton_active' : 'firstButton']" elevation="0" data-cy="untagged" @click="switchMode('UNTAGGED')">
							<v-icon class="d-md-none">mdi-tag-off</v-icon>
							<span class="d-none d-md-inline">{{ $t('text.untagged') }}</span>
						</v-btn>
						<v-btn :class="[tagMode==='MANAGE' ? 'lastButton_active' : 'lastButton']" elevation="0" data-cy="tagged" @click="switchMode('MANAGE')">
							<v-icon class="d-md-none">mdi-tag</v-icon>
							<span class="d-none d-md-inline">{{ $t('text.tagged') }}</span>
						</v-btn>
					</v-btn-toggle>

					<!-- Filters -->
					<div>
						<v-menu offset-y bottom left origin="top right" style="width:500px !important;min-width:500px !important;max-width:500px !important" :close-on-content-click="false" v-model="isOpened">
						<template v-slot:activator="{ on }" style="width:500px !important;min-width:500px !important;max-width:500px !important">
							<v-row justify="end" v-on="on">
							<v-btn class="gradientButton" data-cy="filterTagManager" elevation="0" x-small>
								<v-icon>mdi-filter</v-icon>
								<span class="d-none d-lg-inline">{{$t('text.filter')}}</span>
								<v-icon>mdi-menu-down</v-icon>
							</v-btn>
							<div v-if="$store.state.filter" class="dot-container stack-top"><span class="dot"></span></div>
							</v-row>
						</template>

						<v-list style="width:500px !important;min-width:500px !important;max-width:500px !important;padding:0px">
							<v-list-item style="width:500px !important;min-width:500px !important;max-width:500px !important;padding-top:20px">
							<v-list-item-title>
								<!-- Tag Filter -->
								<div class="px-2" v-if="filterMode==='tags'" data-cy="tagFilter" @click="closeDropDown('vSelectTags')">
								<span style="width:88%">{{$t('text.tags')}}</span>
								<v-select
									ref="vSelectTags"
									v-model="selectedTags"
									:items="tags"
									:item-text="item => item"
									:item-value="item => item"
									:label="$t('text.allLabel')"
									multiple small-chips hide-details
									filled dense solo
									:menu-props="{ offsetY: true }"
									class="gradientButton"
									style="min-height: initial !important;">
									<template #selection="{ item }">
									<v-chip :style="getTagStyle(item.substring(0,3).toUpperCase())" color='#fff'>{{item.substring(0,3).toUpperCase()}}</v-chip>
									</template>
								</v-select>
								</div><br/>

								<!-- Status Filter -->
								<div class="px-2" data-cy="statusFilter" @click="closeDropDown('vSelectStatus')">
								<span style="width:88%">{{$t('text.status')}}</span>
								<v-select
									ref="vSelectStatus"
									v-model="selectedStatuses"
									:items="statuses"
									:item-text="item => $t('text.' + item.id)"
									:item-value="item => item.id"
									:label="$t('text.allLabel')"
									multiple small-chips hide-details
									filled dense solo
									:menu-props="{ offsetY: true  }"
									class="gradientButton"
									style="min-height: initial !important;">
									<template #selection="{ item }">
									<v-chip :color="item.color" :dark="item.dark">{{$t('text.' + item.id)}}</v-chip>
									</template>
								</v-select>
								</div>
							</v-list-item-title>
							</v-list-item>

							<br/>
							<v-divider/>

							<v-row style="width:100%;padding:20px">
							<v-btn class="gradientButton" data-cy="clearFilter" elevation="0" style="width:48%;margin-right:4%" @click="clearFilter()">{{$t('text.clearFilter')}}</v-btn>
							<v-btn class="greenButton" data-cy="applyFilter" elevation="0" dark style="width:48%;" @click="applyFilter()">{{$t('text.applyFilter')}}</v-btn>
							</v-row>
						</v-list>
						</v-menu>
					</div>
				</v-row>
			</v-row>
		</div>

		<!-- content -->
		<v-card class="tableCard">
			<v-data-table fixed-header hide-default-footer disable-sort
        :mobile-breakpoint="700"
				:headers="headers"
				:items="items"
				:items-per-page="limit">

        <template v-slot:item.checked="{ item }">
          <v-checkbox v-model="item.checked" @change="updateItemsCheckedCount()" :key="item.sys.id" />
        </template>
        <template v-slot:item.title="{ item }">
          <div @click="select(item)" class="d-flex fill-height align-center">
            <template v-if="item.fields.title">{{ item.fields.title.de }}</template>
            <template v-if="!item.fields.title">{{ item.sys.id }}</template>
          </div>
        </template>
        <template v-slot:item.tags="{ item }">
          <!-- TODO: the tags must come from the server! (clientAssignments) -->
          <div @click="select(item)" class="tags d-flex flex-wrap fill-height align-center justify-start">
			<button class="tag" v-for="tag of item.fields.tags.de" :key="tag" elevation="0" width="32px" :style="getTagStyle(tag.substring(0,3).toUpperCase())">{{ tag.substring(0,3).toUpperCase() }}</button>
          </div>
        </template>
        <template v-slot:item.status="{ item }">
          <Status :status="item.addl.statusClient" />
        </template>
			</v-data-table>
			<TablePaginator v-model="offset" :limit="limit" :results="items" :total="total" @input="load()" />

			<Dialog ref="dialog"
              :title="selectedItem && selectedItem.fields.title ? selectedItem.fields.title.de : ''"
              :confirm-label="$t('text.save')"
              :confirm-handler="dialogConfirm"
              :cancel-label="$t('text.cancel')"
              :showClose="false"
              :cancel-handler="dialogCancel">
				<template #content>
					<div>
						<!-- TODO: show some detail infos about the SP? location! -->
						<v-row v-for="tag of $store.state.loggedInUser.fields.canManageTags.de" :key="tag">
							<input type="checkbox"
								v-model="selectedItem.tags[tag]"
								:id="'tag-' + tag"
								style="width: 20px; height: 20px; margin: 4px 10px 10px;"
							/>
							<label :for="'tag-' + tag" style="font-size: larger">{{ tag }}</label>
						</v-row>
					</div>
				</template>
			</Dialog>
		</v-card>
	</v-container>
</template>

<script>
import Dialog from '@/components/common/Dialog.vue'
import Loading from 'vue-loading-overlay'
import Status from '@/components/common/Status.vue'
import TablePaginator from '@/components/common/TablePaginator.vue'
import Alert from '@/components/common/Alert.vue'

export default {
	name: "TagManagerView",
	components: { Status, Loading, Dialog, TablePaginator, Alert },
	props: {
		navbarValue: Object,
	},
	data() {
		return {
		locale: 'de', // TODO
		loading: false,
		items: [],
		selectedItem: null, // contains a copy of the selected item (or an artificial model in case of bulk edit)
		selectedItemsOriginal: null, // contains a reference to the selected item for update
		limit: 15,
		offset: 0,
		errorTitle: null,
		errorDetail: null,
		currentTagMode: null,
		tagColors: ['#00bcd4', '#f44336', '#8bc34a', '#e81e63', '#9c27b0', '#ffc107','#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#009688', '#4caf50', '#cddc39',  '#ff9800', '#ff5722' ],
		uniqueTags: [],
		tagMode: 'UNTAGGED',
		itemsCheckedCount: 0,
		searchString: '',
		tags: [],
		filter: null,
		selectedTags: [],
		isOpened: false,
		total: 0,
		filterModes: ["tags","serviceProviders"],
		filterMode: "serviceProviders",
		selectedStatuses: {id:'ALL', color:'grey'},
		statuses: [
			{id:'pending', color:'#ff9e21', dark:true},
      {id:'reapprove', color:'#ffb400'},
      {id:'approved', color:'#64c823', dark:true},
			{id:'deactivated', color:'#f34545', dark:true},
			{id:'declined', color:'#f34545', dark:true}
		],
	}},

	computed: {
		headers() {
			return [
				{ text: '', value: 'checked', width: '50' },
				{ text: this.$t('text.serviceProvider'), value: "title" },
				{ text: this.$t('text.tags'), value: "tags" },
				{ text: this.$t('text.status'), align:"center", value: "status", width: '200' },
			]
		},
	},
	
	watch: {
		selectedItem(item) {
			this.$refs.dialog.show = !!item
		},
		errorTitle() {
			if (!this.errorTitle) return
			setTimeout(() => {
				this.errorTitle = null
				this.errorDetail = null
			}, 3000);
		}
	},

	mounted() {
		this.load()
	
		this.tags = this.$store.state.loggedInUser.fields?.canManageTags?.de
		this.selectedTags = this.$store.state.filter ? this.$store.state.filter.tags : []
		this.selectedStatuses = this.$store.state.filter ? this.$store.state.filter.statuses : []

		if (!this.uniqueTags.length && this.tags?.length) {
			this.uniqueTags = []
			for (let i=0; i < this.tags.length; i++) {
				this.uniqueTags.push({
					label: this.tags[i].substring(0,3).toUpperCase(),
					colour: this.tagColors[i]
				})
			}
		}
	},

	methods: {
		parseJwt (token) {
			var base64Url = token.split('.')[1];
			var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
			var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			}).join(''));

    		return JSON.parse(jsonPayload);
		},
		switchMode(mode) {
			if (mode === "UNTAGGED") {
				this.filterMode = 'serviceProviders'
			} else {
				this.filterMode = 'tags'
			}

			this.$store.state.filter = null
			this.selectedStatuses = []
			this.selectedTags = []

			this.tagMode = mode
			this.offset = 0
			this.searchString = ''
			this.load()
		},
		closeDropDown(ref) {
			if (!this.dropdownOpen) {
				this.$refs[ref].focus()
				this.dropdownOpen = true
			} else {
				this.$refs[ref].blur()
				this.dropdownOpen = false
			}
		},
		async load() {
			if (this.searchString?.length > 0) {
				this.search()
			} else {
				this.loading = true
				try {
					let url = `/serviceproviders?clientId=${this.$store.state.selectedClient.sys.id}&skip=${this.offset}&limit=${this.limit + 1}&tagMode=${this.tagMode}`

					if (this.$store.state.filter?.tags && this.selectedTags.length > 0) {
						url += `&tags=${this.selectedTags}`
					} else {
						if (!this.$store.state.loggedInUser.fields.canSeeObjectsWithTags.de.find(x => x === "ALL")) {
							url += `&tags=${this.$store.state.loggedInUser.fields.canSeeObjectsWithTags.de}`
						}
					}

					if (this.$store.state.filter?.statuses) {
						url += `&filter=${JSON.stringify(this.$store.state.filter)}`
					}

					const data = await this.$httpGet(url)
					let items = data.serviceProviders
					this.total = data.total

					// we add some ui information
					if  (items?.length > 0) {
						items = this.setItemInformation(items)
						this.items = items
						this.updateItemsCheckedCount()
					} else {
						this.items = []
						this.itemsCheckedCount = 0
					}

				} catch (e) {
					if (e.response?.status === 401) {
						this.$emit("show-login")
					}
					else if (e.response?.status == 400) {
						this.items = []
					}
					else {
						console.error(e)
						this.errorTitle = this.$t('text.ERROR')
						this.errorDetail = e.response?.data.error ?? e
					}
				}
				this.loading = false
			}
		},
		setItemInformation(items) {
			for (const item of items) {
				// set status to the status of the clientAssignment of the calling clientId
				item.addl = {
					statusClient: 'INVALID',
					isHomebase: false,
					clientAssignmentInfos: [],
				}
			
				for (let ca of item.fields.clientAssignments?.de ?? []) {
					if (!ca.fields?.client?.de?.fields?.clientId?.de) {
						continue
					}

					//Move Homebase to front of clientassignments for UI
					if (ca.fields?.isHomebase?.de === true) {
						item.addl.clientAssignmentInfos.unshift({
							clientId: ca.fields.client.de.fields.clientId.de,
							status: ca.fields.status.de,
						})
					} else {
						item.addl.clientAssignmentInfos.push({
							clientId: ca.fields.client.de.fields.clientId.de,
							status: ca.fields.status.de,
						})
					}

					if (ca.fields.client.de.sys.id != this.$store.state.selectedClient.sys.id) continue
					item.addl.statusClient = ca.fields.status.de
					item.addl.isHomebase = !!ca.fields?.isHomebase?.de
					item.fields.tags = {de: ca.fields.tags?.de ? ca.fields.tags.de : []}
				}
				
				item.checked = false
				if (!item.fields.tags) item.fields.tags = { de: [] }
			}
			return items
		},
		select(item) {
			this.selectedItemsOriginal = [ item ]
			item = JSON.parse(JSON.stringify(item))
			item.tags = {}
			// TODO: remove! we repair broken data
			if (item.fields?.tags?.de && !Array.isArray(item.fields.tags.de)) item.fields.tags.de = []
			for (const tag of item.fields?.tags?.de ?? []) {
				item.tags[tag] = true
			}
			this.selectedItem = item
		},
		bulkSet() {
			const originals = []
			for (const item of this.items) {
				if (!item.checked) continue
				originals.push(item)
			}
			this.selectedItemsOriginal = originals

			// we have to add all possible tags to the model, because we have to distinguish even false values from "not-my-values"
			const tags = {}
			for (const tag of this.$store.state.loggedInUser.fields.canManageTags.de) {
				tags[tag] = false
			}
			this.selectedItem = {
				fields: {
					title: { de: this.$t('text.bulkSetTags') },
				},
				// TODO: consolidate the current array from all selected items?
				tags: tags,
			}
		},
		async dialogConfirm() {
			this.loading = true
			this.selectedItem.fields.title = undefined
			for (const item of this.selectedItemsOriginal) {
				try {
					// we merge the tags from the dialog into the item tags
					const tagsMap = {}
					for (const tag of item.fields?.tags?.de ?? []) {
						tagsMap[tag] = true
					}
					for (const tag in this.selectedItem.tags) {
						if (!this.selectedItem.tags[tag])
							tagsMap[tag] = undefined
						else
							tagsMap[tag] = true
					}
					const tags = []
					for (const tag in tagsMap) {
						if (!tagsMap[tag]) continue
						tags.push(tag)
					}

					await this.$httpPut(`/serviceProvider/${item.sys.id}/tags/${this.$store.state.selectedClient.sys.id}`, tags)

					// ATT: we update the ui without loading new
					item.fields.tags = { de: tags }
					item.checked = false

					if (this.searchString==='') {
						this.load()
					}
				}
				catch (e) {
					console.error(e)
					this.errorTitle = this.$t('text.ERROR')
					this.errorDetail = e.response?.data.error ?? e
				}
			}
			this.selectedItem = null
			this.selectedItemsOriginal = []
			this.loading = false
			return  true
		},
		dialogCancel() {
			this.selectedItem = null
			this.selectedItemsOriginal = []
		},
		updateItemsCheckedCount() {
			let count = 0
			for (const item of this.items) {
				if (item.checked) count++
			}
			this.itemsCheckedCount = count
		},
		applyFilter() {
			if (this.filterMode === 'tags') {
				this.tagMode = "MANAGE"
				this.$store.state.filter = {tags: this.selectedTags, statuses: this.selectedStatuses}

				// if user deselects all filters and clicks apply filter -> act the same as if user clicked clear filter
				if (this.$store.state.filter.statuses?.length === 0 && this.$store.state.filter.tags?.length === 0) {
					this.clearFilter()
				}
			} else {
				this.tagMode = "UNTAGGED"
				this.$store.state.filter = {statuses: this.selectedStatuses}

				// if user deselects all filters and clicks apply filter -> act the same as if user clicked clear filter
				if (this.$store.state.filter.statuses?.length === 0) {
					this.clearFilter()
				}
			}

			this.offset = 0
			this.searchString = ""
			this.isOpened = false
			this.load()
		},
		clearFilter() {
			this.offset = 0
			this.selectedTags = []
			this.selectedStatuses = []
			this.searchString = ""
			this.$store.state.filter = null
			this.isOpened = false
			this.load()
		},
		async search() {
			this.loading = true
		//	this.offset = 0
			try {
				let url = `/search?client=${this.$store.state.selectedClient.sys.id}&contentType=serviceProvider&skip=${this.offset}&limit=${this.limit + 1}&searchString=${encodeURIComponent(this.searchString)}&tagMode=${this.tagMode}`

				if (this.$store.state.filter && this.selectedTags.length > 0) {
					url += `&tags=${this.selectedTags}`
				} else {
					if (!this.$store.state.loggedInUser.fields.canSeeObjectsWithTags.de.find(x => x === "ALL")) {
						url += `&tags=${this.$store.state.loggedInUser.fields.canSeeObjectsWithTags.de}`
					}
				}
				
				if (this.$store.state.filter?.statuses) {
					url += `&filter=${JSON.stringify(this.$store.state.filter)}`
				}

				let res = await this.$httpGet(url)
				let items = res.serviceProviders
				this.total = res.total

				// we add some ui information
				if (items?.length > 0) {
					items = this.setItemInformation(items)
					this.items = items
					this.updateItemsCheckedCount()
				} else {
					this.items = []
					this.itemsCheckedCount = 0
				}
			}
			catch (error) {
				if (error.response?.status == 400) {
					this.serviceProviders = []
				}
				else {
					this.errorTitle = this.$t('text.ERROR')
					this.errorDetail = error.response?.error ?? error
				}
			}
			this.loading = false
		},
		// TODO: move to common?
		// https://stackoverflow.com/a/52171480/667707
		hash(str, seed = 0) {
			let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
			for (let i = 0, ch; i < str.length; i++) {
				ch = str.charCodeAt(i);
				h1 = Math.imul(h1 ^ ch, 2654435761);
				h2 = Math.imul(h2 ^ ch, 1597334677);
			}
			h1 = Math.imul(h1 ^ (h1>>>16), 2246822507) ^ Math.imul(h2 ^ (h2>>>13), 3266489909);
			h2 = Math.imul(h2 ^ (h2>>>16), 2246822507) ^ Math.imul(h1 ^ (h1>>>13), 3266489909);
			return 4294967296 * (2097151 & h2) + (h1>>>0);
		},
		getColor(tag) {
			return this.uniqueTags.find(uniqueTag => uniqueTag.label === tag)?.colour
		},
		getTagStyle(tag) {
			const colour = this.uniqueTags.find(uniqueTag => uniqueTag.label === tag)?.colour
			return `border:solid 3px ${colour} !important; color:${colour} !important;`
		}
	},
}
</script>

<style scoped>
.tag { padding: 5px; border-radius: 45%; white-space: nowrap; border:solid 3px gray; margin-right: 5px; font-weight: bold; background-color: #ffffff !important; }
.dot-container { width: 75px; height: 75px; position: absolute; top: 0; right: 0; }
.stack-top { width: 14px; height: 14px; z-index: 9; margin: 0px; padding:0px; }
.dot { height: 14px; width: 14px; background-color: #ff0022; border-radius: 50%; display: inline-block; position: absolute; top: -1px; right: -1px; }

</style>