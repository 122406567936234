import Vue from 'vue'
import Vuex from 'vuex'
import localforage from 'localforage'

Vue.use(Vuex)

const db = localforage.createInstance({
	name: "MyServicesDB",
	driver: localforage.INDEXEDDB,
})

const store = new Vuex.Store({
	state: {
		selectedLocale: null,
		locales: [],
		serviceLocale: null,
		defaultLocale: null,
		userSpecificGerman: null,
		serviceComponents: null,
		selectedComponent: null,
		selectedServiceProvider: null,
		selectedProduct: null,
		selectedOrder: null,
		selectedFAQGroup: null,
		selectedClient: null,

		loggedInUser: null,
		clientId: null,
		timer: null,
		isImpersonation: null,
		impersonatedServiceProvider: {},
		impersonationStartTime: null,
		filter: null,
		selectedUser: null,
		selectedServiceType: null,
		userMessages: null,
		selectedRegionMapping: null,
		packageVersion: process.env.PACKAGE_VERSION || '0',
		featureBranch:  process.env.FEATURE_BRANCH || '',
		coreConfig: null,
		ticketServiceData: null,
		searchString: '',
		offset: null,

		translations: null,
		spApplications: null

	},
	mutations: {
		async setSelectedLocale(state, item) {
			state.selectedLocale = item;
			await db.setItem('selectedLocale', state.selectedLocale);
		},
		async setLocales(state, locales) {
			state.locales = locales
			await db.setItem('locales', state.locales)
		},
		async setServiceLocale(state, serviceLocale) {
			state.serviceLocale = serviceLocale
			await db.setItem('serviceLocale', state.serviceLocale)
		},
		async setDefaultLocale(state, defaultLocale) {
			state.defaultLocale = defaultLocale
			await db.setItem('defaultLocale', state.defaultLocale)
		},
		async setUserSpecificGerman(state, item) {
			state.userSpecificGerman = item;
			await db.setItem('userSpecificGerman', state.userSpecificGerman);
		},
		async setComponents(state, items) {
			state.serviceComponents = items;
			await db.setItem('components', state.serviceComponents);
		},
		async setSelectedServiceProvider(state, item) {
			state.selectedServiceProvider = item;
			await db.setItem('selectedServiceProvider', state.selectedServiceProvider);
		},
		async setImpersonatedServiceProvider(state, item) {
			state.impersonatedServiceProvider = item;
			await db.setItem('impersonatedServiceProvider', state.impersonatedServiceProvider);
		},
		async setSelectedComponent(state, item) {
			state.selectedComponent = item;
			await db.setItem('selectedComponent', state.selectedComponent);
		},
		async setSelectedProduct(state, item) {
			state.selectedProduct = item;
			await db.setItem('selectedProduct', state.selectedProduct);
		},
		async setSelectedOrder(state, item) {
			state.selectedOrder = item;
			await db.setItem('selectedOrder', state.selectedOrder);
		},
		async setSelectedFAQGroup(state, item) {
			state.selectedFAQGroup = item;
			await db.setItem('selectedFAQGroup', state.selectedFAQGroup);
		},
		async setSelectedClient(state, item) {
			state.selectedClient = item;
			await db.setItem('selectedClient', state.selectedClient);
		},
		async setLoggedInUser(state, item) {
			state.loggedInUser = item;
			await db.setItem('loggedInUser', state.loggedInUser);
		},
		async setClientId(state, item) {
			state.clientId = item;
			await db.setItem('clientId', state.clientId);
		},
		async setTimer(state, item) {
			state.timer = item;
			await db.setItem('timer', state.timer);
		},
		async setIsImpersonation(state, item) {
			state.isImpersonation = item;
			await db.setItem('isImpersonation', state.isImpersonation);
		},
		async setImpersonationStartTime(state, item) {
			state.impersonationStartTime = item;
			await db.setItem('impersonationStartTime', state.impersonationStartTime);
		},
		async setIsLinkedProvider(state, item) {
			state.isLinkedProvider = item;
			await db.setItem('isLinkedProvider', state.isLinkedProvider);
		},
		async setFilter(state, item) {
			state.filter = item;
			await db.setItem('filter', state.filter);
		},
		async setSelectedUser(state, item) {
			state.selectedUser = item;
			await db.setItem('selectedUser', state.selectedUser);
		},
		async setSelectedServiceType(state, item) {
			state.selectedServiceType = item;
			await db.setItem('selectedServiceType', state.selectedServiceType);
		},
		async setUserMessages(state, items) {
			state.userMessages = items;
			await db.setItem('userMessages', state.userMessages);
		},
		async setSelectedRegionMapping(state, item) {
			state.selectedRegionMapping = item
			await db.setItem('selectedRegionMapping', state.selectedRegionMapping);
		},
		async setCoreConfig(state, item) {
			state.coreConfig = item
			await db.setItem('coreConfig', state.coreConfig);
		},
		async setTicketServiceData(state, item) {
			state.ticketServiceData = item
			await db.setItem('ticketServiceData', state.ticketServiceData);
		},
		async setSearchString(state, item) {
			state.searchString = item
			await db.setItem('searchString', state.searchString);
		},
		async setOffset(state, item) {
			state.offset = item
			await db.setItem('offset', state.offset)
		},
		async setTranslations(state, item) {
			state.translations = item
			await db.setItem('translations', state.translations);
		},
		async setSPApplications(state, item) {
			state.spApplications = item
			await db.setItem('spApplications', state.spApplications);
		},

		async clear(state) {
			await db.clear()
		},
	},
	actions: {
		getItem(itemName) {
			return this.state[itemName]
		}
	},
	getters: {
		appVersion: (state) => {
			return state.packageVersion
		},
		featureBranch: (state) => {
			return state.featureBranch
		}
	}
})

store.init = async () => {
	if (db) {
		store.state.selectedLocale = await db.getItem('selectedLocale') ?? 'de'
		store.state.locales = await db.getItem('locales') ?? []
		store.state.serviceLocale = await db.getItem('serviceLocale') ?? 'de'
		store.state.userSpecificGerman = await db.getItem('userSpecificGerman') ?? 'de'
		store.state.serviceComponents = await db.getItem('components')
		store.state.selectedComponent = await db.getItem('selectedComponent')
		store.state.selectedServiceProvider = await db.getItem('selectedServiceProvider')
		store.state.selectedProduct = await db.getItem('selectedProduct')
		store.state.selectedOrder = await db.getItem('selectedOrder')
		store.state.selectedFAQGroup = await db.getItem('selectedFAQGroup')
		store.state.selectedClient = await db.getItem('selectedClient')
		store.state.loggedInUser = await db.getItem('loggedInUser')
		store.state.clientId = await db.getItem('clientId')
		store.state.timer = await db.getItem('timer')
		store.state.isImpersonation = await db.getItem('isImpersonation') ?? false
		store.state.impersonationStartTime = await db.getItem('impersonationStartTime')
		store.state.isLinkedProvider = await db.getItem('isLinkedProvider') ?? false
		store.state.impersonatedServiceProvider = await db.getItem('impersonatedServiceProvider')
		store.state.filter = await db.getItem('filter')
		store.state.selectedUser = await db.getItem('selectedUser')
		store.state.selectedServiceType = await db.getItem('selectedServiceType')
		store.state.userMessages = await db.getItem('userMessages')
		store.state.selectedRegionMapping = await db.getItem('selectedRegionMapping')
		store.state.coreConfig = await db.getItem('coreConfig')
		store.state.ticketServiceData = await db.getItem('ticketServiceData')
		store.state.searchString = await db.getItem('searchString')
		store.state.offset = await db.getItem('offset')
		store.state.translations = await db.getItem('translations')
		store.state.spApplications = await db.getItem('spApplications')

	}	
}

export default store
