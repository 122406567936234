<template>
  <div style="height: 0">
    <v-navigation-drawer
        fixed
        hide-overlay
        :permanent="isSideBarFixed"
        v-model="drawer"
        width="380"
        class="sidebar"
        style="z-index:3"
    >
      <slot></slot>
    </v-navigation-drawer>
    <v-btn
        large
        icon
        class="nav-drawer__burger-icon"
        v-if="!isSideBarFixed"
        @click="drawer = true"
    >
      <v-icon>mdi-forwardburger</v-icon>
    </v-btn>
  </div>
</template>

<script>
import Common from '@/mixins/Common.vue'

export default {
  name: "SideBar",
  mixins: [Common],
  data() {
    return {
      drawer: false
    }
  },
  methods: {
    closeSidebar() {
      if (!this.isSideBarFixed) {
        this.drawer = false
      }
    }
  },
  watch: {
    isSideBarFixed(v) {
      if (!v) {
        this.drawer = false;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.sidebar {
  top: 64px !important;
  z-index: 4 !important;

  ::v-deep .v-navigation-drawer__content {
    padding: 12px 12px 150px 12px;
  }

  ::v-deep #navlist li {
    padding-left: 0;
  }
}

.nav-drawer__burger-icon {
  position: fixed;
  z-index: 1;
  top: 50vh;
  //transform: translateY(-50%);
  left: 4px;
  background: lightgray;
}
</style>