import Vue from 'vue';
const FreshworksWidget = new Vue({
	data() {
		return {
		store: null
	}},
	methods: {
		install($store) {
			this.store = $store
			this.remove()
			window.fwSettings = {
				'widget_id': 29000000124,
				// TODO: watch?
				'locale': this.store.state.selectedLocale
			}
			!function () {
				if ("function" != typeof window.FreshworksWidget) {
					var n = function () { n.q.push(arguments) }
					n.q = []
					window.FreshworksWidget = n
				}
			}()

			window.freshdeskScript = document.createElement('script')
			window.freshdeskScript.setAttribute('src', 'https://widget.freshworks.com/widgets/29000000124.js')
			document.head.appendChild(window.freshdeskScript)
			window.FreshworksWidget('hide', 'ticketForm', ['custom_fields.cf_mys_client_id', 'custom_fields.cf_themenbereich', 'custom_fields.cf_mys_scope', 'custom_fields.cf_mys_user_type'])
		},

		installed() {
			return typeof window.FreshworksWidget == "function"
		},

		// scope = ContactTracing | MyServices
		prefill(scope) {
			if (!this.installed()) return
			window.FreshworksWidget('prefill', 'ticketForm', { custom_fields: {
				cf_mys_client_id: this.store.state.selectedClient?.fields?.clientId?.de,
				cf_mys_scope: scope,
				cf_mys_user_type: this.store.state.loggedInUser?.user?.type,
			} })
			this.identify(this.store.state.loggedInUser.fields.email.de, this.store.state.loggedInUser.fields.name.de)
		},

		identify(email, name) {
			if (!this.installed()) return
			window.FreshworksWidget('identify', 'ticketForm', { email: email, name: name })
		},

		remove() {
			try {
				window.FreshworksWidget('destroy')
				if (!window.freshdeskScript) return
				window.freshdeskScript.parentNode.removeChild(window.freshdeskScript)
				document.getElementById('freshworks-frame')?.parentNode.removeChild(document.getElementById('freshworks-frame'))
			}
			catch (e) {
				console.log('failed destroying FD widget')
			}
			window.FreshworksWidget = undefined
			window.freshdeskScript = undefined
		},
	},
})
export default FreshworksWidget