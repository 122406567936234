<template>
  <v-container fluid style="line-height:0;margin-top:20px;width:98% !important">

    <loading :active.sync="isLoading"
      :is-full-page="fullPage"
      color="#4caf50">
    </loading>

    <!-- Navbar -->
		<div class="navbar">
			<v-row align="center" class="flex-nowrap" style="white-space:nowrap; overflow:hidden;">

        <v-row>
          <v-btn-toggle>
            <v-btn id="btnShowServices" class="firstButton" elevation="0" @click="showServices">
              <v-icon class="d-md-none">mdi-cart</v-icon>
              <span class="d-none d-md-inline">{{$t('text.services')}}</span>
            </v-btn>
            <v-btn id="btnShowFAQs" class="lastButton_active" elevation="0" @click="showFAQs">
              <v-icon class="d-md-none">mdi-frequently-asked-questions</v-icon>
              <span class="d-none d-md-inline">{{$t('text.faqs')}}</span>
            </v-btn>
          </v-btn-toggle>
        </v-row>
        <v-row justify="end">
          <v-btn id="addFAQ" class="gradientButton" small elevation="0" @click="showAddFAQ">
            <v-icon>mdi-plus</v-icon>
            <span class="d-none d-md-inline">{{$t('text.addFAQ')}}</span>
          </v-btn>
        </v-row>

			</v-row>
		</div>

    <div :style="showImpersonationInfo ? 'padding-top: 24px !important;' : 'padding-top:0px'">
      <v-row align="center" justify="center">
        <v-col>
          <v-card class="tableCard">
            <br/>

            <v-data-table
              :headers="headers"
              :items="faqGroups"
              fixed-header
              hide-default-footer
              class="elevation-0"
              style="margin-top:10px"
              :items-per-page="50"
              disable-sort>

              <template v-slot:item="{ item }">
                <tr @click="showFAQDetail(item)">
                  <td>{{item.fields && item.fields.title ? item.fields.title[selectedLocale] : ''}} </td>
                  <td>{{item.sys ? item.sys.createdAt.split("T")[0] : ''}}</td>
                  <td><v-chip :color="getColor()" dark>{{$t(`text.${status.toLowerCase()}`)}}</v-chip></td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Loading from 'vue-loading-overlay';
import Common from '@/mixins/Common'
import FreshdeskWidget from '@/utils/FreshdeskWidget.js';

export default {
  name: 'FAQListView',
  mixins: [Common],
  components: {
    Loading
  },
  data () {
    return {
      startRecCount: 0,
      endRecCount: 25,
      pageRecCount: 25,
      totalRecCount: 0,

      isLoading: false,
      fullPage: true,

      faqGroups: [],
      status: 'published',
      searchString: ''
    }
  },

  computed: {
    headers () {
      return [
        { text: this.$t('text.FAQTitle'), value: 'title' },
        { text: this.$t('text.createDate'), value: 'createDate', width: "200" },
        { text: this.$t('text.status'), value: 'status', width: "200" }
      ]
    }
  },

  created () {
    FreshdeskWidget.prefill('MyServices')
  },

  async mounted() {
    this.getFAQs()

    await this.$store.commit('setSelectedFAQGroup', {});
  },

  methods: {
    getColor () {
      return '#66BB6A'
    },

    /* Server Functions */
    getFAQs() {
      this.isLoading = true;

      this.$httpGet(`/faqs?serviceProviderId=${this.$store.state.selectedServiceProvider.sys.id}&skip=${this.startRecCount}&limit=${this.pageRecCount}`).then(res => {
        this.faqGroups = res.faqGroups;
        this.isLoading = false
      }).catch(error => {
        this.errorAlertVisible = true;
        this.errorTitle = this.$t('text.ERROR');

        if (error.response) {
          this.errorDetail = error.response.data.error;
        } else {
          this.errorDetail = error;
        }
      });
    },

    showFAQDetail(item) {
      this.isLoading = true;

      this.$httpGet(`/faq?faqId=${item.sys.id}`).then(async res => {
        this.isLoading = false
        await this.$store.commit('setSelectedFAQGroup', res.faqGroup);
        this.$router.push('/faq-detail');
      }).catch(error => {
        this.errorAlertVisible = true;
        this.errorTitle = this.$t('text.ERROR');

        if (error.response) {
          this.errorDetail = error.response.data.error;
        } else {
          this.errorDetail = error;
        }
      });
    },
    search() {
      this.$root.$emit('search', this.searchString);
    },
    clear() {
      this.$root.$emit('search', "");
    },
    async showAddFAQ() {
      await this.$store.commit('setSelectedFAQGroup', null);
      this.$router.push('/faq-detail');
    },
    showServices() {
      this.$router.push('/services');
    },
    showFAQs() {
      this.$router.push('/faq-list');
    },
  },
}
</script>

<style scoped>
.container {
  padding: 0 !important;
  width:100% !important;
}
.tableCard {
  border-radius:5px;
  background-color:#808080 !important;
  top: 0;
}
.v-text-field.v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 12px !important;
}
.v-text-field.v-text-field--enclosed .v-input__append-inner {
  margin-top: 12px !important;
}
</style>