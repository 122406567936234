<template>
	<v-container class="flex-nowrap mb-16" fluid>
		<!-- Navbar -->
		<div class="navbar">
			<v-row align="center" class="flex-nowrap" style="white-space:nowrap; overflow:hidden;">
				<v-row v-if="model" class="buttonGroup">
					<v-btn
						:class="{
							gradientButton: true,
							active: model.page == 'salesChannels',
						}"
						@click="model.page = 'salesChannels'"
						elevation="0"
						data-cy="salesChannels"
					>
						<span>{{$t('text.salesChannelsInfoTitle')}}</span>
					</v-btn>
					<v-btn
						:class="{
							gradientButton: true,
							active: model.page == 'clients',
						}"
						@click="model.page = 'clients'"
						elevation="0"
						data-cy="marketplaces"
					>
						<span>{{$t('text.clientsTitle')}}</span>
					</v-btn>
				</v-row>
			</v-row>
		</div>

		<MarketplaceSalesChannelsView v-if="!model || model.page == 'salesChannels'"></MarketplaceSalesChannelsView>
		<MarketplaceClientsView v-if="model && model.page == 'clients'"></MarketplaceClientsView>
	</v-container>
</template>

<script>
import MarketplaceSalesChannelsView from './MarketplaceSalesChannelsView.vue'
import MarketplaceClientsView from './MarketplaceClientsView.vue'

export default {
	name: "MarketplaceView",
	components: { MarketplaceSalesChannelsView, MarketplaceClientsView },
	data() {
		return {
		model: null,
	}},
	mounted() {
		if (this.value == null) {
			this.model = {
				page: 'salesChannels',
			}
			this.$emit('input', this.model)
		}
		else {
			this.model = this.value
		}
	}
}
</script>

<style scoped>
.row .buttonGroup { display: flex; }
.row .buttonGroup .gradientButton { border-radius: 0 !important; border: 0; margin-right: -1px; }
.row .buttonGroup .gradientButton:first-child { border-top-left-radius: 10px !important; border-bottom-left-radius: 10px !important; }
.row .buttonGroup .gradientButton:last-child { border-top-right-radius: 10px !important; border-bottom-right-radius: 10px !important; }
.row .buttonGroup .gradientButton.active { background: linear-gradient(0deg, #4aaf51 0%, #5ecc64 100%) !important; border-top-color: #13f51e !important; border-bottom-color: #4d9151 !important; }
</style>