<template>
	<div>
	  <v-data-table 
	  :headers="headers"
	  :items="items" 
	  item-key="entityid"
	  style="margin-top:10px;"
	  :items-per-page="1000"
	  fixed-header hide-default-footer disable-sort
	  >
	  <template v-slot:top>
		  <v-toolbar flat color="white">
			  <v-toolbar-title>PEAK Sync</v-toolbar-title>
			  <v-spacer></v-spacer>
			</v-toolbar>
			</template>
			<template v-slot:item="{ item }">
                <tr style="cursor: auto">
					<td v-if="item.entityid">{{item.entityid}}</td>
					<td v-else><v-icon color="red">mdi-close-circle</v-icon></td>
					<!-- <td v-if="!item.error_message || item.error_message === ''" style="backgroundColor: #90EE90">{{item.updated_at | formatDateTime}}</td> -->
					<td v-if="item.status === 'done'" style="backgroundColor: #E3FCEF">{{item.updated_at | formatDateTime}}</td>
					<td v-else style="backgroundColor: #FFEBE6">{{showMessage(item.error_message)}}</td>
                </tr>
			</template>
		</v-data-table>
	</div> 
</template>

<script>
	import Common from '@/mixins/Common'

	export default {
	name: "PeakInfo",
	mixins: [Common],
	props: {
		peakSyncData: {
			type: Array,
			required: true
		}
	},

	data() {
		return {
		model: {},
		sectionMessage: {
			error: false,
			message: ''
		},
		items: [],
		headers: [
			{
				text: 'PEAK ID',
				value: "entityid",
				align: 'center',
				width: '40%'
			},
			{
				text: 'Export Status',
				value: "updated_at",
				align: 'center',
			}
		],
		}
	},

	created() {
		this.items = this.peakSyncData
	},

	methods: {
		showMessage(message) {
			if (message && message !== 'Not exported from PEAK') {
				// return the string after "Error: " and before the first "("
				return message.substring(message.indexOf("Error: ") + 7, message.indexOf("("))
			} else if(message === 'Not exported from PEAK') {
				return this.$t('text.notExportedFromPeak')
			} else {
				return this.$t('text.noErrorMessage')
			}
		}
	}
	}

</script>

<style scoped>
table,
table td {
  border: 1px solid #cccccc;
}
td {
  height: 80px;
  width: 160px;
  text-align: center;
  vertical-align: middle;
}
</style>