<template>
	<v-row v-if="userIsOperator && featureEnabled('service-provider-checklist')" justify="center">
		<Disclosure :title="$t('text.checklist')" :lock="!userMayEditFields" :expanded="true" data-cy="checklistInfo" ref="SECTION_checklistInfo">
			<div v-if="checklist && checklist.fields && checklist.fields.items && checklist.fields.items.de" class="field left-border">
				<p v-if="component==='BusinessProfile'" class="helpText" v-html="$t('text.checklistBPHelp')"/>
				<p v-if="component==='ServiceDesigner'" class="helpText" v-html="$t('text.checklistSDHelp')"/>

				<div v-for="(item, index) of checklist.fields.items.de" :key="index">
					<v-row align="center" class="pa-3">
						<v-checkbox hide-details v-model="item.checked"/>
						<span class="checklist-item-label">{{item.text[selectedLocale]}}</span>
					</v-row>
					<hr/>
				</div>
				<br/>
				<v-btn class="greenButton" dark elevation="0" :disabled="allCompleted" @click="markAllAsComplete()" data-cy="markAllAsComplete"><v-icon>mdi-check</v-icon>{{$t('text.markAllAsComplete')}}</v-btn>
			</div>
		</Disclosure>
	</v-row>
</template>

<script>
import Common from '@/mixins/Common'
import Disclosure from '@/components/common/Disclosure'

export default {
  name: 'Checklist',
  components: { Disclosure },

  mixins: [Common],
  props: {
		serviceProvider: { 
			required: true,
			type: Object 
		},
		component: {
			required: true,
			type: String
		},
	},
	data() {
		return {
		checklist: {
			sys: {
				id: ''
			},
			fields: {
				items: { de: []}
			}
		},
	}},
	computed: {
		allCompleted() {
			const incompleteItem = this.checklist?.fields?.items?.de?.find(x => x.checked === false)
			incompleteItem ? this.$refs['SECTION_checklistInfo']?.open() : this.$refs['SECTION_checklistInfo']?.close()
			return incompleteItem ? false : true
		}
	},
	watch: {
		serviceProvider() {
			this.setChecklistDetails()
		}
	},
	mounted() {
		this.setChecklistDetails()
	},
	methods: {
		haveChecklist() {
			return checklist?.fields?.items?.de?.length ? true : false
		},
		markAllAsComplete() {
			for (let item of this.checklist.fields.items.de) {
				item.checked = true
			}
		},
		setChecklistDetails() {
			this.checklist = {
				sys: {
					id: ''
				},
				fields: {
					items: { de: []}
				}
			}

			const selectedClient = this.$store.state.selectedClient
			const checklistFeature = selectedClient.fields.features.de.find(x => x.id === 'service-provider-checklist')
			
			if (checklistFeature?.status === 'enabled') {
				let clientAssignmentForSelectedClient = this.serviceProvider.fields?.clientAssignments?.de?.find(x => x.fields.client.de.sys.id === selectedClient.sys.id)

				if (clientAssignmentForSelectedClient) {
					if (!clientAssignmentForSelectedClient.fields.checklist?.de || Object.keys(clientAssignmentForSelectedClient.fields.checklist).length === 0) {
						this.checklist = selectedClient?.fields?.serviceProviderChecklist?.de

						if (!this.checklist?.sys) {
							this.checklist = {
								sys: {
									id: ''
								},
								fields: {
									items: { de: []}
								}
							}
						} else {
							if (this.checklist?.fields?.items?.de?.length) {
								for (const item of this.checklist.fields.items.de) {
									item.checked = false
								}
							}
						}

					} else {
						let caChecklist = clientAssignmentForSelectedClient.fields.checklist.de
						this.checklist.sys.id = caChecklist.sys.id
						this.checklist.fields.items.de =  []

						//Merge client checklist in case items have been added / removed since last update
						if (selectedClient?.fields?.serviceProviderChecklist?.de?.fields?.items?.de?.length) {
							for (const checklistItem of selectedClient.fields.serviceProviderChecklist.de.fields.items.de) {
								const item = caChecklist?.fields?.items?.de.find(x => x.key === checklistItem.key)
								//Add new checklist item
								if (!item) {
									this.checklist.fields.items.de.push(checklistItem)
								} else {
									//Add existing ca checklist items that have not been removed from the client checklist
									this.checklist.fields.items.de.push(item)
								}
							}
						}
					}
				}
			}
			this.$forceUpdate()
		}
	}
}
</script>

<style scoped>
.checklist-item-label {
  padding-top:15px;
  font-size: 14pt !important;
}
</style>