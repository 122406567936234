<script>
import moment from 'moment'
import ContentfulObject from '../common/ContentfulObject.js'
import countries from '@/json/countries.json'

export default {
	computed: {
		locales: {
			get() {
				return this.$store.state.locales
			},
			async set(newValue) {
				await this.$store.commit('setLocales', newValue)
			}
		},
		defaultLocale: {
			get() {
				return this.$store.state.defaultLocale
			},
			async set(newValue) {
				await this.$store.commit('setDefaultLocale', newValue)
			}
		},
		serviceLocale: {
			get() {
				return this.$store.state.serviceLocale
			},
			async set(newValue) {
				await this.$store.commit('setServiceLocale', newValue)
			}
		},
		selectedLocale: {
			get() {
				return this.$store.state.selectedLocale
			},
			async set(newValue) {
				await this.$store.commit('setSelectedLocale', newValue)
			}
		},
		showImpersonationInfo() {
			return this.userIsImpersonating === true ? true : false
		},
		userIsImpersonating() {
			return !!this.$store.state.isImpersonation
		},
		userIsOperator() {
			return this.$store.state.loggedInUser?.fields?.type?.de === 'operator' && this.$store.state.isImpersonation === false
		},
		userMayEditFields() {
			return !this.userIsOperator || this.userIsHomeOperator
		},
		phoneFormat() {
			switch(this.$store.state.userSpecificGerman) {
				case 'ch':
					return '+41 79 123 4567'
				case 'at':
					return '+43 79 123 4567'
				case 'de':
				default:
					return '+49 79 123 4567'
			}
		},
		// ATT: this can only be effectively used on BusinessProfile, ServiceDetail and ClientAssignmentsField currently
		clientAssignments() {
			// TODO: currently these implementations are the same - in the past we looked at the product clientAssignments on SD.
			//       if this stays the same, we can simplify this function
			if (this.$options.name == 'BusinessProfile' || this.app == 'BusinessProfile') {
				if (!this.serviceProvider?.sys?.id) return undefined
				return this.serviceProvider.fields.clientAssignments.de
			}
			if (this.$options.name == 'ServiceDetail') {
				if (!this.serviceProvider?.sys?.id) return undefined
				return this.serviceProvider.fields.clientAssignments.de
			}
			return undefined
		},
		// ATT: this can only be effectively used on BusinessProfile, ServiceDetail and ClientAssignmentsField currently
		userIsHomeOperator() {
			if (!this.userIsOperator) return false
			let cas = this.clientAssignments ?? this.$store.state.selectedServiceProvider?.fields?.clientAssignments?.de
			if (!cas) return true
			for (let ca of cas || []) {
				if (!ca.fields?.isHomebase?.de) continue
				for (let client of this.$store.state.loggedInUser.fields.clients.de) {
					if (client.sys.id != ca.fields.client.de.sys.id) continue
					return true
				}
				return false
			}
			return false
		},
		// ATT: this can only be effectively used on BusinessProfile, ServiceDetail and ClientAssignmentsField currently
		userIsExternalOperator() {
			if (!this.userIsOperator) return false
			let cas = this.clientAssignments ?? this.$store.state.selectedServiceProvider?.fields?.clientAssignments?.de
			if (!cas) return false
			if (this.userIsHomeOperator) return false
			return true
		},
	
    // Defines the breakpoint form which the SideBar component is changed from fixed to collapsible
    isSideBarFixed() {
      return this.$vuetify.breakpoint.mdAndUp
    },
	},
	methods: {
		getCountries() {
			return countries.sort((a, b) => (a.priority > b.priority) ? 1 : -1)
		},
		sortClients(clients, mp) {
			// the sorting below sorts clients by: homebase client is first in the list; if there are selected clients they are second on the list and the others are sorted alphabetically
			clients.sort((a, b) => {
				if (a.sys.id === mp.fields.homeClient.de.sys.id) return -1
				if (b.sys.id === mp.fields.homeClient.de.sys.id) return 1
				if (this.model[a.sys.id] && !this.model[b.sys.id]) return -1
				if (!this.model[a.sys.id] && this.model[b.sys.id]) return 1
				return a.fields.title.de.localeCompare(b.fields.title.de)
			})
		},
		setSectionError(sectionMessage, message) {
			sectionMessage.error = true
			sectionMessage.message = message
		},
		resetSectionError(sectionMessage, message = '') {
			sectionMessage.error = false
			sectionMessage.message = message
		},
		setSectionWarning(sectionMessage, message) {
			sectionMessage.warning = true
			sectionMessage.message = message
		},
		resetSectionWarning(sectionMessage, message = '') {
			sectionMessage.warning = false
			sectionMessage.message = message
		},
		featureEnabled(featureName) {
			const clientFeature = this.$store.state.selectedClient.fields.features.de.find(x => x.id === featureName)
			return clientFeature?.status === 'enabled' ? true : false
		},
		compare(a, b) {
			const objA = a?.fields?.title?.de ? a?.fields?.title?.de?.toUpperCase() : a ? a?.de : ''
			const objB = b?.fields?.title?.de ? b?.fields?.title?.de?.toUpperCase() : b ? b?.de : ''
			return objA ? objA.localeCompare(objB, `de`, {ignorePunctuation: true}) : -1
		},
		compareCountry(a, b) {
			const objA = a?.name[this.selectedLocale] ? a?.priority+a?.name[this.selectedLocale].toUpperCase() : a 
			const objB = b?.name[this.selectedLocale] ? b?.priority+b?.name[this.selectedLocale].toUpperCase() : b 
			return objA && typeof(objA) === 'string' ? objA?.localeCompare(objB, `${this.selectedLocale}`, {ignorePunctuation: true}) : -1
		},
		cfoClean(o) {
			o = ContentfulObject.cleanSys(o)
			return o
		},
		cfoFlatten(o) {
			return ContentfulObject.flatten(o)
		},
		cfoLink(o) {
			return ContentfulObject.link(o)
		},
		// equivalent to contentService.getAssetUrl
		getAssetUrl(asset, locale = 'de') {
			if (asset?.fields?.file?.[locale]?.url?.length) {
				let url = asset.fields?.file?.[locale]?.url
				if (!url && locale != 'de') return asset.fields.file.de?.url
				if (url.substr(0, 2) == '//') return 'https:' + url
				return url
			}
		},
		// returns a map statusName => true
		// SP: collect status
		// OP: search for the status in the right client
		getStatusMap(object) {
			let status = {}

			for (let ca of object.fields.clientAssignments?.de ?? []) {
				if (ca.fields?.client?.de?.sys?.id && ca.fields?.status?.de) {
					if (this.userIsOperator && ca.fields.client.de.sys.id == this.$store.state.selectedClient.sys.id)
						return { [ca.fields.status.de]: true }
					status[ca.fields.status.de] = true
				}
			}
			return status
		},
		getStatusClient(object) {
			for (const ca of object.fields.clientAssignments?.de ?? []) {
				// TODO: can it happen, that a sp does not have a home client assignment?
				// for the service provider we show the home client status
				if (!this.userIsOperator && !ca.fields.isHomebase?.de) continue
				// for the operator we show the status of his client
				if (this.userIsOperator && ca.fields.client.de.sys.id !== this.$store.state.selectedClient.sys.id) continue

				return ca.fields.status.de
			}

			return 'INVALID'
		},
		// SP: update all statuses
		// OP: search for the status in the right client and update it
		updateStatus(object, status) {
			for (let ca of object.fields.clientAssignments?.de ?? []) {
				if (this.userIsOperator && ca.fields.client.de.sys.id != this.$store.state.selectedClient.sys.id)
					return ca.fields.status.de = status
				ca.fields.status.de = status
			}
		},
		async impersonate(sp) {
			this.loading = true
			try {
				//Set info for audit logging
				await this.$store.commit('setSelectedServiceProvider', sp)
				await this.$store.commit('setIsImpersonation', true)
				await this.$store.commit('setImpersonatedServiceProvider', sp)
				await this.$store.commit('setImpersonationStartTime', moment().toISOString())

				const res = await this.$httpGet(`/serviceprovider/${sp.sys.id}?startImpersonation=true`)
				
				if (!res.serviceProvider?.fields.mainUserAccount) throw this.$t('text.impersonationError')

				await this.$store.commit('setSelectedServiceProvider', res.serviceProvider)
				await this.$store.commit('setImpersonatedServiceProvider', res.serviceProvider)
				await this.$store.commit('setComponents', res.serviceProvider.fields.mainUserAccount.fields.applications.de)
				
				this.$emit("show-dashboard")
			}
			catch (error) {
				if (error.response?.status == 401) {
					this.$emit("show-login")
				}
				else if (error.response) {
					this.errorTitle = this.$t('text.ERROR')
					this.errorDetail = error.response.error
				}
				else {
					this.errorTitle = this.$t('text.ERROR')
					this.errorDetail = error
				}
			}
			this.loading = false
		},
		async exitImpersonation() {
			let impersonatedServiceProvider = this.$store.state.impersonatedServiceProvider

			if (!impersonatedServiceProvider || Object.keys(impersonatedServiceProvider).length== 0) {
				await this.$store.commit('setIsImpersonation', false);
			} else {
				const res = await this.$httpPost(`/exit-impersonation`)
			}
			if (this.$store.state.loggedInUser.fields.type.de == 'serviceprovider') {
				await this.$store.commit('setSelectedServiceProvider', this.$store.state.loggedInUser.fields.serviceProvider.de)
			} else {
				await this.$store.commit('setIsImpersonation', false)
				await this.$store.commit('setSelectedServiceProvider', null)
				await this.$store.commit('setImpersonatedServiceProvider', null)
			}

			await this.$store.commit('setComponents', this.$store.state.loggedInUser.fields.applications.de)
			await this.$store.commit('setSelectedComponent', {})

			this.$emit("show-dashboard", 'exitImpersonation')
		},
		$tn(key, param) {
			return this.$t(key).replace('{n}', param)
		},
		$validateGeneric(validation) {
			if (!validation) return []
			// listen to the dirty event as this function is used by computed fields
			validation.$dirty
			const errors = []
			if (validation.required === false) errors.push(this.$tn('text.generic_required'))
			if (validation.minLength === false) errors.push(this.$tn('text.generic_minLength', validation.$params.minLength.min))
			if (validation.maxLength === false) errors.push(this.$tn('text.generic_maxLength', validation.$params.maxLength.max))

//			validation.maxLength || errors.push(this.$tn('text.genericMaxChars'), validation.$params.maxLength.max)

			return errors
		},
		convertLink(input){
			if (input && typeof input === 'string') {
				input = input.replaceAll(/href="(?!http)/g, 'href="http://')
			}
			
			return input
		},
		isDecimal(value, evt) {
			evt = (evt) ? evt : window.event
			//Restrict to only one decimal
			if (evt.key === "." && value.indexOf(".") > -1) {
				evt.preventDefault()
				return false
			} else {
				//Disallow alphas
				if (evt.key !== "." && isNaN(evt.key)) {
					evt.preventDefault()
					return false
				}
			}
			
			return true
		},
		isNaturalNumber(evt) {
			evt = (evt) ? evt : window.event

			//Disallow alphas
			if (isNaN(evt.key)) {
				evt.preventDefault()
				return false
			}
		
			return true
		},
		validateTextLocales(text) {
			let haveText = false
			if (this.locales) {
				if (this.locales.length === 0) {
				  // if the async locale initialization is not yet done, before this function is executed
				  return true;
				}
				for (const locale of this.locales) {
					if (text[locale.code]?.length > 0 && text[locale.code] !== '<p></p>') {
						haveText = true
						break
					}
				}
			}
			return haveText
		},
		objectValues(object) {
			let haveValues = true
			for (const key of Object.keys(object)) {
				if (object[key].length === 0) {
					haveValues = false
				}
			}
			return haveValues
		},
		validEmail(email) {
			return (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))
		},
		showError(error) {
			this.loading = false
			const detail = error?.response?.data?.error ?? error
			
			this.errorTitle = this.$t('text.ERROR')
			this.errorDetail = detail
		},
		requestHeaders() {
			return {
				Authorization: 'Bearer ' + this.$store.state.loggedInUser.kc_token,
				Accept: 'application/json, text/plain, */*',
				'Content-Type': 'application/json',
				'mys-logged-in-user': this.$store.state.loggedInUser?.email?.de ? this.$store.state.loggedInUser.email.de : '',
				'mys-is-impersonation': this.$store.state.isImpersonation && this.$store.state.isImpersonation === true ? true : false,
				'mys-impersonation-timestamp': '',
				'mys-selected-sp': this.$store.state.selectedServiceProvider?.sys?.id ? this.$store.state.selectedServiceProvider.sys.id : '',
				'mys-client-id': this.$store.state.selectedClient?.sys?.id ? this.$store.state.selectedClient.sys.id : ''
			}
		},
		checkRequiredForAllLocales(locales, fieldValue) {
			let hasTextForAllLocales = true
			for (const locale of locales) {
				if (!fieldValue[locale.code]?.length) {
					hasTextForAllLocales = false
					break
				}
			}
			return hasTextForAllLocales
		},
		async loadCoreConfig() {
			try {
				const coreConfig = await this.$httpGet(`/content/entry/CORE-CONFIG`)
				await this.$store.commit('setCoreConfig', coreConfig)

			} catch (error) {
				if (error.response?.status == 401)
					return this.$emit("show-login")
				this.showError(error)
			}
    }	,

		//Service Designer
		hasField(sectionName, fieldName, product) {
			//New Product
			if (product.sys?.id === '') {
				return this.$store.state.selectedServiceType?.fields?.template?.de && this.$store.state.selectedServiceType.fields.template.de[sectionName]?.[fieldName]
			} else {
				//Existing Product
				return product.fields?.serviceType?.de?.fields?.template?.de && product.fields?.serviceType?.de?.fields?.template?.de[sectionName]?.[fieldName]
			}
		},
		validateRefOnActivate(refName, refs) {
			let isValid = true
			if (this.hasSection(refName)) {
				isValid = refs[refName]?.validateAllFields()
			}
			return isValid
		},
		validateRefOnSave(refName, refs, runValidation) {
			let isValid = true
			if (this.hasSection(refName)) {
				isValid = refs[refName]?.validateTitle(runValidation)
			}
			return isValid
		},
		getPattern(patternSource) {
			const parts = patternSource.split('/')
			let images

			switch (parts[0]) {
				case 'red': images = require.context(`@/assets/patterns/red/`, false, /\.png$/); break
				case 'orange': images = require.context(`@/assets/patterns/orange/`, false, /\.png$/); break
				case 'yellow': images = require.context(`@/assets/patterns/yellow/`, false, /\.png$/); break
				case 'green': images = require.context(`@/assets/patterns/green/`, false, /\.png$/); break
				case 'blue': images = require.context(`@/assets/patterns/blue/`, false, /\.png$/); break
				case 'purple': images = require.context(`@/assets/patterns/purple/`, false, /\.png$/); break
			}
			return images('./' + parts[1])
		},
		spEnabledSetting(settings, settingToCheck) {
			if (this.userIsOperator) return true

			const setting = settings?.find(setting => setting.id === settingToCheck)
			return setting?.enabled === true ? true : false 
		}
	}
}
</script>
