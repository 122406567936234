<template>
  <div v-if="show" :class="{backdrop}" @click="backdropClick" class="dialog-div">
      <v-toolbar elevation="0" color="white" width="100%" class="dialog-header">
        <v-row style="width:100%" align="center">
          <v-toolbar-title class="ml-5">{{$t('text.mediaViewer')}}</v-toolbar-title>
        </v-row>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider/>

      <v-layout row wrap class="scrollable dialog-content">
        <div style="width: 100%">
          	<v-card fluid height="100%">
              <Alert v-model="errorTitle">{{ errorDetail }}</Alert>
              <loading :active.sync="isLoading" :is-full-page="true" color="#4caf50" />

              <v-container style="height:100%;padding:0px;margin:0">
                <v-row class="mb-6" style="height:100%" no-gutters>
                  <!-- Sidebar -->
                  <v-col cols="auto">
                    <v-card flat class="mediaSidebar">
                      <ul id="mediaNavlist">
                        <a href="#">
                          <li @click="setActive('gallery')" :class="{'active': activeLink === 'gallery'}" class="pl-5 pr-5" data-cy="gallery">
                            <v-icon :color="activeLink === 'gallery' ? '#ffffff' : '#000000'" class="mr-2">mdi-image-multiple-outline</v-icon>
                            <span class="d-none d-md-inline">{{$t('text.mediaGallery')}}</span>
                          </li>
                        </a>

                        <a href="#">
                          <li @click="setActive('myComputer')" :class="{'active': activeLink === 'myComputer'}" class="pl-5 pr-5" data-cy="myComputer">
                            <v-icon :color="activeLink === 'myComputer' ? '#ffffff' : '#000000'" class="mr-2">mdi-laptop</v-icon>
                            <span class="d-none d-md-inline">{{$t('text.myComputer')}}</span>
                          </li>
                        </a>

                        <a href="#">
                          <li @click="setActive('viaURL')" :class="{'active': activeLink === 'viaURL'}" class="pl-5 pr-5" data-cy="viaURL">
                            <v-icon :color="activeLink === 'viaURL' ? '#ffffff' : '#000000'" class="mr-2">mdi-link-variant</v-icon>
                            <span class="d-none d-md-inline">{{$t('text.viaURL')}}</span>
                          </li>
                        </a>
                      </ul>
                    </v-card>
                  </v-col>

                  <v-col cols="9" style="height: 100%; overflow-y: scroll;">
                    <!-- Content -->
                    <v-container v-if="isView" grid-list-md text-xs-left class="pa-0">
                      <v-layout row wrap class="scrollable">
                        <template v-if="isView">
                          <v-row>
                            <v-card v-for="media in providerMedia" :key="media.sys.id"  flat style="background-color:transparent;padding:0px">
                              <MediaCard :media="media" :canSelect="true" :showTitle="true" @checkbox-change="setSelectedMedia"/>
                            </v-card>
                          </v-row>
                        </template>
                      </v-layout>
                    </v-container>

                    <!-- Upload Image -->
                    <div v-if="isComputerUpload">
                      <v-card id="card" style="box-shadow: none !important;padding:0px 12px 0px 12px">
                          <div class="field left-border">
                            <v-label>{{$t('text.chooseFile')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
                            <FileUpload :options="dropOptions" ref="dropzone" data-cy="dropzone" @thumbnail-created="onThumbnailCreated" @file-removed="onFileRemoved" />
                          </div>

                            <div class="field left-border">
                              <v-label>{{$t('text.title')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
                                <v-text-field outlined dense counter="256" maxLength="256" 
                                id="title"
                                v-model="mediaAsset.fields.title.de"></v-text-field>
                            </div>

                            <div class="field left-border">
                              <v-label>{{$t('text.altText')}}</v-label>
                                <v-text-field outlined dense counter="256" maxLength="256"
                                v-model="mediaAsset.fields.altText.de"/>
                                <p class="helpText" style="margin-top:-20px" v-html="$t('text.altTextHelp')"/>
                            </div>

                            <div class="field left-border">
                              <v-label>{{$t('text.description')}}</v-label>
                              <v-textarea outlined counter="180" maxLength="180"
                                v-model="mediaAsset.fields.description.de"></v-textarea>
                            </div>

                            <div class="field left-border">
                              <v-label>{{$t('text.copyrightOwner')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
                              <v-text-field outlined dense
                                  id="copyrightOwner"
                                  autocomplete="off"
                                  counter="256" maxLength="256"
                                  v-model="mediaAsset.fields.copyright"/>
                            </div>

                            <div class="field left-border">
                              <v-checkbox
                                id="usageConsent"
                                hide-details
                                :label="$t('text.usageConsent')"
                                :error-messages="usageConsentError"
                                v-model="usageConsent"/>
                                <span class="error-text">({{$t('text.required')}})</span>
                            </div>

                      </v-card>
                    </div>

                    <!-- URL Link -->
                    <div v-if="isURLUpload" class="container-grid">
                        <v-card id="card" style="box-shadow: none !important;padding:0px 12px 0px 12px">
                          <div class="field left-border">
                            <v-label>URL <span class="error-text">({{$t('text.required')}})</span></v-label>
                            <v-text-field outlined dense
                                id="urlToLink"
                                autocomplete="off"
                                v-model="urlToLink"
                                required/>
                          </div>

                           <div class="field left-border">
                              <v-label>{{$t('text.title')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
                                <v-text-field outlined dense counter="256" maxLength="256"
                                id="title"
                                v-model="mediaAsset.fields.title.de"/>
                            </div>

                            <div class="field left-border">
                              <v-label>{{$t('text.altText')}}</v-label>
                                <v-text-field outlined dense counter="256" maxLength="256"
                                v-model="mediaAsset.fields.altText.de"/>
                                <p class="helpText" style="margin-top:-20px" v-html="$t('text.altTextHelp')"/>
                            </div>

                            <div class="field left-border">
                                <v-label>{{$t('text.description')}}</v-label>
                                <v-textarea outlined counter="180" maxLength="180"
                                  v-model="mediaAsset.fields.description.de"></v-textarea>
                            </div>

                            <div class="field left-border">
                              <v-label>{{$t('text.copyrightOwner')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
                              <v-text-field outlined dense
                                  id="copyrightOwner"
                                  autocomplete="off"
                                  counter="256" maxLength="256"
                                  v-model="mediaAsset.fields.copyright"/>
                            </div>

                            <div class="field left-border">
                              <v-checkbox
                                id="usageConsent"
                                :label="$t('text.usageConsent')"
                                :error-messages="usageConsentError"
                                v-model="usageConsent"/>
                                <span class="error-text">({{$t('text.required')}})</span>
                            </div>
                        </v-card>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
        </div>
      </v-layout>

      <v-divider/>
      <v-toolbar elevation="0" color="white" width="100%" height="60px" class="dialog-footer">
        <v-spacer></v-spacer>
        <v-btn class="gradientButton" dark elevation="0"
          @click="cancel()"
          v-html="$t('text.cancel')"
          data-cy="cancel"
        />
        <v-btn class="greenButton" dark elevation="0" v-if="showConfirm"
          @click="addMedia()"
          v-html="addLabel"
          data-cy="confirm"
        />

        <v-btn class="greenButton" elevation="0" dark style="height:40px !important" :disabled="!isEnabledUpload"
        v-if="isComputerUpload||isURLUpload"
        @click="uploadMedia()" data-cy="uploadFile"><v-icon color="#ffffff">mdi-arrow-up-circle</v-icon> {{$t('text.uploadFile')}}</v-btn>
      </v-toolbar>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import MediaCard from './MediaCard'
import Alert from '@/components/common/Alert.vue';
import FileUpload from "@/components/media/FileUpload";
import Common from '@/mixins/Common.vue'

export default {
	name: 'MediaViewer',
	components: {FileUpload, Loading, MediaCard, Alert },
  mixins: [ Common ],
  
  data () {
    return {
      show: false,
      backdrop: this.showBackdrop,
      activeLink: '',
      confirmLabel: '',
      showConfirm: false,

      isLoading: false,

      serviceProvider: {},
      media: [],
      fileToUpload: {},

      mediaAsset: {
        fields: {
          title: {},
          altText: {},
          description: {},
          copyright: ''
        }
      },

      selectedFiles: [],
      usageConsent: false,
      usageConsentError : '',
      errorTitle: '',
      errorDetail: '',

      providerMedia: [],

      isView: true,
      isComputerUpload: false,
      isURLUpload: false,
      uploadDisabled: true,
      selectedMedia: [],
      urlToLink: '',

      defaultAddLabel: this.$t('text.addImages'),
      addLabel: this.$t('text.addImages'),

      host: '',

      dropOptions: {
        acceptedFiles: '.jpg, .jpeg',
        addRemoveLinks: true,
        maxFilesize: 10,
        maxFiles: 1
      },
    }
  },

  computed: {
    isEnabledUpload() {
      let isValid = true
       if (!this.mediaAsset.fields.copyright.length > 0 || !this.usageConsent) {
        isValid = false
      }

      if (!this.mediaAsset.fields.file && this.urlToLink.length === 0) {
        isValid = false
      }

      //New Media Validation
      if (Object.keys(this.mediaAsset.fields.title).length === 0 || !this.mediaAsset?.fields?.title?.de?.length) {
        isValid = false
      }
      return isValid
    }
  },

  watch: {
    show() {
      if (this.show === true) {
        if (this.$store.state.selectedServiceProvider?.sys && this.providerMedia?.length === 0) {
          this.getMedia()
        }
      } else {
        this.clearSelectedMedia()
      }
    }
  },

  async mounted() {
    this.isView = true
    this.setActive("gallery")
  },

  beforeDestroy () {
		this.fixZindexes(false)
	},

  methods: {
    onThumbnailCreated(file) {
      this.mediaAsset.fields.file = file
    },

    onFileRemoved() {
      this.mediaAsset.fields.file = null
    },

    setActive(key) {
      this.activeLink = key;
      this.files = [];
      this.usageConsentError = ""

      if (key === 'myComputer') {
        this.isComputerUpload = true;
        this.isView = false;
        this.isURLUpload = false;
        this.confirmLabel = this.$t('text.uploadFile')
      } else if (key === "viaURL") {
        this.isURLUpload = true;
        this.isView = false;
        this.isComputerUpload = false;
      } else {
        this.isView = true;
        this.isURLUpload = false;
        this.isComputerUpload = false;
      }
    },

    setSelectedMedia(media) {
      if (media.checked) {
        this.selectedMedia.push(media);
      } else {
        for (let i=0; i < this.selectedMedia.length; i++) {
          if (this.selectedMedia[i].sys.id === media.sys.id) {
            this.selectedMedia.splice(i, 1);
            break;
          }
        }
      }

      this.addLabel = this.$t('text.addImages').replace("%n%", this.selectedMedia.length)
      this.showConfirm = this.selectedMedia.length > 0;
    },

    clearSelectedMedia() {
      this.selectedMedia = []
      this.showConfirm = false

      this.activeLink = 'gallery'
      this.isView = true
      this.isURLUpload = false
      this.isComputerUpload = false
      this.usageConsent = false
      this.urlToLink = ''
      this.errorTitle = ''
      this.errorDetail = ''

      for (let media of this.providerMedia) {
        delete media.checked
      }

      this.mediaAsset = {
        fields: {
          title: {},
          altText: {},
          description: {},
          copyright: ''
        }
      }
    },

    addMedia() {
      this.$emit("add-media", this.selectedMedia)
    },

    async getMedia() {
      this.isLoading = true

      try {
        const res = await this.$httpGet(`/media/${this.$store.state.selectedServiceProvider.sys.id}`)

        this.providerMedia = res?.media
        this.setActive("gallery")    

        this.isComputerUpload = false
        this.isURLUpload = false
        this.isView = true

        this.isLoading = false
        
      } catch (error) {
        this.showError(error)
      }
    },

    async uploadMedia() {
      const isValid = this.validate()

     if (isValid) {
        this.isLoading = true;

        this.mediaAsset.fields.title.de = !this.mediaAsset.fields.title.de ? this.mediaAsset.fields.title[this.selectedLocale] : this.mediaAsset.fields.title.de
        this.mediaAsset.fields.altText.de = !this.mediaAsset.fields.altText.de ? this.mediaAsset.fields.altText[this.selectedLocale] : this.mediaAsset.fields.altText.de
        this.mediaAsset.fields.description.de = !this.mediaAsset.fields.description.de ? this.mediaAsset.fields.description[this.selectedLocale] : this.mediaAsset.fields.description.de

        var data = {
          mediaAssets: [this.mediaAsset],
          url: this.urlToLink,
          serviceProviderId: this.$store.state.selectedServiceProvider.sys.id,
          serviceProviderName: this.$store.state.selectedServiceProvider.fields.title.de,
          copyrightOwner: this.mediaAsset.fields.copyright,
          usageConsent: this.usageConsent
        }

        try {
          const res = await this.$httpPost('/media', data)

          this.urlToLink = ""
          this.usageConsent = false
          this.copyrightError = ""
          this.copyrightOwnerError = ''
          this.mediaAsset = {
            fields: {
              title: {},
              altText: {},
              description: {},
              copyright: ''
            }
          }

          this.getMedia()

         } catch(error) {
          this.isLoading = false;

          if (error.response && error.response.status === 401) {
            this.$emit("show-login")
          }

          this.errorTitle = this.$t('text.ERROR');
          this.errorDetail = this.$t('text.uploadError');
        }
      }
    },

    validate() {
      let isValid = true

      if (!this.mediaAsset.fields.copyright.length > 0 || !this.usageConsent) {
        isValid = false
      }

      if (!this.mediaAsset.fields.file && this.urlToLink.length === 0) {
        isValid = false
      }

      //New Media Validation
      if (Object.keys(this.mediaAsset.fields.title).length === 0) {
        isValid = false
      }

      this.copyrightError = this.usageConsent === false ? this.$t('text.copyrightError') : ''
      this.copyrightOwnerError = this.mediaAsset.fields.copyright.length === 0 ? ' ' :  ''

      return isValid
    },

    confirm() {
      if (this.confirmHandler && typeof this.confirmHandler === 'function') {
        this.confirmHandler();
      }
      this.closeDialog();
    },

    cancel() {
      if (this.cancelHandler && typeof this.cancelHandler === 'function') {
        this.cancelHandler();
      }
      this.closeDialog();
    },

    remove() {
      if (this.deleteHandler && typeof this.deleteHandler === 'function') {
        this.deleteHandler();
      }
      this.closeDialog();
    },

    closeDialog() {
      this.show = false;
    },

    backdropClick() {
      if (this.closeOnOuterClick) {
        this.closeDialog();
      }
    },
		fixZindexes(n) {
			// raise/restore the whole parent stack's z-index to avoid overlaying siblings
			for (let el = this.$parent; el; el = el.$parent) {
				if (!el?.$el) continue
				if (n) {
					el.$el.style['z-index-backedup'] = true
					el.$el.style['z-index-backup'] = el.$el.style['z-index']
					el.$el.style['z-index'] = 999
				}
				else {
					if (el.$el.style['z-index-backedup'])
						el.$el.style['z-index'] = el.$el.style['z-index-backup']
				}
			}
		}
  }
};
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
}
.mediaSidebar {
  background-color: #f4f4f4;
  border-right: 1px solid #f4f4f4;
  height: 100% !important;
  padding: 0px;
  background-color: #f4f4f4 !important;
  border-right: 1px solid #dbdbdb !important;
}
#mediaNavlist {
  padding: 0;
}
#mediaNavlist li {
  list-style-type: none;
  width:100%;
  height:64px;
  line-height:64px;
  padding-right:10px;

  font-family: 'Inter', sans-serif;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.25px;
  font-weight: 500;

  display: flex;
  align-items: middle;
  border-bottom: 1px solid #dbdbdb !important;
}
#mediaNavlist a {
  color: #000000;
}
#mediaNavlist .active {
  border-top: 1px solid #00aeef;
  border-bottom: 1px solid #00aeef;
  background-color: #00aeef;
  color: #ffffff !important;
}
.backdrop {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  top: 64px;
  left: 0;
}
.dialog-div {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 1100px;
  height: 100%;
  max-width: calc(100vw - 20px);

  background-color: #ffffff;
  border-radius: 10px !important;
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.2);
  z-index: 999;
  overflow: hidden;

  .dialog-header {
    max-height: 64px;
  }

  .dialog-footer {
    max-height: 60px;

    .gradientButton {
      height: 40px !important;
      min-height: 40px !important;
      margin-left: 10px;
      margin-right: 10px;
      color: #000;
    }

    .greenButton {
      height: 40px !important;
      margin-left: 10px;
      margin-right: 10px;
    }

    .redButton {
      height: 40px !important;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
