<template>
	<v-container fluid style="padding-bottom: 70px;">
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<loading data-cy="loading" :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

		<!-- Navbar -->
		<div class="navbar">
			<v-row align="center" class="flex-nowrap" style="white-space:nowrap; overflow:hidden;">
				<v-row v-if="!addNewProviderClicked" class="px-2 flex-nowrap" align="center">
            <v-text-field outlined dense clearable hide-details height="48" class="mr-2"
            prepend-inner-icon="mdi-magnify"
            placeholder='Search'
            style="max-width:33%; min-width: 100px;"
            v-model="searchString"
            @keyup.enter="search()"
            data-cy="search"
            @click:clear="clearSearch()">
            </v-text-field>

            <v-spacer/>

            <v-btn id="btnCreate" class="gradientButton mr-2" elevation="0" x-small @click="addNewProvider">
              <v-icon>mdi-plus</v-icon>
              <span class="d-none d-md-inline">{{$t('text.addServiceProvider')}}</span>
            </v-btn>

            <!-- Filters -->
            <div>
              <v-menu offset-y bottom left origin="top right" style="max-width:600px" :close-on-content-click="false" v-model="isOpened" >
                <template v-slot:activator="{ on }" style="max-width:600px !important">
                  <v-row justify="end" v-on="on">
					<div v-if="filter" class="dot-container stack-top"><span class="dot"></span></div>
                    <v-btn data-cy="spFilter" class="gradientButton" elevation="0" x-small>
                      <v-icon>mdi-filter</v-icon>
                      <span class="d-none d-md-inline">{{$t('text.filter')}}</span>
					  <v-icon style="cursor:pointer;">mdi-menu-down</v-icon>
                    </v-btn>
                  </v-row>
                </template>
                <v-list style="max-width:600px !important;">
                  <v-list-item style="max-width:600px !important;" class="pb-5">
                    <v-list-item-title data-cy="statusFilter" @click="closeDropDown('vSelectStatus')">
                        <span style="width:88%">{{$t('text.serviceProviderStatus')}}</span>
                        <v-select
                          ref="vSelectStatus"
                          v-model="selectedStatuses"
                          :items="statuses"
                          :item-text="item => $t('text.' + item.id)"
                          :item-value="item => item.id"
                          multiple small-chips hide-details
                          :placeholder="$t('text.allLabel')"
                          filled solo
                          :menu-props="{ offsetY: true  }"
                          class="gradientButton"
                          style="min-height: initial !important;">
                          <template #selection="{ item }">
                            <v-chip :color="item.color" dark>{{$t('text.' + item.id)}}</v-chip>
                          </template>
                        </v-select>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item style="width:500px !important;min-width:500px !important;max-width:500px !important;" class="pb-5">
                    <v-list-item-title>
                      <div data-cy="categoryFilter" @click="closeDropDown('vSelectCategory')">
                        <span style="width:88%">{{$t('text.serviceType')}}</span>
                        <v-select
                          ref="vSelectCategory"
                          :placeholder="$t('text.allLabel')"
                          filled solo
                          :menu-props="{ offsetY: true  }"
                          v-model="selectedProductCategories"
                          :items="productCategories"
                          :item-text="item => item.fields.title[selectedLocale]"
                          :item-value="item => item.sys.id"
                          multiple small-chips hide-details
                          class="gradientButton"
                          style="min-height: initial !important;">
                          <template #selection="{ item }">
                            <v-chip :color="item.color" dark>{{item.fields.title[selectedLocale]}}</v-chip>
                          </template>
                        </v-select>
                      </div>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item style="width:500px !important;min-width:500px !important;max-width:500px !important;" class="pb-5">
                    <v-list-item-title>
                      <div data-cy="marketplaceFilter" @click="closeDropDown('vSelectMarketplace')">
                        <span style="width:88%">{{$t('text.clientsTitle')}}</span>
                        <v-select
                          ref="vSelectMarketplace"
                          :placeholder="$t('text.allLabel')"
                          filled solo
                          :menu-props="{  offsetY: true  }"
                          v-model="selectedClients"
                          :items="clients"
                          :item-text="item => item.fields.title[selectedLocale]"
                          :item-value="item => item.sys.id"
                          multiple small-chips hide-details
                          class="gradientButton"
                          style="min-height: initial !important;">
                          <template #selection="{ item }">
                            <v-chip :color="item.color" dark>{{item.fields.title[selectedLocale]}}</v-chip>
                          </template>
                        </v-select>
                      </div>
                    </v-list-item-title>
                  </v-list-item>

                  <v-divider/>

                  <v-row style="width:100%;" class="pa-4">
                    <v-btn class="gradientButton" elevation="0" style="width:48%;margin-right:4%" @click="clearFilter()">{{$t('text.clearFilter')}}</v-btn>
                    <v-btn class="greenButton" elevation="0" dark style="width:48%;" data-cy="applyFilter" @click="applyFilter()">{{$t('text.applyFilter')}}</v-btn>
                  </v-row>
                </v-list>
              </v-menu>
            </div>
			</v-row>
			</v-row>
		</div>

		<v-card class="tableCard">
			<v-data-table fixed-header hide-default-footer
				v-model="selected"
				:headers="headers"
				:items="serviceProviders"
				:items-per-page="limit"
        		:mobile-breakpoint="700"
				item-key="name"
				class="elevation-0"
				style="border-radius: 0"
				@update:sort-desc="sortTable($event)"
				>
				<template v-slot:item="{ item }">
					<tr @click="showProviderDetail(item)">
						<td>
							<v-icon color="#f34545" v-if="!userHasAccount(item)">mdi-account-remove</v-icon>
							<v-icon color="#64c823" v-else-if="userHasAccount(item)">mdi-check-circle</v-icon>
							{{item.fields.title[selectedLocale]}}
						</td>
						<td>{{getFirstProductCategory(item)}}</td>
						<td>{{ item.sys.createdAt | formatDate }}</td>
						<td>
							<div style="display:inline-block" v-for="cai of item.fields.clientAssignments.de" :key="cai.sys.id">
								<span v-if="cai && cai.fields && cai.fields.status"
									:class="{
										clientAssignmentChip: true,
										[cai.fields.status.de]: true,
									}"
									:title="cai.fields.status.de"
									>
									{{ getClientId(cai) }}
								</span>
							</div>
						</td>
						<td><Status :status="getClientStatus(item)" /></td>
						<td>
							<v-btn class="blueButtonMin"  dark elevation="0"
							v-if="canOperateAs(item)"
							@click.stop="impersonate(item)">{{$t('text.operateAs')}}</v-btn>
						</td>
					</tr>
				</template>
			</v-data-table>
			<v-card class="tableFooter">
				<v-card-actions>
					<TablePaginator v-model="offset" :limit="limit" :total="serviceProviderCount" @input="getServiceProviders()" :results="serviceProviders" />
				</v-card-actions>
			</v-card>
		</v-card>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Status from '@/components/common/Status.vue'
import Alert from '@/components/common/Alert.vue'
import TablePaginator from '@/components/common/TablePaginator.vue'
import Common from '@/mixins/Common.vue'

export default {
	name: 'ServiceProviders',
	components: { Loading, Status, Alert, TablePaginator },
	mixins: [ Common ],
	
	data() {
		return {
		loading: false,
		offset: this.$store.state.offset,
		limit: 15,
		searchSubmitted: false,
		errorTitle: '',
		errorDetail: '',
		selected: [],
		singleSelect: '',
		serviceProviders: [],
		serviceProviderCount: 0,

		searchString: this.$store.state.searchString,
		addNewProviderClicked: false,
		isOpened: false,
		filter: null,
		statuses: [
				{id:'pending', color:'#ff9e21', dark:true},
				{id:'reapprove', color:'#ffb400'},
				{id:'approved', color:'#64c823', dark:true},
				{id:'deactivated', color:'#f34545', dark:true},
				{id:'declined', color:'#f34545', dark:true},
		],
		selectedStatuses: this.filter ? this.filter.statuses : [],
		validFromDate: this.filter ? this.filter.validFromDate : '',
		validToDate: this.filter ? this.filter.validToDate : '',
		productCategories: {de:[]},
		selectedProductCategories: this.filter ? this.filter.productCategories : [],
		clients: [],
		selectedClients: this.filter ? this.filter.selectedClients : [],
		showFromDate: false,
		showToDate: false,
		isNegative:  this.filter ? this.filter.isNegative : false,
		dropdownOpen: false,
	}},

	computed: {
		headers () {
			return [
				{ text: this.$t('text.providerName'), value: "provider_name", width: '30%', sortable: false, cellClass: 'truncate' },
				{ text: this.$t('text.category'), value: "category", width: '15%', sortable: false, cellClass: 'truncate' },
				{ text: this.$t('text.registrationDate'), value: "registration_date", width: '10%', sortable: false, cellClass: 'truncate' },
				{ text: this.$t('text.clientAssignments'), value: "client_assignments", width: '20%', sortable: false },
				{ text: this.$t('text.status'), value: "status", width: '15%', sortable: false },
				{ text: '', value: "operate_as", width: '10%', sortable: false },
			]
		},
		validFromText() {
			if (this.validFromDate === '' || this.isNegative) {
				return this.$t('text.validFrom')
			}
			else return this.validFromDate
			// return this.validFromDate !== '' ? this.validFromDate : this.$t('text.validFrom')
		},
		validToText() {
			if (this.validToDate === '' || this.isNegative) {
				return this.$t('text.validUntil')
			}
			else return this.validToDate
			// return this.validToDate !== '' ? this.validToDate : this.$t('text.validUntil')
		},
	},

	watch: {
		'$store.state.selectedLocale': function() {
			this.selectedLocale = this.$store.state.selectedLocale
		},
	},

	async mounted() {
		this.getServiceProviders()

		await this.getProductCategories()
		await this.getMarketplaceClientsForClient(this.$store.state.selectedClient)
	},

	methods: {
		userHasAccount(item) {
			return item?.fields?.mainAccountId?.de !== '' || (item?.fields?.userAccount?.de && Object.keys(item?.fields?.userAccount?.de).length > 0)
		},
		getFirstProductCategory(serviceProvider) {
			const currentCA = serviceProvider.fields.clientAssignments?.de?.find(x => x?.fields?.client?.de?.sys?.id === this.$store.state.selectedClient.sys.id)
			return currentCA?.fields?.productCategories?.de?.[0]?.fields?.title?.[this.selectedLocale]
		},
		getClientStatus(serviceProvider) {
			const currentCA = serviceProvider.fields.clientAssignments.de.find(x => x?.fields?.client?.de?.sys?.id === this.$store.state.selectedClient.sys.id)
			return currentCA?.fields?.status?.de
		},
		canOperateAs(serviceProvider) {
			const currentCA = serviceProvider.fields.clientAssignments.de.find(x => x?.fields?.client?.de?.sys?.id === this.$store.state.selectedClient.sys.id)
			if (currentCA?.fields?.isHomebase?.de === true && serviceProvider.fields.mainAccountId?.de) {
				return true
			}
			return false
		},
		getClientId(cai) {
			return cai?.fields?.client?.de?.fields?.clientId?.de ? cai.fields.client.de.fields.clientId.de : ''
		},
		addNewProvider() {
			this.addNewProviderClicked = true
			this.showNewProvider()
		},

		applyFilter() {
			if (this.selectedStatuses.length > 0 || this.validFromDate.length > 0 || this.validToDate.length > 0 || this.selectedProductCategories.length > 0 || this.selectedClients.length > 0 || this.isNegative) {
				this.filter = {
					statuses: this.selectedStatuses,
					validFromDate: this.validFromDate,
					validToDate: this.validToDate,
					isNegative: this.isNegative,
					productCategories: this.selectedProductCategories,
					selectedClients: this.selectedClients
				}
				this.isOpened = false
				this.getServiceProviders()
			}
			else {
				this.clearFilter()
			}
		},
		clearFilter() {
			this.isOpened = false
			this.selectedStatuses = []
			this.validFromDate = ''
			this.validToDate = ''
			this.isNegative = false
			this.selectedProductCategories = []
			this.selectedClients = []
			this.filter = null
			this.offset = 0
			this.getServiceProviders()
		},
		toggleDatePicker(pickerToToggle) {
			if (pickerToToggle === 'from') {
				this.showToDate = false
				this.showFromDate = true
			} else {
				this.showFromDate = false
				this.showToDate = true
			}
		},
		resetDates() {
			this.validToDate = ''
			this.showFromDate = false
		},
		closeDropDown(ref) {
			if (!this.dropdownOpen) {
				this.$refs[ref].focus()
				this.dropdownOpen = true
			} else {
				this.$refs[ref].blur()
				this.dropdownOpen = false
			}
		},

		async getProductCategories() {
			try {
				this.productCategories = this.$store.state.selectedClient.fields.productCategories.de
				if (this.productCategories) {this.productCategories.sort(this.compare)}

				if (this.productCategories?.length > 0) {
					for (let productCategory of this.productCategories) {
						if (productCategory.fields?.subProductCategories?.de) {productCategory.fields.subProductCategories.de.sort(this.compare)}
					}
				}
			}
			catch (error) {
				this.showError(error)
			}
		},

		async getMarketplaceClientsForClient(client) {
			let clients = []
			const clientSysId = client.sys.id
			let marketplaces = await this.$httpGet('/marketplace?clientSysId=' + clientSysId)
			let mp = marketplaces?.[0]
			let lookup = {}
			for (let client of mp.fields.externalClients.de) {
				clients.push(client)
				lookup[client.sys.id] = client
			}
			// add homeClient if it wasnt in the external clients
			if (!lookup[mp.fields.homeClient.de.sys.id])
				clients.push(mp.fields.homeClient.de)
				this.clients = clients
	},
		async sortTable(event) {
			let sortMode = event[0]
			await this.getServiceProviders(sortMode)
		},
		async showProviderDetail(item) {
			await this.$store.commit('setSelectedServiceProvider', item)
			this.$router.push('/businessProfile')
		},
		async showNewProvider() {
			await this.$store.commit('setSelectedServiceProvider', null)
			this.$router.push('/businessProfile')
		},
		async getServiceProviders(sortMode) {
			if (this.searchSubmitted) {
				return this.getSearchedServiceProviders()
			}

			this.loading = true
			try {
				await this.$store.commit('setOffset', this.offset)

				let url = `/serviceproviders?clientId=${this.$store.state.selectedClient.sys.id}&skip=${this.offset}&limit=${this.limit + 1}`
				if (this.$store.state.isImpersonation === true && this.$store.state.selectedServiceProvider.fields.tags?.de) {
					url += `&tags=${this.$store.state.selectedServiceProvider.fields.tags.de}`
				}
				if (this.userIsOperator && !this.$store.state.loggedInUser.fields.canSeeObjectsWithTags.de.find(x => x === "ALL")) {
					url += `&tags=${this.$store.state.loggedInUser.fields.canSeeObjectsWithTags.de}`
				}
				if (this.filter) {
					url += `&filter=${JSON.stringify(this.filter)}`
				}

				if (sortMode != undefined) url += `&sortMode=${sortMode}&selectedLocale=${this.selectedLocale}`

				let res = await this.$httpGet(url)
				this.serviceProviders = res.serviceProviders
				this.serviceProviderCount = res.total
			}
			catch (error) {
				if (error.response?.status == 400) {
					this.serviceProviders = []
				}
				else {
					this.errorTitle = this.$t('text.ERROR')
					this.errorDetail = error.response?.error ?? error
				}
			}
			this.loading = false
		},
		async getSearchedServiceProviders() {
			this.loading = true
			try {
				await this.$store.commit('setOffset', this.offset)
				await this.$store.commit('setSearchString', this.searchString)

				let url = `/search?client=${this.$store.state.selectedClient.sys.id}&contentType=serviceProvider&skip=${this.offset}&limit=${this.limit + 1}&searchString=${encodeURIComponent(this.searchString)}`

				if (this.userIsOperator && this.$store.state.isImpersonation === false && !this.$store.state.loggedInUser.fields.canSeeObjectsWithTags.de.find(x => x === "ALL")) {
					url += `&tags=${this.$store.state.loggedInUser.fields.canSeeObjectsWithTags.de}`
				}

				const res = await this.$httpGet(url)
				
				this.serviceProviders = res?.serviceProviders
				this.serviceProviderCount = res?.total ? res.total : 0
			}
			catch (error) {
				if (error.response?.status == 400) {
					this.serviceProviders = []
				}
				else {
					this.errorTitle = this.$t('text.ERROR')
					this.errorDetail = error.response?.error ?? error
				}
			}
			this.loading = false
		},
		async clearSearch() {
			this.offset = 0
			this.searchString = ''
			await this.$store.commit('setSearchString', null)

			this.search()
		},
		async search() {
			this.offset = 0
			if (this.searchString && this.searchString !== '') {
				this.searchSubmitted = true
				this.getSearchedServiceProviders()
			}
			else {
				this.searchSubmitted = false
				this.getServiceProviders()
			}
		},
	},

}
</script>

<style scoped>
input::placeholder {
  color: red!important;
  margin-left: 20px;
}

.v-data-table__mobile-row {
  word-break: break-all;
}

.v-badge--bordered .v-badge__badge::after {
	width: 14px !important;
	height: 14px !important;
}
.v-badge__badge {
	width: 14px !important;
	height: 14px !important;
	min-width: 14px !important;
	max-width: 14px !important;
}
.dot-container { width: 75px; height: 75px; position: absolute; top: 0; right: 0; }
.stack-top { width: 14px; height: 14px; z-index: 9; margin: 0px; padding:0px; }
.dot { height: 14px; width: 14px; background-color: #ff0022; border-radius: 50%; display: inline-block; position: absolute; top: 0px; right: 5px; }
</style>
