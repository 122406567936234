<template>
	<div>
		<Alert style="margin-top:4em" v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert style="margin-top:4em" v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<loading :active.sync="isLoading" :is-full-page="true" color="#4caf50"></loading>

		<OptionSelectField
			:serviceProviderOptions.sync="serviceProviderOptions"
			:clientOptions="clientOptions"
			v-model="model"
			:canAddNew="true"
			@focus="$emit('focus', $event)"
			@show-add-option='showDialog'
			:error="error"
			:data-cy="dataCy"
		></OptionSelectField>

		<Dialog  ref="addDialog"
		:title="$t('text.addNewOption')"
		:confirmLabel="$t('text.addOption')"
		:cancelLabel="$t('text.discardChanges')"
		:confirm-handler="addOption"
		:cancel-handler="resetOption"
		:close-handler="resetOption">
		>
		<template #content>
			<v-row>
			<v-col>
				<v-row v-for="(value, propertyName) in name" :key="propertyName">
				<div class="col-2 col-lg-1 pt-0 pl-0 pb-0"><img :src="getFlagIcon(propertyName)" width="24px" height="24px" style="margin-top:12px;margin-right:5px"/></div>
				<v-text-field class="col-10 col-lg-11" data-cy="addOptionField" outlined v-model="name[propertyName]"></v-text-field>
				</v-row>
			</v-col>
			</v-row>
		</template>
		</Dialog>
	</div>
</template>

<script>
import OptionSelectField from './OptionSelectField.vue'
import Dialog from '@/components/common/Dialog'
import Loading from 'vue-loading-overlay'
import Alert from '@/components/common/Alert.vue'


export default {
	components: { OptionSelectField, Dialog, Loading, Alert },
	props: {
		value: Object, // CfObject<ticketType>
		dataCy: String,
		error: Boolean
	},
	data() {
		return {
		model: null,
		name: {
			de: '',
			en: '',
			fr: '',
			it: ''
		},
		serviceProvider: '',
		errorTitle: '',
		errorDetail: '',
		successTitle: '',
		successDetail: '',
		isLoading: false,
	}},
	watch: {
		value(n) { this.model = n },
		model(n) { this.$emit('input', n) },
	},
	mounted() {
		this.model = this.value
		this.serviceProvider = this.$store.state.selectedServiceProvider
	},
	methods: {
		showDialog() {
			this.$refs.addDialog.show = true
		},
		addOption() {
			this.isLoading = true;
			let data
			// client adds option
			if (this.userIsOperator && this.$store.state.isImpersonation === false) {
				data = {
					itemType: 'ticketType',
					name: this.name,
					clientId: this.$store.state.selectedClient.sys.id
				}
			}
			// service provider adds option
			else {
				data = {
					itemType: 'ticketType',
					name: this.name,
					serviceProviderId: this.serviceProvider.sys.id,
					clientId: this.$store.state.selectedClient.sys.id
				}
			}

			this.$httpPost(`/option`, data).then(res => {		
				this.clear()	
				this.isLoading = false
				this.$refs.addDialog.show = false

				if (this.userIsOperator && this.$store.state.isImpersonation === false) {
					this.$store.state.selectedClient.fields.ticketTypes.de = res.client.fields.ticketTypes.de
				} else {
					this.serviceProvider = res.serviceProvider
					this.$store.state.selectedServiceProvider = this.serviceProvider
					this.model = res.option
				}
				
			}).catch(error => {
				this.isLoading = false;
				this.resetOption()

				this.$refs.addDialog.show = false;

				this.errorTitle = this.$t('text.ERROR');

				if (error?.response?.data?.error === 'You already have this option (client default)') {
					this.errorDetail = this.$t('text.optionAlreadyAssignedError')
					} else if (error?.response?.data?.error === 'This service option is already assigned to your profile') {
					this.errorDetail = this.$t('text.alreadyAssignedError')
					}  else {
					if (error.response) {
						if (error.response.data.error) {
						this.errorDetail = error.response.data.error;
						}
					} else {
						this.errorDetail = error;
					}
				}
			});
		},
		resetOption() {
			this.name = {
				de: '',
				en: '',
				fr: '',
				it: ''
			}
			this.$refs.addDialog.show = false
			this.model = null
		},
		clear() {
			this.errorTitle = ""
			this.errorDetail = ""
			this.successTitle = ""
			this.successDetail = ""

			this.requestSubmitted = false

			this.resetOption()
		},
		getFlagIcon(locale) {
			if (locale === "de") {
				return this.getGermanFlagIcon()
			} else {
				const images = require.context('@/assets/flags/', false, /\.svg$/)
				return images('./' + locale + ".svg")
			}
    	},
		getGermanFlagIcon() {
			var images = require.context('@/assets/flags/', false, /\.svg$/)

			if (this.$store.state.userSpecificGerman && this.$store.state.userSpecificGerman !== "") {
				return images('./' + this.$store.state.userSpecificGerman + ".svg")
			} else {
				return images('./' + "de.svg")
			}
		},
	},
	computed: {
		clientOptions() {
			return this.$store.state.selectedClient.fields.defaultAssignments.de.filter(option => option.sys?.contentType?.sys?.id === 'ticketType')
		},
		serviceProviderOptions() {
			return this.$store.state.selectedServiceProvider?.fields?.ticketTypes?.de?.length > 0 ? this.$store.state.selectedServiceProvider.fields.ticketTypes.de : []
		}
	}
}
</script>

<style scoped>
</style>