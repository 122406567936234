<template>
  <Disclosure :title="$t('text.personalisation')" :error="sectionMessage.error" :warning="sectionMessage.warning"
              :message="sectionMessage.message" :lock="!userMayEditFields || productIsExternal"
              data-cy="ticketSettingsInfo" ref="SECTION_ticketSettingsInfo">
    <div class="field left-border">
      <v-label>{{ $t('text.personalisationType') }}</v-label>
      <v-select outlined dense hide-details
                v-model="model.fields.personalisationType.de"
                :items="personalisationTypes"
                :item-text="item => $t('text.' + item.value.replace(/\s+/g, ''))"
                :disabled="!hasTicket"
                data-cy="personalisationType"
                @input="setDefaultPersonalisations()"
      />
      <p class="helpText" v-html="$t('text.personalisationTypeDesc')"/>
    </div>

    <div class="field left-border"
         v-if="model.fields.personalisationType.de !== personalisationType_no">
      <v-label>{{ $t('text.personalisationFields') }}</v-label>
      <div>
        <h4>{{ title }}</h4>
        <v-row class="ticketOptionRow" align="center" v-if="title">
        <span style="margin-left: 10px">
          <span
              v-if="ticketOption.fields.ticketType.de && ticketOption.fields.ticketType.de.fields && ticketOption.fields.ticketType.de.fields.title !== ''">
            {{ ticketOption.fields.ticketType.de.fields.title[selectedLocale] }} /
          </span>
          <span
              v-if="ticketOption.fields.reduction.de && ticketOption.fields.reduction.de.fields && ticketOption.fields.reduction.de.fields.title !== ''">
            {{ ticketOption.fields.reduction.de.fields.title[selectedLocale] }} /
          </span>
          <span v-if="ticketOption.fields.price && ticketOption.fields.price.de && ticketOption.fields.price.de !== ''">
            {{ $store.state.selectedClient.fields.currency.de }} {{ ticketOption.fields.price.de }}
          </span>
        </span>
        </v-row>
        <br/>

        <div>
          <v-btn class="gradientButton" elevation="0" style="width:100%" @click="openDialog()"
                 v-if="chips.length === 0">{{ $t('text.choose') }}
          </v-btn>

          <div class="chips-selector" ref="selector" @click.stop="openDialog" :data-cy="dataCy" v-if="chips.length > 0">
            <div class="chips-search">
              <div class="selected-chips">
                <span v-for="(chip, index) in chips" :key="'selected_' + index">{{ chipsLabel(chip) }}</span>
              </div>
            </div>
          </div>

          <Dialog ref="personalisationFieldsDialog"
                  :confirmLabel="$t('text.confirmSelection')"
                  :cancelLabel="$t('text.discardChanges')"
                  :cancel-handler="cancelDialog"
                  :confirm-handler="confirmDialog"
                  :closeOnOuterClick="false"
                  :showClose="false"
                  :title="$t('text.personalisationFields')"
                  :height="'90%'"
                  :key="1">
            <template #content>
              <PersonalisationFields
                  ref="personalisationFields"
                  :ticketOption="ticketOption"
                  :locales="locales"
                  :disabled="false"
                  @remove-additional-personalisation="setAdditionalPersonalisationForRemoval"
                  data-cy="personalisationGroups"/>
            </template>
          </Dialog>
        </div>
      </div>
    </div>
  </Disclosure>
</template>

<script>
import Dialog from '@/components/common/Dialog';
import PersonalisationFields from './PersonalisationFields';
import Common from '@/mixins/Common'
import Disclosure from '@/components/common/Disclosure'
import {eventBus} from "@/main"


export default {
  name: 'TicketPersonalisations',
  components: {Dialog, PersonalisationFields, Disclosure},
  mixins: [Common],
  props: {
    product: Object,
    updateModel: Boolean,
    title: String,
    dataCy: {type: String, default: undefined},
    productIsExternal: Boolean,
  },

  data() {
    return {
      hasTicket: false,
      model: {},
      items: [],
      chips: [],
      fixedPersonalisationGroups: [],
      additionalPersonalisations: [],
      error: '',
      sectionMessage: {
        error: false,
        warning: false,
        message: ''
      },
      personalisationType_no: 'No personalisation',
      personalisationTypes: [{value: 'No personalisation'}, {value: 'Contact person only'}, {value: 'Personalisation for all'}],
      ticketOption: {},
    }
  },

  watch: {
    // make ticketOption with deep:true
    ticketOption: {
      handler: function () {
        this.updateChips()
      },
      deep: true
    },
    hasTicket: {
      handler: function (val) {
        if (!val) {
          this.setSectionWarning(this.sectionMessage, this.$t('text.ticketRequiredWarning'))
        } else {
          this.resetSectionWarning(this.sectionMessage)
        }
      },
      immediate: true
    },
    updateModel() {
      eventBus.$on("has-ticket", (data) => {
        this.hasTicket = data
      })
      this.defaultLocale = this.locales[0]
      this.model = this.valueToModel(this.product)
      this.ticketOption = this.model.fields.ticket.de.fields.ticketOptions.de[this.model.fields.ticket.de.fields.ticketOptions.de.length - 1]
    },
    'model.fields.personalisationType.de': function (val) {
      eventBus.$emit('personalisation-type-changed', val)
    },
  },

  created() {
    eventBus.$on("has-ticket", (data) => {
      this.hasTicket = data
    })
    this.defaultLocale = this.locales[0]
    this.model = this.valueToModel(this.product)
    this.ticketOption = this.model.fields.ticket.de.fields.ticketOptions.de[this.model.fields.ticket.de.fields.ticketOptions.de.length - 1]
  },

  async mounted() {
    // make sure, the "Name" field is selected
    const groups = this.$store.state.coreConfig.fields.personalisationGroups.de.filter((pg) => pg.fields.title.de === 'Name')
    let nameId = groups?.[0]?.sys.id
    if (nameId) {
      const pgs = this.ticketOption.fields.personalisationGroups?.de
      if (pgs && !pgs.includes(nameId))
        pgs.push(nameId)
    }

    if (this.ticketOption.fields.ticketPersonalisations?.de?.length > 0) {
      for (let item of this.ticketOption.fields.ticketPersonalisations.de) {
        if (item.fields.personalisationGroup.de.fields) {
          this.chips.push({
            title: item.fields.personalisationGroup.de.fields.title,
            id: item.sys.id,
          })
        }
      }
    }
  },

  beforeDestroy () {
    eventBus.$off('has-ticket')
  },

  methods: {
    sendTicketPersonalizationsToTicketEventBus() {
      eventBus.$emit('ticket-personalisations-changed', this.ticketOption.fields.ticketPersonalisations.de)
    },
    valueToModel(v) {
      return JSON.parse(JSON.stringify(v ?? {}))
    },
    setDefaultPersonalisations() {
      if (this.model.fields.personalisationType.de === 'No personalisation') {
        this.model.fields.ticket.de.fields.ticketOptions.de[0].fields.ticketPersonalisations.de = []

      } else if (this.hasTicket) {
        const namePersonalisationGroup = this.$store.state.coreConfig.fields.personalisationGroups.de.find(x => x.fields?.title?.de === 'Name')
        const hasName = this.model.fields.ticket.de.fields.ticketOptions.de[0].fields.ticketPersonalisations.de.find(x => x.fields?.personalisationGroup?.de?.fields?.title?.de === 'Name')
        if (!hasName) {
          this.model.fields.ticket.de.fields.ticketOptions.de[0].fields.ticketPersonalisations.de.push(
              {
                sys: {id: ''},
                fields: {
                  personalisationGroup: {de: namePersonalisationGroup},
                  required: {de: true}
                }
              })
        }
      }
      this.sendTicketPersonalizationsToTicketEventBus()
    },
    openDialog() {
      this.$refs.personalisationFieldsDialog.show = true;
    },
    chipsLabel(chip) {
      let title = chip.title[this.selectedLocale] ?? chip.title.de
      if (chip.count) return title + ' +' + chip.count
      return title
    },
    cancelDialog() {
      //Undo removal of additional personalisations
      for (let ticketPersonalisation of this.ticketOption.fields.ticketPersonalisations.de) {
        if (ticketPersonalisation["addl"] === "remove") {
          delete ticketPersonalisation.addl
        }
      }
      for (let additionalPersonalisation of this.$refs.personalisationFields.additionalPersonalisations) {
        if (additionalPersonalisation["addl"] === "remove") {
          delete additionalPersonalisation.addl
        }
      }
    },
    confirmDialog() {
      const isValid = this.updatePersonalisations()
      this.sendTicketPersonalizationsToTicketEventBus()
      return isValid
    },
    validateAdditionalOptions() {
      let valid = true
      let additionalPersonalisations = this.$refs.personalisationFields ? this.$refs.personalisationFields.additionalPersonalisations : this.additionalPersonalisations
      let defaultLocale = "de"
      let key

      if (additionalPersonalisations.length > 0) {
        for (let additionalPersonalisation of additionalPersonalisations) {
          additionalPersonalisation.fields.personalisationGroup["errors"] = {}
          const inputType = additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.inputType.de
          const optionValues = additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.options ? additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.options.de : []

          if (!this.validateTextLocales(additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.label)) {
            additionalPersonalisation.fields.personalisationGroup.errors["title"] = 'error'
          } else {
            //Copy value to other locales
            if (additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.label[defaultLocale] === '') {
              for (key of Object.keys(additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.label)) {
                if (additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.label[key] !== '') {
                  defaultLocale = key;
                  break
                }
              }
            }

            for (key of Object.keys(additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.label)) {
              if (additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.label[key] === '') {
                additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.label[key] = additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.label[defaultLocale]
              }
            }
          }

          if (!inputType) {
            additionalPersonalisation.fields.personalisationGroup.errors["inputType"] = 'error'
          } else {
            if (inputType === 'List') {
              if (!optionValues || optionValues.length === 0) {
                additionalPersonalisation.fields.personalisationGroup.errors["optionValues"] = 'error'
              } else {
                for (const optionValue of optionValues) {
                  delete optionValue["errors"]

                  if (!this.validateTextLocales(optionValue.values)) {
                    additionalPersonalisation.fields.personalisationGroup.errors["optionValues"] = 'error'
                    optionValue["errors"] = 'error'
                  } else {
                    //Copy value to other locales
                    if (optionValue.values[defaultLocale] === '') {
                      for (key of Object.keys(optionValue.values)) {
                        if (optionValue.values[key] !== '') {
                          defaultLocale = key;
                          break
                        }
                      }
                    }

                    for (key of Object.keys(optionValue.values)) {
                      if (optionValue.values[key] === '') {
                        optionValue.values[key] = optionValue.values[defaultLocale]
                      }
                    }
                  }
                }
              }
            }
          }

          if (Object.keys(additionalPersonalisation.fields.personalisationGroup.errors).length > 0) {
            valid = false
          }
        }
      }

      if (!valid && this.$refs.personalisationFields) {
        this.$refs.personalisationFields.$forceUpdate()
      }

      return valid
    },
    setAdditionalPersonalisationForRemoval(additionalPersonalisation) {
      for (const ticketPersonalisation of this.ticketOption.fields.ticketPersonalisations.de) {
        if (ticketPersonalisation.fields.personalisationGroup.de.sys.id == additionalPersonalisation.fields.personalisationGroup.de.sys.id &&
            ticketPersonalisation.fields.personalisationGroup.de.fields.title == additionalPersonalisation.fields.personalisationGroup.de.fields.title) {
          ticketPersonalisation["addl"] = "remove"
          additionalPersonalisation["addl"] = "remove"
          break
        }
      }
    },
    updatePersonalisations() {
      let valid = this.validateAdditionalOptions()

      if (valid) {
        if (!this.ticketOption.fields.ticketPersonalisations?.de) {
          this.ticketOption.fields["ticketPersonalisations"] = {de: []}
        }
        //Remove additional personalisations that were set for removal
        this.ticketOption.fields.ticketPersonalisations.de = this.ticketOption.fields.ticketPersonalisations.de.filter((pg) => !pg.addl || pg.addl !== "remove")

        //Set remaining personalisations
        this.fixedPersonalisationGroups = this.$refs.personalisationFields.fixedPersonalisationGroups
        this.additionalPersonalisations = this.$refs.personalisationFields.additionalPersonalisations

        //Fixed Personalisation Groups
        for (const fixedPersonalisationGroup of this.fixedPersonalisationGroups) {
          let tpIndex = this.ticketOption.fields?.ticketPersonalisations?.de?.findIndex(x => x.fields?.personalisationGroup?.de?.sys?.id === fixedPersonalisationGroup.sys.id)

          if (tpIndex < 0) {
            if (fixedPersonalisationGroup.addl.checked) {
              this.ticketOption.fields.ticketPersonalisations.de.push(
                  {
                    sys: {id: this.ticketOption.fields.ticketPersonalisations.de.length + 1},
                    fields: {
                      personalisationGroup: {de: fixedPersonalisationGroup},
                      required: {de: fixedPersonalisationGroup.addl.required}
                    }
                  }
              )
            }
          } else {
            if (!fixedPersonalisationGroup.addl.checked) {
              this.ticketOption.fields.ticketPersonalisations.de.splice(tpIndex, 1)
            } else {
              //Check required
              this.ticketOption.fields.ticketPersonalisations.de[tpIndex].fields.required = {de: fixedPersonalisationGroup.addl.required}
            }
          }
        }

        //Additional Personalisation Groups
        for (const additionalPersonalisation of this.additionalPersonalisations) {
          let adIndex = this.ticketOption.fields?.ticketPersonalisations?.de?.findIndex(x => x.sys?.id === additionalPersonalisation.sys.id)

          if (adIndex < 0) {
            //Add New
            additionalPersonalisation.fields.personalisationGroup.de["sys"] = {id: this.additionalPersonalisations.length}
            additionalPersonalisation.fields.personalisationGroup.de.fields.title = additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.label
            this.ticketOption.fields.ticketPersonalisations.de.push(additionalPersonalisation)
          }
        }

        //Update Chips
        this.updateChips()
      }

      return valid
    },
    updateChips() {
      this.chips = []
      if (this.ticketOption.fields.ticketPersonalisations?.de?.length > 0) {
        for (let ticketPersonalisation of this.ticketOption.fields.ticketPersonalisations.de) {
          if (ticketPersonalisation.fields?.personalisationGroup?.de?.fields) {
            this.chips.push({
              title: ticketPersonalisation.fields.personalisationGroup.de.fields.title,
              id: ticketPersonalisation.fields.personalisationGroup.de.sys.id,
            })
          }
        }
      }
    },
  }
}
</script>

<style scoped lang="scss">
h4 {
  color: #000000;
  font-size: 14pt;
  text-align: left;
  font-weight: bold;
}

.ticketOptionRow {
  height: 38px;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #f2f2f2;
  margin-top: 10px;
  margin-bottom: 10px;
}

.personalisationGroupCol {
  height: 38px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.personalisationGroupCol label, .timeframe-contingent-row label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.v-input--selection-controls {
  margin-top: -10px !important;
}

.chips-selector {
  position: relative;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
  margin-bottom: 0px;
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
  cursor: pointer;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.86);
  }

  .chips-search {
    i {
      position: absolute;
      right: 0;
      top: 0;
      height: 48px;
      width: 48px;
      cursor: pointer;
      transition: all .3s cubic-bezier(.25, .8, .5, 1);

      &.rotated {
        transform: rotate(180deg);
      }
    }
  }

  .selected-chips {
    min-height: 48px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    span {
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
      border: thin #dddddd solid !important;
      color: #000;
      padding: 7px 18px;
      border-radius: 10px;
      margin-left: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
      height: 36px;
      align-items: center;
      background: rgb(245, 245, 245);
      background: linear-gradient(0deg, rgba(245, 245, 245, 1) 0%, rgba(254, 254, 254, 1) 100%);
      cursor: pointer;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
    }
  }

  .error {
    border-color: red;
  }
}
</style>
