<template>
	<v-container
      fluid
      class="pa-0 mb-12"
      :style="isSideBarFixed ? 'padding-left: 380px !important;' : 'padding-left: 40px !important;'"
  >
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<loading v-if="loading" :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

		<!-- navbar -->
		<div class="navbar">
			<v-row align="center" class="flex-nowrap" style="white-space:nowrap; overflow:hidden;">
				<v-btn id="btnBack" elevation="0" style="background-color:transparent;" @click="goback()">
					<v-icon>mdi-chevron-left</v-icon>
				</v-btn>
				<v-row justify="center">
					<h1>{{ $t('text.checklist') }}</h1>
				</v-row>
			</v-row>
		</div>


		<SideBar>
			<div class="buttonBlock">
				<v-btn block class="gradientButton" elevation="0" @click="addChecklistItem()" data-cy="save"><v-icon>mdi-plus</v-icon>{{$t('text.addChecklistItem')}}</v-btn><br/>
				<v-btn block class="greenButton" dark elevation="0" @click="upsertChecklist()" data-cy="activate"><v-icon>mdi-check</v-icon>{{$t('text.saveChanges')}}</v-btn>
			</div>

			<br/>
			<v-select outlined dense required hide-details class="mt-3"
					v-model="serviceLocale"
					:items="locales"
					:item-text="locale => $t('text.' + locale.name)"
					:item-value="locale => locale.code"
			/>
		</SideBar>

		<!-- General Information -->
		<div class="pa-3">
			<Disclosure :title="$t('text.checklistSettings')" :expanded="true" :error="sectionMessage.checklistSettings.error" :message="sectionMessage.checklistSettings.message">
				<div class="field left-border">
					<p class="helpText" v-html="$t('text.enableChecklistHelp')"/>
					<v-switch
						v-model="enableChecklistFeature"
						:label="$t('text.enableChecklistFeature')"
					/>
				</div>

				<div class="field left-border" style="width:100%" v-if="checklist.fields && checklist.fields.items">
					<p class="title">{{ $t('text.checklistItems') }}</p>
					<p class="helpText" v-html="$t('text.checklistItemsHelp')"/><br/>

					<v-row v-for="(item, index) in checklist.fields.items.de" :key="index">
						<div style="width:100%">
							<LanguageFlag v-model="serviceLocale" class="flag"/>
							<v-row style="width:100%" align="center">
								<v-text-field outlined required 
									data-cy="checklistItem"
									v-model="item.text[serviceLocale]"
									:hide-details="!itemError.length"
									:error-messages="itemError(index)"
								/>
								<v-btn class="gradientButton btn-align" dark elevation="0" @click="removeChecklistItem(index)"><v-icon color="red">mdi-delete</v-icon></v-btn>
							</v-row>
						</div>
					</v-row>
				</div>
			</Disclosure>
		</div>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Common from '@/mixins/Common.vue'
import SideBar from "@/components/common/Sidebar"
import Alert from '@/components/common/Alert.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import LanguageFlag from '@/components/common/LanguageFlag.vue'

export default {
	name: "ChecklistView",
	mixins: [ Common ],
	components: { Loading, SideBar, Alert, Disclosure, LanguageFlag },

	data() {
		return {
			loading: false,
			errorTitle: '',
			errorDetail: '',
			successTitle: '',
			successDetail: '',
			runValidation: false,

			maxKey: "0",
			checklist: {},
			checklistItem: {
				key: "",
				text: {},
				checked: false
			},
			enableChecklistFeature: false,

			sectionMessage: {
				checklistSettings: {error:false,warning:false,message:''}
			},
		}
	},
	async mounted() {
		this.setChecklistDetails()
	},
	methods: {
		goback() {
			this.$router.push('/settingsView')
		},
		itemError(index) {
			if (this.checklist?.fields?.items?.de?.length) {
				if (this.runValidation && !this.checkRequiredForAllLocales(this.locales, this.checklist.fields.items.de[index].text)) {
					return this.$t('text.itemMissingLocale')
				}
			}
			return ''
		},
		setChecklistDetails() {
			const client = this.$store.state.selectedClient
			this.enableChecklistFeature = client.fields.features?.de?.find(x => x.id === 'service-provider-checklist')?.status === 'enabled' ? true : false 
			
			if (client.fields.serviceProviderChecklist?.de) {
				this.checklist = client.fields.serviceProviderChecklist.de
			}

			if (this.checklist?.fields?.items?.de?.length) {
				for (const item of this.checklist.fields.items.de) {
					if  (item.key > this.maxKey) {
						this.maxKey = item.key
					}
				}

				this.maxKey = (parseInt(this.maxKey)+1).toString()
			} else {
				this.checklist = {
					sys: {
						id: ''
					},
					fields: {
						items: {
							de: []
						}
					}
				}
			}
		},
		addChecklistItem() {
			let checklistItem = {
				key: "",
				text: {},
				checked: false
			}
			for (const locale of this.locales) {
				checklistItem.text[locale.code] = ''
			}

			checklistItem.key = (parseInt(this.maxKey)+1).toString()
			this.maxKey = (parseInt(this.maxKey)+1).toString()

			this.checklist.fields.items.de.push(checklistItem)
		},
		removeChecklistItem(index) {
			this.checklist.fields.items.de.splice(index, 1)
		},
		validateChecklist() {
			this.runValidation = true
			let missingLocaleText = false
			for (const item of this.checklist.fields.items.de)  {
				if (!this.checkRequiredForAllLocales(this.locales, item.text)) {
					missingLocaleText = true
					break
				}
			}
			if (missingLocaleText) {
				this.sectionMessage.checklistSettings.error = true
				this.sectionMessage.checklistSettings.message = this.$t('text.missingFieldsError')
				return false
			} else {
				this.sectionMessage.checklistSettings.error = false
				return true
			}
		},
		async upsertChecklist() {
			const isValid = this.validateChecklist()
			if (!isValid) return

			this.loading = true

			const data = {
				clientId: this.$store.state.selectedClient.sys.id,
				enabled: this.enableChecklistFeature,
				checklist: this.checklist
			}

			try {
				const client = await this.$httpPost(`/checklist`, data)
				await this.$store.commit("setSelectedClient", client)
				
				this.successTitle = this.$t('text.checklistSuccessTitle')
				this.successDetail = this.$t('text.checklistSucessMsg')

				this.setChecklistDetails()

			} catch (error) {
				this.showError(error.response ? error.response?.data.error : error)
			}
			
			this.loading = false
		}
	}
}
</script>

<style lang="scss" scoped>
.flag { position: absolute; z-index: 10; margin-top: 30px; margin-left: -26px; zoom: 0.8; }
.btn-align { margin-left:10px; margin-top:-10px; height:56px !important}
.btn-spacing { margin-left: 70px }
.v-menu__content {
	min-width: 200px !important;
	max-width: 200px !important;
	width: 200px !important;
	right: 30px !important;
	left: calc(100% - 235px) !important;
}
</style>