<template>
	<div class="SalesChannelAssignmentsField">
		<v-row v-for="item of itemsModel" :key="item.sys.id" class="flex-nowrap" align="baseline">
			<div class="mr-1">
				<input type="checkbox" :id="'salesChannel-' + item.sys.id"
					:checked="model[item.sys.id]"
					@click="toggleItem(item)"
					:disabled="(item.fields && item.fields.mandatory && item.fields.mandatory.de === true) || !enableSelection"
				/>
			</div>
			<div>
				<label :for="'salesChannel-' + item.sys.id">
				<span v-if="item.fields">{{ item.fields.title.de }}</span>
				{{ item.info ? '(' + item.info + ')' : '' }}
				</label>
			</div>
		</v-row>
	</div>
</template>

<script>
import Common from '@/mixins/Common.vue'

export default {
	name: 'SalesChannelAssignmentsField',
	mixins: [ Common ],
	props: {
		value: Array, // of salesChannelAssignment
		items: Array, // of salesChannel
	},
	data() {
		return {
		model: {}, // salesChannel.id => salesChannelAssignment
		modelBackup: {},
		itemsModel: [],
		enableSelection: true
	}},
	watch: {
		value(n) {
			this.model = this.valueToModel(n)
			this.itemsModel = this.itemsToModel(this.items, n)
		},
		items(n) {
			this.itemsModel = this.itemsToModel(n, this.value)
		},
	},
	mounted() {
		this.model = this.valueToModel(this.value)
		this.itemsModel = this.itemsToModel(this.items, this.value)
		this.enableSelection = this.spEnabledSetting(this.$store.state.selectedClient.fields.marketplaceSettings?.de, 'spSalesChannelSelectionEnabled')
	},
	methods: {
		valueToModel(v) {
			const m = {}
			for (const o of v ?? {}) {
				m[o.fields.salesChannel.de.sys.id] = o
			}
			return m
		},
		modelToValue(m) {
			let v = []
			for (const i in m ?? []) {
				v.push(m[i])
			}
			return v
		},
		getNewAssignment(salesChannel) {
			return {
				sys: { id: 'MUI-SCA-' + new Date().getTime() + '-' + Math.floor(Math.random() * 99999) },
				fields: {
					title: { de: 'SCA' },
					salesChannel: { de: { sys: { id: salesChannel.sys.id, type: 'Link', linkType: 'Entry' } } }
				},
				addl: {
					isResellerChannel: salesChannel.fields.isResellerChannel?.de ? salesChannel.fields.isResellerChannel.de : false
				}
			}
		},
		itemsToModel(salesChannels, assignments) {
			let model = []
			let lookup = {} // salesChannel.id => salesChannel
			for (let salesChannel of salesChannels) {
				const key = salesChannel.sys.id
				lookup[key] = salesChannel
				model.push(salesChannel)

				// in case the salesChannel is mandatory, we check the box if it isnt already
				let modified = false
				if (salesChannel.fields.mandatory?.de && !this.model[key]) {
					this.model[key] = this.getNewAssignment(salesChannel)
					modified = true
				}
				if (modified) {
					this.afterToggleItem()
				}
			}
			// TODO: do we really want to do this? seems weird to have invalid SC assignments..
			// add the items of the value that didnt come from client..
			for (let a of assignments) {
				const salesChannel = lookup[a.fields.salesChannel.de.sys.id]
				if (!salesChannel) {
					a.fields.salesChannel.de.info = 'NOT_ON_CLIENT'
					model.push(a.fields.salesChannel.de)
				}
			}
			return model
		},
		toggleItem(item) {
			let key = item.sys.id
			if (this.model[key]) {
				this.modelBackup[key] = this.model[key]
				delete this.model[key]
				this.afterToggleItem()
				return
			}
			if (this.modelBackup[key]) {
				this.model[key] = this.modelBackup[key]
				this.afterToggleItem()
				return
			}
			this.model[key] = this.getNewAssignment(item)
			this.afterToggleItem()
		},
		afterToggleItem(item) {
			this.$emit('input', this.modelToValue(this.model))
		}
	},
}
</script>

<style scoped>
.SalesChannelAssignmentsField { border: 1px solid silver; border-radius: 5px; padding: 5px 10px; }
input[type="checkbox"] { width: 15px; height: 15px; }
</style>