<template>
  <v-container fluid class="pa-0 mb-12" :style="isSideBarFixed ? 'padding-left: 380px !important;' : 'padding-left: 40px !important;'">
    <div class="navbar">
      <v-row align="center" class="flex-nowrap" style="white-space:nowrap; overflow:hidden;">
        <v-btn id="btnBack" elevation="0" style="background-color:transparent;" @click="goBack">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-row justify="center">
          <v-btn-toggle class="ml-2">
            <v-btn id="btnMobileApp" class="firstButton" style="width:200px" elevation="0" @click="toggleView">
              <span class="d-none d-lg-inline">{{$t('text.mobileApp')}}</span>
            </v-btn>
            <v-btn id="btnWebApp" class="lastButton_active" style="width:200px" elevation="0" @click="toggleView">
              <span class="d-none d-lg-inline">{{$t('text.webApp')}}</span>
            </v-btn>
          </v-btn-toggle>
        </v-row>
      </v-row>
    </div>

    <div :style="showImpersonationInfo ? 'padding-top: 24px !important;' : 'padding-top:0px'">
      <SideBar>
        <ul id="navlist" :style="showImpersonationInfo ? 'padding-top: 24px !important;' : 'padding-top:0px'">
          <a v-for="section of sections" :key="section"
            :href="'#' + section">
            <li @click="setActive(section)" :class="{ active: section === activeLink }">
              {{ $t('text.' + section) }}
            </li>
          </a>
        </ul>
      </SideBar>

      <!-- How to use the web app -->
      <div class="pa-3" style="width: 100%">
        <v-row id="howToUseWebApp">
          <Disclosure :title="$t('text.howToUseWebApp')" ref="SECTION_howToUseWebApp">
            <div class="field left-border">
              <p v-html="$t('text.webAppLabel1')" class="override-default"/>
              <p class="helpText" v-html="$t('text.webAppDesc1')"/>
            </div>
            
            <div class="field left-border">
              <p v-html="$t('text.webAppLabel2')" class="override-default"/>
              <p class="helpText" v-html="$t('text.webAppDesc2')"/>
            </div>

            <div class="field left-border">
              <p v-html="$t('text.webAppLabel3')" class="override-default"/>
              <p class="helpText" v-html="$t('text.webAppDesc3')"/>
            </div>

            <div class="field left-border">
              <p v-html="$t('text.webAppLabel4')" class="override-default"/>
              <p class="helpText" v-html="$t('text.webAppDesc4')"/>
            </div>

            <div class="field left-border">
              <p class="helpText" v-html="$t('text.webAppHelp')"></p>
            </div>
          </Disclosure>
        </v-row>
      </div>

      <!-- Scan Code -->
      <div class="pa-3" style="width: 100%">
        <v-row id="validateTickets">
          <Disclosure :title="$t('text.validateTickets')" ref="SECTION_validateTickets" :expanded="true">
            <div class="field left-border">
              <loading :active.sync="loading" :is-full-page="false" color="#4caf50"></loading>
              <v-label>{{ $t('text.enterCode') }}</v-label>
              <v-row>
                <v-text-field outlined required 
                  data-cy="ticketCode"
                  v-model.trim="qrCodeData" 
                  :hide-details="qrCodeDataError===''"
					        :error-messages="qrCodeDataError" 
                  @keyup.enter="validateQRCodeData()"
                />
                <v-btn class="gradientButton btn-spacing" elevation="0" :disabled="qrCodeData===''" @click="clearQRCodeData()">{{$t('text.clear')}}</v-btn>
                <v-btn class="blueButton btn-spacing" elevation="0" dark :disabled="qrCodeData===''" @click="validateQRCodeData()">{{$t('text.validate')}}</v-btn>
              </v-row>
              <p class="helpText" v-html="$t('text.codeScanHelp')"></p>
            </div>
          </Disclosure>
        </v-row>
      </div>

      <!-- History -->
      <div class="pa-3" style="width: 100%">
        <v-row style="width:100%">
          <Disclosure :title="$t('text.history')" ref="SECTION_history" :expanded="true">
            <v-text-field outlined dense clearable hide-details height="48"
              data-cy="search"
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('text.searchTitle')"
              style="min-width:90%;"
              v-model="searchString"
              @keyup="searchRedemptionHistory()"
              @click:clear="clearSearch()" />
            <br/>
          
            <v-card class="tableCard" style="width:100%">
              <v-data-table fixed-header hide-default-footer
                v-model="selected"
                :headers="headers"
                :items="redemptionHistory"
                :items-per-page="limit"
                :mobile-breakpoint="700"
                item-key="name"
                class="elevation-0"
                style="border-radius: 0"
                >
                <template v-slot:item="{ item }">
                  <tr @click="viewTicketHistory(item)">
                    <td class="padded-row" align="center">
                      <img src="@/assets/icons/validity-checker/success.png" width="24px" height="24px"/>
                    </td>
                    <td class="padded-row">
                      {{item.ticketInfo.productName | truncate(70, '...')}}<br/>
                      <span class="generalLabel">{{ $store.state.selectedServiceProvider.fields.title[selectedLocale] }}</span>
                    </td>
                    <td class="padded-row">
                      {{item.usageDate.split(' ')[0]}}<br/>
                      <span class="generalLabel">{{item.usageDate.split(' ')[1]}}</span>
                    </td>
                    <td class="padded-row">{{item.ticketInfo.identificationId}}</td>
                    <td class="padded-row">{{getTypeFromSKU(item.ticketInfo.sku)}}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </Disclosure>
        </v-row>

      </div>
    </div>

    <!-- Ticket Redemption Dialog -->
	  <TicketRedemption v-if="showTicketRedemption" :isGuestcard="isGuestcard" :ticketServiceResponse="ticketServiceResponse" :gateId="gateId" :qrCodeData="qrCodeData" @close-dialog="updateView"/>

    <!-- Ticket History Dialog -->
	  <TicketHistory v-if="showTicketHistory" :ticket="selectedTicket" @close-dialog="showTicketHistory=false"/>

    <!-- Message Dialog -->
    <MessageDialog 
      :show="showMessage"
      :confirmLabel="confirmLabel"
      :cancelLabel="this.$t('text.cancel')"
      :confirm-handler="confirmHandler"
      :cancel-handler="closeMessage"
      title=""
      :type="messageType"
      :messageTitle="messageTitle"
      :messageText="messageText" 
      :showRetry="canRetry">
    </MessageDialog>
  </v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Common from '@/mixins/Common.vue'
import SideBar from "@/components/common/Sidebar"
import Disclosure from "@/components/common/Disclosure"
import MessageDialog from '@/components/validityChecker/MessageDialog.vue'
import TicketRedemption from '@/components/validityChecker/TicketRedemption.vue'
import TicketHistory from '@/components/validityChecker/TicketHistory.vue'

export default {
  name: 'WebAppView',
  components: {Disclosure, SideBar, Loading, MessageDialog, TicketRedemption, TicketHistory},
  mixins: [Common],
  props: {},

  data() {
    return {
      loading: false,
      hasError: false,
      activeLink: '',
      sections: [
        'howToUseWebApp',
        'validateTickets',
        'history'
      ],

      installationId: this.$store.state.selectedClient.fields.installationId?.de,
      serviceProviderGateGroups: [],
      gateId: '',
      qrCodeData: '',
      qrCodeDataError: '',
      ticketServiceResponse: null,

      showMessage: false,
      canRetry: false,
      messageType: '',
      messageTitle: '',
      messageText: '',
      confirmLabel: '',
      cancelLabel: '',

      showTicketRedemption: false,
      showTicketHistory: false,
      isGuestcard: false,

      searchString: '',
      redemptionHistory: [],
      limit: 999,
      selected: [],
      selectedTicket: null,
    }
  },

  computed: {
		headers () {
			return [
				{ text: this.$t('text.validity'), width: '30px', sortable: false, cellClass: 'truncate' },
				{ text: this.$t('text.productAndServiceProvider'), width: '50%', sortable: false, cellClass: 'truncate' },
				{ text: this.$t('text.date'), width: '10%', sortable: false, cellClass: 'truncate' },
				{ text: this.$t('text.id'), width: '20%', sortable: false },
				{ text: this.$t('text.type'), width: '20%', sortable: false },
			]
		}
  },

  mounted() {
    this.gateId = this.$store.state.ticketServiceData.gates?.[0]?.gateKey
  },

  methods: {
    async validateQRCodeData() {
      this.loading = true
      
      try {
        this.ticketServiceResponse = await this.$httpPost('/validitychecker/validate-qrcode',
          { 
            qrCodeData: this.qrCodeData,
            gateId: this.gateId,
            clientId: this.$store.state.selectedClient.sys.id
          })
        this.loading = false

        if (!this.ticketServiceResponse) {
          const text = this.ticketServiceResponse?.guestcard?.type ? this.$t('text.guestcardInvalid') : this.$t('text.ticketInvalid')
          this.showInvalidWarning(text)
         
        } else {
          if (this.ticketServiceResponse?.guestcard?.type) {
            this.isGuestcard = true
          }

          if (this.ticketServiceResponse.ticketInfo) {
            this.showTicketRedemption = true
          } else {
            this.showInvalidWarning(this.$t('text.ticketInvalid'))
          }
        }

        this.getRedemptionHistory()
      } catch (e) {
        this.loading = false
        this.showGeneralError()
      }
    },
    getRedemptionHistory() {
      if (this.ticketServiceResponse?.usages?.length) {
        for (const usage of this.ticketServiceResponse.usages) {
          usage.ticketInfo = this.ticketServiceResponse.ticketInfo
          this.redemptionHistory.push(...this.ticketServiceResponse.usages)
        }
      }
     
      if (this.ticketServiceResponse?.identifications?.length) {
        for (const identification of this.ticketServiceResponse.identifications) {
          if (!identification.usages?.length) continue

          for (let usage of identification.usages) {
            usage.ticketInfo = identification.ticketInfo
          }
          
          this.redemptionHistory.push(...identification.usages)
        }
      }
      return []
    },
    searchRedemptionHistory() {
      this.redemptionHistory = []
        this.getRedemptionHistory()

      if (this.searchString?.length) {
        const results = this.redemptionHistory.filter(x => x.ticketInfo?.productName.toLowerCase().indexOf(this.searchString.toLowerCase()) > -1)
        this.redemptionHistory = results?.length ? results : []
      } 
    },
    viewTicketHistory(ticket) {
      this.selectedTicket = ticket
      this.selectedTicket.orderInfo = this.ticketServiceResponse.orderInfo
      this.showTicketHistory = true
    },
    showGeneralError() {
      this.messageType = 'error'
			this.messageTitle = this.$t('text.generalErrorTitle')
			this.messageText = this.$t('text.errorUnexpected')
			this.canRetry = true
			this.confirmLabel = ''
			this.showMessage = true
    },
    showInvalidWarning(text) {
      this.messageType = 'warning'
		  this.messageTitle = this.$t('text.invalidErrorTitle')
		  this.messageText = text
      this.confirmLabel = this.$t('text.done')
		  this.canRetry = false
		  this.showMessage = true
    },
    clearQRCodeData() {
      this.qrCodeData = ''
    },
    getTypeFromSKU(sku) {
      if (this.isGuestcard === true) {
        return this.$t('text.guestcardIncludedOffer')
      } else if (sku.indexOf('.ADDON.') > -1) {
        return this.$t('text.addon')
      } else if (sku.indexOf('SKIPASS.') > -1) {
        return this.$t('text.skipass')
      } else if (sku.indexOf('ACCESSORY.') > -1) {
        return this.$t('text.accessory')
      } else if (sku.indexOf('COUPON_') > -1) {
        return this.$t('text.coupon')
      } else {
        return this.$t('text.ticket')
      }
    },
    setActive(key) {
      this.activeLink = key
      if (this.$refs['SECTION_' + key]) {
        this.$refs['SECTION_' + key].open()
      }
    },
    confirmHandler() {
      this.showMessage = false
      
      if (this.canRetry === true) {
        this.validateQRCodeData()
      }
    },
    closeMessage() {
      this.showMessage = false
    },
	  toggleView() {
      this.$emit('toggle-view')
    },
    async updateView() {
      this.showTicketRedemption = false
      
      this.ticketServiceResponse = await this.$httpPost('/validitychecker/validate-qrcode',
      { 
        qrCodeData: this.qrCodeData,
        gateId: this.gateId,
        clientId: this.$store.state.selectedClient.sys.id
      })
      this.getRedemptionHistory()
    },
    goBack() {
      this.$router.push('/')
    },
  },
}
</script>

<style scoped>
.title { font-size: 24pt; color: #000000; font-weight: bold; }
.override-default { color: black !important; font-weight: bold; margin-bottom: 8px; font-size: 1.1rem !important; }
.btn-spacing { height: 56px !important; min-width: 100px !important; margin-left: 10px; margin-top: 12px; }
.generalLabel { color: #999999 !important; font-size: 12pt;}
.padded-row { padding-top:10px !important; padding-bottom:10px !important }
</style>