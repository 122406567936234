<template>
	<div>
		<tiptap-vuetify required
			ref="textarea"
			:class="{
				'enabled-text-style': true,
				disabledTiptap: disabled,
			}"
			v-model="model"
			:extensions="extensions"
		/>
		<div v-if="errorMessages" style="color: red;">
			<div v-for="(errorMessage, i) of errorMessages" :key="i">{{ errorMessage }}</div>
		</div>
	</div>
</template>

<script>
// TODO: emit focus event
import { TiptapVuetify, Heading, Bold, Italic, Underline, BulletList, OrderedList, ListItem, History } from 'tiptap-vuetify'

export default {
	name: 'TextArea',
	components: { TiptapVuetify, },
	props: {
		value: String,
		disabled: Boolean,
		errorMessages: Array,
	},
	data() {
		return {
		model: '',
		extensions: [
			History,
			[ Heading, { options: { levels: [1, 2, 3, 4, 5, 6] } } ],
			Bold,
			Italic,
			Underline,
			ListItem,
			BulletList,
			OrderedList,
		],
	}},
	watch: {
		value(n) {
      this.model = n
    },
		model(n) { this.$emit('input', n) },
	},
	mounted() {
		this.model = this.value
	},
}
</script>

<style>
.enabled-text-style >>> .v-input__control > .v-input__slot { background: #ffffff !important; }
.enabled-text-style >>> .tiptap-vuetify-editor__content { background: #ffffff !important; height: 300px; }
.tiptap-vuetify-editor { margin: 10px 0; }
.tiptap-vuetify-editor.disabledTiptap { pointer-events: none; }
.tiptap-vuetify-editor__content { padding: 0 !important; }
body .tiptap-vuetify-editor .ProseMirror { margin: 0 !important; min-height: 200px; padding: 20px; }
body .tiptap-vuetify-editor__content p { color: black; margin-top: 0 !important; }
</style>