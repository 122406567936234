<template>
	<img :src="flagIcon" />
</template>

<script>
export default {
	name: 'LanguageFlag',
	props: {
		value: { type: String, default: null },
	},
	data() {
		return {
		userSpecificGerman: 'de',
	}},
	computed: {
		flagIcon() {
			var images = require.context('@/assets/flags/', false, /\.svg$/)

			if (this.value == "de") {
				if (this.userSpecificGerman)
					return images('./' + this.userSpecificGerman + ".svg")
				return images('./de.svg')
			}
			if (this.value)
				return images('./' + this.value + ".svg")
			return images('./en.svg')
		},
	},
	async mounted() {
		this.userSpecificGerman = await this.$store.state.userSpecificGerman
	}
}
</script>

<style scoped>
img { width: 24px; height: 24px; }
</style>