<template>
  <Disclosure :title="$t('text.tickets')" :error="sectionMessage.error" :message="sectionMessage.message"
              :lock="!userMayEditFields || productIsExternal" data-cy="ticketInfo" ref="SECTION_ticketInfo">
    <div v-if="hasField('ticketInfo','taxClass', model)" class="field left-border">
      <v-label>{{ $t('text.taxClass') }} <span class="error-text">({{ $t('text.required') }})</span></v-label>
      <v-select
          outlined dense return-object
          :hide-details="taxClassErrors.length===0"
          :error-messages="taxClassErrors"
          :items="taxClasses"
          :item-text="item => item && item.fields && item.fields.title ? item.fields.title[$store.state.serviceLocale] : ''"
          v-model="model.fields.taxClass.de"
          data-cy="taxClass"
      />
      <p class="helpText" v-html="$t('text.taxClassDesc')"/>
    </div>


    <div v-if="hasField('ticketInfo','tickets', model)" class="field left-border"> 
      <v-label>{{ $t('text.tickets') }}&nbsp;<span class="error-text">({{ $t('text.required') }})</span></v-label>
      <div style="width:100%;padding:0px">
					<v-row style="padding:0px" align="center">
						<v-col style="padding-left:0px">
							<p class="helpText" v-html="$t('text.fromPriceDesc')"/>
						</v-col>
						<v-col style="padding-right:0px">
              <v-switch
              v-model="fromPriceEnabled"
              :label="$t('text.fromPrice')"
              :data-cy="'fromPrice'"
								style="margin-right:0px;margin-top:0px">
							</v-switch> 
						</v-col>
					</v-row>
				</div>

      <template
          v-if="model.fields.ticket && model.fields.ticket.de && model.fields.ticket.de.fields && model.fields.ticket.de.fields.ticketOptions && model.fields.ticket.de.fields.ticketOptions.de"
      >
        <div class="prices" v-for="(ticketOption, index) in model.fields.ticket.de.fields.ticketOptions.de"
             :key="index">
          <v-btn x-small class="gradientButton removeTicketRow" elevation="0"
                 :style="index!==0 ? 'opacity:1' : 'opacity:0;cursor:default !important'"
                 @click="removeTicketRow(index)">
            <v-icon size="24px" color="#fb3640">mdi-delete</v-icon>
          </v-btn>
          <v-label>{{ $t('text.ticketType') }}</v-label>
          <TicketTypeField
              v-model="ticketOption.fields.ticketType.de"
              :error="ticketTypeError(ticketOption)" :data-cy="'ticketType_' + index">
          </TicketTypeField>
          <br>
          <v-label>{{ $t('text.reduction') }}</v-label>
          <ReductionField
              v-model="ticketOption.fields.reduction.de"
              :error="reductionError(ticketOption)" :data-cy="'reduction_' + index">
          </ReductionField>
          <br>
          <table style="width: 100%;">
            <tr style="text-align: left">
              <th style="font-weight: normal">
                <v-label>{{ $t('text.price') }}</v-label>
              </th>
              <th v-if="fromPriceEnabled" style="font-weight: normal">
                <v-label>{{ $t('text.fromPrice') }}</v-label>
              </th>
            </tr>
            <tr>
              <td :class="{'pr-4': fromPriceEnabled}">
                <v-text-field
                    outlined hide-details
                    v-model="model.fields.ticket.de.fields.ticketOptions.de[index].fields.price.de"
                    :data-cy="'price_' + index" style="background-color:#ffffff"
                    @keypress="isDecimal"
                />
              </td>
              <td  v-if="fromPriceEnabled">
                <v-checkbox
                    class="checkbox" hide-details
                    v-model="ticketOption.fields.fromPriceOption.de"
                    @change="setFromPriceOption(ticketOption.sys.id)"
                />
              </td>
            </tr>
          </table>
          <br>
          <div v-if="hasField('ticketInfo','minimumTickets', model)">
            <v-label>{{ $t('text.minTickets') }}</v-label>
            <v-text-field outlined
                          :hide-details="checkMinMax(ticketOption).length === 0"
                          :error-messages="checkMinMax(ticketOption)"
                          style="background-color:#ffffff"
                          type="number" min="1"
                          v-model="ticketOption.fields.minimumTickets.de"
                          @keypress="isNaturalNumber($event)"/>
            <br>
          </div>

          <div v-if="hasField('ticketInfo','maximumTickets', model)">
            <v-label>{{ $t('text.maxTickets') }}</v-label>
            <v-text-field outlined class="mt-3" hide-details style="background-color:#ffffff"
                          type="number" min="1"
                          v-model="ticketOption.fields.maximumTickets.de"
                          @keypress="isNaturalNumber($event)"/>
            <br>
          </div>

          <div v-if="hasField('ticketInfo','contingentQuantity', model)">
            <v-label>{{ $t('text.noOfPeople') }}</v-label>
            <v-text-field outlined class="mt-3" hide-details style="background-color:#ffffff"
                          type="number" min="1"
                          v-model="ticketOption.fields.contingentQuantity.de"
                          @keypress="isNaturalNumber($event)"/>
            <p class="helpText" v-html="$t('text.ticketsDesc')"/>
          </div>
        </div>
      </template>
      <v-btn id="btnAddTicketRow" class="gradientButton" elevation="0"
             style="height:40px;font-size:12pt;margin-right:10px" @click="addTicketRow()">
        <v-icon size="24px">mdi-plus</v-icon>
        {{ $t('text.addTicketOption') }}
      </v-btn>
    </div>
  </Disclosure>
</template>

<script>
import Common from '@/mixins/Common.vue'
import Disclosure from "@/components/common/Disclosure";
import TicketTypeField from "./TicketTypeField";
import ReductionField from "./ReductionField";
import {eventBus} from "@/main"
import { isEqual } from 'lodash'

export default {
  name: 'TicketInfo',
  components: {Disclosure, TicketTypeField, ReductionField},
  mixins: [Common],
  props: {
    product: Object,
    updateModel: Boolean,
    productIsExternal: Boolean
  },
 data() {
		return {
    model: {},
    initData: {},
    sectionMessage: {
      error: false,
      message: ''
    },
    fromPriceEnabled: true,
    taxClassErrors: [],
    taxClasses: [],
  }},

    watch: {
    'model.fields.ticket.de.fields.ticketOptions.de': {
      handler: function (ticketOptions) {
        const hasTicket = ticketOptions?.[0]?.fields?.reduction?.de !== null && ticketOptions?.[0]?.fields?.ticketType?.de !== null
        eventBus.$emit("has-ticket", hasTicket)
      },
      deep: true
    },
    updateModel() {
      // we need to update the child model when the product is created
      this.model = this.valueToModel(this.product)
      this.setInitData()
    },
  },
  created() {
    this.model = this.valueToModel(this.product)
    
    if (!this.model.fields.fromPriceEnabled) {
      this.model.fields.fromPriceEnabled = {de: true}
    }
    this.fromPriceEnabled = this.model.fields.fromPriceEnabled.de

    this.setInitData()
  
    this.taxClasses = this.$store.state.selectedClient.fields.taxClasses.de.sort(this.compare)
    this.setTicketInfoDetails()
    eventBus.$on("personalisation-type-changed", (personalisationType) => {
      this.model.fields.personalisationType.de = personalisationType
    })
    // listen on ticket-personalisations-changed event and update the ticket personalisations
    eventBus.$on("ticket-personalisations-changed", (ticketPersonalisations) => {
      for (let ticketOption of this.model.fields.ticket.de.fields.ticketOptions.de) {
        ticketOption.fields.ticketPersonalisations.de = ticketPersonalisations
      }
    })
  },
  beforeDestroy () {
    eventBus.$off('personalisation-type-changed')
    eventBus.$off('ticket-personalisations-changed')
  },

  methods: {
     valueToModel(v) {
      return JSON.parse(JSON.stringify(v ?? {}))
    },
    setInitData() {
      const initModel = JSON.parse(JSON.stringify(this.model))

      this.initData = {
        personalisationType: initModel.fields.personalisationType
      }

      if (this.hasField('ticketInfo', 'tickets', this.model)) {
        this.initData.ticket = initModel.fields.ticket
        this.initData.fromPriceEnabled = initModel.fields.fromPriceEnabled
      }

      if (this.hasField('ticketInfo','taxClass', this.model)) {
        this.initData.taxClass = initModel.fields.taxClass
      }
    },
    sendData() {
      this.resetPersonalisationIds()

      const data = {
        personalisationType: this.model.fields.personalisationType
      }

      // if minimum and maximum tickets as well as nr of people are explicitly set to 0 then default to 1 and 6 for min/max and 1 for nr of people
      if (this.model.fields.ticket?.de?.fields?.ticketOptions?.de?.length > 0) {
        for (let ticketOption of this.model.fields.ticket.de.fields.ticketOptions.de) {
          if (ticketOption.fields.minimumTickets.de == 0) {
            ticketOption.fields.minimumTickets.de = 1
          }
          if (ticketOption.fields.maximumTickets.de == 0) {
            ticketOption.fields.maximumTickets.de = 6
          }
          if (ticketOption.fields.contingentQuantity.de == 0) {
            ticketOption.fields.contingentQuantity.de = 1
          }
        }
      }

      // problem: we send the entire ticket below but some fields within the ticket should only be sent if hasField for those is true (ex: min/max contingents/nr of people for ADDONS)

      if (this.hasField('ticketInfo', 'tickets', this.model)) {
        data.ticket = this.model.fields.ticket
        data.fromPriceEnabled = {de: this.fromPriceEnabled }
      }

      if (this.hasField('ticketInfo','taxClass', this.model)) {
        data.taxClass = this.model.fields.taxClass
      }

      data.changed = !isEqual(data, this.initData)
      
      return data
    },
    resetPersonalisationIds() {
      //Ticket Personalisations - set id's to empty strings; we need to do this because the id-s need to be empty strings initially for the backend, but they need to be numbers for the frontend component 
			if (this.model.fields.ticket?.de?.fields?.ticketOptions?.de?.length > 0) {
				//Reset ID's
				if (typeof this.model.fields.ticket.de.sys.id !== 'string') {
					this.model.fields.ticket.de.sys.id = ""
				}
				for (let ticketOption of this.model.fields.ticket.de.fields.ticketOptions.de) {
					if (typeof ticketOption.sys.id !== 'string') {
						ticketOption.sys.id = ""
					}
				}

				let ticketPersonalisations

				if (this.model.fields.ticket.de.fields.ticketOptions.de[this.model.fields.ticket.de.fields.ticketOptions.de.length-1].fields?.ticketPersonalisations?.de) {
					ticketPersonalisations = this.model.fields.ticket.de.fields.ticketOptions.de[this.model.fields.ticket.de.fields.ticketOptions.de.length-1].fields.ticketPersonalisations.de
					for (let ticketPersonalisation of ticketPersonalisations) {
						if (typeof ticketPersonalisation.sys.id !== 'string') {
							ticketPersonalisation.sys.id = ""
						}

						if (ticketPersonalisation.fields.personalisationGroup?.de?.sys?.id) {
							if (typeof ticketPersonalisation.fields.personalisationGroup.de.sys.id !== 'string') {
								ticketPersonalisation.fields.personalisationGroup.de.sys.id  = ""
							}
						}
					}
				}

				//Set ticketpersonalistions on all ticket options - remove this when we support personalisation per ticket option again
				for (let ticketOption of this.product.fields.ticket.de.fields.ticketOptions.de) {
					if (ticketOption.fields.ticketPersonalisations?.de) {
						ticketOption.fields.ticketPersonalisations.de = ticketPersonalisations
					}
				}
			}
    },
    checkMinMax(ticketOption) {
      let errors = []
      if (ticketOption.fields.minimumTickets.de > ticketOption.fields.maximumTickets.de) {
        errors = [this.$t('text.minMaxTicketsError')]
      } else {
        errors = []
      }
      return errors
    },
    validateAllFields() {
      let allFieldsAreValid = true
      this.resetSectionError(this.sectionMessage)

      if (this.productIsExternal) return allFieldsAreValid

      if (!this.validateTaxClass()) {
        allFieldsAreValid = false
        this.setSectionError(this.sectionMessage, this.$t('text.taxClassRequired'))
      }

      if (!this.validateTicket()) {
        allFieldsAreValid = false
        this.setSectionError(this.sectionMessage, this.$t('text.missingFieldsError'))
      }

      return allFieldsAreValid
    },
    validateTaxClass() {
      let isValid = true
      this.taxClassErrors = []
      if (!this.model.fields.taxClass.de || !Object.keys(this.model.fields.taxClass.de).length) {
        isValid = false
        this.taxClassErrors.push(this.$t('text.taxClassRequired'))
      }

      return isValid
    },
    validateTicket() {
      let isValid = true

      if (this.hasField('ticketInfo', 'tickets', this.model) && this.model.fields.ticket?.de?.fields?.ticketOptions?.de?.length > 0) {
        for (const ticketOption of this.model.fields.ticket.de.fields.ticketOptions.de) {
          if (!ticketOption.fields?.reduction?.de?.fields?.title?.de?.length ||
              !ticketOption.fields?.ticketType?.de?.fields?.title?.de?.length ||
              ticketOption.fields.ticketType.de.sys.id === '' ||
              ticketOption.fields.reduction.de.sys.id === '' ||
              !ticketOption.fields.price?.de ||
              ticketOption.fields.price.de === '') {

            isValid = false
          }
          if (this.hasField('ticketInfo', 'minimumTickets', this.model) &&
              this.hasField('ticketInfo', 'maximumTickets', this.model) &&
              ticketOption.fields.minimumTickets.de > ticketOption.fields.maximumTickets.de) {
            isValid = false
          }

        }
      }

      return isValid
    },
    removeTicketRow(rowIndex) {
      if (rowIndex > 0) {
        this.model.fields.ticket.de.fields.ticketOptions.de.splice(rowIndex, 1)
      }
    },
    addTicketRow() {
      if (!this.model.fields.ticket || this.model.fields.ticket.de.error) {
        this.model.fields["ticket"] = {de: {sys: {id: ''}, fields: {ticketOptions: {de: []}}}}
      }
      let ticketPersonalisations = []
      if (this.model.fields.ticket.de.fields.ticketOptions.de.length > 0) {
        ticketPersonalisations = this.model.fields.ticket.de.fields.ticketOptions.de[0].fields.ticketPersonalisations?.de
      }

      this.counter++;
      this.model.fields.ticket.de.fields.ticketOptions.de.push({
        sys: {id: (this.model.fields.ticket.de.fields.ticketOptions.de.length + 1).toString()},
        fields: {
          title: {de: ''},
          ticketType: {de: null},
          reduction: {de: null},
          price: {de: ''},
          ticketPersonalisations: {de: ticketPersonalisations ? ticketPersonalisations : []},
          minimumTickets: {de: 1},
          maximumTickets: {de: 6},
          contingentQuantity: {de: 1},
          fromPriceOption: {de: false}
        },
        selected: false
      });
    },
    setTicketInfoDetails() {
      if (!this.model.fields.ticket) {
        this.addTicketRow()
      } else {
        let ticketOptions = this.model.fields.ticket?.de?.fields?.ticketOptions?.de ? this.model.fields.ticket.de.fields.ticketOptions.de : []
        if (ticketOptions && ticketOptions.length > 0) {
          for (let ticketOption of ticketOptions) {
            if (!ticketOption.fields.ticketType?.de) ticketOption.fields.ticketType = {de: null}
            if (!ticketOption.fields.reduction?.de) ticketOption.fields.reduction = {de: null}
            if (!ticketOption.fields.fromPriceOption?.de) ticketOption.fields.fromPriceOption = {de: false}
            if (!ticketOption.fields.contingentQuantity?.de) ticketOption.fields.contingentQuantity = {de: 1}
          }
        }
      }
    },
    ticketTypeError(ticketOption) {
      if (this.productIsExternal) {
        return false
      }
      return !ticketOption?.fields?.ticketType?.de?.fields || !Object.keys(ticketOption.fields.ticketType.de).length
    },
    reductionError(ticketOption) {
      if (this.productIsExternal) {
        return false
      }
      return !ticketOption?.fields?.reduction?.de?.fields || !Object.keys(ticketOption.fields.reduction.de).length
    },
    setFromPriceOption(ticketOptionId) {
      for (let ticketOption of this.model.fields.ticket.de.fields.ticketOptions.de) {
        ticketOption.fields['fromPriceOption'].de = ticketOption.sys.id === ticketOptionId && ticketOption.fields['fromPriceOption'].de
      }
    },
    // TODO: only used once for price - make it go away
    isDecimal: function (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode

      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 44) {
        evt.preventDefault()
        return false
      }
      return true;
    }
  }

}
</script>

<style scoped>
.prices {
  margin-top: 15px;
  margin-bottom: 10px;
  border: 1px solid #cccccc;
  border-radius: 10px;
  padding: 25px 10px 10px 10px;
  width: 100%;
  background-color: #f9f9f9;
  position: relative;
}

.removeTicketRow {
  position: absolute;
  top: -1px;
  right: -1px;
}
</style>