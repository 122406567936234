<template>
	<v-container fluid style="padding-bottom: 70px;">
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

		<!-- Navbar -->
		<div class="navbar">
			<!-- Service Provider Navbar-->
			<v-row align="center" class="flex-nowrap" style="white-space:nowrap; overflow:hidden;" v-if="!appIsServiceManager">
				<v-row class="px-2 flex-nowrap" >
								<v-text-field outlined dense clearable hide-details height="48"
								prepend-inner-icon="mdi-magnify"
								placeholder='Search'
                              style="max-width:33%; min-width: 150px;"
                              v-model="searchString"
								@focus="focusedSearch"
								@blur="blurredSearch"
								@keyup.enter="search()"
								data-cy="search"
								@click:clear="clear()">
								</v-text-field>

                <v-btn-toggle class="ml-2">
									<v-btn id="btnShowServices" class="firstButton_active" elevation="0">
                    <v-icon class="d-lg-none">mdi-cart</v-icon>
                    <span class="d-none d-lg-inline">{{$t('text.services')}}</span>
                  </v-btn>
									<v-btn id="btnShowFAQs" class="lastButton" elevation="0" @click="showFAQs">
                    <v-icon class="d-lg-none">mdi-frequently-asked-questions</v-icon>
                    <span class="d-none d-lg-inline">{{$t('text.faqs')}}</span>
                  </v-btn>
								</v-btn-toggle>

								<v-row class="flex-nowrap" justify="end">
								  <v-btn id="addService" class="gradientButton mr-2" small elevation="0" @click="showAddService" data-cy="addService">
                    <v-icon>mdi-plus</v-icon>
                    <span class="d-none d-lg-inline">{{$t('text.addService')}}</span>
                  </v-btn>

									<!-- Filters -->
									<div>
										<v-menu offset-y bottom left origin="top right" style="max-width:600px !important;" :close-on-content-click="false" v-model="isOpened">
											<template v-slot:activator="{ on }" style="max-width:600px !important">
												<v-row justify="end" v-on="on">
													<div v-if="filter" class="dot-container stack-top"><span class="dot"></span></div>
													<v-btn data-cy="productFilter" class="gradientButton" elevation="0" x-small>
                            <v-icon>mdi-filter</v-icon>
                            <span class="d-none d-lg-inline">{{$t('text.filter')}}</span>
													  <v-icon style="cursor:pointer;">mdi-menu-down</v-icon>
													</v-btn>
												</v-row>
											</template>

											<v-list style="max-width:600px !important;">
												<v-list-item style="max-width:600px !important;" class="pb-5">
													<v-list-item-title data-cy="statusFilter" @click="closeDropDown('vSelectStatus')">
                            <span>{{$t('text.serviceStatus')}}</span>
                            <v-select
                              ref="vSelectStatus"
                              :placeholder="$t('text.allLabel')"
                              filled solo
                              :menu-props="{ offsetY: true  }"
                              v-model="selectedStatuses"
                              :items="statuses"
                              :item-text="item => $t('text.' + item.id)"
                              :item-value="item => item.id"
                              multiple small-chips hide-details
                              class="gradientButton"
                              style="min-height: initial !important;">
                              <template #selection="{ item }">
                                <v-chip :color="item.color" dark>{{$t('text.' + item.id)}}</v-chip>
                              </template>
                            </v-select>
													</v-list-item-title>
												</v-list-item>

												<v-list-item style="max-width:600px !important;" class="pb-5">
													<v-list-item-title>
                            <span>{{$t('text.availabilityPeriod')}}</span>
                            <br/><br/>
                            <v-row>
                              <v-btn class="clearButton flex-grow-1 mr-4" elevation="0" @click="toggleDatePicker('from')">{{validFromText}}</v-btn>
                              <v-btn class="clearButton flex-grow-1" elevation="0" @click="toggleDatePicker('to')">{{validToText}}</v-btn>
                            </v-row>
                            <br/>
                            <v-row justify="center">
                              <v-date-picker v-if="showFromDate" no-title v-model="validFromDate" :first-day-of-week="1" color="green lighten-1" @input="resetDates()"/>
                              <v-date-picker v-if="showToDate" no-title v-model="validToDate" :first-day-of-week="1" color="green lighten-1" :min="validFromDate" @input="showToDate=false"/>
                            </v-row>
                            <v-switch
                              v-model="isNegative"
                              :label="$t('text.negativeAvailabilityPeriod')"
                            ></v-switch>
													</v-list-item-title>
												</v-list-item>

												<v-list-item v-if="productCategories.length > 0" style="max-width:600px !important;" class="pb-5">
													<v-list-item-title data-cy="categoryFilter" @click="closeDropDown('vSelectCategory')">
                            <span>{{$t('text.serviceType')}}</span>
                            <v-select 
                              ref="vSelectCategory"
                              :placeholder="$t('text.allLabel')"
                              filled solo
                              :menu-props="{ offsetY: true  }"
                              v-model="selectedProductCategories"
                              :items="productCategories"
                              :item-text="item => item.fields.title[selectedLocale]"
                              :item-value="item => item.sys.id"
                              multiple small-chips hide-details
                              class="gradientButton"
                              style="min-height: initial !important;">
                              <template #selection="{ item }">
                                <v-chip :color="item.color" dark>{{item.fields.title[selectedLocale]}}</v-chip>
                              </template>
                            </v-select>
													</v-list-item-title>
												</v-list-item>

												<v-list-item style="max-width:600px !important;" class="pb-5">
													<v-list-item-title data-cy="marketplaceFilter" @click="closeDropDown('vSelectMarketplace')">
                            <span>{{$t('text.clientsTitle')}}</span>
                            <v-select
                              ref="vSelectMarketplace"
                              :placeholder="$t('text.allLabel')"
                              filled solo
                              :menu-props="{ offsetY: true }"
                              v-model="selectedClients"
                              :items="clients"
                              :item-text="item => item.fields.title[selectedLocale]"
                              :item-value="item => item.sys.id"
                              multiple small-chips hide-details
                              class="gradientButton"
                              style="min-height: initial !important;">
                              <template #selection="{ item }">
                                <v-chip :color="item.color" dark>{{item.fields.title[selectedLocale]}}</v-chip>
                              </template>
                            </v-select>
													</v-list-item-title>
												</v-list-item>

												<v-divider/>

												<v-row class="pa-4">
													<v-btn class="gradientButton flex-grow-1 mr-4" elevation="0" @click="clearFilter()">{{$t('text.clearFilter')}}</v-btn>
													<v-btn class="greenButton flex-grow-1" elevation="0" dark data-cy="applyFilter" @click="applyFilter()">{{$t('text.applyFilter')}}</v-btn>
												</v-row>
											</v-list>
										</v-menu>
										</div>
									</v-row>
						</v-row>
			</v-row>

			<!-- Operator Navbar -->
			<v-row align="center" class="flex-nowrap" style="white-space:nowrap; overflow:hidden;" v-if="appIsServiceManager">
				<v-row class="px-2 flex-nowrap" align="center">
            <v-text-field outlined dense clearable hide-details height="48"
            prepend-inner-icon="mdi-magnify"
            placeholder='Search'
            style="max-width:33%; min-width: 150px;"
            v-model="searchString"
            @keyup.enter="search()"
            data-cy="search"
            @click:clear="clear()">
            </v-text-field>

            <v-spacer/>

            <!-- Filters -->
            <div>
              <v-menu offset-y bottom left origin="top right" style="max-width:600px !important" :close-on-content-click="false" v-model="isOpened">
                <template v-slot:activator="{ on }" style="max-width:600px !important">
                  <v-row justify="end" v-on="on">
					<div v-if="filter" class="dot-container stack-top"><span class="dot"></span></div>
                    <v-btn data-cy="productFilter" class="gradientButton" elevation="0" x-small>
                      <v-icon>mdi-filter</v-icon>
                      <span class="d-none d-md-inline">{{$t('text.filter')}}</span>
                      <v-icon style="cursor:pointer;">mdi-menu-down</v-icon>
                    </v-btn>
                  </v-row>
                </template>

                <v-list style="max-width:600px !important;">
                  <v-list-item style="max-width:600px !important;" class="pb-5">
                    <v-list-item-title data-cy="statusFilter" @click="closeDropDown('vSelectStatus2')">
                      <span>{{$t('text.serviceStatus')}}</span>
                      <v-select
                        ref="vSelectStatus2"
                        :placeholder="$t('text.allLabel')"
                        filled solo
                        :menu-props="{ offsetY: true  }"
                        v-model="selectedStatuses"
                        :items="statuses"
                        :item-text="item => $t('text.' + item.id)"
                        :item-value="item => item.id"
                        multiple small-chips hide-details
                        class="gradientButton"
                        style="min-height: initial !important;">
                        <template #selection="{ item }">
                          <v-chip :color="item.color" dark>{{$t('text.' + item.id)}}</v-chip>
                        </template>
                      </v-select>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item style="max-width:600px !important;" class="pb-5">
                    <v-list-item-title>
                      <span>{{$t('text.availabilityPeriod')}}</span>
                      <br/><br/>
                      <v-row>
                        <v-btn class="clearButton flex-grow-1 mr-4" elevation="0" @click="toggleDatePicker('from')">{{validFromText}}</v-btn>
                        <v-btn class="clearButton flex-grow-1" elevation="0" @click="toggleDatePicker('to')">{{validToText}}</v-btn>
                      </v-row>
                      <br/>
                      <v-row justify="center">
                        <v-date-picker v-if="showFromDate" no-title v-model="validFromDate" :first-day-of-week="1" color="green lighten-1" @input="resetDates()"/>
                        <v-date-picker v-if="showToDate" no-title v-model="validToDate" :first-day-of-week="1" color="green lighten-1" :min="validFromDate" @input="showToDate=false"/>
                      </v-row>
                      <v-switch
                        v-model="isNegative"
                        :label="$t('text.negativeAvailabilityPeriod')"
                      ></v-switch>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item v-if="productCategories.length > 0" style="max-width:600px !important;" class="pb-5">
                    <v-list-item-title data-cy="categoryFilter" @click="closeDropDown('vSelectCategory2')">
                      <span>{{$t('text.serviceType')}}</span>
                      <v-select 
                        ref="vSelectCategory2"
                        :placeholder="$t('text.allLabel')"
                        filled solo
                        :menu-props="{ offsetY: true  }"
                        v-model="selectedProductCategories"
                        :items="productCategories"
                        :item-text="item => item.fields.title[selectedLocale]"
                        :item-value="item => item.sys.id"
                        multiple small-chips hide-details
                        class="gradientButton"
                        style="min-height: initial !important;">
                        <template #selection="{ item }">
                          <v-chip :color="item.color" dark>{{item.fields.title[selectedLocale]}}</v-chip>
                        </template>
                      </v-select>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item style="max-width:600px !important;" class="pb-5">
                    <v-list-item-title data-cy="marketplaceFilter" @click="closeDropDown('vSelectMarketplace2')">
                      <span>{{$t('text.clientsTitle')}}</span>
                      <v-select
                        ref="vSelectMarketplace2"
                        :placeholder="$t('text.allLabel')"
                        filled solo
                        :menu-props="{ offsetY: true  }"
                        v-model="selectedClients"
                        :items="clients"
                        :item-text="item => item.fields.title[selectedLocale]"
                        :item-value="item => item.sys.id"
                        multiple small-chips hide-details
                        class="gradientButton"
                        style="min-height: initial !important;">
                        <template #selection="{ item }">
                          <v-chip :color="item.color" dark>{{item.fields.title[selectedLocale]}}</v-chip>
                        </template>
                      </v-select>
                    </v-list-item-title>
                  </v-list-item>

                  <v-divider/>

                  <v-row class="pa-4">
                    <v-btn class="gradientButton flex-grow-1 mr-4" elevation="0" @click="clearFilter()">{{$t('text.clearFilter')}}</v-btn>
                    <v-btn class="greenButton flex-grow-1" elevation="0" dark data-cy="applyFilter" @click="applyFilter()">{{$t('text.applyFilter')}}</v-btn>
                  </v-row>
                </v-list>
              </v-menu>
            </div>
          </v-row>
			</v-row>
		</div>

		<div :style="showImpersonationInfo ? 'padding-top: 24px !important;' : 'padding-top:0px'">
			<v-card class="tableCard">
				<v-data-table fixed-header hide-default-footer
					:headers="headers"
					:items="products"
					:items-per-page="limit"
					:mobile-breakpoint="900"
					@update:sort-desc="sortTable($event)"
					class="elevation-0"
					style="border-radius: 0">

			<template v-slot:item="{ item }">
				<tr @click="getServiceDetail(item)">
					<td>
						<span v-if="hasListIcon(item)">
							<v-img style="opacity:0.5;max-width:28px;max-height:28px"
								width="28" height="28" contain
								:src="getAssetUrl(item.fields.serviceType.de.fields.listIcon.de)">
							</v-img>
						</span>
					</td>
					<td>{{item.fields.title[selectedLocale]}}</td>
					<td v-if="userIsOperator && item.fields.serviceProvider.de.fields">{{item.fields.serviceProvider.de.fields.title[selectedLocale]}}</td>
					<td>{{getFirstProductCategory(item)}}</td>
					<td>{{item.sys.createdAt.split("T")[0]}}</td>
					<td>
						<div style="display:inline-block" v-for="cai of item.fields.clientAssignments.de" :key="cai.sys.id">
							<span v-if="cai.fields && cai.fields.status && cai.fields.status.de"
								:class="{
									clientAssignmentChip: true,
									[cai.fields && cai.fields.status && cai.fields.status.de]: true,
								}"
								:title="cai.fields && cai.fields.status && cai.fields.status.de ? cai.fields.status.de : ''"
								>
								{{ getClientId(cai) }}
							</span>
						</div>
					</td>
					<td><Status :status="getClientStatus(item)" /></td>
				</tr>
			</template>

				</v-data-table>
				<v-card class="tableFooter">
					<v-card-actions>
						<TablePaginator v-model="offset" :limit="limit" :results="products" :total="productCount" @input="getProducts()" />
					</v-card-actions>
				</v-card>
			</v-card>
		</div>

		<!-- Service Type Selector -->
		<Dialog ref="serviceTypeDialog"
            :confirmLabel="$t('text.createService')"
            :cancelLabel="$t('text.cancel')"
            :confirm-handler="onServiceTypeConfirmed"
            :cancel-handler="onServiceTypeCancelled"
			:showClose="false"
			:isBeta="false"
            :title="$t('text.newService')">
			<template #content>
				<v-row justify="center">
					<p class="subTitle" v-html="$t('text.newServiceHelp')"/>
					<v-card v-for="(serviceType, index) in serviceTypes" :key="serviceType.sys.id" width="320px" height="350px" flat style="background-color:transparent">
						<ServiceTypeCard :serviceType="serviceType" :data-cy="'serviceType_' + index"/>
					</v-card>
				</v-row>
			</template>
		</Dialog>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import FreshdeskWidget from '@/utils/FreshdeskWidget.js'
import Status from '@/components/common/Status.vue'
import Alert from '@/components/common/Alert.vue'
import TablePaginator from '@/components/common/TablePaginator.vue'
import Common from '@/mixins/Common.vue'
import ServiceTypeCard from '@/components/serviceDesigner/ServiceTypeCard.vue'
import Dialog from '@/components/common/Dialog.vue'

export default {
	name: 'ServicesView',
	components: { Loading, Status, Alert, TablePaginator, Dialog, ServiceTypeCard },
	mixins: [ Common ],
	data() {
		return {
		offset: this.$store.state.offset,
		limit: 15,
		loading: false,
		fullPage: true,
		errorTitle: '',
		errorDetail: '',
		products: [],
		productCount: 0,
		searchSubmitted: false,
		serviceTypes: [],

		searchString: this.$store.state.searchString,
		searchSmCols: 2,
		showControls: true,

		statuses: [
		{id:'active', color:'green'},
		{id:'deactivated', color:'red'},
		{id:'pending', color:'orange'},
		{id:'archived', color:'grey'},
		{id:'expired', color:'#737373'},
		],

		filter: null,
		selectedStatuses: this.filter ? this.filter.statuses : [],
		validFromDate: this.filter ? this.filter.validFromDate : '',
		validToDate: this.filter ? this.filter.validToDate : '',
		showFromDate: false,
		showToDate: false,
		isOpened: false,
		isNegative: this.filter ? this.filter.isNegative : false,
		productCategories: {de:[]},
		selectedProductCategories: this.filter ? this.filter.productCategories : [],
		clients: [],
		selectedClients: this.filter ? this.filter.selectedClients : [],
		dropdownOpen: false
	}},
	computed: {
		headers() {
			let serviceTitleWidth = '50%'

			if (this.$store.state.loggedInUser.fields?.type?.de === 'operator' && this.$store.state.isImpersonation===false) {
				serviceTitleWidth = '30%'
			}

			let headers = [
				{ text: '', value: "list_icon", width: 45, sortable: false },
				{ text: this.$t('text.service'), value: "service", width: serviceTitleWidth, sortable: false }
			];
			if (this.$store.state.loggedInUser.fields?.type?.de === 'operator' && this.$store.state.isImpersonation===false) {
				// headers.push(
				//     { text: this.$t('text.service'), value: "service", width: "30%", sortable: false }
				// );
				headers.push(
					{ text: this.$t('text.serviceProvider'), value: "service_provider", width: "20%", sortable: false }
				);
			} else {
				//  headers.push(
				//     { text: this.$t('text.service'), value: "service", width: "50%", sortable: false }
				// );
				
			}
			headers.push(
				{ text: this.$t('text.serviceType'), value: "service_type", width: "15%", sortable: false },
				{ text: this.$t('text.createDate') , value: "create_date", width: "15%", sortable: false },
				{ text: '', value: "clients", width: "15%", sortable: false },
				{ text: this.$t('text.status'), value: "status", width: "5%", sortable: false }
			);

			return headers;
		},
		appIsServiceManager() {
			return this.$store.state.selectedComponent?.fields?.title?.en == 'Manage Services'
		},

		validFromText() {
			return this.validFromDate !== '' ? this.validFromDate : this.$t('text.validFrom')

		},
		validToText() {
			return this.validToDate !== '' ? this.validToDate : this.$t('text.validUntil')

		},
		notValidFromText() {
			return this.validFromDate !== '' ? this.validFromDate : 'Not valid from'
		},
		notValidToText() {
			return this.validToDate !== '' ? this.validToDate : 'Not valid to'
		},
	},
	created () {
		FreshdeskWidget.prefill('MyServices')

		if (!this.userIsOperator) {
			this.statuses.unshift({id:'saved', color:'blue'})
		}
	},
	async mounted() {
		await this.$store.commit('setSelectedServiceType', {})
		this.getProducts()

		await this.getProductCategories()
		await this.getMarketplaceClientsForClient()
	},

	methods: {
		hasListIcon(item) {
			return item?.fields?.serviceType?.de?.fields?.listIcon?.de
		},
		getFirstProductCategory(product) {
			let currentCA = product.fields.clientAssignments?.de?.find(x => x?.fields?.client?.de?.sys?.id === this.$store.state.selectedClient.sys.id)

			if (!currentCA) {
				//Service has clientAssignments other than the home client
				currentCA = product.fields.clientAssignments?.de?.[0]
			}
			
			return currentCA?.fields?.productCategories?.de?.[0]?.fields?.title?.[this.selectedLocale] ?? ''
		},
		getClientStatus(product) {
			let currentCA = product.fields.clientAssignments.de.find(x => x?.fields?.client?.de?.sys?.id === this.$store.state.selectedClient.sys.id)

			if (!currentCA) {
				//Service has clientAssignments other than the home client
				currentCA = product.fields.clientAssignments?.de?.[0]
			}

			return currentCA?.fields?.status?.de
		},
		getClientId(cai) {
			return cai.fields?.client?.de?.fields?.clientId?.de ? cai.fields.client.de.fields.clientId.de : ''
		},

		async showAddService() {
			await this.getServiceTypes()
		},
		showFAQs() {
			this.$router.push('/faq-list')
		},
		focusedSearch() {
			this.searchSmCols = 10;
			this.showControls = false;
		},
		blurredSearch() {
			this.searchSmCols = 2;
			this.showControls = true;
		},

		resetDates() {
			this.validToDate = ''
			this.showFromDate = false
		},
		applyFilter() {
			this.offset  = 0
			
			if (this.selectedStatuses.length > 0 || this.validFromDate.length > 0 || this.validToDate.length > 0 || this.selectedProductCategories.length > 0 || this.selectedClients.length > 0) {
				this.filter = {
					statuses: this.selectedStatuses,
					validFromDate: this.validFromDate,
					validToDate: this.validToDate,
					isNegative: this.isNegative,
					productCategories: this.selectedProductCategories,
					selectedClients: this.selectedClients
					}
				this.isOpened = false
				this.getProducts()
			}
			else {
				this.clearFilter()
			}
		},
		clearFilter() {
			this.isOpened = false
			this.selectedStatuses = []
			this.validFromDate = ''
			this.validToDate = ''
			this.isNegative = false
			this.selectedProductCategories = []
			this.selectedClients = []
			this.filter = null
			this.getProducts()
		},
		toggleDatePicker(pickerToToggle) {
			if (pickerToToggle === 'from') {
				this.showToDate = false
				this.showFromDate = true
			} else {
				this.showFromDate = false
				this.showToDate = true
			}
		},
		closeDropDown(ref) {
			if (!this.dropdownOpen) {
				this.$refs[ref].focus()
				this.dropdownOpen = true
			} else {
				this.$refs[ref].blur()
				this.dropdownOpen = false
			}
		},
		async getProductCategories() {
			try {
				this.productCategories = this.$store.state.selectedClient.fields.productCategories?.de ?? []

				if (this.productCategories?.length > 0) {
					this.productCategories.sort(this.compare)
					for (let productCategory of this.productCategories) {
						if (productCategory.fields?.subProductCategories?.de) {productCategory.fields.subProductCategories.de.sort(this.compare)}
					}
				}
			}
			catch (error) {
				this.showError(error)
			}
		},

		async getMarketplaceClientsForClient() {
			const client = this.$store.state.selectedClient

			let clients = []
			const clientSysId = client.sys.id
			let marketplaces = await this.$httpGet('/marketplace?clientSysId=' + clientSysId)
			let mp = marketplaces?.[0]
			let lookup = {}

			for (let client of mp.fields.externalClients.de) {
				clients.push(client)
				lookup[client.sys.id] = client
			}
			// add homeClient if it wasnt in the external clients
			if (!lookup[mp.fields.homeClient.de.sys.id])
				clients.push(mp.fields.homeClient.de)
				this.clients = clients
		},
		async sortTable(event) {
			let sortMode = event[0]
			await this.getProducts(sortMode)
		},
		async getProducts(sortMode) {
			await this.$store.commit('setOffset', this.offset)
			
			var url = ""

			if (this.appIsServiceManager) {
				if (this.userIsOperator) {
					url = `/products?`
				} else {
					url = `/products/${this.$store.state.selectedServiceProvider.sys.id}?`
				}
			} else {
				url = `/products/${this.$store.state.selectedServiceProvider.sys.id}?`
			}

			url += `clientId=${this.$store.state.selectedClient.sys.id}`

			if (this.$store.state.isImpersonation === true) {
				if (this.$store.state.selectedServiceProvider.fields.tags?.de) {
					url += `&tags=${this.$store.state.selectedServiceProvider.fields.tags.de}`
				}
			} else if (!this.$store.state.loggedInUser.fields.canSeeObjectsWithTags.de.find(x => x === "ALL")) {
				url += `&tags=${this.$store.state.loggedInUser.fields.canSeeObjectsWithTags.de}`
			}

			if (this.filter) {
				url += `&filter=${JSON.stringify(this.filter)}`
			}

			if (this.searchString?.length > 0) {
				url += `&searchString=${encodeURIComponent(this.searchString)}`
			}

			this.loading = true

			try {
				url += `&skip=${this.offset}&limit=${this.limit + 1}`
				const res = await this.$httpGet(url)
				this.loading = false
				this.products = res?.products
				this.productCount = res?.total ? res.total : 0
			}
			catch (error) {
				this.errorTitle = this.$t('text.ERROR')
				this.errorDetail = error.response ? error.response.error : error
			}
			this.loading = false
		},
		
		async getServiceDetail(item) {
			if (this.userIsOperator) {
				this.loading = true
				try {
					await this.$store.commit('setSelectedServiceProvider', item.fields.serviceProvider.de)
					await this.$store.commit('setSelectedProduct', { sys: { id: item.sys.id } })
					this.$router.push('/service-designer')
				}
				catch (error) {
					this.errorTitle = this.$t('text.ERROR');
					this.errorDetail = error.response ? error.response.error : error
				}
				this.loading = false
			}
			else {
				await this.$store.commit('setSelectedProduct', {sys:{id:item.sys.id}})
				this.$router.push('/service-designer')
			}
		},
		async search() {
			await this.$store.commit('setSearchString', this.searchString)
			
			if (this.searchString?.length > 0) {
				this.offset = 0
				this.getProducts()
			} else {
				this.clearSearch()
			}
		},
		async clear() {
			this.offset = 0
			this.searchString = ''
			await this.$store.commit('setSearchString', null)

			this.getProducts()
		},
		async getServiceTypes() {
			this.loading = true
			try {
				let url = `/servicetypes?skip=0&limit=100`

				let res = await this.$httpGet(url)
				this.serviceTypes = res.serviceTypes
				const defaultServiceType = this.serviceTypes.find(x => x.fields.default === true)

				await this.$store.commit('setSelectedServiceType', defaultServiceType)
				await this.$store.commit('setSelectedProduct', {})

				//Check if feature is enabled for client
				const client = this.$store.state.selectedClient

				if (client.fields.features?.de?.length > 0) {
					const serviceDesignerTemplateFeature = client.fields.features.de.find(x => x.id === 'service-designer-templates')

					if (serviceDesignerTemplateFeature) {
						this.isBeta = serviceDesignerTemplateFeature.status === 'beta'
						this.$refs.serviceTypeDialog.show = true
					} else {
						this.$router.push('/service-designer')
					}
				} else {
					this.$router.push('/service-designer')
				}
			}
			catch (error) {
				this.errorTitle = this.$t('text.ERROR')
				this.errorDetail = error.response?.error ?? error
			}
			this.loading = false
		},
		async onServiceTypeCancelled() {
			await this.$store.commit('setSelectedServiceType', {})
			this.$refs.serviceTypeDialog.show = false
		},
		async onServiceTypeConfirmed() {
			this.$refs.serviceTypeDialog.show = false
			await this.$store.commit('setSelectedProduct', {})
			this.$router.push('/service-designer')
		}
	}
}
</script>

<style scoped>
.dot-container { width: 75px; height: 75px; position: absolute; top: 0; right: 0; }
.stack-top { width: 14px; height: 14px; z-index: 9; margin: 0px; padding:0px; }
.dot { height: 14px; width: 14px; background-color: #ff0022; border-radius: 50%; display: inline-block; position: absolute; top: 0px; right: 5px; }
</style>
