<template>
  <v-container
      fluid
      class="pa-0"
      :style="isSideBarFixed ? 'padding-left: 380px !important;' : 'padding-left: 40px !important;'"
  >
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<loading v-if="isLoading" :active.sync="isLoading" :is-full-page="true" color="#4caf50"></loading>

    <!-- navbar -->
    <div class="navbar">
      <v-row align="center" class="flex-nowrap" style="white-space:nowrap; overflow:hidden;">
          <v-btn v-if="!isView" small elevation="0" class="gradientButton" @click="resetView()"><v-icon>mdi-chevron-left</v-icon></v-btn>
          <h1 v-if="isUpload" class="pl-2">{{$t('text.uploadFile')}}</h1>
          <v-spacer/>
        <template v-if="isView">
          <v-btn-toggle mandatory v-model="viewType">
            <v-btn id="btnList" class='btnList' elevation="0"><v-icon>mdi-format-list-bulleted</v-icon></v-btn>
            <v-btn id="btnGrid" class="btnGrid  active-btn" elevation="0"><v-icon>mdi-view-grid</v-icon></v-btn>
          </v-btn-toggle>
        </template>

      </v-row>
    </div>

    <!-- Floating Menu -->
    <SideBar ref="sidebar">
      <div :style="showImpersonationInfo ? 'padding-top: 24px !important;' : 'padding-top:0px'">
      <div>
        <p class="sidebarTitle">{{$t('text.actions')}}</p>
        <v-btn class="gradientButton mb-3" data-cy="uploadButtonGrey" elevation="0" block v-if="isView" @click="showUpload()"><v-icon>mdi-plus</v-icon> {{$t('text.uploadFile')}}</v-btn>
        <v-btn class="gradientButton mb-3 red--text" data-cy="deleteButton" block elevation="0" v-if="isView&&selectedMedia.length>0" @click="deleteMedia()"><v-icon color="#f34545">mdi-delete</v-icon> {{deleteLabel}}</v-btn>
        <v-btn class="greenButton mb-3" elevation="0" data-cy="uploadButtonGreen" block dark :disabled="isDisabledUpload" v-if="isUpload" @click="uploadMedia()"><v-icon color="#ffffff">mdi-arrow-up-circle</v-icon> {{$t('text.uploadFile')}}</v-btn>
        <v-btn class="gradientButton mb-3" elevation="0" data-cy="saveChanges" block v-if="isUpdate" @click="updateMedia()"><v-icon>mdi-check</v-icon>{{$t('text.saveChanges')}}</v-btn>
      </div>
      <br/>
      <div v-if="!isView">
        <ul id="navlist">
          <div>
            <v-select outlined dense required hide-details
              class="mb-3"
              v-model="serviceLocale"
              :items="locales"
              :item-text="locale => $t('text.' + locale.name)"
              :item-value="locale => locale.code"
            ></v-select>
          </div>
        </ul>
      </div>
      </div>
    </SideBar>

    <div :style="showImpersonationInfo ? 'width:100%; padding-top: 24px !important;' : 'width;100%; padding-top:0px'">
      <!-- Content -->
      <div class="container-grid" v-if="isView">
        <Pagination v-if="viewType===1" :items="providerMedia" :no-data-text="$t('text.noImages')">
          <template v-slot:item="{ item }">
            <v-card flat style="background-color:transparent">
              <MediaCard :media="item" :canEdit="true" :canSelect="true" :showTitle="true" @checkbox-change="setSelectedMedia" @show-media-update="showUpdate(item)"/>
            </v-card>
          </template>
        </Pagination>

        <v-card class="mb-14 tableCard" v-if="viewType===0">
          <v-data-table fixed-header hide-default-footer disable-sort disable-pagination
                        v-model="selected"
                        :headers="headers"
                        :items="providerMedia"
                        :items-per-page="10"
                        :no-data-text="$t('text.noImages')"
                        class="elevation-0">
            <template v-slot:item.checkbox="{ item }">
              <v-checkbox v-model="item.checked"></v-checkbox>
            </template>
            <template v-slot:item.thumbnail="{ item }">
              <v-img width="60px" height="60px" :src="item && item.fields && item.fields.media && item.fields.media.de && item.fields.media.de.fields && item.fields.media.de.fields.file && item.fields.media.de.fields.file.de && item.fields.media.de.fields.file.de.url ? item.fields.media.de.fields.file.de.url : ''"></v-img>
            </template>
            <template v-slot:item.title="{ item }">
              <span v-if="item.fields.title">{{ item.fields.title[serviceLocale] }}</span>
              <span v-if="!item.fields.title">{{ item.sys.id }}</span>
            </template>
            <template v-slot:item.description="{ item }">
              <span v-if="item.fields.description">{{ item.fields.description[serviceLocale] }}</span>
            </template>
            <template v-slot:item.dimensions="{ item }">
              <span v-if="item.fields.media.de.fields.file.de.details && item.fields.media.de.fields.file.de.details.image">{{item.fields.media.de.fields.file.de.details.image.width}}x{{item.fields.media.de.fields.file.de.details.image.height}}</span>
            </template>
            <template v-slot:item.created_at="{ item }">
              {{item.sys.createdAt.replace("T", " ")}}
            </template>
            <template v-slot:item.edit="{ item }">
              <v-btn icon @click="showUpdate(item)">
                <v-icon>mdi-circle-edit-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </div>

      <!-- Update Image -->
      <v-col class="col-lg-8 mb-16" v-if="isUpdate">
        <Disclosure class="disclosure" :title="$t('text.editImage')" :expanded="true">
          <div style="padding-bottom: 70px;">
            <v-row justify="center">
              <v-col>
                <div class="field left-border">
                  <p class="sidebarTitle">{{$t('text.copyRightInfo')}}</p>
                  <v-label>{{$t('text.copyRightInfoDesc')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
                  <v-text-field outlined dense
                                id="copyrightOwner"
                                autocomplete="off"
                                :error-messages="copyrightOwnerError"
                                counter="256" maxLength="256"
                                v-model="copyrightOwner"></v-text-field>

                  <v-checkbox
                      id="usageConsent"
                      :label="$t('text.usageConsent')"
                      :error-messages="copyrightError"
                      v-model="usageConsent"/>
                </div>
                <br/>

                <template v-if="selectedMedia">
                  <div class="field left-border">
                    <MediaItem ref="updateMediaItemRef" :mediaAsset="selectedMediaAsset" :key="renderKey"></MediaItem>
                  </div>
                </template>
              </v-col>
            </v-row>
          </div>
        </Disclosure>
      </v-col>

      <!-- Upload Image -->
      <v-col class="col-lg-8 mb-16" v-if="isUpload">
        <Disclosure class="disclosure" :title="$t('text.uploadNewFile')" :expanded="true">
          <div style="padding-bottom: 70px;">
            <v-row justify="center">
              <v-col>
                <v-row align="center">
                  <v-col>
                    <p v-html="$t('text.uploadFileDesc')"/>
                  </v-col>
                </v-row>

                  <div class="field left-border">
                    <p>{{$t('text.chooseFile')}} <span class="error-text">({{$t('text.required')}})</span></p>
                    <v-row>
                      <FileUpload :options="dropOptions" ref="fileUpload" data-cy="fileUpload" @thumbnail-created="onThumbnailCreated" @file-removed="onFileRemoved"/>
                    </v-row>
                  </div><br/>

                <div class="field left-border">
                  <p class="sidebarTitle">{{$t('text.copyRightInfo')}}</p>
                  <v-label>{{$t('text.copyRightInfoDesc')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
                  <v-text-field outlined dense
                                id="copyrightOwner"
                                data-cy="copyrightOwner"
                                autocomplete="off"
                                :error-messages="copyrightOwnerError"
                                counter="256" maxLength="256"
                                v-model="copyrightOwner"></v-text-field>

                  <v-checkbox
                      id="usageConsent"
                      data-cy="usageConsent"
                      :label="$t('text.usageConsent')"
                      :error-messages="copyrightError"
                      v-model="usageConsent"/>
                </div><br/>

                <div v-if="mediaAssets.length > 0">
                  <div v-for="mediaAsset of mediaAssets" :key="mediaAsset.renderKey" class="field left-border">
                    <MediaItem :mediaAsset="mediaAsset"></MediaItem>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </Disclosure>
      </v-col>
    </div>
  </v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import MediaCard from '@/components/media/MediaCard'
import Alert from '@/components/common/Alert.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import MediaItem from './MediaItem.vue'
import Common from '@/mixins/Common.vue'

import SideBar from "@/components/common/Sidebar"
import Pagination from "@/components/common/Pagination"
import FileUpload from "@/components/media/FileUpload"


export default {
  name: 'MediaGallery',
  components: {FileUpload, Pagination, SideBar, Loading, MediaCard, Alert, Disclosure, MediaItem },
  mixins: [ Common ],
  data () {
    return {
      isLoading: false,
      selected: [],
      
      errorTitle: '',
      errorDetail: '',
      successTitle: '',
      successDetail: '',

      errorAlertVisible: false, //?
      successAlertVisible: false, //?

      deleteLabel: this.$t('text.deleteImages'),

      serviceProvider: {},
      media: [],
      selectedMediaAsset: {},
      uploadedFile: {},
      
      copyrightOwner: '',
      files: [],
      selectedFiles: [],
      usageConsent: false,
      urlToLink: '',
      renderKey: 1,

      providerMedia: [],

      viewType: 1,
      isView: true,
      isUpdate: false,
      isUpload: false,
      selectedMedia: [],
      copyrightError : '',
      copyrightOwnerError: '',

      mediaAssets: [],

      dropOptions: {
        acceptedFiles: '.jpg, .jpeg',
        addRemoveLinks: true,
        maxFilesize: 10
      },
      
    }
  },

  computed: {
    headers() {
			return [
				{ text: '', value: "checkbox", width:'30px' },
				{ text: '', value: "thumbnail", width:'60px' },
				{ text: this.$t('text.title'), value: "title", width: '30%', cellClass: 'truncate' },
        { text: this.$t('text.description'), value: "description", width: '30%', cellClass: 'truncate', align: " d-none d-lg-table-cell" },
        { text: this.$t('text.dimensions'), value: "dimensions", width:'10%', align: " d-none d-lg-table-cell" },
        { text: this.$t('text.createdAt'), value: "created_at", width:'20%' },
        { text: '', value: "edit", align: "center", width:'35px' },
			]
		},
    isDisabledUpload() {
      return this.mediaAssets.length === 0 && this.urlToLink === "";
    },
    errorMessages() {
      return ' <span style="font-size:14pt;font-weight:bold;"> ' + this.errorTitle + ' </span><br> ' + this.errorDetail;
    },
    successMessages() {
      return ' <span style="font-size:14pt;font-weight:bold;"> ' + this.successTitle + ' </span><br> ' + this.successDetail;
    },
  },

  watch: {
    providerMedia: {
      deep: true,
			handler() {
        this.setSelectedMedia()
			},
    },
    errorAlertVisible() {
      if (this.errorAlertVisible) {
        setTimeout(this.closeAlert, 5000);
      }
    },
    successAlertVisible() {
      if (this.successAlertVisible) {
        setTimeout(this.closeAlert, 3000);
      }
    }
  },

  mounted() {
    this.copyrightError = ''
    this.copyrightOwnerError = ''

    this.isView = true;

    this.getMedia()

    this.$root.$on('remove-media-file', (file) => {
      this.$refs.fileUpload.dropzone.removeFile(file)
		})

    this.$root.$on('uploaded-media-file', (file) => {
			this.selectedMediaAsset["imgData"] = file
		})
  },

  beforeDestroy () {
    this.$root.$off('remove-media-file')
    this.$root.$off('uploaded-media-file')
  },
  methods: {
    onThumbnailCreated(file) {
      this.mediaAssets.push({
        sys: {
          id: file.upload.uuid
        },
        fields:{
          title: {},
          altText: {},
          description: {},
          file: file
        }
      })
    },
    onFileRemoved(file) {
      for (let i = 0; i < this.mediaAssets.length; i++) {
        if (this.mediaAssets[i].fields.file.upload.filename === file.upload.filename && this.mediaAssets[i].fields.file.upload.total === file.upload.total) {
          this.mediaAssets.splice(i,1)
          break
        }
      }
    },
    resetView() {
      this.isView=true
      this.isUpload=false
      this.isUpdate=false
      this.copyrightOwner = ''
      this.usageConsent = false
    },
    setSelectedMedia() {
      this.selectedMedia = []

      for (const media of this.providerMedia) {
        if (media.checked) {
          this.selectedMedia.push(media.sys.id)
        }
      }

      this.deleteLabel = this.$t('text.deleteImages').replace("%n%", this.selectedMedia.length)
    },
    closeAlert() {
      if (this.errorAlertVisible) {
        this.errorAlertVisible = false;
      } else if (this.successAlertVisible) {
        this.successAlertVisible = false;
      }
    },
    showUpload() {
      this.isView=false
      this.isUpload=true
      this.mediaAssets = []
      this.onlyJpegSupportedError = false
      this.$refs.sidebar.closeSidebar();
    },
    showUpdate(item) {
        this.isView = false
        this.isUpload = false
        this.isUpdate = true
        this.selectedMediaAsset = item
        this.selectedMediaAsset.fields["altText"] = this.selectedMediaAsset.fields.altText ?  this.selectedMediaAsset.fields.altText : {de: ''}
        this.selectedMediaAsset.fields["description"] = this.selectedMediaAsset.fields.description ?  this.selectedMediaAsset.fields.description : {de: ''}
        
        this.copyrightOwner = item.fields.copyright.de
        this.usageConsent = item.fields.usageConsent.de
        
        this.copyrightError = ''
        this.copyrightOwnerError = ''
    },
    getMedia() {
      if (!this.$store.state.selectedServiceProvider) {
        this.errorAlertVisible = true
        this.errorTitle = 'ERROR'
        this.errorDetail = 'This app is only for service Providers!'
        return
      }

      this.isLoading = true;
      this.$httpGet(`/media/${this.$store.state.selectedServiceProvider.sys.id}`).then(res => {
        this.isLoading = false;

        if (res.media) {
          for (let media of res.media) {
            media["checked"] = false
          }
        }

        this.providerMedia = res.media

        this.resetView()

      }).catch(error => {
        this.isLoading = false;

        if (error.response && error.response.status === 401) {
          this.$emit("show-login")
        }

        this.errorTitle = this.$t('text.ERROR');

        if (error.response) {
          this.errorDetail = error.response.data.error;
        } else {
          this.errorDetail = error;
        }
      });
    },
    uploadMedia() {
      const isValid = this.validate()

      if (isValid) {
        this.isLoading = true;

        let data = {
          mediaAssets: this.mediaAssets,
          url: this.urlToLink,
          serviceProviderId: this.$store.state.selectedServiceProvider.sys.id,
          serviceProviderName: this.$store.state.selectedServiceProvider.fields.title.de,
          title: this.title ? this.title : "MyServices Media Upload",
          copyrightOwner: this.copyrightOwner,
          usageConsent: this.usageConsent
        }

        this.$httpPost('/media', data).then(res => {
          this.urlToLink = ""
          this.title = ""
          this.copyrightOwner = ""
          this.usageConsent = false
          this.copyrightError = ""
          this.copyrightOwnerError = ''
          this.getMedia()
        }).catch(error => {
          this.isLoading = false;

          if (error.response && error.response.status === 401) {
            this.$emit("show-login")
          }

          this.errorTitle = this.$t('text.ERROR');
          this.errorDetail = this.$t('text.uploadError');
        })
      }
    },
    deleteMedia() {
      this.isLoading = true;

      this.axios.delete(`/media`, 
      {
         headers: this.requestHeaders(),
         data: {
           selectedMedia: this.selectedMedia,
           serviceProviderId: this.$store.state.selectedServiceProvider.sys.id
         } 
      }
      ).then(res => {
        this.isLoading = false;

        this.getMedia();
        this.selectedMedia = [];

      }).catch(error => {
        this.isLoading = false;

        this.errorTitle = this.$t('text.ERROR');

        if (error.response) {
          if (error.response.data.error==='Linked to Entries Error') {
            this.errorDetail = this.$t('text.deleteMediaLinkedError');
          } else {
            this.errorDetail = error.response.data.error;
          }
        } else {
          this.errorDetail = error;
        }
      });
    },
    async updateMedia() {
      this.selectedMediaAsset.fields.title[this.serviceLocale] = this.$refs.updateMediaItemRef.mediaAssetTitleModel
      this.selectedMediaAsset.fields.altText[this.serviceLocale] = this.$refs.updateMediaItemRef.mediaAssetAltTextModel
      this.selectedMediaAsset.fields.description[this.serviceLocale] = this.$refs.updateMediaItemRef.mediaAssetDescriptionModel
      const isValid = this.validate(this.selectedMediaAsset)

      if (isValid) {
        this.isLoading = true
        var data = {
          mediaAsset: this.selectedMediaAsset,
          imageData: this.$refs.updateMediaItemRef.imgData,
          serviceProviderId: this.$store.state.selectedServiceProvider.sys.id,
          copyrightOwner: this.copyrightOwner,
          usageConsent: this.usageConsent
        }

        const res = await this.$httpPut('/media', data)
        this.getMedia()
      }
    },
    validate(selectedMediaAsset) {
      let isValid = true

      if (this.usageConsent === false || this.copyrightOwner.length === 0) {
          isValid = false
      }

      //Update Media Validation
      if (selectedMediaAsset) {
        if (selectedMediaAsset.fields.title[this.serviceLocale].length === 0) {
          this.renderKey = this.renderKey+1
          selectedMediaAsset.renderKey = this.renderKey
          selectedMediaAsset.titleError = this.$t('text.titleRequiredError')
          isValid = false
        }
      }

      //New Media Validation
      for (let mediaAsset of this.mediaAssets) {
        if (Object.keys(mediaAsset.fields.title).length === 0) {
          this.renderKey = this.renderKey+1
          mediaAsset.renderKey = this.renderKey
          mediaAsset.titleError = this.$t('text.titleRequiredError')
          isValid = false
        }
      }

      this.copyrightError = this.usageConsent === false ? this.$t('text.copyrightError') : ''
      this.copyrightOwnerError = this.copyrightOwner.length === 0 ? ' ' :  ''

      return isValid
    },
    showError(error) {
			this.loading = false
			const detail = error?.response?.data?.error ?? error
			
			this.errorTitle = this.$t('text.ERROR')
			this.errorDetail = detail
		},
  }
}
</script>

<style scoped lang="scss">
.container-grid { padding: 20px; }
a { text-decoration: none;}

.dz-max-files-reached {
  pointer-events: none;
  cursor: default;
}

.tableCard span {
  @media (max-width: 600px) {
    white-space: initial;
  }
}
.serviceLocale { position: absolute; z-index: 10; margin-top: 32px; margin-left: -32px; zoom: 0.8; }

</style>
