<template>
	<v-container
      fluid
      class="pa-0 mb-12"
      :style="isSideBarFixed ? 'padding-left: 380px !important;' : 'padding-left: 40px !important;'"
  >
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<loading v-if="loading" :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

		<!-- navbar -->
		<div class="navbar">
			<v-row align="center" class="flex-nowrap" style="white-space:nowrap; overflow:hidden;">
				<v-text-field outlined dense clearable hide-details height="48"
				prepend-inner-icon="mdi-magnify"
				:placeholder="$t('text.searchCategoryOrSubcategory')"
				style="max-width:33%" 
				v-model="searchString" 
				@keyup.enter="offset=0;search()"
				@click:clear="clearSearch()"
            	data-cy="search"
			/>
			<v-spacer/>
			<v-row justify="end">
				<v-btn class="gradientButton" elevation="0" data-cy="addNew" @click="showNewCategory()"><v-icon>mdi-plus</v-icon>{{$t('text.addMainCategory')}}</v-btn><br/>
			</v-row>
			</v-row>
		</div>


		<SideBar>
			<ul id="navlist">
				<a v-for="state of states" :key="state" @click="changeState(state)">
				<li @click="setActive(state)" :class="{ active: state == activeLink }">
					{{ $t('text.' + state) }}
				</li>
				</a>
			</ul>
		</SideBar>

		<!-- Product Category List -->
		<div class="pa-3">
			<v-card class="tableCard">
				<v-data-table fixed-header hide-default-footer disable-sort
					:headers="headers"
					:items="productCategories"
					:items-per-page="limit"
					class="elevation-0"
					style="border-radius:0">

				<template v-slot:item="{ item }">
					<tr @click="showCategoryDetails(item)">
					<td>{{ item.fields.title[selectedLocale] }}</td>
					<td align="right">
						<span v-if="item.fields.subProductCategories && item.fields.subProductCategories.de">
							{{ item.fields.subProductCategories.de.length }}
						</span>
						<span v-if="!item.fields.subProductCategories || !item.fields.subProductCategories.de">
							0
						</span>
					</td>
					</tr>
				</template>
				</v-data-table>

				<v-card class="tableFooter">
					<v-card-actions>
						<TablePaginator v-model="offset" :limit="limit" :total="categoryCount" @input="getProductCategories()" :results="productCategories" />
					</v-card-actions>
				</v-card>
			</v-card>
		</div>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Common from '@/mixins/Common.vue'
import SettingsMixin from '@/mixins/SettingsMixin.vue'
import SideBar from "@/components/common/Sidebar"
import Alert from '@/components/common/Alert.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import LanguageFlag from '@/components/common/LanguageFlag.vue'
import TablePaginator from '@/components/common/TablePaginator.vue'


export default {
	name: "ProductCategoryView",
	mixins: [ Common, SettingsMixin ],
	components: { Loading, SideBar, Alert, Disclosure, LanguageFlag, TablePaginator },

	data() {
		return {
			loading: false,
			errorTitle: '',
			errorDetail: '',
			productCategories: [],
			searchString: '',
			categoryCount: 0,
			allCategories: [],
		}
	},

	computed: {
		headers() {
			return [
				{
					text: this.$t('text.category'),
					align: 'left',
					sortable: true,
					value: 'item_type'
				},
				{ text: this.$t('text.subCategoriesAvailable'), width:'100px', align:"right" }
			]
		},
	},

	async mounted() {
		this.state = 'categories'
		this.activeLink = 'categories'
		this.getProductCategories()
	},

	methods: {
		search() {
			// filter out categories based on the category title or the sub-category title containing the search string
			this.productCategories = this.allCategories.filter(category => {
				if (category.fields.title[this.selectedLocale].toLowerCase().includes(this.searchString.toLowerCase())) {
					return true
				}
				if (category.fields.subProductCategories?.de) {
					for (let subCategory of category.fields.subProductCategories.de) {
						if (subCategory.fields?.title?.[this.selectedLocale]?.toLowerCase().includes(this.searchString.toLowerCase())) {
							return true
						}
					}
				}
				return false
			})
			this.categoryCount = this.productCategories.length
		},
		clearSearch() {
			this.searchString = ''
			this.getProductCategories()
		},
		showNewCategory() {
			this.$router.push(`/productCategoryDetail`)
		},
		showCategoryDetails(item) {
			this.$router.push(`/productCategoryDetail?categoryId=${item.sys.id}`)
		},
		async getProductCategories() {
			let categories = this.$store.state.selectedClient.fields.productCategories?.de?.length ? this.$store.state.selectedClient.fields.productCategories.de : []
			
			if (categories?.length) {
				this.productCategories = categories.filter(x => x?.fields?.mainCategory?.de === true)
				this.allCategories = this.productCategories
				this.categoryCount = this.productCategories.length
				let start = this.offset
				let end = this.offset + this.limit
				this.productCategories = this.productCategories.slice(start, end)
				this.productCategories.sort(this.compare)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.btn-align { margin-left:10px; margin-top:-10px; height:56px !important}
.btn-spacing { margin-left: 70px }
.v-menu__content {
	min-width: 200px !important;
	max-width: 200px !important;
	width: 200px !important;
	right: 30px !important;
	left: calc(100% - 235px) !important;
}
</style>