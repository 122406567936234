<template>
	<v-row justify="center">
		<Disclosure :title="$t('text.linkServiceProvidersTitle')" :lock="!userMayEditFields" data-cy="linkServiceProviders" ref="SECTION_linkServiceProviders">
			<p class="subTitle" v-html="$t('text.linkServiceProvidersText')"/>
			<v-row class="field left-border" align="center">
				<v-text-field outlined clearable hide-details
					prepend-inner-icon="mdi-magnify"
					placeholder='Search'
					style="max-width: 35%;"
					class="mr-2 mt-0"
					v-model="searchString"
					@click:clear="clear()"
					@keyup.enter="search()"
				/>
				<v-btn class="gradientButton" elevation="0" @click="search()">{{$t('text.search')}}</v-btn>
			</v-row>
			<div class="field left-border" style="display: flex">
				<v-btn class="gradientButton" elevation="0" style="margin-right:10px" :disabled="!unselected" @click="unlinkServiceProviders()"><v-icon>mdi-link-variant-off</v-icon> {{$t('text.unlinkServiceProviders')}}</v-btn>
				<v-btn class="gradientButton" elevation="0" :disabled="!selected" @click="linkServiceProviders()"><v-icon>mdi-link-variant</v-icon> {{$t('text.linkServiceProviders')}}</v-btn>
			</div>
			<!-- Search Service Providers -->
			<div class="field left-border" v-if="searchServiceProviders.length > 0">
				<p class="subTitle" v-html="$t('text.searchResults')"/>
				<v-row v-for="serviceProvider in searchServiceProviders" :key="serviceProvider.sys.id">
					<div @click="checkSelected" style="display: flex" v-if="serviceProvider&&serviceProvider.fields&&serviceProvider.fields.title&&serviceProvider.fields.title.de">
						<v-checkbox v-model="serviceProvider.selected" :label="serviceProvider.fields.title.de" />
					</div>
				</v-row>
			</div>
			<!-- Linked Service Providers -->
			<div class="field left-border" v-if="selectedServiceProviders.length > 0">
				<p class="subTitle" v-html="$t('text.linkedServiceProviders')"/>
				<v-row v-for="serviceProvider in selectedServiceProviders" :key="serviceProvider.sys.id">
					<div @click="checkUnselected" style="display: flex">
						<v-checkbox v-model="serviceProvider.unselected" :label="serviceProvider.fields.title.de" />
					</div>
				</v-row>
			</div>
		</Disclosure>
	</v-row>
</template>

<script>
	import Common from '@/mixins/Common'
	import Disclosure from '@/components/common/Disclosure'
	import { isEqual } from 'lodash'

	export default {
		name: "ServiceProviderLink",
		components: {
		Disclosure
		},
		mixins: [Common],
		
		props: {
			serviceProvider: {
				type: Object,
				required: true
			},
			updateModel: Boolean
		},
		data() {
			return {
				model: {},
				initData: {},
				sectionMessage: {
				error: false,
				message: ''
				},
				searchString: '',
				searchServiceProviders: [],
				// selectedServiceProviders is used as a ref in the parent component
				selectedServiceProviders: [],
				selected: false,
				unselected: false,
			}
		},
		watch: {
			updateModel() {
				this.model = this.valueToModel(this.serviceProvider)
			
				if (this.model.fields.linkedServiceProviders?.de?.length > 0) {
					this.selectedServiceProviders = this.model.fields.linkedServiceProviders.de
					if (this.selectedServiceProviders) {this.selectedServiceProviders.sort(this.compare)}
				}

				this.setInitData()
			}
		},
		created() {
			this.model = this.valueToModel(this.serviceProvider)
			
			if (this.model.fields.linkedServiceProviders?.de?.length > 0) {
				this.selectedServiceProviders = this.model.fields.linkedServiceProviders.de
				if (this.selectedServiceProviders) {this.selectedServiceProviders.sort(this.compare)}
			}

			this.setInitData()
		},
		methods: {
			valueToModel(v) {
				return JSON.parse(JSON.stringify(v ?? {}))
			},
			setInitData() {
				this.initData = {
					selectedServiceProviders: JSON.parse(JSON.stringify(this.selectedServiceProviders))
				}
			},
			sendData() {
				const data = {
					selectedServiceProviders: this.selectedServiceProviders
				}

				data.changed = !isEqual(data, this.initData)
				return data
			},
			linkServiceProviders() {
				for (var serviceProvider of this.searchServiceProviders) {
					if (serviceProvider.selected) {
					this.selectedServiceProviders.push(serviceProvider)
					}
				}

				this.searchServiceProviders = [];
				this.searchString = "";
				this.checkSelected();
			},
			unlinkServiceProviders() {
				for (let i = this.selectedServiceProviders.length - 1; i >= 0; i--) {
					if (this.selectedServiceProviders[i].unselected) {
						this.selectedServiceProviders.splice(i, 1)
					}
				}
				this.checkUnselected();
			},
			checkSelected() {
				this.selected = false;

				for (var serviceProvider of this.searchServiceProviders) {
					if (serviceProvider.selected) {
					this.selected = true;
					break;
					}
				}
			},
			checkUnselected() {
				this.unselected = false;

				for (var serviceProvider of this.selectedServiceProviders) {
					if (serviceProvider.unselected) {
					this.unselected = true;
					break;
					}
				}
			},
			clear() {
				this.searchServiceProviders = []
			},
			async search() {
				this.$emit('loading', true)
				try {
					this.searchString = this.searchString ? this.searchString : "" //Set searchString to empty string if it is null (it is null after Search field is cleared)

					const res = await this.$httpGet(`/search?client=${this.$store.state.selectedClient.sys.id}&contentType=serviceProvider&searchString=${this.searchString}&homebaseOnly=true`)

					this.searchServiceProviders = res.serviceProviders
					if (this.searchServiceProviders) {this.searchServiceProviders.sort(this.compare)}

					//MYS-1036: Remove current service provider from search results
					let spIndex = -1

					if (res.serviceProviders && res.serviceProviders.length > 0) {
						spIndex = res.serviceProviders.findIndex(x => x.sys.id === this.serviceProvider.sys.id);

						if (spIndex > -1) {
							this.searchServiceProviders.splice(spIndex, 1)
						}

						//MYS-1258: Prevent the same SP being linked multiple times
						if (this.selectedServiceProviders && this.selectedServiceProviders.length > 0) {
							for (let selectedSP of this.selectedServiceProviders) {
								spIndex = res.serviceProviders.findIndex(x => x.sys.id === selectedSP.sys.id);
								if (spIndex > -1) {
									this.searchServiceProviders.splice(spIndex, 1)
								}
							}
						}
					}
				}
				catch (error) {
					console.log(error)
					// if (error.response?.status === 401)
					// 	return this.$emit("show-login")
					// 	this.showError(error.response ? error.response?.data.error : error)
				}
				this.$emit('loading', false)
			},
		}
		
	}
</script>

<style scoped>

</style>

	