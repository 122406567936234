<template>
	<v-row justify="center">
		<Disclosure :title="$t('text.trustLevelTitle')" :lock="!userMayEditFields" ref="SECTION_trustLevel">
			<p class="subTitle" v-html="$t('text.trustLevelDesc')"/>
			<div class="field left-border">
				<v-radio-group
					v-model="model.fields.trustLevel.de"
					hide-details>
					<div style="margin-bottom:15px;padding-bottom:15px">
						<v-radio :value="1" :label="levelName('1')" @click="setTrustLevel(1)"></v-radio>
						<p class="helpText" style="padding-left:30px" v-html="$t('text.trustLevel1Desc')"/>
					</div>

					<div style="margin-bottom:15px;padding-bottom:15px">
						<v-radio :value="2" :label="levelName('2')" @click="setTrustLevel(2)"></v-radio>
						<p class="helpText" style="padding-left:30px" v-html="$t('text.trustLevel2Desc')"/>
					</div>

					<div>
						<v-radio :value="3" :label="levelName('3')" @click="setTrustLevel(3)"></v-radio>
						<p class="helpText" style="padding-left:30px" v-html="$t('text.trustLevel3Desc')"/>
					</div>
				</v-radio-group>
			</div>
		</Disclosure>
	</v-row>
</template>

<script>
	import Common from '@/mixins/Common'
	import Disclosure from '@/components/common/Disclosure'
	import { isEqual } from 'lodash'
	
	export default {
	  name: "TrustLevel",
	  components: {
		Disclosure
	  },
	  mixins: [Common],
	 
	  props: {
		serviceProvider: {
		  type: Object,
		  required: true
		},
		updateModel: Boolean
	  },
	  data() {
		return {
		  model: {},
		  initData: {},
		  sectionMessage: {
			error: false,
			message: ''
		  },
		}
	  },
	  watch: {
		updateModel() {
			this.setInitData()
		}
	  },
	  mounted() {
		this.setInitData()
	  },
	  methods: {
		valueToModel(v) {
			return JSON.parse(JSON.stringify(v ?? {}))
		},
		setInitData() {
			this.initData = {trustLevel: JSON.parse(JSON.stringify(this.model.fields.trustLevel))}
		},
		sendData() {
			let data = {
				trustLevel: this.model.fields.trustLevel
			}

			data.changed = !isEqual(data, this.initData)
      		return data
		},
		levelName(level) {
			return this.$t('text.level') + ' ' + level
		},
		setTrustLevel(val) {
			this.model.fields.trustLevel.de = val
		},
		
	  },

	  created() {
		this.model = this.valueToModel(this.serviceProvider)
	  }
	}
	</script>
	
	<style scoped>
	
	</style>
	
	